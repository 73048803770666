import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./IssueList.scss";
import { Loader } from "../custom-loader";
import { apiService } from "../../services";
import { notify } from "../../common/utils";
import CustomButton from "../custom-button";
import constants from "../../constants/constants";
import MainHeader from "../common/MainHeader";

function IssueList() {
    const navigate = useNavigate();
    const { orderId } = useParams();
    const [orderDetails, setOrderDetails] = useState({});
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (orderId) {
            apiService
                .getOrder(orderId)
                .then((res) => {
                    setOrderDetails(res);
                })
                .catch((err) => {
                    notify(err.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    const handleNavigate = (issueType) => {
        navigate(
            `/${constants.ROUTES.ORDER}/${orderId}/${constants.ROUTES.ISSUE}/${issueType}`,
        );
    };

    return (
        <>
            {isLoading && <Loader />}
            <div className="issue-list">
                <MainHeader
                    name={"Report Issue"}
                    showBackButton={true}
                    showBottomLine={true}
                />
                <div className="your-order-container your-order-header-info box-container">
                    <h2 className="order-place-title">
                        {orderDetails?.vendor?.displayName}
                    </h2>
                    <div className="your-order-order-info">
                        <h2 className="order-number">
                            Order ID: {orderDetails.orderNum}
                        </h2>
                        {orderDetails?.orderReferenceNumber ? (
                            <h2 className="order-number">
                                Order Reference:{" "}
                                {orderDetails.orderReferenceNumber}
                            </h2>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <hr className="m-0" />
                <div className="box-container issue-list-container">
                    {orderDetails.status === "COMPLETED" ? (
                        <CustomButton
                            className="btn-block issue-button-type"
                            onClick={() => handleNavigate("food")}
                        >
                            Food
                        </CustomButton>
                    ) : null}
                    <CustomButton
                        className="btn-block issue-button-type"
                        onClick={() => handleNavigate("payment")}
                    >
                        Payment
                    </CustomButton>
                    <CustomButton
                        className="btn-block issue-button-type"
                        onClick={() => handleNavigate("technical")}
                    >
                        Technical
                    </CustomButton>
                </div>
            </div>
        </>
    );
}

export default IssueList;
