import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { setGuestSession } from "../../store/actions/UserAction";
import CustomButton from "../custom-button";
import "./landingauth.scss";

const LandingAuth = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div className="form-container landing-auth">
            <CustomButton
                type="button"
                onClick={() =>
                    navigate("/login", {
                        state: { redirectURL: window.location.href },
                    })
                }
            >
                Sign In
            </CustomButton>
            <div className="form-item-center">Or</div>
            <CustomButton
                type="button"
                onClick={() => {
                    props.setGuestSession("guest");
                    navigate(`?authType=guest`, { state: location.state });
                }}
                className="btn-outline"
            >
                Continue as Guest
            </CustomButton>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        authType: state.authType,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setGuestSession: (authType) => dispatch(setGuestSession(authType)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingAuth);
