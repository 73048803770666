import { useState } from "react";
import {
    AUTOMATED_PROMO_PROMOTION,
    PROMO_CODE_PROMOTION,
} from "../../common/utils";
import CustomButton from "../custom-button";

export const PromoCodeInput = ({ promotion, handlePromoCodeInput }) => {
    const [promoCode, setPromoCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const pattern = /^[a-zA-Z0-9]+$/;

    if (
        promotion &&
        promotion?.promotionType === PROMO_CODE_PROMOTION &&
        Object.keys(promotion).length
    ) {
        return <></>;
    }
    const handleOnClick = () => {
        if (pattern.test(promoCode)) {
            setErrorMessage("");
            handlePromoCodeInput(promoCode);
            setPromoCode("");
        } else {
            setErrorMessage(
                "Min 1 character, Only alphabets and numbers are allowed",
            );
        }
    };

    return (
        <div>
            <div className="separator separator-theme"></div>
            <h4>DO YOU HAVE A PROMO CODE?</h4>
            <div className="row mb-5">
                <div className="checkout-input-group">
                    <input
                        type="text"
                        name="name"
                        placeholder={"Insert your promo code"}
                        className={"promotion-input"}
                        value={
                            promotion?.promotionType === promoCode
                                ? promotion
                                : promoCode
                        }
                        onChange={(e) => {
                            setPromoCode(
                                String(e.target.value).toUpperCase().trim(),
                            );
                            setErrorMessage("");
                        }}
                    />
                    {errorMessage ? (
                        <span className="text-danger error-msg">
                            {errorMessage}
                        </span>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="box-container">
                    <CustomButton
                        className="btn-block promotion-apply-button"
                        onClick={handleOnClick}
                        disabled={promoCode.length === 0}
                    >
                        Apply
                    </CustomButton>
                </div>
            </div>
            <div className="mb-13 secondary-text-color">
                *excluding items on offer
            </div>
        </div>
    );
};
