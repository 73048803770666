import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import { connect } from "react-redux";
import { setUser } from "../../store/actions/UserAction";
import { AiOutlineCloseCircle, AiOutlineLogout } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { ImPriceTags, ImProfile } from "react-icons/im";
import { MdFastfood } from "react-icons/md";
import { Loader } from "../custom-loader";
import { notify, notifySuccess } from "../../common/utils";
import constants from "../../constants/constants";
import {
    getUserDetail,
    deleteCustomer,
} from "../../services/authenticate.service";
import NoqLogo from "../../assets/imgs/logo.png";
import "./profile.scss";
import jwtService from "../../services/jwtService";
import { emptyCart } from "../../store/actions/ProductActions";
import CustomModal from "../custom-modal";
import CustomButton from "../custom-button";

const Account = (props) => {
    const location = useLocation();
    const noBackURL = location.state?.noBackURL;
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const ICON_SIZE = constants.ICON_SIZE;
    useEffect(() => {
        if (localStorage.getItem("jwt_access_token") == null)
            navigate("/welcome-page", { replace: true });
        else {
            getUserDetail()
                .then((user) => {
                    props.setUser(user);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    notify(err);
                    navigate("/login", { replace: true });
                });
        }
    }, []);

    const deleteUser = () => {
        deleteCustomer()
            .then((message) => {
                props.setUser(null);
                localStorage.removeItem("jwt_access_token");
                setLoading(false);
                notifySuccess(message);
                navigate("/login", { replace: true });
            })
            .catch((err) => {
                setLoading(false);
                notify(err);
            });
    };

    if (!props.user || loading) return <Loader />;

    return (
        <div className="profile-container">
            <div className="profile-header">
                <div className="title-with-icon">
                    <CgProfile size={35} />
                    <h2>
                        {props.user.firstName} {props.user.lastName}
                    </h2>
                </div>
                {!noBackURL ? (
                    <div>
                        <span onClick={() => navigate(-1)}>
                            <AiOutlineCloseCircle size={35} />
                        </span>
                    </div>
                ) : null}
            </div>

            <div className="item-container">
                <Link to={`/${constants.ROUTES.ORDER}`}>
                    <div className="item-row">
                        <div>
                            <span>
                                <MdFastfood size={ICON_SIZE} />
                            </span>
                        </div>
                        <div className="text">Your Orders</div>
                    </div>
                </Link>
                <Link to={`/${constants.ROUTES.LOYALTY}`}>
                    <div className="item-row">
                        <div>
                            <span>
                                <ImPriceTags size={ICON_SIZE} />
                            </span>
                        </div>
                        <div className="text">Loyalty</div>
                    </div>
                </Link>
                {/* <Link to="">
        <div className='item-row'>
            <div><span><MdNotificationsActive size={ICON_SIZE} /></span></div>
            <div className="text">Notification</div>
        </div>
    </Link> */}
            </div>

            {/* <div className='item-container'>
        <h3>Marketplace</h3>
        <Link to="">
            <div className='item-row'>
                <div><span><GiForkKnifeSpoon size={ICON_SIZE} /></span></div>
                <div className="text">Find Venue</div>
            </div>
        </Link>
        <Link to="">
            <div className='item-row'>
                <div><span><AiOutlineSmile size={ICON_SIZE} /></span></div>
                <div className="text">Find Events, Food Markets and more</div>
            </div>
        </Link>
    </div> */}
            <h3 className="drawer-sub-heading">Account</h3>
            <div className="item-container">
                <Link to={`/${constants.ROUTES.CUSTOMER}/${props.user._id}`}>
                    <div className="item-row">
                        <div>
                            <span>
                                <ImProfile size={ICON_SIZE} />
                            </span>
                        </div>
                        <div className="text">Edit Profile</div>
                    </div>
                </Link>
                {/* <Link to="">
        <div className='item-row'>
            <div><span><FaAddressCard size={ICON_SIZE} /></span></div>
            <div className="text">Edit Your Adresses</div>
        </div>
    </Link>
    <Link to="">
        <div className='item-row'>
            <div><span><FaRegCreditCard size={ICON_SIZE} /></span></div>
            <div className="text">Edit Payment Details</div>
        </div>
    </Link>
    <Link to="">
        <div className='item-row'>
            <div><span><GiForkKnifeSpoon size={ICON_SIZE} /></span></div>
            <div className="text">Edit your Allergens and Deitary</div>
        </div>
    </Link> */}
                <span
                    onClick={() => {
                        jwtService.logout();
                        props.emptyCart();
                        props.setUser(null);
                        localStorage.removeItem("jwt_access_token");
                        navigate("/", { replace: true });
                    }}
                >
                    <div className="item-row">
                        <div>
                            <span>
                                <AiOutlineLogout size={ICON_SIZE} />
                            </span>
                        </div>
                        <div className="text">Sign Out</div>
                    </div>
                </span>
                {/* <span onClick={() => setIsModalOpen(true)}>
                    <div className='item-row'>
                        <div><span><RiDeleteBin6Line size={ICON_SIZE} /></span></div>
                        <div className="text">Delete Account Permenantly</div>
                    </div>
                </span> */}
            </div>
            <div className="footer">
                <div className="greyed-text">Powered by</div>
                <div>
                    <img src={NoqLogo} alt="noq-logo" />
                </div>
            </div>
            <CustomModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
            >
                <div className="flex-col">
                    <p>
                        Are you sure you want to delete your Account
                        Permenantly?
                    </p>
                    <CustomButton
                        className="btn-block btn-danger"
                        onClick={() => deleteUser()}
                    >
                        Confirm
                    </CustomButton>
                    <CustomButton
                        className="btn-block"
                        onClick={() => setIsModalOpen(false)}
                    >
                        Cancel
                    </CustomButton>
                </div>
            </CustomModal>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => {
            dispatch(setUser(user));
        },
        emptyCart: () => dispatch(emptyCart()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
