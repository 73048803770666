const InfoMessage = ({ message = "" }) => {
    return (
        <>
            <div className={`box-container info-message`}>
                {message ? (
                    <pre>{message}</pre>
                ) : (
                    <>
                        How it works:) <br />
                        Please note that if you start building a cart with one
                        of the below service types and then change, your cart
                        will be deleted.
                    </>
                )}
            </div>
        </>
    );
};

export default InfoMessage;
