import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getZoneObject, notify } from "../../common/utils";
import constants from "../../constants/constants";
import { apiService } from "../../services";
import { emptyCart, getUserCart, setServiceData } from "../../store/actions/ProductActions";
import { setSiteData } from "../../store/actions/SiteAction";
import { setUniqueMetricId, setZoneData } from "../../store/actions/UserAction";
import { Loader } from "../custom-loader";
import useQuery from "../../common/useQuery";

function SiteWrapper({
    children,
    siteData,
    setSiteData,
    setZoneData,
    user,
    emptyCart,
    getUserCart,
    serviceData,
    basket,
    metricId,
    setUniqueMetricId,
    setServiceData,
}) {
    const { siteId, zoneId, outletId, serviceType } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [fetchingCart, setFetchingCart] = useState(false);

    const query = useQuery();
    const metricIdFromURL = query.get("metricId");
    if (!metricId || (metricIdFromURL && metricIdFromURL !== metricId)) {
        setUniqueMetricId(metricIdFromURL);
    } else {
        // API call for deviceId if needed in future for scalability
    }

    useEffect(() => {
        if (siteId && !siteData) {
            apiService
                .getSite(siteId)
                .then(async (response) => {
                    const bodyStyles = document.body.style;
                    bodyStyles.setProperty(
                        "--theme-text-color",
                        response.primaryTextColor || "#fff",
                    );
                    bodyStyles.setProperty(
                        "--theme-background-color",
                        response.primaryColor || "#000",
                    );
                    setSiteData({ ...response });
                    if (zoneId || (serviceData && serviceData.zoneId)) {
                        setZoneData(
                            getZoneObject(
                                response,
                                zoneId || serviceData?.zoneId,
                                false,
                            ),
                        );
                    } else {
                        setZoneData(null);
                    }
                    setIsLoading(false);
                    if (
                        siteId &&
                        outletId &&
                        serviceType &&
                        response.category === "SITE DEPENDENT"
                    ) {
                        const siteSettings = response?.settings;
                        const serviceTypeData = siteSettings[serviceType] || {};
                        let vendorZoneName = serviceTypeData.vendorZoneName;
                        if (zoneId && !serviceTypeData.vendorZoneName) {
                            vendorZoneName = getZoneObject(response, zoneId, false)?.name;
                        }
                        setServiceData({
                            zoneId: zoneId,
                            ...serviceTypeData,
                            serviceType: serviceType,
                            serviceTypeDisplayName:
                                serviceTypeData?.displayName || camelToTitleCase(serviceType),
                            preOrder: false,
                            minOrder: serviceTypeData.absoluteMinOrder || 0,
                            deliveryFee:
                                serviceType === constants.SERVICE_TYPE.offsiteDelivery ||
                                serviceType === constants.SERVICE_TYPE.onsiteDelivery
                                    ? serviceTypeData.deliveryFee
                                    : 0,
                            tableNumber:
                                serviceType === constants.SERVICE_TYPE.onsiteDelivery
                                    ? serviceTypeData.tableNumber
                                    : null,
                            postCode:
                                serviceType === constants.SERVICE_TYPE.offsiteDelivery
                                    ? serviceTypeData.postCode
                                    : null,
                            vendorZoneName: vendorZoneName || "",
                            locationIdentifier: serviceTypeData.locationIdentifier,
                        });
                        navigate(
                            `/site/${siteId}/service-type/${serviceType}/outlet/${outletId}/menus`,
                            { replace: true },
                        );
                    }
                })
                .catch((err) => {
                    notify(err && err.message
                        ? err.message
                        : "Something went wrong, please try again later");
                    navigate("/404", { replace: true });
                });
        } else setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteId]);

    useEffect(() => {
        if (
            siteData &&
            siteData.category === constants.SITE_CATEGORY.SITE_DEPENDENT &&
            siteId &&
            user &&
            user._id
        ) {
            if (!fetchingCart) setFetchingCart(true);
            apiService
                .getUserCart(user._id, "")
                .then((res) => {
                    if (
                        res.site &&
                        res.site.siteRef === siteId &&
                        serviceData?.serviceType === res.serviceType
                    ) {
                        getUserCart(res);
                        setFetchingCart(false);
                    } else {
                        apiService
                            .removeCart({ _id: res._id, userRef: res.userRef })
                            .then((res) => {
                                emptyCart();
                                setFetchingCart(false);
                            })
                            .catch(() => setFetchingCart(false));
                    }
                })
                .catch((error) => {
                    emptyCart();
                    setFetchingCart(false);
                });
        } else if (
            siteData &&
            siteData.category === constants.SITE_CATEGORY.SITE_DEPENDENT &&
            siteId &&
            serviceData?.serviceType &&
            basket?._id &&
            !user
        ) {
            if (serviceData?.serviceType !== basket.serviceType) {
                apiService
                    .removeCart({ _id: basket?._id })
                    .then((res) => {
                        emptyCart();
                    })
                    .catch((err) => console.log("site-wrapper->error", err));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteData, siteId, user, serviceData?.serviceType]);

    if (!siteData || isLoading || fetchingCart) return <Loader />;
    if (children) return children;
    return <Outlet />;
}

const mapStateToProps = (state) => {
    return {
        siteData: state.siteData,
        user: state.user,
        metricId: state.metricId,
        basket: state.cart,
        serviceData: state.serviceData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSiteData: (site) => {
            dispatch(setSiteData(site));
        },
        setZoneData: (zone) => {
            dispatch(setZoneData(zone));
        },
        emptyCart: () => dispatch(emptyCart()),
        setUniqueMetricId: (metricId) => dispatch(setUniqueMetricId(metricId)),
        getUserCart: (cart) => dispatch(getUserCart(cart)),
        setServiceData: (data) => {
            dispatch(setServiceData(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteWrapper);
