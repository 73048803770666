import { useEffect } from "react";
import "./Layouts.scss";
import { useLocation } from "react-router-dom";

function Layouts({ children }) {
    const { pathname } = useLocation();
    useEffect(() => {
        document.getElementsByClassName("layout")[0]?.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const resizeHeight = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        };
        window.addEventListener("resize", () => {
            // We execute the same script as before
            resizeHeight();
        });
        resizeHeight();
        return () => {
            window.removeEventListener("resize", () => {});
        };
    });
    return <div className="layout">{children}</div>;
}

export default Layouts;
