import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/error.scss";
import CustomButton from "../custom-button";

export default function NotFoundComponent() {
    const navigate = useNavigate();

    useEffect(() => {
        // document.body.style.setProperty("--theme-background-color", 'red')
        // document.body.style.setProperty("--theme-text-color", '#fff')
    }, []);
    return (
        <div role="alert" className="error-container">
            <h1 className="title">404</h1>
            <h1 className="sub-title">Page Not Found</h1>
            <CustomButton
                className="error-button-container"
                onClick={() => navigate(-1)}
            >
                Click here to go back
            </CustomButton>
        </div>
    );
}
