export const GET_USER_CART = "GET USER CART";
export const ADD_ITEMS_CART_ACTION = "ADD_ITEMS_CART_ACTION";
export const EMPTY_CART_ACTION = "EMPTY_CART_ACTION";
export const ADD_VENDOR_ID = "ADD_VENDOR_ID";

export const MENU_RELOAD = "MENU_RELOAD";
export const SET_OUTLET_DATA = "SET_OUTLET_DATA";
export const SET_SERVICE_DATA = "SET_SERVICE_DATA";
export const REMOVE_SERVICE_DATA = "REMOVE_SERVICE_DATA";
export const SET_SITE_DATA = "SET_SITE_DATA";
export const SET_USER = "SET_USER";
export const SET_GUEST_SESSION = "SET_GUEST_SESSION";
export const SET_ZONE_DATA = "SET_ZONE_DATA";
export const SET_REDIRECT_URL = "SET_REDIRECT_URL";
export const SET_CART_LOADING = "SET_CART_LOADING";
export const SET_MENU_PAY_TYPE = "SET_MENU_PAY_TYPE";
export const SET_PREPAID_MAX_PRODUCT = "SET_PREPAID_MAX_PRODUCT";
export const SET_PREPAID_EMAIL = "SET_PREPAID_EMAIL";
export const SET_QUANTITY_LOADER = "SET_QUANTITY_LOADER";
export const GET_UNIQUE_METRIC_ID = "GET_UNIQUE_METRIC_ID";
export const SET_UNIQUE_METRIC_ID = "SET_UNIQUE_METRIC_ID";
export const GET_UNIQUE_DEVICE_ID = "GET_UNIQUE_DEVICE_ID";
export const SET_UNIQUE_DEVICE_ID = "SET_UNIQUE_DEVICE_ID";
