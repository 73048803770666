import CustomButton from "../custom-button";
export const StoreDropdown = ({ isMultiOrderWithStore, onSelectStore }) => {
    if (!isMultiOrderWithStore) return null;

    return (
        <div className="store-dropdown-container">
            <p className="text-bold m-0">Add to your order from:</p>
            <CustomButton label="Change Store" onClick={onSelectStore} />
            {/* <CustomDropdown
        options={stores}
        onSelect={onSelectStore}
        value={selectedStore.label ? selectedStore.label : null}
        placeHolder={"Select Store"}
        dropDownContainerClass="select-menu-container"
        dropDownHeaderClass="select-menu-header"
        dropDownListContainerClass="select-menu-list-container"
        dropDownListClass="select-menu-list"
        dropDownItemClass="select-menu-item"
      /> */}
        </div>
    );
};
