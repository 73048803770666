import { useMemo, useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";

import "./PaymentGateway.scss";
import CustomButton from "../custom-button";

const useResponsiveFontSize = () => {
    const getFontSize = () => (window.innerWidth < 450 ? "15px" : "18px");
    const [fontSize, setFontSize] = useState(getFontSize);

    useEffect(() => {
        const onResize = () => {
            setFontSize(getFontSize());
        };

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    });

    return fontSize;
};

const useGetCardOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontFamily: "Cantarell",
                    fontSize: "15px",
                    letterSpacing: "0.025em",
                    "::placeholder": {
                        // color: "#fffff",
                    },
                },
                invalid: {
                    color: "#9e2146",
                },
            },
        }),
        [fontSize],
    );

    return options;
};

export const CardPayment = ({ popUpClose, placeOrder, setShowLoader }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardError, setCardError] = useState("");
    const [disabled, setDisabled] = useState(true);
    const options = useGetCardOptions();
    const navigate = useNavigate();

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setCardError(event.error ? event.error.message : "");
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        setCardError("");
        if (!stripe || !elements) {
            setCardError("Something went wrong, please reload the page.");
            return;
        }
        setShowLoader(true);

        const card = elements.getElement("card");
        if (card) {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card,
            });

            if (error) {
                setCardError(error.message);
                setShowLoader(false);
            } else {
                const orderIdPromise = placeOrder(paymentMethod, stripe);
                orderIdPromise.then((order) => {
                    if (order && order.cartId && order.isMultiOrder === true) {
                        navigate(`/multi/orders/${order.cartId}`, {
                            replace: true,
                        });
                        return;
                    } else if (order && order.orderId) {
                        navigate(`/orders/${order.orderId}`, { replace: true });
                        return;
                    }
                });
            }
        }
    };

    return (
        <div className="pay-via-card-modal">
            <form onSubmit={onSubmit}>
                <label>
                    <span style={{ fontSize: "18px" }}>Card Details</span>
                    <CardElement options={options} onChange={handleChange} />
                </label>
                {cardError && (
                    <span
                        style={{
                            display: "block",
                            marginBottom: "10px",
                            marginTop: "-5px",
                            color: "red",
                            marginLeft: "1rem",
                            fontSize: "1.4rem",
                        }}
                    >
                        {cardError}
                    </span>
                )}
                <div className="flex justify-space-between">
                    <CustomButton
                        className="pay-button"
                        type="submit"
                        disabled={!stripe || disabled}
                    >
                        Pay
                    </CustomButton>

                    <button className="cancel-button" onClick={popUpClose}>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};
