import { FormattedNumber } from "react-intl";
import {
    AUTOMATED_PROMO_PROMOTION,
    MEMBERSHIP_PROMO_PROMOTION,
    PROMO_CODE_PROMOTION,
} from "../../common/utils";
import CustomButton from "../custom-button";
import OrderSummaryInfo from "./OrderSummaryInfo";
import SingleOrderSummaryInfo from "./SingleOrderSummaryInfo";

export const OrderSummary = ({
    basket,
    serviceType,
    loyalty,
    currency,
    loyaltyUsed,
    handleLoyaltyChange,
    handlePromotionChange,
    promotion,
    storesEstimatedPrepTime,
    automatedPromotion,
    handleAutomatedPromoChange,
    membershipPromotion,
    itemBasedPromotion,
}) => {
    if (basket && basket.isMultiOrder) {
        const itemBasedPromotionWithAmount = itemBasedPromotion.map(
            (itemPromo) => {
                const promoIndex = basket?.itemBasedPromotion?.findIndex(
                    (promotion) =>
                        promotion.promotionRef === itemPromo.promotionRef,
                );
                return {
                    ...itemPromo,
                    amount: basket?.itemBasedPromotion[promoIndex]?.amount || 0,
                };
            },
        );

        const isItemBasedPromoApplied = basket.carts.filter(d => d?.itemBasedPromotion?.length).length > 0;
        return (
            <>
                {(basket.carts || []).map((cart, index) => (
                    <>
                        <OrderSummaryInfo
                            basket={JSON.parse(JSON.stringify(cart))}
                            index={index}
                            key={index}
                            serviceType={serviceType}
                            storesEstimatedPrepTime={storesEstimatedPrepTime}
                        />
                        {index + 1 !== basket.carts.length ? (
                            <div
                                key={index}
                                className="separator separator-theme"
                            ></div>
                        ) : (
                            <></>
                        )}
                    </>
                ))}
                <div className="separator separator-theme"></div>
                {itemBasedPromotionWithAmount &&
                itemBasedPromotionWithAmount.length ? (
                    itemBasedPromotionWithAmount.map((itemPromo, index) => {
                        return (
                            <div
                                className="row multi-order total-amount "
                                key={itemPromo?.promotionRef || index}
                            >
                                <span className="column-two text-secondary">
                                    {itemPromo?.name || "Promotion"} (
                                    {itemPromo?.value + "%"})
                                </span>
                                {itemPromo?.amount ? (
                                    <div
                                        className={`column-two flex-end ${
                                            itemPromo?.amount
                                                ? "text-success"
                                                : ""
                                        }`}
                                    >
                                        <FormattedNumber
                                            style={`currency`}
                                            currencyDisplay="narrowSymbol"
                                            value={
                                                itemPromo?.amount
                                                    ? `-${itemPromo?.amount}`
                                                    : 0
                                            }
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            currency={currency || "gbp"}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
                {!isItemBasedPromoApplied && membershipPromotion &&
                Object.keys(membershipPromotion).length ? (
                    <div className="row multi-order total-amount ">
                        <span className="column-two text-secondary">
                            {membershipPromotion?.name || "Promotion"} (
                            {membershipPromotion?.savingType === "percent" ? (
                                membershipPromotion?.value + "%"
                            ) : (
                                <FormattedNumber
                                    style={`currency`}
                                    currencyDisplay="narrowSymbol"
                                    value={membershipPromotion?.value}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    currency={currency || "gbp"}
                                />
                            )}
                            )
                        </span>
                        <div className="column-two flex-end">
                            <div className="column-two flex-end">
                                <CustomButton
                                    className={`btn-link p-0 mr-5 ${
                                        basket.promotion?.amount &&
                                        basket?.promotion?.promotionType ===
                                            MEMBERSHIP_PROMO_PROMOTION
                                            ? "text-danger"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        handlePromotionChange(
                                            MEMBERSHIP_PROMO_PROMOTION,
                                            Boolean(
                                                basket?.promotion?.amount > 0 &&
                                                    basket?.promotion
                                                        ?.promotionType ===
                                                        MEMBERSHIP_PROMO_PROMOTION,
                                            ),
                                        );
                                    }}
                                >
                                    {basket.promotion?.amount &&
                                    basket?.promotion?.promotionType ===
                                        MEMBERSHIP_PROMO_PROMOTION
                                        ? "Remove"
                                        : "Add Promotion"}
                                </CustomButton>
                            </div>
                            <div
                                className={`column-two flex-end ${
                                    basket.promotion?.amount &&
                                    basket?.promotion?.promotionType ===
                                        MEMBERSHIP_PROMO_PROMOTION
                                        ? "text-success"
                                        : ""
                                }`}
                            >
                                {basket?.promotion?.promotionType ===
                                    MEMBERSHIP_PROMO_PROMOTION &&
                                basket?.promotion?.amount ? (
                                    <FormattedNumber
                                        style={`currency`}
                                        currencyDisplay="narrowSymbol"
                                        value={
                                            basket?.promotion?.amount &&
                                            basket?.promotion?.promotionType ===
                                                MEMBERSHIP_PROMO_PROMOTION
                                                ? `-${basket.promotion?.amount}`
                                                : 0
                                        }
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={currency || "gbp"}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {!isItemBasedPromoApplied && promotion && Object.keys(promotion).length ? (
                    <div className="row multi-order total-amount ">
                        <span className="column-two text-secondary">
                            {promotion?.name || "Promotion"} (
                            {promotion?.savingType === "percent" ? (
                                promotion?.value + "%"
                            ) : (
                                <FormattedNumber
                                    style={`currency`}
                                    currencyDisplay="narrowSymbol"
                                    value={promotion?.value}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    currency={currency || "gbp"}
                                />
                            )}
                            )
                        </span>
                        <div className="column-two flex-end">
                            <div className="column-two flex-end">
                                <CustomButton
                                    className={`btn-link p-0 mr-5 ${
                                        basket.promotion?.amount &&
                                        basket?.promotion?.promotionType ===
                                            PROMO_CODE_PROMOTION
                                            ? "text-danger"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        handlePromotionChange(
                                            PROMO_CODE_PROMOTION,
                                            Boolean(
                                                basket?.promotion?.amount > 0 &&
                                                    basket?.promotion
                                                        ?.promotionType ===
                                                        PROMO_CODE_PROMOTION,
                                            ),
                                        );
                                    }}
                                >
                                    {basket.promotion?.amount &&
                                    basket?.promotion?.promotionType ===
                                        PROMO_CODE_PROMOTION
                                        ? "Remove"
                                        : "Add Promotion"}
                                </CustomButton>
                            </div>
                            <div
                                className={`column-two flex-end ${
                                    basket.promotion?.amount &&
                                    basket?.promotion?.promotionType ===
                                        PROMO_CODE_PROMOTION
                                        ? "text-success"
                                        : ""
                                }`}
                            >
                                {basket?.promotion?.promotionType ===
                                    PROMO_CODE_PROMOTION &&
                                basket?.promotion?.amount ? (
                                    <FormattedNumber
                                        style={`currency`}
                                        currencyDisplay="narrowSymbol"
                                        value={
                                            basket?.promotion?.amount &&
                                            basket?.promotion?.promotionType ===
                                                PROMO_CODE_PROMOTION
                                                ? `-${basket.promotion?.amount}`
                                                : 0
                                        }
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={currency || "gbp"}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {!isItemBasedPromoApplied && automatedPromotion &&
                Object.keys(automatedPromotion).length ? (
                    <div className="row multi-order total-amount ">
                        <span className="column-two text-secondary">
                            {automatedPromotion?.name || "Promotion"} (
                            {automatedPromotion?.savingType === "percent" ? (
                                automatedPromotion?.amountSaved + "%"
                            ) : (
                                <FormattedNumber
                                    style={`currency`}
                                    currencyDisplay="narrowSymbol"
                                    value={automatedPromotion?.amountSaved}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    currency={currency || "gbp"}
                                />
                            )}
                            )
                        </span>
                        <div className="column-two flex-end">
                            <div className="column-two flex-end">
                                <CustomButton
                                    className={`btn-link p-0 mr-5 ${
                                        basket.promotion?.amount &&
                                        basket?.promotion?.promotionType ===
                                            AUTOMATED_PROMO_PROMOTION
                                            ? "text-danger"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        handleAutomatedPromoChange(
                                            automatedPromotion?._id,
                                            Boolean(
                                                basket?.promotion?.amount > 0 &&
                                                    basket?.promotion
                                                        ?.promotionType ===
                                                        AUTOMATED_PROMO_PROMOTION,
                                            ),
                                        );
                                    }}
                                >
                                    {basket.promotion?.amount &&
                                    basket?.promotion?.promotionType ===
                                        AUTOMATED_PROMO_PROMOTION
                                        ? "Remove"
                                        : "Add Promotion"}
                                </CustomButton>
                            </div>
                            <div
                                className={`column-two flex-end ${
                                    basket?.promotion?.amount &&
                                    basket?.promotion?.promotionType ===
                                        AUTOMATED_PROMO_PROMOTION
                                        ? "text-success"
                                        : ""
                                }`}
                            >
                                {basket?.promotion?.promotionType ===
                                    AUTOMATED_PROMO_PROMOTION &&
                                basket.promotion?.amount ? (
                                    <FormattedNumber
                                        style={`currency`}
                                        currencyDisplay="narrowSymbol"
                                        value={
                                            basket?.promotion?.amount &&
                                            basket?.promotion?.promotionType ===
                                                AUTOMATED_PROMO_PROMOTION
                                                ? `-${basket.promotion?.amount}`
                                                : 0
                                        }
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={currency || "gbp"}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {basket.subTotal - (basket?.promotion?.amount || 0) > 0 &&
                    loyalty &&
                    loyalty.availablePoints > 0 && (
                        <div className="row multi-order total-amount">
                            <span className="column-two text-secondary">
                                Loyalty ({loyalty?.availablePoints} points,{" "}
                                <FormattedNumber
                                    style={`currency`}
                                    currencyDisplay="narrowSymbol"
                                    value={
                                        loyalty.availablePoints *
                                        loyalty.redeemValue
                                    }
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    currency={currency || "gbp"}
                                />
                                )
                            </span>
                            <div className="column-two flex-end">
                                <div className="column-two flex-end">
                                    <CustomButton
                                        className={`btn-link p-0 mr-5 ${
                                            loyaltyUsed
                                                ? "text-danger"
                                                : "text-success"
                                        }`}
                                        onClick={handleLoyaltyChange}
                                    >
                                        {loyaltyUsed ? "Remove" : "Redeem"}
                                    </CustomButton>
                                </div>
                                <div className="column-two flex-end loyalty-used-amount">
                                    {loyaltyUsed ? (
                                        <FormattedNumber
                                            style={`currency`}
                                            currencyDisplay="narrowSymbol"
                                            value={-loyaltyUsed}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            currency={currency || "gbp"}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                <div className="row multi-order total-amount ">
                    <span className="column-two">Total</span>
                    <div className="column-two flex-end text-bold">
                        <FormattedNumber
                            style={`currency`}
                            currencyDisplay="narrowSymbol"
                            value={basket.totalAmount}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={currency || "gbp"}
                        />
                    </div>
                </div>
                {basket.inclusiveTaxTotal > 0 && (
                    <div className="row multi-order total-amount">
                        <span className="column-two text-secondary">
                            Inclusive Taxes
                        </span>
                        <div className="column-two flex-end text-bold text-secondary">
                            <FormattedNumber
                                style={`currency`}
                                currencyDisplay="narrowSymbol"
                                value={basket.inclusiveTaxTotal}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                currency={currency || "gbp"}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            {(basket.carts || []).map((cart, index) => (
                <SingleOrderSummaryInfo
                    loyalty={loyalty}
                    currency={currency}
                    loyaltyUsed={loyaltyUsed}
                    handleLoyaltyChange={handleLoyaltyChange}
                    basket={cart}
                    index={index}
                    key={index}
                    serviceType={serviceType}
                    handlePromotionChange={handlePromotionChange}
                    promotion={promotion || {}}
                    basketPromotion={basket.promotion || {}}
                    automatedPromotion={automatedPromotion}
                    handleAutomatedPromoChange={handleAutomatedPromoChange}
                    membershipPromotion={membershipPromotion}
                    itemBasedPromotion={itemBasedPromotion}
                    basketItemBasedPromotion={basket?.itemBasedPromotion || []}
                />
            ))}
        </>
    );
};
