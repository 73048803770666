import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../custom-button";
import { connect } from "react-redux";
import constants from "../../../constants/constants";
import "./index.scss";
import {
    camelToTitleCase,
    generateServiceTypeDetailUrl,
    getZoneObject,
} from "../../../common/utils";
import { setServiceData } from "../../../store/actions/ProductActions";
import CustomModal from "../../custom-modal";
import ServiceSelectionForm from "./ServiceSelectionForm";
import { useState } from "react";

function ServiceTypeList(props) {
    const navigate = useNavigate();
    const { siteId, zoneId } = useParams();
    const [service, setService] = useState();
    const redirectUrl = generateServiceTypeDetailUrl({ siteId, zoneId });
    const { siteData, setServiceData } = props;

    const [showServiceForm, setShowServiceForm] = useState(false);

    const handleNavigate = (serviceType, serviceData) => {
        let vendorZoneName = serviceData.vendorZoneName;
        if (zoneId && !serviceData.vendorZoneName) {
            vendorZoneName = getZoneObject(siteData, zoneId, false)?.name;
        }
        setServiceData({
            zoneId: zoneId,
            ...serviceData,
            serviceType: serviceType,
            serviceTypeDisplayName:
                serviceData?.displayName || camelToTitleCase(serviceType),
            preOrder: false,
            minOrder: serviceData.absoluteMinOrder || 0,
            deliveryFee:
                serviceType === constants.SERVICE_TYPE.offsiteDelivery ||
                serviceType === constants.SERVICE_TYPE.onsiteDelivery
                    ? serviceData.deliveryFee
                    : 0,
            tableNumber:
                serviceType === constants.SERVICE_TYPE.onsiteDelivery
                    ? serviceData.tableNumber
                    : null,
            postCode:
                serviceType === constants.SERVICE_TYPE.offsiteDelivery
                    ? serviceData.postCode
                    : null,
            vendorZoneName: vendorZoneName || "",
            locationIdentifier: serviceData.locationIdentifier,
        });
        navigate(
            `${redirectUrl}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`,
            { state: { showBack: true } },
        );
    };

    const handleSelectServiceType = (serviceType, serviceData) => {
        if (serviceType === constants.SERVICE_TYPE.onsiteDelivery) {
            setService({
                serviceType: serviceType,
                isLiveOrdersActive: true,
                minOrder: serviceData.absoluteMinOrder || 0,
                deliveryFee: serviceData.deliveryFee || 0,
                ...serviceData,
            });
            if (
                serviceData.locationInput === "Customer" &&
                serviceData.locationIdentifierCustomerInput.length > 0
            ) {
                setShowServiceForm(true);
            } else if (
                serviceData.locationInput === "Vendor" &&
                serviceData.locationIdentifierSelects.length > 0
            ) {
                setShowServiceForm(true);
            } else {
                handleNavigate(serviceType, serviceData);
            }
        } else handleNavigate(serviceType, serviceData);
    };

    const renderServiceTypes = (settings, className) => {
        const buttons = [];
        for (const service in settings) {
            if (settings[service].isActive) {
                buttons.push(
                    <CustomButton
                        key={service}
                        className="btn-block service-type-button"
                        onClick={() =>
                            handleSelectServiceType(service, settings[service])
                        }
                    >
                        {settings[service].displayName ||
                            camelToTitleCase(service)}
                    </CustomButton>,
                );
            }
        }
        if (buttons.length > 0) {
            return (
                <>
                    <div className="row">
                        <div className="column-two service-type-title">
                            {"Select Service Type"}
                        </div>
                    </div>
                    <div className={`zone-list no-image`}>{buttons}</div>
                </>
            );
        }
        return (
            <div className="box-container">
                <p className="closed-center">
                    {" "}
                    Sorry, We Are closed and not accepting orders
                </p>
            </div>
        );
    };

    return (
        <>
            {renderServiceTypes(siteData.settings, "")}
            <CustomModal
                isOpen={showServiceForm}
                modalContainerClass={"service-type-modal-container"}
                onRequestClose={() => setShowServiceForm(false)}
                title={
                    service?.displayName ||
                    camelToTitleCase(service?.serviceType)
                }
            >
                <ServiceSelectionForm
                    siteData={siteData}
                    zoneId={zoneId}
                    serviceData={service}
                    serviceType={service?.serviceType}
                    onSubmit={handleNavigate}
                />
            </CustomModal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        siteData: state.siteData,
        authType: state.authType,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setServiceData: (data) => {
            dispatch(setServiceData(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTypeList);
