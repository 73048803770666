import { useEffect, useState } from "react";
import { FormattedNumber } from "react-intl";
import { generateLocationDropdown } from "../../../common/utils";
import constants from "../../../constants/constants";
import CustomButton from "../../custom-button";
import { CustomDropdown } from "../../custom-dropdown";
import { CustomInput } from "../../custom-input";

const renderOnsiteServiceType = ({
    isRegisterOpen,
    service,
    serviceType,
    setTableNumber,
    tableNumber,
    locationNames,
    locationIdentifier,
}) => {
    if (
        ((isRegisterOpen && service.isLiveOrdersActive) ||
            service.isPreOrdersActive) &&
        serviceType === constants.SERVICE_TYPE.onsiteDelivery
    ) {
        if (service.locationInput === constants.LOCATION_INPUT.VENDOR) {
            return (
                <>
                    {/* {service.vendorZoneName ? <div className="row">
                    <h4 className="column-two flex-start m-0">Location</h4>
                    <div className="column-two flex-end m-0">
                        <CustomInput disabled onChange={(e) => null} name="vendorZoneName" value={service.vendorZoneName} className={"label-input"} />
                    </div>
                </div> : null} */}
                    <div className="row dropdown">
                        <h4 className="column-two flex-start m-0">
                            {locationIdentifier}
                        </h4>
                        <div className="column-two flex-end m-0">
                            <CustomDropdown
                                options={locationNames.map((loc) => ({
                                    label: loc,
                                    value: loc,
                                }))}
                                onSelect={setTableNumber}
                                value={tableNumber}
                                placeHolder="Location"
                                className="menu-select-box"
                                label="Location"
                            />
                        </div>
                    </div>
                </>
            );
        }

        if (service.locationInput === constants.LOCATION_INPUT.CUSTOMER) {
            return (
                <div className="row">
                    <h4 className="column-two flex-start m-0">
                        {locationIdentifier}
                    </h4>
                    <div className="column-two location-identifier-input flex-end m-0">
                        <CustomInput
                            type={
                                service?.isInputTypeNumber ? "number" : "text"
                            }
                            onChange={(e) => setTableNumber(e)}
                            name="tableNumber"
                            value={tableNumber}
                            className={"label-input"}
                        />
                    </div>
                </div>
            );
        }
    }
    return null;
};

const ServiceSelectionForm = (props) => {
    const { zoneId, siteData, serviceData, serviceType, onSubmit } = props;
    const [tableNumber, setTableNumber] = useState(""); //dropdown
    const [locationNames, setLocationNames] = useState([]); //dropdown
    const [locationIdentifierSelect, setLocationIdentifierSelect] = useState(
        {},
    ); //dropdown
    const [vendorZoneName, setVendorZoneName] = useState(
        serviceData.vendorZoneName || "",
    );

    useEffect(() => {
        if (serviceType === constants.SERVICE_TYPE.onsiteDelivery) {
            const { locationName, locationDetail } = generateLocationDropdown(
                serviceData.locationInput === "Customer"
                    ? serviceData.locationIdentifierCustomerInput
                    : serviceData.locationIdentifierSelects,
                zoneId,
                true,
            );
            setLocationIdentifierSelect(locationDetail);
            setLocationNames(locationName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteData, serviceType]);

    return (
        <>
            <hr className="m-0 mb-10"></hr>
            <div className="box-container">
                <div className="precaution-information">
                    {Boolean(serviceData.absoluteMinOrder) && (
                        <div className="row justify-content-between">
                            <h4 className="column-two flex-start ml-0">
                                Minimum Order
                            </h4>
                            <h4 className="column-two flex-end">
                                <FormattedNumber
                                    style={`currency`}
                                    currencyDisplay="narrowSymbol"
                                    value={serviceData.absoluteMinOrder}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    currency={siteData.currency || "gbp"}
                                />
                            </h4>
                        </div>
                    )}
                    {renderOnsiteServiceType({
                        isDependent: true,
                        service: serviceData,
                        serviceType,
                        isRegisterOpen: true,
                        locationIdentifier:
                            locationIdentifierSelect?.locationIdentifier ||
                            serviceData?.locationIdentifier,
                        locationNames,
                        tableNumber,
                        vendorZoneName,
                        setVendorZoneName,
                        setTableNumber,
                    })}
                    <CustomButton
                        className="btn-block menu-button-detail"
                        onClick={() =>
                            onSubmit(serviceType, {
                                ...serviceData,
                                locationIdentifier:
                                    locationIdentifierSelect?.locationIdentifier ||
                                    serviceData?.locationIdentifier,
                                locationNames,
                                tableNumber,
                                vendorZoneName,
                            })
                        }
                    >
                        Save
                    </CustomButton>
                </div>
            </div>
        </>
    );
};

export default ServiceSelectionForm;
