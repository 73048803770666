import { useEffect, useState } from "react";
import {
    AiOutlineInfoCircle,
    AiOutlineMinusSquare,
    AiOutlinePlusSquare,
} from "react-icons/ai";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
    calculateRemainingQty,
    checkPrePaidDisable,
    getImageUrl,
    observerShowDescription,
    useDebounce,
} from "../../../common/utils";
import constants from "../../../constants/constants";
import {
    addItemsToCart,
    setQuantityLoader,
} from "../../../store/actions/ProductActions";
import CustomModal from "../../custom-modal";
import QuantityLoader from "../../custom-quantity-loader";
import AllergensModal from "./AllergensModal";
import ItemModifierModal from "./ItemModifierModal";
import { IMAGE_SIZES } from "../../../constants/image";
import AttributesModal from "./AttributesModal";

function ItemCard(props) {
    const {
        itemData,
        inBasket,
        outletData,
        basket,
        menuPayType,
        prepaidMaxProduct,
        quantityLoader,
        setQuantityLoader,
    } = props;
    const { serviceType, outletId } = useParams();
    const [diff, setDiff] = useState(0);
    const bodyStyles = document.body.style;
    const backgroundColor = bodyStyles.getPropertyValue(
        "--theme-background-color",
    );
    const [enableModifiers, setEnableModifiers] = useState(false);
    const [showCustomAttributeModal, setShowCustomAttributeModal] =
        useState(false);
    const outletRef = itemData.outletRef ? itemData.outletRef : outletId;
    const currentBasket =
        (basket?.carts || []).find(
            (v) => String(v.vendorRef) === String(outletRef),
        ) || {};
    function openModal() {
        setEnableModifiers(true);
    }

    function closeModal() {
        setEnableModifiers(false);
    }

    const handleQuantityChange = useDebounce(() => {
        setQuantityLoader(true);
        props
            .addItemsToCart({ ...itemData, quantity: diff })
            .then(() => {
                setDiff(0);
                setQuantityLoader(false);
            })
            .catch(() => {
                setDiff(0);
                setQuantityLoader(false);
            });
    }, 300);

    useEffect(() => {
        observerShowDescription();
    }, []);

    useEffect(() => {
        if (diff) {
            handleQuantityChange();
        }
    }, [diff]);

    const handlePlusClick = () => {
        const d = diff + 1;
        if (itemData.assignedModifiers.length || itemData.variants.length) {
            openModal();
        } else {
            if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
                let basketItems =
                    basket?.carts && basket?.carts.length > 0
                        ? basket?.carts[0].items
                        : [];
                if (
                    !checkPrePaidDisable(
                        calculateRemainingQty(prepaidMaxProduct, basketItems) -
                            d,
                        menuPayType,
                    )
                ) {
                    setDiff(d);
                }
            } else {
                setDiff(d);
            }
        }
    };

    function handleMinusClick() {
        setDiff(diff - 1);
    }

    function getItemDiet(diet) {
        let res = "";
        if (typeof diet === "string") {
            return diet;
        }
        for (let i = 0; i < diet.length; i++) {
            if (res) {
                res += `, `;
            }
            res += diet[i];
        }
        return res;
    }

    const onShowModal = () => {
        if (
            serviceType !== "onsiteCounter" &&
            serviceType !== "onsiteOffCounter"
        ) {
            openModal();
        }
    };

    const basketItemIndex = (currentBasket.items || []).findIndex(
        (i) => i.assignedMenuItemRef === itemData._id,
    );
    let modifiersItemsData = JSON.parse(JSON.stringify(itemData));
    let prepaidDisabled = false;
    if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
        const basketItems =
            basket?.carts && basket?.carts.length > 0
                ? basket?.carts[0].items
                : [];
        prepaidDisabled = checkPrePaidDisable(
            calculateRemainingQty(prepaidMaxProduct, basketItems) - (diff + 1),
            menuPayType,
        );
    }

    return (
        <>
            <div className="item-container">
                <div className="item-name-price" onClick={onShowModal}>
                    <div className="item-name">{itemData.itemName}</div>
                    {menuPayType !== constants.MENU_PAY_TYPE.PREPAID ? (
                        <div
                            className="item-price"
                            style={{ textAlign: "end" }}
                        >
                            {itemData.assignedModifiers.length ||
                            itemData.variants.length
                                ? "From "
                                : ""}
                            <FormattedNumber
                                style={`currency`}
                                currencyDisplay="narrowSymbol"
                                value={itemData.itemPrice}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                currency={outletData.currency || "gbp"}
                            />
                        </div>
                    ) : null}
                </div>
                <div className="item-image-container">
                    {itemData.itemImage && (
                        <div className="item-logo">
                            <img
                                src={
                                    getImageUrl(itemData.itemImage) +
                                    IMAGE_SIZES.logo
                                }
                                onError={(e) => {
                                    e.target.src = getImageUrl(
                                        itemData.itemImage,
                                    );
                                }}
                                alt="Display not found"
                            />
                        </div>
                    )}
                    <div className="item-description-footer">
                        <div
                            className="item-description text-secondary p-2"
                            onClick={onShowModal}
                        >
                            <p className="m-0">{itemData.description}</p>
                            <label>View More</label>
                        </div>
                        <div className="item-footer">
                            <div className="item-footer_first_sec">
                                {((itemData.customAttributes &&
                                    itemData.customAttributes.length > 0) ||
                                    (itemData.nutritions &&
                                        Object.values(itemData.nutritions).some(
                                            (val) => val
                                        ))) && (
                                    <div
                                        className="custom-attribute-details"
                                        onClick={() =>
                                            setShowCustomAttributeModal(true)
                                        }
                                    >
                                        <AiOutlineInfoCircle /> Item details
                                    </div>
                                )}
                            </div>
                            {serviceType !== "onsiteCounter" &&
                                serviceType !== "onsiteOffCounter" && (
                                    <>
                                        {
                                            <div
                                                style={{ position: "relative" }}
                                            >
                                                <div
                                                    className={`item-count ${
                                                        quantityLoader
                                                            ? "disable-qty-btn"
                                                            : ""
                                                    }`}
                                                >
                                                    {Boolean(inBasket + diff) &&
                                                        itemData
                                                            .assignedModifiers
                                                            .length === 0 &&
                                                        itemData.variants
                                                            .length === 0 && (
                                                            <AiOutlineMinusSquare
                                                                color={
                                                                    backgroundColor
                                                                }
                                                                size={40}
                                                                onClick={
                                                                    handleMinusClick
                                                                }
                                                            />
                                                        )}
                                                    {Boolean(
                                                        inBasket + diff,
                                                    ) && (
                                                        <span className="item-quantity">
                                                            {inBasket + diff}
                                                        </span>
                                                    )}
                                                    <AiOutlinePlusSquare
                                                        color={backgroundColor}
                                                        size={40}
                                                        onClick={
                                                            handlePlusClick
                                                        }
                                                        className={`${
                                                            prepaidDisabled
                                                                ? "disabled"
                                                                : ""
                                                        }`}
                                                    />
                                                </div>
                                                {quantityLoader && (
                                                    <QuantityLoader />
                                                )}
                                            </div>
                                        }
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                isOpen={enableModifiers}
                onRequestClose={closeModal}
                hideDivider={true}
                title={itemData.itemName}
                modalContainerClass={"modifier-modal-class"}
            >
                {basketItemIndex >= 0 &&
                Boolean(inBasket + diff) &&
                itemData.assignedModifiers.length === 0 &&
                itemData.variants.length === 0 ? (
                    <ItemModifierModal
                        itemData={{
                            ...modifiersItemsData,
                            quantity: inBasket,
                            ...(currentBasket.items || [])[basketItemIndex],
                            specialInstructions: (currentBasket.items || [])[
                                basketItemIndex
                            ].specialInstructions,
                        }}
                        closeModal={closeModal}
                        isEdit={true}
                        itemIndex={basketItemIndex}
                    />
                ) : (
                    <ItemModifierModal
                        enableModifiers={enableModifiers}
                        itemData={modifiersItemsData}
                        closeModal={closeModal}
                    />
                )}
            </CustomModal>
            <AttributesModal
                showAttributeModal={showCustomAttributeModal}
                setShowAttributeModal={setShowCustomAttributeModal}
                itemData={itemData}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        basket: state.cart,
        outletData: state.outletData,
        siteData: state.siteData,
        menuPayType: state.menuPayType,
        prepaidMaxProduct: state.prepaidMaxProduct,
        quantityLoader: state.quantityLoader,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addItemsToCart: (item) => dispatch(addItemsToCart(item)),
        setQuantityLoader: (value) => {
            return dispatch(setQuantityLoader(value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCard);
