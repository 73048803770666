import React, { useEffect, useRef, useState } from "react";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import {
    getInBasketCount,
    restructureUpsellingItems,
} from "../../../common/utils";
import constants from "../../../constants/constants";
import CustomButton from "../../custom-button";
import ItemCard from "../../menu-items/CategoryCard/ItemCard";
import "../../menu-items/menu.scss";
import MenuCard from "./menuCard";

function UpsellingItemsModal(props) {
    const {
        upsellingData,
        menuPayType,
        outletData,
        basket,
        prepaidConfirmOrder,
        close,
    } = props;
    const [itemIds, setItemIds] = useState([]);
    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current) mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (mounted.current && basket?.carts?.length) {
            const items = basket?.carts
                ?.map((e) => e?.items?.map((item) => item.itemRef))
                ?.flatMap((e) => e);
            setItemIds(items);
        }
    }, [basket]);

    const [structuredUpsellingData, setStructuredUpsellingData] = useState([]);
    const [inBasketCount, setInBaseketCount] = useState(
        getInBasketCount(basket),
    );

    useEffect(() => {
        setInBaseketCount(getInBasketCount(basket));
    }, [basket]);

    useEffect(() => {
        const sellingData = restructureUpsellingItems(upsellingData);
        setStructuredUpsellingData(sellingData);
    }, [upsellingData]);

    const [loading, setLoading] = useState(false);
    const continueOrder = () => {
        setLoading(true);
        prepaidConfirmOrder();
    };

    useEffect(() => {
        if (mounted.current && basket?.carts?.length) {
            const isColumnLayout =
                structuredUpsellingData.length === 1 &&
                !basket.isMultiOrder &&
                structuredUpsellingData[0].menu.length === 1;
            if (structuredUpsellingData.length === 0) return;

            const basketItemIds = basket.carts
                .map((e) => e.items.map((item) => item.itemRef))
                .flatMap((e) => e);

            const upsellItemIds = structuredUpsellingData
                .map((e) =>
                    e.menu.map((e) => e.items.map((item) => item.itemRef)),
                )
                .flatMap((e) => e)
                .flatMap((e) => e);

            const isAllThere = upsellItemIds.every((item) =>
                basketItemIds.includes(item),
            );

            if (isAllThere && !isColumnLayout) close();
        }
    }, [basket, props, structuredUpsellingData]);

    return (
        <div className="menu">
            <div className="upselling-header-extented">
                <div
                    className={`${
                        Object.keys(inBasketCount).length
                            ? "menu-list"
                            : "category-list-long"
                    } category-list-scroll`}
                >
                    <div className="box-container">
                        {structuredUpsellingData ? (
                            structuredUpsellingData.length === 1 &&
                            !basket.isMultiOrder ? (
                                <>
                                    {structuredUpsellingData[0].menu.length ===
                                    1 ? (
                                        <div className="item-list">
                                            {structuredUpsellingData[0].menu[0].items.map(
                                                (item, index) => {
                                                    return (
                                                        <ItemCard
                                                            itemData={{
                                                                ...item,
                                                                isUpsell: true,
                                                                outletRef:
                                                                    item.outletRef ||
                                                                    structuredUpsellingData[0]
                                                                        .vendorRef,
                                                            }}
                                                            key={item._id}
                                                            inBasket={
                                                                inBasketCount[
                                                                    item._id
                                                                ] || 0
                                                            }
                                                            vendorRef={
                                                                structuredUpsellingData[0]
                                                                    .vendorRef
                                                            }
                                                        />
                                                    );
                                                },
                                            )}
                                        </div>
                                    ) : (
                                        structuredUpsellingData[0].menu.length >
                                            1 && (
                                            <div>
                                                {structuredUpsellingData[0].menu.map(
                                                    (menuData, index) => {
                                                        return (
                                                            <React.Fragment
                                                                key={
                                                                    menuData._id
                                                                }
                                                            >
                                                                {menuData.items &&
                                                                menuData.items
                                                                    .length >
                                                                    0 ? (
                                                                    <MenuCard
                                                                        itemIds={
                                                                            itemIds
                                                                        }
                                                                        menu={{
                                                                            ...menuData,
                                                                            outletRef:
                                                                                structuredUpsellingData[0]
                                                                                    .vendorRef,
                                                                        }}
                                                                        inBasketCount={
                                                                            inBasketCount
                                                                        }
                                                                        key={
                                                                            menuData?._id
                                                                        }
                                                                        vendorRef={
                                                                            structuredUpsellingData[0]
                                                                                .vendorRef
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </React.Fragment>
                                                        );
                                                    },
                                                )}
                                            </div>
                                        )
                                    )}
                                </>
                            ) : (
                                (structuredUpsellingData.length > 1 ||
                                    basket.isMultiOrder) && (
                                    <>
                                        {structuredUpsellingData.map(
                                            (store, index) => {
                                                let storeRef = store.vendorRef;
                                                return (
                                                    <div
                                                        className="store-section"
                                                        key={storeRef}
                                                    >
                                                        {store?.menu?.some(
                                                            (e) =>
                                                                e?.items
                                                                    ?.length,
                                                        ) && (
                                                            <div className="store-name">
                                                                People also
                                                                added with{" "}
                                                                {
                                                                    store.displayName
                                                                }
                                                            </div>
                                                        )}
                                                        {store.menu.map(
                                                            (
                                                                menuData,
                                                                index,
                                                            ) => {
                                                                if (
                                                                    menuData
                                                                        .items
                                                                        .length ===
                                                                    0
                                                                )
                                                                    return null;
                                                                const isAllThere =
                                                                    menuData.items
                                                                        .map(
                                                                            (
                                                                                e,
                                                                            ) =>
                                                                                e.itemRef,
                                                                        )
                                                                        .every(
                                                                            (
                                                                                item,
                                                                            ) =>
                                                                                itemIds.includes(
                                                                                    item,
                                                                                ),
                                                                        );
                                                                if (isAllThere)
                                                                    return null;
                                                                return (
                                                                    <React.Fragment
                                                                        key={
                                                                            menuData?._id
                                                                        }
                                                                    >
                                                                        {menuData.items &&
                                                                        menuData
                                                                            .items
                                                                            .length >
                                                                            0 ? (
                                                                            <>
                                                                                <MenuCard
                                                                                    itemIds={
                                                                                        itemIds
                                                                                    }
                                                                                    menu={{
                                                                                        ...menuData,
                                                                                        outletRef:
                                                                                            storeRef,
                                                                                    }}
                                                                                    inBasketCount={
                                                                                        inBasketCount
                                                                                    }
                                                                                    vendorRef={
                                                                                        storeRef
                                                                                    }
                                                                                    key={
                                                                                        menuData?._id
                                                                                    }
                                                                                />
                                                                                <div
                                                                                    className={`${
                                                                                        index !==
                                                                                        store
                                                                                            .menu
                                                                                            .length -
                                                                                            1
                                                                                            ? "menu-separator"
                                                                                            : "menu-separator-theme"
                                                                                    }`}
                                                                                ></div>
                                                                            </>
                                                                        ) : null}
                                                                    </React.Fragment>
                                                                );
                                                            },
                                                        )}
                                                    </div>
                                                );
                                            },
                                        )}
                                    </>
                                )
                            )
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="sticky-footer cart-wrapper-footer">
                <div className="separator separator-theme"></div>
                <div className="box-container footer">
                    {menuPayType !== constants.MENU_PAY_TYPE.PREPAID ? (
                        <>
                            <div className="cart-total-section">
                                <span>Subtotal</span>
                                <span>
                                    <FormattedNumber
                                        style={`currency`}
                                        currencyDisplay="narrowSymbol"
                                        value={basket.subTotal}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={outletData.currency || "gbp"}
                                    />
                                </span>
                            </div>
                            <CustomButton
                                disabled={loading}
                                onClick={continueOrder}
                                className="btn-block"
                            >
                                Go to Checkout
                            </CustomButton>
                        </>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <div className="cart-prepaid-form">
                                <CustomButton
                                    onClick={continueOrder}
                                    disabled={loading}
                                    type="submit"
                                    className="btn-block"
                                >
                                    Confirm Order
                                </CustomButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        outletData: state.outletData,
        basket: state.cart,
        serviceData: state.serviceData,
        menuPayType: state.menuPayType,
        prepaidEmail: state.prepaidEmail,
        user: state.user,
        cartLoading: state.cartLoading,
    };
};

export default connect(mapStateToProps, null)(UpsellingItemsModal);
