import CustomButton from "../custom-button";
import "./serviceType.scss";
import MenuSelection from "./MenuSelection";
import Actionsheet from "actionsheet-react";
import { Loader } from "../custom-loader";
import { connect } from "react-redux";
import { useEffect, useRef } from "react";

const actionSheetStyle = {
    borderRadius: 0,
    margin: "auto",
    left: 0,
    right: 0,
    maxWidth: "768px",
    minHeight: "500px",
};

const PreOrderMenuSelection = ({
    isPreOrderLoading,
    menuList,
    onRequestClose,
    selectedMenu,
    setSelectedMenu,
    preModal,
    service,
    minOrder,
    deliveryFee,
    vendorZoneName,
}) => {
    const ref = useRef();
    useEffect(() => {
        if (!isPreOrderLoading && preModal && menuList.length === 1) {
            handleNavigate(menuList[0]);
        }
    }, [preModal, menuList, isPreOrderLoading]);

    const handleActionOpen = () => {
        ref.current.open();
    };

    const handleActionClose = () => {
        ref.current.close();
    };

    const handleNavigate = (selectedMenu) => {
        setSelectedMenu(selectedMenu);
        handleActionOpen();
        // onRequestClose();
    };
    if (isPreOrderLoading) return <Loader />;
    return (
        <>
            <div className="box-container">
                {menuList && menuList.length ? (
                    <>
                        <div className="row">
                            <div className="column-two menu-selection-heading">
                                Select Menu
                            </div>
                        </div>
                        {menuList.map((menu, index) => (
                            <CustomButton
                                className="btn-block menu-button-detail"
                                onClick={() => handleNavigate(menu)}
                                key={index}
                            >
                                {menu?.menu?.menuRef?.displayName +
                                    " " +
                                    (menu?.menu?.menuRef?.menuPayType ===
                                    "prepaid"
                                        ? "(Guest list)"
                                        : "")}
                            </CustomButton>
                        ))}
                    </>
                ) : (
                    <p>Menu is not available</p>
                )}
            </div>
            <Actionsheet
                ref={ref}
                sheetStyle={actionSheetStyle}
                closeOnBgTap={false}
                touchEnable={false}
            >
                <MenuSelection
                    onRequestClose={handleActionClose}
                    selectedMenu={selectedMenu}
                    service={service}
                    minOrder={minOrder}
                    deliveryFee={deliveryFee}
                    vendorZoneName={vendorZoneName}
                />
            </Actionsheet>
        </>
    );
};

export default connect(null, null)(PreOrderMenuSelection);
