import { useEffect } from "react";
import jwtService from "../../services/jwtService";

const Authentication = ({ children }) => {
    useEffect(() => {
        jwtService.init();
    }, []);
    return <>{children}</>;
};

export default Authentication;
