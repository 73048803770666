import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { connect } from "react-redux";
import { getFormatedTime, getTimeRemaining } from "../../common/utils";
import constants from "../../constants/constants";

const SlotTimer = (props) => {
    const deadline = new Date(
        new Date(props.serviceData.serviceSelectionTime).getTime() + 10 * 60000,
    );
    const [timer, setTimer] = useState(
        getFormatedTime(getTimeRemaining(deadline)),
    );
    const Ref = useRef(null);
    const navigate = useNavigate();
    const { siteId, zoneId, outletId, serviceType } = useParams();
    const redirectUrl = zoneId
        ? `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.ZONE}/${zoneId}/${constants.ROUTES.OUTLET}/${outletId}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`
        : siteId
        ? `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.OUTLET}/${outletId}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`
        : `/${constants.ROUTES.OUTLET}/${outletId}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`;

    const startTimer = (e) => {
        let { difference, hours, minutes, seconds } = getTimeRemaining(e);
        if (difference >= 0) {
            setTimer(getFormatedTime({ hours, minutes, seconds }));
        } else {
            navigate(redirectUrl);
            // navigate(-1);
        }
    };

    useEffect(() => {
        const id = setInterval(() => startTimer(deadline), 1000);
        Ref.current = id;
        return () => {
            clearInterval(Ref.current);
        };
    }, []);

    return (
        <div
            className=""
            style={{ display: "flex", gap: "20px", alignItems: "center" }}
        >
            <div className="slot-timer">Slot Timer:</div>
            <div className="slot-timer">{timer}</div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        serviceData: state.serviceData,
    };
};

export default connect(mapStateToProps, null)(SlotTimer);
