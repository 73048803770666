import { Route, Routes, useLocation } from "react-router-dom";
import constants from "./constants/constants";
import NotFoundComponent from "./components/error-component/NotFoundComponent";
import MenuItems from "./components/menu-items";
import Outlets from "./components/outlets";
import Services from "./components/service-type";
import ComponentWrapper from "./components/component-wrapper";
import Cart from "./components/cart";
import Checkout from "./components/checkout";
import YourOrder from "./components/your-order";

import SiteWrapper from "./components/site-wrapper";
import Register from "./components/register";
import SignIn from "./components/login";
import Logout from "./components/logout";
import ForgotPassword from "./components/forgot-password";
import Account from "./components/account";
import YourOrders from "./components/your-orders";
import IssueList from "./components/issue-list";
import IssueMessage from "./components/issue-message";
import Feedback from "./components/feedback-page";
import Profile from "./components/account/profile";
import WelcomePage from "./components/welcome-page";
import RedirectAdyen from "./components/verify-adyen";
import Loyalty from "./components/loyalty";
import LoyaltyOutlet from "./components/loyalty-outlet";
import LoyaltyPoints from "./components/loyalty-points";
import ThankYouForFeedback from "./components/thankyou-for-feedback";
import SiteDetail from "./components/site-detail";
import ActivateMembership from "./components/activate-membership";
// import ZoneDetail from "./components/zone-detail";
// import OutletList from "./components/site-dependents/outlet-list";
// import MenuList from "./components/site-dependents/menu-list";
// import OutletComponentWrapper from "./components/site-dependents/outlet-wrapper";

import React, { Suspense, useEffect } from "react";
import { Loader } from "./components/custom-loader";
import ReactGA from "react-ga4";
import apiConsts from "./constants/apiConstants";
import ActivateSiteMembership from "./components/activate-site-membership";
import PaymentLink from "./components/payment-link";

const ZoneDetail = React.lazy(() => import("./components/zone-detail"));
const OutletList = React.lazy(() =>
    import("./components/site-dependents/outlet-list"),
);
const MenuList = React.lazy(() =>
    import("./components/site-dependents/menu-list"),
);
const OutletComponentWrapper = React.lazy(() =>
    import("./components/site-dependents/outlet-wrapper"),
);

const MultiOrderDetail = React.lazy(() =>
    import("./components/multi-order-detail"),
);

const BaseRouter = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize([
            {
                trackingId: apiConsts.REACT_MEASUREMENT_ID,
                testMode: apiConsts.DEV,
            },
        ]);
    }, []);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location?.pathname + location?.search,
        });
    }, [location.pathname]);

    return (
        <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/welcome-page" element={<WelcomePage />} />
            <Route path="/redirect-adyen" element={<RedirectAdyen />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
                path={`${constants.ROUTES.CUSTOMER}`}
                element={<Account />}
            />
            <Route
                path={`${constants.ROUTES.CUSTOMER_DETAIL}`}
                element={<Profile />}
            />
            <Route
                path={`${constants.ROUTES.OUTLET_DETAIL}`}
                element={<ComponentWrapper />}
            >
                <Route path="" element={<Outlets />} />
                <Route
                    path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}`}
                    element={<Services />}
                />
                <Route
                    path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}/menus/:menuId`}
                    element={<MenuItems />}
                />
                <Route
                    exact
                    path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}/basket`}
                    element={<Cart />}
                />
                <Route
                    exact
                    path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}/checkout`}
                    element={<Checkout />}
                />
            </Route>

            <Route
                path={`${constants.ROUTES.SITE_DETAIL}`}
                element={<SiteWrapper />}
            >
                <Route exact path="" element={<SiteDetail />} />
                {/* <Route exact path={`${constants.ROUTES.OUTLET}`} element={<OutletList />} /> */}
                <Route
                    path="activate-membership"
                    element={<ActivateMembership />}
                />
                <Route
                    path="activate-site-membership"
                    element={<ActivateSiteMembership />}
                />
                <Route
                    exact
                    path={`${constants.ROUTES.ZONE_DETAIL}`}
                    element={
                        <Suspense fallback={<Loader />}>
                            <ZoneDetail />
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path={`${constants.ROUTES.ZONE_DETAIL}/${constants.ROUTES.OUTLET_DETAIL}`}
                    element={<ComponentWrapper />}
                >
                    <Route path="" element={<Outlets />} />
                    <Route
                        path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}`}
                        element={<Services />}
                    />
                    <Route
                        path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}/menus/:menuId`}
                        element={<MenuItems />}
                    />
                    <Route
                        exact
                        path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}/basket`}
                        element={<Cart />}
                    />
                    <Route
                        exact
                        path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}/checkout`}
                        element={<Checkout />}
                    />
                </Route>
                <Route
                    exact
                    path={`${constants.ROUTES.OUTLET_DETAIL}`}
                    element={<ComponentWrapper />}
                >
                    <Route path="" element={<Outlets />} />
                    <Route
                        path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}`}
                        element={<Services />}
                    />
                    <Route
                        path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}/menus/:menuId`}
                        element={<MenuItems />}
                    />
                    <Route
                        exact
                        path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}/basket`}
                        element={<Cart />}
                    />
                    <Route
                        exact
                        path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}/checkout`}
                        element={<Checkout />}
                    />
                </Route>

                {/* SITE SERVICE TYPE ROUTES */}
                <Route
                    exact
                    path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}`}
                    element={
                        <Suspense fallback={<Loader />}>
                            <OutletList />
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path={`${constants.ROUTES.ZONE_DETAIL}/${constants.ROUTES.SERVICE_TYPE_DETAIL}`}
                    element={
                        <Suspense fallback={<Loader />}>
                            <OutletList />
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path={`${constants.ROUTES.ZONE_DETAIL}/${constants.ROUTES.SERVICE_TYPE_DETAIL}/${constants.ROUTES.OUTLET_DETAIL}`}
                    element={
                        <Suspense fallback={<Loader />}>
                            <SiteWrapper />
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path={`${constants.ROUTES.SERVICE_TYPE_DETAIL}/${constants.ROUTES.OUTLET_DETAIL}`}
                    element={
                        <Suspense fallback={<Loader />}>
                            <OutletComponentWrapper />
                        </Suspense>
                    }
                >
                    <Route
                        exact
                        path={`${constants.ROUTES.MENUS}`}
                        element={
                            <Suspense fallback={<Loader />}>
                                <MenuList />
                            </Suspense>
                        }
                    />
                    <Route
                        exact
                        path={`${constants.ROUTES.MENUS_DETAIL}`}
                        element={<MenuItems />}
                    />
                    <Route
                        exact
                        path={`${constants.ROUTES.BASKET}`}
                        element={<Cart />}
                    />
                    <Route
                        exact
                        path={`${constants.ROUTES.CHECKOUT}`}
                        element={<Checkout />}
                    />
                </Route>
            </Route>
            <Route
                exact
                path={`${constants.ROUTES.ORDER}`}
                element={<YourOrders />}
            />
            <Route
                exact
                path={`${constants.ROUTES.MULTI_ORDER_DETAIL}`}
                element={
                    <Suspense fallback={<Loader />}>
                        <MultiOrderDetail />
                    </Suspense>
                }
            />
            <Route
                exact
                path={`${constants.ROUTES.ORDER_DETAILS}`}
                element={<YourOrder />}
            />
            <Route
                exact
                path={`${constants.ROUTES.PAYMENT_DETAILS}`}
                element={<PaymentLink />}
            />
            <Route
                exact
                path={`${constants.ROUTES.ORDER_DETAILS}/${constants.ROUTES.ISSUE}`}
                element={<IssueList />}
            />
            <Route
                exact
                path={`${constants.ROUTES.ORDER_DETAILS}/${constants.ROUTES.ISSUE_DETAIL}`}
                element={<IssueMessage />}
            />
            <Route
                exact
                path={`${constants.ROUTES.ORDER_DETAILS}/${constants.ROUTES.FEEDBACK}`}
                element={<Feedback />}
            />
            <Route
                exact
                path={`${constants.ROUTES.ORDER_DETAILS}/${constants.ROUTES.FEEDBACK_REDIRECT}`}
                element={<ThankYouForFeedback />}
            />
            <Route
                exact
                path={`${constants.ROUTES.LOYALTY}`}
                element={<Loyalty />}
            />
            <Route
                exact
                path={`${constants.ROUTES.LOYALTY_OUTLET}`}
                element={<LoyaltyOutlet />}
            />
            <Route
                exact
                path={`${constants.ROUTES.LOYALTY_POINTS}`}
                element={<LoyaltyPoints />}
            />
            <Route exact path="/" element={<Logout />} />
            <Route path="*" element={<NotFoundComponent />} />
        </Routes>
    );
};

export default BaseRouter;
