import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

const CustomModal = ({
    showHeader = true,
    showClose = true,
    ...modalProps
}) => {
    const { siteId } = useParams();
    const backgroundColor =
        (siteId
            ? modalProps.siteData.primaryColor
            : modalProps?.outletData?.primaryColor
            ? modalProps?.outletData?.primaryColor
            : "black") || "#000";
    return (
        <Modal
            isOpen={modalProps.isOpen || false}
            onRequestClose={modalProps.onRequestClose}
            style={modalStyle.modalContainer}
            portalClassName={modalProps.modalContainerClass}
            ariaHideApp={false}
        >
            <div style={modalStyle.contentContainer}>
                {showHeader ? (
                    <div
                        style={modalStyle.headerStyle}
                        className={
                            modalProps.headerClass ||
                            "box-container custom-header-wrapper header"
                        }
                    >
                        <div className="header-title">{modalProps.title}</div>
                        {showClose && modalProps.onRequestClose ? (
                            <AiOutlineClose
                                onClick={modalProps.onRequestClose}
                                size={40}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                ) : null}
                {!modalProps.hideDivider && (
                    <div
                        style={{
                            ...modalStyle.divider,
                            backgroundColor: backgroundColor,
                        }}
                    ></div>
                )}
                {modalProps.children}
            </div>
        </Modal>
    );
};

const modalStyle = {
    headerTitle: {
        fontFamily: "Europa Bold",
        fontSize: "2rem",
    },
    modalContainer: {
        overlay: {
            margin: "auto",
            maxWidth: "768px",
            zIndex: 1000,
        },
        content: {
            top: "0",
            left: "auto",
            right: "auto",
            bottom: "auto",
            width: "100vw",
            height: "calc(100*var(--vh, 1vh))",
            padding: 0,
            margin: "auto",
            maxWidth: "768px",
        },
    },
    contentContainer: {
        // marginTop: "1.2rem",
        // padding: "20px"
    },
    divider: {
        display: "flex",
        height: "1px",
        backgroundColor: "#000",
    },
};

const mapStateToProps = (state) => {
    return {
        outletData: state.outletData,
        siteData: state.siteData,
    };
};

export default connect(mapStateToProps, null)(CustomModal);
