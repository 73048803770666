import { useMemo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    useStripe,
    useElements,
    PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";

import "./PaymentGateway.scss";
import constants from "../../constants/constants";
import { round2digit } from "../../common/utils";

const useGetWalletOptions = (paymentRequest) => {
    const options = useMemo(
        () => ({
            paymentRequest,
            style: {
                paymentRequestButton: {
                    theme: "light",
                    height: "55px",
                },
            },
        }),
        [paymentRequest],
    );

    return options;
};

const usePaymentRequest = ({ options, onPaymentMethod, setShowPayLoader }) => {
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [canMakePayment, setCanMakePayment] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (stripe && elements && paymentRequest === null) {
            const pr = stripe.paymentRequest(options);
            setPaymentRequest(pr);
        }
    }, [stripe, elements, options, paymentRequest]);

    useEffect(() => {
        if (paymentRequest) {
            paymentRequest
                .canMakePayment()
                .then((res) => {
                    if (res) {
                        setCanMakePayment(true);
                        setShowPayLoader(false);
                    } else {
                        setShowPayLoader(false);
                    }
                })
                .catch((err) => {
                    setShowPayLoader(false);
                });
        }
        return;
    }, [paymentRequest]);

    useEffect(() => {
        if (paymentRequest) {
            paymentRequest.on("paymentmethod", onPaymentMethod);
        }
        return () => {
            if (paymentRequest) {
                paymentRequest.off("paymentmethod", onPaymentMethod);
            }
        };
    }, [paymentRequest, onPaymentMethod]);

    return canMakePayment ? paymentRequest : null;
};

export const WalletPayment = ({ basket, placeOrder, setShowPayLoader }) => {
    const stripe = useStripe();
    const navigate = useNavigate();
    let isZeroDecimalCountry = false;
    if (basket.currency) {
        isZeroDecimalCountry =
            basket.currency in constants.ZERO_DECIMAL_COUNTRIES;
    }

    const paymentRequest = usePaymentRequest({
        options: {
            country: "GB", // stripe account country code
            currency: basket.currency || "gbp",
            total: {
                label: "NOQ Events Ltd",
                amount: isZeroDecimalCountry
                    ? basket.totalAmount
                    : round2digit(round2digit(basket.totalAmount) * 100) || 0,
            },
        },
        onPaymentMethod: ({ complete, paymentMethod, ...data }) => {
            const orderIdPromise = placeOrder(paymentMethod, stripe, complete);
            orderIdPromise.then((order) => {
                if (order && order.cartId && order.isMultiOrder === true) {
                    navigate(`/multi/orders/${order.cartId}`, {
                        replace: true,
                    });
                    return;
                } else if (order && order.orderId) {
                    navigate(`/orders/${order.orderId}`, { replace: true });
                    return;
                }
            });
        },
        setShowPayLoader,
    });

    const options = useGetWalletOptions(paymentRequest);
    return (
        paymentRequest && (
            <PaymentRequestButtonElement
                className="wallet_payment"
                options={options}
                onClick={(event) => {}}
            />
        )
    );
};
