import { AUTOMATED_PROMO_PROMOTION } from "../../common/utils";
import { updateObject } from "../actions/utility";

const storedCart = JSON.parse(localStorage.getItem("cartItems")) || {};

export const productsInitialState = {
    products: [],
    productsError: null,
    loadingProducts: true,
    vendorId: null,
    cart: { ...storedCart },
};

export const addVendorId = (state, action) => {
    return updateObject(state, {
        vendorId: action.payload,
    });
};

export const addItemsToCart = (state, action) => {
    localStorage.setItem("cartItems", JSON.stringify(action.payload));
    return updateObject(state, {
        cart: action.payload,
    });
};

export const getUserCart = (state, action) => {
    localStorage.setItem("cartItems", JSON.stringify(action.payload));
    return updateObject(state, {
        cart: action.payload,
    });
};

export const emptyCart = (state, action) => {
    localStorage.removeItem("cartItems");

    // [
    //   "serviceData",
    //   "outletData",
    //   "prepaidEmail",
    //   "menuPayType",
    //   "prepaidMaxProduct"
    // ].forEach((value) => {
    //   sessionStorage.removeItem(value);
    // })

    return updateObject(state, {
        cart: {},
    });
};

export const setReload = (state, action) => {
    return updateObject(state, {
        menuReload: action.payload,
    });
};

export const setOutletData = (state, action) => {
    sessionStorage.setItem("outletData", JSON.stringify(action.payload));
    return updateObject(state, {
        outletData: action.payload,
    });
};

export const setServiceData = (state, action) => {
    localStorage.setItem("serviceData", JSON.stringify(action.payload));
    return updateObject(state, {
        serviceData: action.payload,
    });
};

export const removeServiceData = (state, action) => {
    localStorage.removeItem("serviceData");
    return updateObject(state, {
        serviceData: action.payload,
    });
};

export const setSiteData = (state, action) => {
    if (action.payload && action.payload.assignedPromotions) {
        localStorage.setItem(
            "assignedPromotions",
            JSON.stringify(action.payload.assignedPromotions),
        );
    } else {
        localStorage.removeItem("assignedPromotions");
    }
    localStorage.setItem(
        "isDisableSocialLogin",
        action.payload?.isDisableSocialLogin || false,
    );
    return updateObject(state, {
        siteData: action.payload,
        isDisableSocialLogin: action.payload?.isDisableSocialLogin,
    });
};

export const setCartLoading = (state, action) => {
    return updateObject(state, {
        cartLoading: action.payload,
    });
};

export const setMenuPayType = (state, action) => {
    sessionStorage.setItem("menuPayType", action.payload);
    return updateObject(state, {
        menuPayType: action.payload,
    });
};

export const setPrepaidMaxProduct = (state, action) => {
    sessionStorage.setItem("prepaidMaxProduct", action.payload);
    return updateObject(state, {
        prepaidMaxProduct: action.payload,
    });
};

export const setPrepaidEmail = (state, action) => {
    sessionStorage.setItem("prepaidEmail", action.payload);
    return updateObject(state, {
        prepaidEmail: action.payload,
    });
};

export const setZoneData = (state, action) => {
    localStorage.setItem("zoneData", JSON.stringify(action.payload));
    if (!action.payload) {
        localStorage.removeItem("zoneData");
    }
    return updateObject(state, {
        zoneData: action.payload,
    });
};

export const setQuantityLoader = (state, action) => {
    return updateObject(state, {
        quantityLoader: action.payload,
    });
};
