const apiConsts = {
    portalURL: process.env.REACT_APP_PORTAL_URL,
    serverURL: process.env.REACT_APP_SERVER_URL,
    imageURL: process.env.REACT_APP_IMAGE_URL,
    socketURL: process.env.REACT_APP_SOCKET_URL,
    placesApiKey: process.env.REACT_APP_PLACES_API_KEY,
    placesApiUrl: process.env.REACT_APP_PLACES_API_URL,
    isSquareEnabled: process.env.REACT_APP_SQUARE_ENABLED === "true",
    outletURI: "/v2/web/outlet",
    orderURI: "/v2/web/orders",
    upsellURI: "/v2/web/find-upsell-item",
    multiOrderURI: "/v2/web/multi/orders",
    paymentURI: "/v2/web/payments",
    siteURI: "/v2/web/sites",
    serviceTypeURI: "/v2/web/service-type",
    stockURI: "/v2/web/check-items-stock",
    usersURI: "/v2/users",
    customerURI: "/v2/web/customer",
    filledSlotURL: "/v2/web/get-pre-slot-counter",
    feedbackURI: "/v2/feedback",
    paymentLinkURI: "/v2/payment-link/web",
    issueURI: "/v2/report-issue",
    loyaltyURI: "/v2/web/loyalty",
    webURI: "/v2/web/menu",
    prepaidURI: "/prepaid-information",
    userPromotionURI: "/v2/web/user-promotion",
    getPromoCodePromotionURI: "/v2/web/find-promotion-by-promo-code",
    adyenPaymentSessionURI: "/v2/web/create-adyen-payment-session",
    adyenClientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
    gatewayMerchantId: process.env.REACT_APP_ADYEN_ONLINE_MERCHANT_ID,
    gatewayMerchantIdUS: process.env.REACT_APP_ADYEN_ONLINE_MERCHANT_ID_US,
    DEV: process.env.REACT_APP_DEV === "true",
    stripeKey: process.env.REACT_APP_STRIPE_PUBLISH_KEY,
    REACT_MEASUREMENT_ID: process.env.REACT_APP_MEASUREMENT_ID,
};

export default apiConsts;
