import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "../../common/useQuery";
import constants from "../../constants/constants";
import CustomButton from "../custom-button";
import { CustomInput } from "../custom-input";
import { Loader } from "../custom-loader";
import SiteOutletList from "../site-outlet-list";

const GeographicalArea = ({
    site,
    className,
    isLoading,
    siteAutomatedPromo,
}) => {
    const [postCode, setPostCode] = useState();
    const navigate = useNavigate();
    const query = useQuery();
    const location = useLocation();

    useEffect(() => {
        if (query.has("postalCode")) {
            setPostCode(query.get("postalCode"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const postalCodeTrimmed = postCode ? postCode.replace(/\s/g, "") : "";

    if (
        site.siteNavigationType === constants.SITE_NAVIGATION_TYPE.ATOZ ||
        query.has("postalCode")
    ) {
        if (isLoading)
            return (
                <div className={`zone-list ${className}`}>
                    <Loader />
                </div>
            );
        return (
            <>
                {site.outlets && site.outlets.length ? (
                    <>
                        <div className="row">
                            <div className="column-two">
                                Select Available Stores{" "}
                                {postalCodeTrimmed
                                    ? `around ${postalCodeTrimmed}`
                                    : ""}
                            </div>
                        </div>
                        <div className={`zone-list ${className}`}>
                            <SiteOutletList
                                outlets={site.outlets}
                                isGeographical={true}
                                siteAutomatedPromotion={siteAutomatedPromo}
                            />
                        </div>
                    </>
                ) : (
                    <h3> No Stores Available</h3>
                )}
            </>
        );
    }

    let navigateUrl = location.pathname;
    if (query.has("authType")) {
        navigateUrl += "?authType=" + query.get("authType");
    }
    if (postalCodeTrimmed) {
        navigateUrl +=
            (navigateUrl.indexOf("?") === -1 ? "?" : "&") +
            "postalCode=" +
            postalCodeTrimmed;
    }

    return (
        <>
            <div className="site-postal-code-form">
                <div className="row post-code-container">
                    <div className="column-two">Enter Postal Code </div>
                    <div className="column-two">
                        <CustomInput
                            onChange={(e) => setPostCode(e)}
                            name="postcode"
                            value={postCode}
                            placeHolder="Postcode"
                            className="label-input"
                        />
                    </div>
                </div>
                <CustomButton
                    className={"postcode-btn btn-block"}
                    type="button"
                    onClick={() => navigate(navigateUrl)}
                    disabled={
                        !postCode ||
                        postalCodeTrimmed.length < 4 ||
                        postalCodeTrimmed.length > 7
                    }
                >
                    Check
                </CustomButton>
            </div>
        </>
    );
};

export default GeographicalArea;
