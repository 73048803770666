import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CartList from "./cart-list";
import CustomButton from "../custom-button";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import "./cart.scss";
import constants from "../../constants/constants";
import { Loader, LoaderWithChildren } from "../custom-loader";
import {
    calculateRemainingQty,
    prePaidBasketToRequest,
} from "../../common/utils";
import { notify } from "../../common/utils";
import { apiService } from "../../services";
import {
    clearPrepaidInformation,
    emptyCart,
} from "../../store/actions/ProductActions";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { CustomInput } from "../custom-input";
import MainHeader from "../common/MainHeader";
import CustomModal from "../custom-modal";
import UpsellingModal from "./upselling/upsellingModal";

const Cart = (props) => {
    const [loading, setLoading] = useState(false);
    const [enableUpsellingItems, setEnableUpsellingItems] = useState(false);
    const [upsellingData, setUpSellingData] = useState([]);
    const navigate = useNavigate();
    const {
        basket,
        outletData,
        cartLoading,
        menuPayType,
        prepaidEmail,
        removeCart,
        serviceData,
        user,
        prepaidMaxProduct,
    } = props;

    // eslint-disable-next-line no-unused-vars
    const [initialState, setInitialState] = useState({
        name:
            user && user.firstName ? user.firstName + " " + user?.lastName : "",
        tableNumber: serviceData?.vendorZoneName
            ? serviceData?.vendorZoneName
            : serviceData?.tableNumber
            ? serviceData?.tableNumber
            : "",
        prePopulatedField: serviceData?.vendorZoneName
            ? "vendorZoneName"
            : "tableNumber",
    });
    const [formData, setFormData] = useState(initialState);

    const { siteId, zoneId, outletId, serviceType } = useParams();
    let redirectUrl = zoneId
        ? `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.ZONE}/${zoneId}/${constants.ROUTES.OUTLET}/${outletId}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`
        : siteId
        ? `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.OUTLET}/${outletId}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`
        : `/${constants.ROUTES.OUTLET}/${outletId}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`;

    const location = useLocation();
    if (location.pathname && location.pathname.indexOf("/basket") > -1) {
        redirectUrl = location.pathname.replace("/basket", "");
    }

    let schema = {
        name: yup.string().trim().required().label("Full Name"),
    };
    if (serviceType === "onsiteDelivery") {
        schema["tableNumber"] = yup
            .string()
            .trim()
            .required()
            .label("Table Number");
    }
    const validationSchema = yup.object().shape(schema);

    const allowCheckout = () => {
        if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) return false;
        if (outletData?.settings[serviceType]?.absoluteMinOrder) {
            return (
                basket.subTotal <
                outletData?.settings[serviceType]?.absoluteMinOrder
            );
        } else {
            return !basket.subTotal;
        }
    };
    const doAutoRedirect = (data) => {
        if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
            prepaidConfirmOrder({ ...formData, name: data?.name });
        } else {
            setLoading(false);
            navigate(`${redirectUrl}/checkout`);
        }
    };

    const prepaidConfirmOrder = (values) => {
        setLoading(true);
        let vendorZoneName = serviceData?.vendorZoneName
            ? serviceData?.vendorZoneName
            : "";
        let tableNumber = serviceData?.tableNumber
            ? serviceData?.tableNumber
            : "";
        if (values.prePopulatedField === "vendorZoneName") {
            vendorZoneName = values.tableNumber;
        } else {
            tableNumber = values.tableNumber;
        }
        const singleBasket = basket.carts[0];
        apiService
            .createFreeOrder(
                prePaidBasketToRequest(
                    singleBasket,
                    outletId,
                    serviceData,
                    prepaidEmail,
                    values.name,
                    tableNumber,
                    vendorZoneName,
                ),
            )
            .then((orderDetails) => {
                removeCart();
                navigate(`/orders/${orderDetails.data._id}`, { replace: true });
            })
            .catch((err) => {
                setLoading(false);
                notify(err.message);
            });
    };

    const handleCheckout = (data) => {
        setFormData(data);
        if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
            let remainingQty = 0;
            if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
                const basketItems =
                    basket?.carts && basket?.carts[0]?.items
                        ? basket?.carts[0]?.items
                        : [];
                remainingQty = calculateRemainingQty(
                    prepaidMaxProduct,
                    basketItems,
                );
            }
            if (remainingQty === 0) {
                doAutoRedirect(data);
                return;
            }
        }

        const menuIdsVendorWise = basket.carts
            .map((cartItem) => {
                return cartItem.items.map((item) => {
                    return {
                        menuId: item.menuRef,
                        vendorRef: cartItem.vendorRef,
                        siteRef: cartItem?.site?.siteRef,
                    };
                });
            })
            ?.flatMap((e) => e);

        const menuIdsVendorWiseNoDuplicates = Object.values(
            menuIdsVendorWise.reduce((acc, obj) => {
                const key = `${obj.menuId}-${obj.vendorRef}`; // Combine the keys as a unique identifier
                if (!acc[key]) {
                    acc[key] = obj; // Add the object to the accumulator object
                }
                return acc;
            }, {}),
        );

        if (
            !menuIdsVendorWiseNoDuplicates ||
            menuIdsVendorWiseNoDuplicates.length === 0
        ) {
            return;
        }
        setLoading(true);
        apiService
            .getUpsellItems({
                menuIds: menuIdsVendorWiseNoDuplicates,
                serviceType,
            })
            .then((resp) => {
                if (resp && resp.length) {
                    const allBasketItems = basket.carts
                        ?.map((e) => e?.items?.map((item) => item.itemRef))
                        .flatMap((e) => e);
                    let structuredResponse = [];
                    let tempStores = [];
                    resp.forEach((store) => {
                        if (store.menu && store.menu.length) {
                            let tempMenus = [];
                            store.menu.forEach((menu) => {
                                let filteredMenuItems = [];
                                filteredMenuItems = menu.items.filter(
                                    (record) => {
                                        return !allBasketItems.includes(
                                            record.item._id,
                                        );
                                    },
                                );
                                tempMenus.push({
                                    ...menu,
                                    items: filteredMenuItems,
                                });
                            });
                            tempStores.push({
                                ...store,
                                menu: tempMenus,
                            });
                        }
                        structuredResponse = [...tempStores];
                    });

                    const frequentItemIds = structuredResponse
                        .flatMap((store) => store.menu)
                        .flatMap((menu) => menu.items)
                        .map((e) => e.itemRef);
                    const items = basket.carts
                        .map((e) => e.items.map((item) => item.itemRef))
                        .flatMap((e) => e);
                    const filteredFrequentItemIds = frequentItemIds.filter(
                        (item) => !items.includes(item),
                    );
                    if (filteredFrequentItemIds.length === 0) {
                        doAutoRedirect(data);
                    }
                    if (structuredResponse.length) {
                        setUpSellingData(structuredResponse);
                        setLoading(false);
                        setEnableUpsellingItems(true);
                    } else {
                        doAutoRedirect(data);
                    }
                } else {
                    doAutoRedirect(data);
                }
            })
            .catch((err) => {
                setLoading(false);
                doAutoRedirect(data);
            });
    };

    return (
        <div className="cart-page">
            <MainHeader
                name={"Your Basket"}
                showBackButton={true}
                showBottomLine={true}
            />
            {Object.keys(basket).length === 0 || !basket.carts ? (
                <>
                    <div className={"cart-small-list"}>
                        <div className="box-container display-vertical-center">
                            <h4 className="m-0"> Oops!, No items in cart</h4>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div
                        className={
                            menuPayType === constants.MENU_PAY_TYPE.PREPAID
                                ? serviceType === "onsiteDelivery"
                                    ? "cart-list-small"
                                    : "cart-list-medium"
                                : "cart-list-long"
                        }
                    >
                        <CartList />
                    </div>
                    <div className="sticky-footer cart-wrapper-footer">
                        <div className="separator separator-theme"></div>
                        <div className="box-container footer">
                            {menuPayType !== constants.MENU_PAY_TYPE.PREPAID ? (
                                <>
                                    <div className="cart-total-section">
                                        <span>Subtotal</span>
                                        <span>
                                            <FormattedNumber
                                                style={`currency`}
                                                currencyDisplay="narrowSymbol"
                                                value={basket.subTotal}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                                currency={
                                                    outletData?.currency ||
                                                    "gbp"
                                                }
                                            />
                                        </span>
                                    </div>
                                    <CustomButton
                                        loader={loading}
                                        disabled={allowCheckout() || loading}
                                        onClick={handleCheckout}
                                        className="btn-block"
                                    >
                                        Go to Checkout
                                    </CustomButton>
                                </>
                            ) : (
                                <div style={{ width: "100%" }}>
                                    <Formik
                                        initialValues={initialState}
                                        validationSchema={validationSchema}
                                        onSubmit={handleCheckout}
                                        validateOnBlur={true}
                                        validateOnMount={true}
                                        enableReinitialize={true}
                                    >
                                        {(props) => (
                                            <Form onSubmit={props.handleSubmit}>
                                                <div className="cart-prepaid-form">
                                                    <div className="form-group  w-100">
                                                        <label>
                                                            Insert Your Full
                                                            Name*
                                                        </label>
                                                        <CustomInput
                                                            type="text"
                                                            name="name"
                                                            className="cart-prepaid-input"
                                                            placeHolder="Insert Full Name"
                                                            value={
                                                                props.values
                                                                    .name
                                                            }
                                                            onChange={(value) =>
                                                                props.setFieldValue(
                                                                    "name",
                                                                    value,
                                                                )
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            name="name"
                                                            component="div"
                                                            className="error"
                                                        />
                                                    </div>
                                                    {serviceType ===
                                                    "onsiteDelivery" ? (
                                                        <div className="form-group  w-100">
                                                            <label>
                                                                Insert{" "}
                                                                {serviceData?.locationTitleCustom?.trim() !==
                                                                ""
                                                                    ? serviceData.locationTitleCustom
                                                                    : "Table Number"}
                                                                *
                                                            </label>
                                                            <CustomInput
                                                                type="text"
                                                                name="tableNumber"
                                                                className="cart-prepaid-input"
                                                                placeHolder={`Insert ${
                                                                    serviceData?.locationTitleCustom?.trim() !==
                                                                    ""
                                                                        ? serviceData.locationTitleCustom
                                                                        : "Table Number"
                                                                }`}
                                                                value={
                                                                    props.values
                                                                        .tableNumber
                                                                }
                                                                onChange={(
                                                                    value,
                                                                ) =>
                                                                    props.setFieldValue(
                                                                        "tableNumber",
                                                                        value,
                                                                    )
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="tableNumber"
                                                                component="div"
                                                                className="error"
                                                            />
                                                        </div>
                                                    ) : null}
                                                    <CustomButton
                                                        disabled={loading}
                                                        loader={loading}
                                                        type="submit"
                                                        className="btn-block"
                                                    >
                                                        Confirm Order
                                                    </CustomButton>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
            {cartLoading && (
                <div className="loader-overlay">
                    <LoaderWithChildren />
                </div>
            )}
            <CustomModal
                isOpen={enableUpsellingItems}
                onRequestClose={() => setEnableUpsellingItems(false)}
                hideDivider={true}
                title={"Frequently Bought Together"}
                modalContainerClass={"modifier-modal-class"}
            >
                <UpsellingModal
                    prepaidConfirmOrder={doAutoRedirect}
                    menuPayType={menuPayType}
                    upsellingData={upsellingData}
                    close={() => setEnableUpsellingItems(false)}
                />
            </CustomModal>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        outletData: state.outletData,
        basket: state.cart,
        serviceData: state.serviceData,
        menuPayType: state.menuPayType,
        prepaidEmail: state.prepaidEmail,
        user: state.user,
        cartLoading: state.cartLoading,
        prepaidMaxProduct: state.prepaidMaxProduct,
    };
};

const mapDispatchToPros = (dispatch) => {
    return {
        removeCart: () => dispatch(emptyCart()),
        removePrepaidInfo: () => dispatch(clearPrepaidInformation()),
    };
};

export default connect(mapStateToProps, mapDispatchToPros)(Cart);
