import * as actionTypes from "./ActionTypes";

export const setUser = (value) => {
    return {
        type: actionTypes.SET_USER,
        payload: value,
    };
};

export const setUniqueMetricId = (value) => {
    return {
        type: actionTypes.SET_UNIQUE_METRIC_ID,
        payload: value,
    };
};

export const setUniqueDeviceId = (value) => {
    return {
        type: actionTypes.SET_UNIQUE_DEVICE_ID,
        payload: value,
    };
};

export const setGuestSession = (value) => {
    return {
        type: actionTypes.SET_GUEST_SESSION,
        payload: value,
    };
};

export const setZoneData = (value) => {
    return {
        type: actionTypes.SET_ZONE_DATA,
        payload: value,
    };
};

export const setRedirectURL = (value) => {
    return {
        type: actionTypes.SET_REDIRECT_URL,
        payload: value,
    };
};
