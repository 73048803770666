import { useNavigate, useLocation } from "react-router";
import CustomButton from "../custom-button";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import NoqLogo from "../../assets/imgs/logo.png";
import "../logout/logout.scss";
import constants from "../../constants/constants";

const ThankYouForFeedback = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const orderDetails = location.state?.orderDetails;

    const redirectUrl = (orderDetails) => {
        let url = "/";

        if (orderDetails) {
            if (orderDetails.vendor && orderDetails.vendor._id) {
                url = `/${constants.ROUTES.OUTLET}/${orderDetails.vendor._id}`;
            } else if (orderDetails.site && orderDetails.site.siteRef) {
                url = `/${constants.ROUTES.SITE}/${orderDetails.site.siteRef}`;

                if (
                    orderDetails.site.displayName &&
                    orderDetails.siteZones &&
                    orderDetails.siteZones.siteZoneRef
                ) {
                    url += `/${constants.ROUTES.ZONE}/${orderDetails.siteZones.siteZoneRef}`;
                }
            }
        }
        navigate(url, { replace: true });
    };
    return (
        <div className="logout-container">
            <div className="logout-header">
                <div className="title-with-icon">
                    <CgProfile size={35} />
                    <h2>Thank You For Feedback!</h2>
                </div>
                <div>
                    <span onClick={() => redirectUrl(orderDetails)}>
                        <AiOutlineCloseCircle size={35} />
                    </span>
                </div>
            </div>
            <div className="mt-100">
                {orderDetails &&
                orderDetails.site &&
                orderDetails.site.siteRef === null ? (
                    <CustomButton
                        type="button"
                        className="btn-block btn-bw"
                        onClick={() =>
                            navigate(
                                `/${constants.ROUTES.OUTLET}/${orderDetails.vendor._id}`,
                            )
                        }
                    >
                        Order Again
                    </CustomButton>
                ) : null}
            </div>
            <div className="footer">
                <div className="greyed-text">Powered by</div>
                <div>
                    <img src={NoqLogo} alt="noq-logo" />
                </div>
            </div>
        </div>
    );
};

export default ThankYouForFeedback;
