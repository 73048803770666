import { useNavigate, useParams } from "react-router-dom";
import constants from "../../constants/constants";
import InfoMessage from "../common/InfoMessage";
import CustomButton from "../custom-button";
import ServiceTypeList from "../site-dependents/service-type-list";
import SiteOutletList from "../site-outlet-list";

const zoneList = (zones = [], navigate, siteId) => {
    return zones.map((zone, key) => {
        return (
            <CustomButton
                key={key}
                className="btn-block button-detail"
                onClick={() =>
                    navigate(
                        `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.ZONE}/${zone._id}`,
                        { state: { showBack: true } },
                    )
                }
            >
                {zone.name}
            </CustomButton>
        );
    });
};
const NonGeographicalArea = ({
    settings,
    outlets,
    zones,
    className,
    siteType,
    siteAutomatedPromo,
}) => {
    const navigate = useNavigate();
    const { siteId } = useParams();

    if (
        siteType === constants.SITE_CATEGORY.SITE_DEPENDENT &&
        zones &&
        zones.length
    ) {
        return (
            <>
                {zones && zones.length > 1 ? (
                    <InfoMessage
                        message={
                            "Please note that if you start building a cart with one of the below zones and then change, your cart will be deleted."
                        }
                    />
                ) : null}
                <div className="box-container zone-list-wrapper">
                    <div className="row">
                        <div className="column-two">Select Zone</div>
                    </div>
                    <div className={`zone-list ${className}`}>
                        {zoneList(zones, navigate, siteId)}
                    </div>
                </div>
            </>
        );
    }

    if (siteType === constants.SITE_CATEGORY.SITE_DEPENDENT) {
        return (
            <>
                {settings &&
                Object.values(settings).filter((v) => v.isActive === true)
                    .length > 1 ? (
                    <InfoMessage />
                ) : null}
                <div className="box-container zone-list-wrapper">
                    <ServiceTypeList />
                </div>
            </>
        );
    }

    if (outlets.length || zones.length) {
        return (
            <>
                {zones && zones.length > 1 ? (
                    <InfoMessage
                        message={
                            "Please note that if you start building a cart with one of the below zones and then change, your cart will be deleted."
                        }
                    />
                ) : null}
                <div className="box-container zone-list-wrapper">
                    <div className="row">
                        <div className="column-two">
                            Select{" "}
                            {zones && zones.length ? "Zone" : "Available Store"}
                        </div>
                    </div>
                    <div className={`zone-list ${className}`}>
                        {zones.length ? (
                            zoneList(zones, navigate, siteId)
                        ) : (
                            <SiteOutletList
                                outlets={outlets}
                                siteAutomatedPromotion={siteAutomatedPromo}
                            />
                        )}
                    </div>
                </div>
            </>
        );
    }
    return (
        <div className="box-container zone-list-wrapper">
            <h3 className="">Not Accepting Orders</h3>
        </div>
    );
};

export default NonGeographicalArea;
