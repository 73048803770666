import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getLoyalties } from "../../services/api.service";
import { notify } from "../../common/utils";
import { Loader } from "../custom-loader";
import { getImageUrl } from "../../common/utils";
import { FormattedNumber } from "react-intl";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaAngleRight } from "react-icons/fa";
import "./loyalty-points.scss";

const LoyaltyPoints = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        setLoading(true);
        getLoyalties()
            .then((orders) => {
                setOrders(orders);
                setLoading(false);
            })
            .catch((err) => {
                notify(err);
                setLoading(false);
            });
    }, []);

    const getLoyaltyType = (loyalty) => {
        if (loyalty.type === "SITE") {
            if (loyalty.assignedSites.length > 1) {
                return "Multi Site";
            } else if (loyalty.assignedSites.length === 1) {
                return loyalty.assignedSites[0].displayName;
            } else {
                return "No Site";
            }
        } else {
            if (loyalty.assignedOutlets.length > 1) {
                return "Multi Outlets";
            } else if (loyalty.assignedOutlets.length === 1) {
                return loyalty.assignedOutlets[0].displayName;
            } else {
                return "No Outlets";
            }
        }
    };

    if (loading) return <Loader />;
    return (
        <div className="loyalty-points-container">
            <div className="loyalty-header">
                <AiOutlineArrowLeft
                    size={35}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
                <div className="header-title">
                    <span>Current Points</span>
                </div>
            </div>
            <div className="loyalty-list">
                {orders.map((order) => {
                    return (
                        <Link
                            to={`${order._id}`}
                            state={{ from: "order-list" }}
                        >
                            <div className="item-card">
                                <div className="content">
                                    <h3>
                                        {order.loyaltyRef.name} -{" "}
                                        {getLoyaltyType(order.loyaltyRef)}
                                    </h3>
                                    <div className="loyalty-list-item-row">
                                        <div className="loyalty-qr-code-row">
                                            <img
                                                src={getImageUrl(
                                                    order.qrCodeImage,
                                                )}
                                                alt="loyalty-qr-code"
                                            />
                                        </div>
                                        <div>
                                            <p>Loyalty Points Collected</p>
                                            <p>{order.points}</p>
                                            <p>
                                                Redeemable Value:{" "}
                                                <FormattedNumber
                                                    value={
                                                        order.points *
                                                        order.loyaltyRef
                                                            .redeemValue
                                                    }
                                                    currency={
                                                        order?.currency || "gbp"
                                                    }
                                                    style={`currency`}
                                                    currencyDisplay="narrowSymbol"
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <span>
                                    <FaAngleRight size={30} />
                                </span>
                            </div>
                        </Link>
                    );
                })}
                {(!orders || orders.length === 0) && (
                    <h3> No Points are available!</h3>
                )}
            </div>
        </div>
    );
};

export default LoyaltyPoints;
