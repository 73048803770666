import { useState } from "react";
import { useNavigate } from "react-router";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CustomButton from "../custom-button";
import "../login/login.scss";
import { forgotPasswordCustomer } from "../../services/authenticate.service";
import { notify } from "../../common/utils";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(0);
    const customer = { email: "" };

    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .trim()
            .email("Invalid Email!")
            .required()
            .label("Email"),
    });

    const onSubmit = (values, { resetForm }) => {
        forgotPasswordCustomer(values.email)
            .then(() => {
                setVisible(1);
                resetForm();
            })
            .catch((error) => {
                notify("Email Not Found");
            });
    };
    return (
        <div className="login-flex-col">
            <div className="login-header">
                <div className="mr-1">
                    <AiOutlineArrowLeft
                        size={35}
                        onClick={() => {
                            navigate(-1);
                        }}
                    />
                </div>
                <div className="header-title">Forgot Password</div>
            </div>

            <Formik
                initialValues={customer}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(props) => (
                    <div
                        className="form-container"
                        style={{ marginTop: "2rem" }}
                    >
                        <div className="form-item">
                            <label className="form-label">Email*</label>
                            <input
                                {...props.getFieldProps("email")}
                                name="email"
                                type="email"
                                placeholder="Insert Email"
                                className="form-input"
                            />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="form-error"
                            />
                        </div>
                        <div>
                            <CustomButton
                                disabled={visible}
                                type="submit"
                                onClick={props.handleSubmit}
                                className="btn-block btn-bw"
                            >
                                Send Email
                            </CustomButton>
                        </div>
                    </div>
                )}
            </Formik>

            <div
                className="login-terms-conditions"
                style={{ opacity: visible }}
            >
                <div style={{ color: "#000", fontWeight: 900 }}>
                    Email Sent!
                </div>
                <div style={{ marginTop: "1rem" }}>
                    If you don't see the email
                </div>
                <div>check your "junk mail" folder or "spam" folder</div>
            </div>
        </div>
    );
};

export default ForgotPassword;
