/**
 * Note: this component is used in many please before change check in every places and verify changes you applied.
 * MenuItem Page, Outlet Selection
 */

import { useNavigate, useParams } from "react-router-dom";
import { getImageUrl } from "../../common/utils";
import constants from "../../constants/constants";
import "./index.scss";
import { IMAGE_SIZES } from "../../constants/image";

const formattedAddress = (values) => {
    let str = "";
    if (values.address) {
        str += values.address;
    }
    if (values.address2) {
        str += `, ${values.address2}`;
    }
    if (values.city) {
        str += `, ${values.city}`;
    }
    return str;
};

const redirectUrl = ({ siteId, zoneId, outletId, serviceType }) => {
    const sitePath = `/${constants.ROUTES.SITE}/${siteId}`;
    const zonePath = `${constants.ROUTES.ZONE}/${zoneId}`;
    const servicePath = `${constants.ROUTES.SERVICE_TYPE}/${serviceType}`;
    const outletPath = `${constants.ROUTES.OUTLET}/${outletId}`;
    if (siteId && zoneId && serviceType) {
        return `${sitePath}/${servicePath}/${outletPath}/menus`;
    }
    if (siteId && serviceType) {
        return `${sitePath}/${servicePath}/${outletPath}/menus`;
    }
    if (siteId && zoneId) {
        return `${sitePath}/${zonePath}/${outletPath}`;
    }
    return `${sitePath}/${outletPath}`;
};
const SiteOutletList = ({
    outlets,
    isGeographical = false,
    onSelectStore = null,
    siteAutomatedPromotion,
}) => {
    const navigate = useNavigate();
    const { siteId, zoneId, serviceType } = useParams();

    const onClickAction = ({ siteId, zoneId, outlet, serviceType }) => {
        if (onSelectStore) {
            onSelectStore(outlet);
        } else {
            navigate(
                redirectUrl({
                    siteId,
                    zoneId,
                    outletId: outlet.vendorId,
                    serviceType,
                }),
                { state: { showBack: true } },
            );
        }
    };
    return (outlets || []).map((outlet, key) => {
        const address = formattedAddress(outlet);
        return (
            <div
                className={
                    "list-container div-button row " +
                    (!outlet.outletImage ? "no-logo" : "")
                }
                key={key}
                onClick={() =>
                    onClickAction({ siteId, zoneId, outlet, serviceType })
                }
            >
                <div className="display-name">
                    <div className="heading">{outlet.displayName}</div>
                    {(siteAutomatedPromotion &&
                        Object.keys(siteAutomatedPromotion).length > 0) ||
                    (outlet?.automatedPromotion &&
                        Object.keys(outlet?.automatedPromotion).length > 0) ? (
                        <div className="description">
                            {siteAutomatedPromotion &&
                            siteAutomatedPromotion?.promotionRef
                                ? siteAutomatedPromotion?.promotionRef?.name
                                : outlet?.automatedPromotion?.name}
                        </div>
                    ) : null}
                    {outlet.description ? (
                        <div className="description">{outlet.description}</div>
                    ) : null}
                    {isGeographical && address ? (
                        <div className="description">{address}</div>
                    ) : null}
                    {isGeographical && outlet.distance ? (
                        <div className="description distance">
                            Distance: {outlet.distance} miles
                        </div>
                    ) : null}
                    {/* {outlet.estimatedPreparationTime ? <div className="description">Est. Prep. Time {constants.ESTIMATED_PREP_TIME_MAPPING[outlet.estimatedPreparationTime]}</div> : null } */}
                </div>
                {outlet.outletImage && (
                    <div className="logo">
                        <img
                            src={
                                getImageUrl(outlet.outletImage) +
                                IMAGE_SIZES.logo
                            }
                            onError={(e) => {
                                e.target.src = getImageUrl(outlet.outletImage);
                            }}
                            alt="Logo not found"
                        />
                    </div>
                )}
            </div>
        );
    });
};

export default SiteOutletList;
