import React, { useEffect, useState } from "react";
import "./paymentLink.scss";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { useParams } from "react-router-dom";
import CustomButton from "../custom-button";
import CustomCheckbox from "../custom-checkbox";
import { apiService } from "../../services";
import { FormattedNumber } from "react-intl";
import { Loader } from "../custom-loader";
import useQuery from "../../common/useQuery";

const PaymentLink = () => {
    const query = useQuery();
    const paymentSucceeded =
        query.get("paymentSucceeded") === "true" ? true : false;
    const { paymentId } = useParams();
    const [isAccepted, setIsAccepted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [paymentInvoiceDetail, setPaymentInvoiceDetail] = useState({});

    useEffect(() => {
        // fetch payment invoice
        setIsLoading(true);
        apiService
            .getPaymentLink(paymentId)
            .then((res) => {
                setPaymentInvoiceDetail(res);
            })
            .finally(() => setIsLoading(false));
    }, [paymentId]);

    const onPay = (e) => {
        e.preventDefault();
        window.location.href = paymentInvoiceDetail.adyenPaymentLink;
    };

    const formatOutletAddress = (outletData) => {
        let str = "";
        if (outletData?.address) {
            str += outletData?.address;
        }
        if (outletData?.address2) {
            str += `, ${outletData?.address2}`;
        }
        if (outletData?.city) {
            str += `, ${outletData?.city}`;
        }
        return str;
    };

    if (paymentSucceeded || paymentInvoiceDetail?.status === "COMPLETED") {
        return (
            <div className="paymentSucceeded">
                <h2 className="order-number">
                    Invoice Reference: {paymentInvoiceDetail?.reference || ""}
                </h2>
                <div className="icon">
                    <BsCheck2Circle />
                </div>
                <div>
                    <small>You will be notified by email!</small>
                </div>
            </div>
        );
    }
    if (
        paymentInvoiceDetail?.status === "EXPIRED" ||
        new Date(paymentInvoiceDetail.endDate).getTime() <= new Date().getTime()
    ) {
        return (
            <div className="paymentFailed">
                <div className="icon">
                    <BsXCircle />
                </div>
                <div>
                    <small>Link is Expired!</small>
                </div>
            </div>
        );
    }
    if (isLoading) return <Loader />;

    return (
        <div className="your-payment-invoice">
            <div className="box-container custom-header-wrapper header">
                <div className="header-left">
                    <div className="header-title">
                        <div className="header-ellipsis">
                            {paymentInvoiceDetail?.outletDetails?.displayName}
                        </div>
                        <small className="secondary-text-color header-address header-sub-title">
                            {formatOutletAddress(
                                paymentInvoiceDetail?.outletDetails,
                            )}
                        </small>
                    </div>
                </div>
            </div>
            <div className="separator separator-theme"></div>

            <div className="special">
                <h2 className="order-number">
                    Invoice Reference: {paymentInvoiceDetail?.reference || ""}
                </h2>

                {paymentInvoiceDetail?.shopperDetailsOption === "MANUAL" ? (
                    <div className="sub-section-payment">
                        {paymentInvoiceDetail?.shopperFirstName ? (
                            <div className="row">
                                <span className="column-two text-secondary">
                                    First Name
                                </span>
                                <div className="column-two flex-end">
                                    <div className="checkout-input-group">
                                        {paymentInvoiceDetail?.shopperFirstName}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {paymentInvoiceDetail?.shopperLastName ? (
                            <div className="row">
                                <span className="column-two text-secondary">
                                    Last Name
                                </span>
                                <div className="column-two flex-end">
                                    <div className="checkout-input-group">
                                        {paymentInvoiceDetail?.shopperLastName}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {paymentInvoiceDetail?.shopperTelephoneNumber ? (
                            <div className="row">
                                <span className="column-two text-secondary">
                                    Telephone Number
                                </span>
                                <div className="column-two flex-end">
                                    <div className="checkout-input-group">
                                        {
                                            paymentInvoiceDetail?.shopperTelephoneNumber
                                        }
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {paymentInvoiceDetail?.shopperEmail ? (
                            <div className="row">
                                <span className="column-two text-secondary">
                                    Email
                                </span>
                                <div className="column-two flex-end">
                                    <div className="checkout-input-group">
                                        {paymentInvoiceDetail?.shopperEmail}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {paymentInvoiceDetail?.shopperStreet ? (
                            <div className="row">
                                <span className="column-two text-secondary">
                                    Street
                                </span>
                                <div className="column-two flex-end">
                                    <div className="checkout-input-group">
                                        {paymentInvoiceDetail?.shopperStreet}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {paymentInvoiceDetail?.shopperHouseNumber ? (
                            <div className="row">
                                <span className="column-two text-secondary">
                                    House Number
                                </span>
                                <div className="column-two flex-end">
                                    <div className="checkout-input-group">
                                        {
                                            paymentInvoiceDetail?.shopperHouseNumber
                                        }
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {paymentInvoiceDetail?.shopperPostalCode ? (
                            <div className="row">
                                <span className="column-two text-secondary">
                                    Postal code
                                </span>
                                <div className="column-two flex-end">
                                    <div className="checkout-input-group">
                                        {
                                            paymentInvoiceDetail?.shopperPostalCode
                                        }
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {paymentInvoiceDetail?.shopperCity ? (
                            <div className="row">
                                <span className="column-two text-secondary">
                                    City
                                </span>
                                <div className="column-two flex-end">
                                    <div className="checkout-input-group">
                                        {paymentInvoiceDetail?.shopperCity}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : null}

                {paymentInvoiceDetail?.description ? (
                    <div className="sub-section-payment">
                        <div className="row">
                            <span className="column-two text-secondary">
                                Description
                            </span>
                            <div className="column-two flex-end">
                                <div className="checkout-input-group">
                                    {paymentInvoiceDetail?.description}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                <div className="separator separator-theme"></div>

                <div className="text-bold payment-info">
                    <div className="column-two">Total</div>
                    <div className="column-two">
                        <FormattedNumber
                            style="currency"
                            currencyDisplay="narrowSymbol"
                            value={paymentInvoiceDetail?.amount || 0.5}
                            currency={
                                (
                                    paymentInvoiceDetail?.currency || ""
                                ).toUpperCase() || "GBP"
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="separator separator-theme"></div>
            <div className="checkout-footer">
                <div class="flex-footer">
                    <div>
                        <CustomCheckbox
                            key={"read-terms-and-conditions"}
                            label={""}
                            isChecked={isAccepted}
                            handleClick={(e) => {
                                setIsAccepted(!isAccepted);
                            }}
                        />
                    </div>
                    <div>
                        <small>
                            Before navigating to payment please accept{" "}
                            <a
                                href={
                                    paymentInvoiceDetail.termsAndConditionLink
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms and conditions
                            </a>
                        </small>
                    </div>
                </div>
                <CustomButton
                    className="btn-block mt-2"
                    onClick={(e) => {
                        onPay(e);
                    }}
                    disabled={!isAccepted}
                >
                    Proceed to Pay{" "}
                    <FormattedNumber
                        style="currency"
                        currencyDisplay="narrowSymbol"
                        value={paymentInvoiceDetail?.amount || 0}
                        currency={
                            (
                                paymentInvoiceDetail?.currency || ""
                            ).toUpperCase() || "GBP"
                        }
                    />
                </CustomButton>
            </div>
        </div>
    );
};

export default PaymentLink;
