import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import { CardPayment } from "./CardPayment";
import { WalletPayment } from "./WalletPayment";

import "./PaymentGateway.scss";
import {
    createOrderRequestBody,
    createRequestBody,
    notify,
    createUserOrder,
} from "../../common/utils";
import Popup from "reactjs-popup";

import { emptyCart } from "../../store/actions/ProductActions";
import { setUniqueMetricId } from "../../store/actions/UserAction";
import { apiService } from "../../services";
import CustomButton from "../custom-button";
import { Loader } from "../custom-loader";
import { useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import PromoRemoveModal from "../promo-remove-modal";

const fetchPaymentIntent = async (paymentMethod, basket) => {
    const body = {
        paymentMethod: paymentMethod,
        cart: { ...basket },
    };

    const paymentIntent = await apiService
        .createPaymentIntent(body)
        .then((resp) => {
            return {
                paymentIntentResponse: resp.payment_intent,
                cart: resp.cart,
                paymentIntentError: "",
            };
        })
        .catch((err) => {
            const promotionErrors =
                err.message === "INVALID_PROMOTION" ? err.errors : null;
            return {
                promotionErrors,
                paymentIntentResponse: "",
                cart: null,
                paymentIntentError: err,
            };
        });

    return paymentIntent;
};

const confirmPaymentIntent = async (stripe, paymentIntent, handleActions) => {
    if (!stripe || !paymentIntent) {
        return { error: "Something went wrong, please reload the page." };
    }

    const payload = await stripe
        .confirmCardPayment(paymentIntent.client_secret, {}, { handleActions })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        });

    return payload;
};

// const createUserOrder = async (reqData) => {
//     const body = {
//         cart: reqData.basket,
//         cardData: reqData?.cardData || null,
//         paymentIntent: reqData?.paymentIntentConfirm && reqData?.paymentIntentConfirm.paymentIntent ? reqData?.paymentIntentConfirm.paymentIntent : null,
//         paymentGateway: reqData.paymentGateway,
//         siteZones: reqData.siteZones,
//         tableNumber: reqData.tableNumber,
//         vendorZoneName: reqData.vendorZoneName,
//         name: reqData.name,
//         email: reqData.email,
//         deliveryAddress: reqData.deliveryAddress,
//         address: reqData.address,
//         specialInstructions: reqData.specialInstructions,
//         contactNumber: reqData.contactNumber ? reqData.contactNumber : null,
//         formattedContactNumber: reqData.contactNumber ? `+${reqData.contactNumber}` : null,
//         note: reqData.note ? reqData.note : "",
//         paymentMethodType: reqData.paymentMethodType,
//         newsLetter: reqData.newsLetter,
//         userZoneIdentifier: reqData.userZoneIdentifier,
//         deliveryDate: reqData.deliveryDate
//     };

//     if(reqData.paymentGateway === 'stripe') {
//         const userOrder = await apiService.createOrder(body).catch((err) => err);
//         return userOrder;
//     } else {
//         const userOrder = await apiService.createAdyenOrder(body).catch((err) => err);
//         return userOrder;
//     }
// };

const PaymentGatewayMobile = (props) => {
    const [showLoader, setShowLoader] = useState(false);
    const { outletId } = useParams();
    const [promoRemoveModalOpen, setPromoRemoveModalOpen] = useState(false);
    const [promotionErrors, setPromotionErrors] = useState([]);
    const handleLoader = (value) => {
        if (document.getElementById("popup-root")) {
            if (value) {
                document.getElementById("popup-root").style.visibility =
                    "hidden";
                document.getElementById("header-payment").style.display =
                    "none";
            } else {
                document.getElementById("popup-root").style.visibility =
                    "inherit";
                document.getElementById("header-payment").style.display =
                    "flex";
            }
        }
        setShowLoader(value);
    };

    const placeOrder = async (paymentMethod, stripe, complete) => {
        const paymentMethodType = paymentMethod.card.wallet
            ? paymentMethod.card.wallet.type
                  .replace(/[^a-z]/gi, "")
                  .toUpperCase()
            : "CARD";

        const basket = createRequestBody(
            { ...props.basket },
            outletId,
            props.serviceData,
        );
        const paymentIntent = await fetchPaymentIntent(paymentMethod, {
            ...basket,
            userDetails: { shopperEmail: props.formValues.email },
        });
        if (paymentIntent.promotionErrors) {
            setPromoRemoveModalOpen(true);
            setPromotionErrors(paymentIntent.promotionErrors);
            return;
        }
        if (paymentIntent.paymentIntentError) {
            handleLoader(false);
            notify(paymentIntent.paymentIntentError.message);
            return;
        }

        const paymentIntentConfirm = await confirmPaymentIntent(
            stripe,
            paymentIntent.paymentIntentResponse,
            false,
        );

        if (paymentIntentConfirm && paymentIntentConfirm.error) {
            complete && complete("fail");
            handleLoader(false);
            notify(paymentIntentConfirm.error.message);
            return;
        }

        complete && complete("success");

        let paymentIntentConfirm3d;

        if (paymentIntentConfirm.paymentIntent.status === "requires_action") {
            setShowLoader(true);
            paymentIntentConfirm3d = await confirmPaymentIntent(
                stripe,
                paymentIntentConfirm.paymentIntent,
                true,
            );
        }

        if (paymentIntentConfirm3d && paymentIntentConfirm3d.error) {
            handleLoader(false);
            notify(paymentIntentConfirm3d.error.message);
            return;
        }

        const cardData = {
            cardType: paymentMethod.card.brand,
            lastFourDigit: paymentMethod.card.last4,
        };
        const newsLetter = props.formValues?.newsLetter || false;
        delete props.formValues?.newsLetter;

        const reqBody = createOrderRequestBody(
            paymentIntent.cart,
            props.serviceData,
            {
                ...props.formValues,
                newsLetter: newsLetter,
            },
            {
                cardData: cardData,
                paymentMethodType,
                paymentIntentConfirm: paymentIntentConfirm3d
                    ? paymentIntentConfirm3d
                    : paymentIntentConfirm,
            },
        );
        const orderDetails = await createUserOrder({
            ...reqBody,
            metricId: props?.metricId,
            deviceId: props?.deviceId,
        });
        if (orderDetails.status === 200) {
            props.removeCart();
            props.setUniqueMetricId(null);
            // custom event for conversion NOTE: event_name is conversion_rate
            ReactGA.event("conversion_rate");
            handleLoader(false);
            return {
                isMultiOrder: orderDetails.data.isMultiOrder,
                orderId: orderDetails.data._id,
                cartId: orderDetails.data.cartRef,
            };
        } else {
            handleLoader(false);
            notify(orderDetails.message);
            return null;
        }
    };

    return (
        <>
            <div className={showLoader ? "hidden" : "w-100"}>
                <PromoRemoveModal
                    errors={promotionErrors}
                    isOpen={promoRemoveModalOpen}
                />
                <WalletPayment
                    key={props.totalAmount}
                    placeOrder={placeOrder}
                    basket={{ ...props.basket, totalAmount: props.totalAmount }}
                    setShowPayLoader={handleLoader}
                />
                <Popup
                    trigger={
                        <CustomButton className="btn-block payment-button">
                            {" "}
                            Debit/Credit Card
                        </CustomButton>
                    }
                    modal
                >
                    {(close) => (
                        <CardPayment
                            popUpClose={close}
                            setShowLoader={handleLoader}
                            placeOrder={placeOrder}
                        />
                    )}
                </Popup>
            </div>
            {showLoader && (
                <div className="payment-loader-overlay">
                    <Loader />
                </div>
            )}

            <p className="warning">
                While payment is processing, please do not press/close and do
                not put this app into background. It may cause payment failure
            </p>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeCart: () => dispatch(emptyCart()),
        setUniqueMetricId: (value) => dispatch(setUniqueMetricId(value)),
    };
};

const mapStateToProps = (state) => {
    return {
        basket: state.cart,
        serviceData: state.serviceData,
        metricId: state.metricId,
        deviceId: state.deviceId,
        outletData: state.outletData,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaymentGatewayMobile);
