/* eslint-disable react/style-prop-object */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomModal from "../custom-modal";
import "./menu.scss";
import { Fragment, useEffect, useRef, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";
import FilterModal from "./FilterModal";
import CategoryCard from "./CategoryCard/index.js";
import CustomButton from "../custom-button";
import { apiService } from "../../services";
import { connect } from "react-redux";
import {
    getInBasketCount,
    restructureMenu,
    calculateRemainingQty,
    notify,
    getAutomatedPromotion,
    DEFAULT_TIMEZONE,
    mapMenuItemFromPromoItems,
    injectPromotionToMenuItems,
    getAllMergedCustomAttributes,
    createValuesIdsArray,
} from "../../common/utils";
import { LoaderWithChildren } from "../custom-loader";
import SlotTimer from "../service-type/SlotTimer";
import { FormattedNumber } from "react-intl";
import { CustomDropdown } from "../custom-dropdown";
import constants from "../../constants/constants";
import InfoMessage from "../common/InfoMessage";
import { StoreDropdown } from "./StoreDropdown";
import SiteOutletList from "../site-outlet-list";
import PromotionItemCard from "./PromotionItemCard";
import CustomCarousel from "../custom-carousel";
import { useCallback } from "react";

const MenuItems = (props) => {
    const navigate = useNavigate();
    const {
        basket,
        serviceData,
        outlet,
        prepaidMaxProduct,
        menuPayType,
        siteZoneId,
        siteCategory,
        siteData,
    } = props;
    const { outletId, serviceType, menuId, siteId, zoneId } = useParams();
    const { preOrder = false } = serviceData;
    const location = useLocation();
    const redirectUrl = (location.pathname || "").replace(
        `/${constants.ROUTES.MENUS}/${menuId}`,
        "",
    );
    const [menuLoading, setMenuLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [enableFilters, setEnableFilters] = useState(false);
    const [currentCategory, setCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [originalMenu, setOriginalMenu] = useState([]);
    const inBasketCount = getInBasketCount(basket);
    const [menuList, setMenuList] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState([]);
    // const [dietaryChecked, setDietaryChecked] = useState([]);
    // const [allergenChecked, setAllergenChecked] = useState([]);
    const [stores, setStores] = useState([]);
    const [showStores, setShowStore] = useState(false);
    // const [dynamicAllergens, setDynamicAllergens] = useState([]);
    // const [dynamicDietary, setDynamicDietary] = useState([]);
    const storeChanged = useRef(false);
    const [automatedPromotion, setAutomatedPromotion] = useState({});
    const [siteAutoPromo, setSiteAutoPromo] = useState({});
    const [itemBasedPromo, setItemItemBasedPromo] = useState([]);
    const [mappedPromoItems, setMappedPromoItems] = useState([]);
    const [prevScrollPos, setPrevScrollPos] = useState({
        offsetTopValue: 0,
        menuTopHeight: 0,
        catNavHeight: 0,
        originalCatNavOffset: 0,
    });
    const [dynamicCustomAttributes, setDynamicCustomAttributes] = useState([]);
    const [attributeValueIds, setAttributeValueIds] = useState([]);

    const containerRef = useRef(null);
    const categoryNavRef = useRef(null);
    const menuTopRef = useRef(null);
    const itemBasedPromoElementRef = useRef(null);
    const automatedPromoElementRef = useRef(null);

    async function fetchStores() {
        let newStores = await apiService
            .getOutletList(siteId, zoneId || siteZoneId, null, serviceType)
            .catch((err) => console.log(err));
        setStores(newStores);
    }
    async function fetchMenuList(
        newOutletId,
        timezone,
        isFetchOnChangeStore = false,
    ) {
        const isMultiOrder =
            siteCategory === constants.SITE_CATEGORY.SITE_DEPENDENT;
        setMenuLoading(true);
        if (
            newOutletId &&
            serviceType &&
            !preOrder &&
            (isFetchOnChangeStore === false || newOutletId !== outletId)
        ) {
            try {
                const response = await apiService.getOrderNowMenuDetails(
                    newOutletId,
                    serviceType,
                    timezone,
                    null,
                    false,
                    isMultiOrder ? siteId : "",
                );
                if (response) {
                    const filteredMenus = response.filter(
                        (menu) =>
                            menu.menu.menuRef.menuPayType !==
                            constants.MENU_PAY_TYPE.PREPAID,
                    );
                    let selectedMenuId = menuId;
                    if (
                        isFetchOnChangeStore &&
                        filteredMenus &&
                        filteredMenus.length
                    ) {
                        selectedMenuId = filteredMenus[0].menu.menuRef._id;
                    }
                    const menuListOptions = filteredMenus.map((menu) => {
                        if (menu.menu.menuRef._id === selectedMenuId) {
                            setSelectedMenu({
                                label: menu.menu.menuRef.displayName,
                                value: menu.menu.menuRef._id,
                            });
                        }
                        return {
                            label: menu.menu.menuRef.displayName,
                            value: menu.menu.menuRef._id,
                        };
                    });
                    setMenuList(menuListOptions);
                    if (isFetchOnChangeStore && selectedMenuId) {
                        const url = (location.pathname || "").replace(
                            `${outletId}/${constants.ROUTES.MENUS}/${menuId}`,
                            `${newOutletId}/${constants.ROUTES.MENUS}/${selectedMenuId}`,
                        );
                        navigate(url, { replace: true });
                    }
                    setMenuLoading(false);
                } else {
                    setMenuList([]);
                    setSelectedMenu(null);
                    setOriginalMenu([]);
                    setMenuLoading(false);
                }
            } catch (err) {
                if (isFetchOnChangeStore) {
                    setMenuLoading(false);
                    notify("Menus and it's item not available for this store!");
                } else {
                    navigate(-1);
                }
            }
        } else {
            setMenuLoading(false);
        }
    }

    const getMenuItems = (menuId) => {
        setLoading(true);
        apiService
            .getMenuItemsList(outletId, menuId, serviceType)
            .then((res) => {
                setOriginalMenu(restructureMenu(res, outletId));
                const mapCustomAttributes = getAllMergedCustomAttributes(res);
                setDynamicCustomAttributes(mapCustomAttributes);
                res.length && setCategory(res[0].categoryName);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setOriginalMenu([]);
                setLoading(false);
            });
    };

    const getItemisedPromotions = () => {
        apiService
            .getItemBasedPromotions(
                siteId,
                outletId,
                siteData && siteData.timezone
                    ? siteData.timezone
                    : outlet && outlet.timezone
                    ? outlet.timezone
                    : DEFAULT_TIMEZONE,
            )
            .then((res) => {
                setItemItemBasedPromo(res);
            })
            .catch((err) => {
                setItemItemBasedPromo([]);
            });
    };

    useEffect(() => {
        if (siteCategory === constants.SITE_CATEGORY.SITE_DEPENDENT) {
            fetchStores();
        }
        document.body.style.position = "fixed";
        window.addEventListener("scroll", handleCategoryNavSticky, true);
        return () => {
            document.body.style.position = null;
            window.removeEventListener("scroll", handleCategoryNavSticky, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevScrollPos]);

    useEffect(() => {
        if (categoryNavRef.current && menuTopRef.current) {
            const originalCatNavOffset =
                categoryNavRef?.current?.clientHeight +
                categoryNavRef?.current?.offsetTop -
                menuTopRef.current.clientHeight;
            const values = {
                offsetTopValue: categoryNavRef?.current?.offsetTop,
                catNavHeight: categoryNavRef?.current?.clientHeight,
                menuTopHeight: menuTopRef.current.clientHeight,
                originalCatNavOffset: originalCatNavOffset,
            };
            setPrevScrollPos({ ...values });
        }
    }, [
        mappedPromoItems,
        automatedPromotion,
        categories,
        itemBasedPromoElementRef?.current?.clientHeight,
        automatedPromoElementRef?.current?.clientHeight,
    ]);

    const handleCategoryNavSticky = useCallback(
        (e) => {
            const currentScrollPos = containerRef.current.scrollTop;
            const catNavBar = document.querySelector(
                ".category-nav-sub-heading",
            );
            const distanceFromTop =
                prevScrollPos.offsetTopValue - prevScrollPos.menuTopHeight;
            if (currentScrollPos < prevScrollPos.originalCatNavOffset) {
                if (catNavBar) {
                    catNavBar.classList.remove("category-nav-bar-fixed");
                }
            } else {
                if (currentScrollPos >= distanceFromTop) {
                    if (catNavBar) {
                        catNavBar.classList.add("category-nav-bar-fixed");
                    }
                } else {
                    if (catNavBar) {
                        catNavBar.classList.remove("category-nav-bar-fixed");
                    }
                }
            }
        },
        [prevScrollPos],
    );

    useEffect(() => {
        const siteAutomatedPromo = getAutomatedPromotion(
            siteData?.assignedPromotions || [],
        );
        setSiteAutoPromo(siteAutomatedPromo);
        if (siteAutomatedPromo && Object.keys(siteAutomatedPromo)?.length) {
            setAutomatedPromotion(siteAutomatedPromo);
        } else {
            const outletAutomatedPromo = getAutomatedPromotion(
                outlet?.assignedPromotions || [],
            );
            setAutomatedPromotion(outletAutomatedPromo);
        }
    }, [siteId, outletId]);

    useEffect(() => {
        // NOQ-5694
        // if both store have same menu then below useEffect was not working
        // need to run this useEffect when store changes
        getMenuItems(menuId);
        getItemisedPromotions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuId, outletId]);

    useEffect(() => {
        handleFilterChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalMenu]);

    useEffect(() => {
        if (storeChanged.current === false) {
            fetchMenuList(outletId, outlet.timezone);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outletId, serviceType]);

    useEffect(() => {
        if (menuPayType !== constants.MENU_PAY_TYPE.PREPAID) {
            const mappedPromotionItems = mapMenuItemFromPromoItems(
                categories,
                itemBasedPromo,
            );
            setMappedPromoItems(mappedPromotionItems);
        }
    }, [categories]);

    function openModal() {
        setEnableFilters(true);
    }

    function closeModal() {
        setEnableFilters(false);
    }

    function scrollHandler(event) {
        for (let i = 0; i < categories.length; i++) {
            let categoryElement = document.getElementById(
                categories[i].categoryName.trim(),
            );
            if (categoryElement) {
                if (
                    categoryElement.getBoundingClientRect().top <= 180 &&
                    categoryElement.getBoundingClientRect().bottom > 180
                ) {
                    setCategory(categories[i].categoryName.trim());
                    const categoryContainer = document.querySelector(
                        ".category-nav-sub-heading",
                    );
                    const categoryEl = document.getElementById(
                        categories[i].categoryName.trim() + "-nav",
                    );
                    categoryContainer.scrollLeft = categoryEl.offsetLeft;
                }
            }
        }
    }

    function handleFilterChange() {
        const selectedCustomAttributes = [...dynamicCustomAttributes];

        let selectedAttributesValueIdsToIncludeItems = [];
        let selectedAttributesValueIdsToExcludeItems = [];
        let mapItemsToIncludeByCustomAttributeValues = {};
        let mapItemsToExcludeByCustomAttributeValues = {};

        selectedCustomAttributes.forEach((attribute) => {
            const generateSelectedValuesIds = createValuesIdsArray(
                attribute.attributeObjects,
                attribute.checkedValues,
                attribute.isExcluded,
            );

            selectedAttributesValueIdsToIncludeItems = [
                ...selectedAttributesValueIdsToIncludeItems,
                ...generateSelectedValuesIds.includeItemRes,
            ];
            selectedAttributesValueIdsToExcludeItems = [
                ...selectedAttributesValueIdsToExcludeItems,
                ...generateSelectedValuesIds.exludeItemRes,
            ];
        });

        let filteredCategories = [...originalMenu];

        for (let i = 0; i <= filteredCategories.length; i++) {
            if (filteredCategories[i]?.items?.length) {
                for (let j = 0; j <= filteredCategories[i].items.length; j++) {
                    if (
                        filteredCategories[i]?.items[j]?.customAttributes
                            ?.length
                    ) {
                        for (
                            let m = 0;
                            m <=
                            filteredCategories[i].items[j].customAttributes
                                .length;
                            m++
                        ) {
                            if (
                                filteredCategories[i].items[j].customAttributes[
                                    m
                                ]?.attributeValues?.length
                            ) {
                                for (
                                    let n = 0;
                                    n <=
                                    filteredCategories[i].items[j]
                                        .customAttributes[m].attributeValues
                                        .length;
                                    n++
                                ) {
                                    const attributeValueId =
                                        filteredCategories[i]?.items[j]
                                            ?.customAttributes[m]
                                            .attributeValues[n]?._id;
                                    if (
                                        attributeValueId &&
                                        selectedAttributesValueIdsToIncludeItems?.includes(
                                            attributeValueId,
                                        )
                                    ) {
                                        if (
                                            mapItemsToIncludeByCustomAttributeValues[
                                                attributeValueId
                                            ]
                                        ) {
                                            mapItemsToIncludeByCustomAttributeValues[
                                                attributeValueId
                                            ] = [
                                                ...mapItemsToIncludeByCustomAttributeValues[
                                                    attributeValueId
                                                ],
                                                filteredCategories[i].items[j]
                                                    .itemRef,
                                            ];
                                        } else {
                                            mapItemsToIncludeByCustomAttributeValues[
                                                attributeValueId
                                            ] = [
                                                filteredCategories[i].items[j]
                                                    .itemRef,
                                            ];
                                        }
                                    }
                                    if (
                                        attributeValueId &&
                                        selectedAttributesValueIdsToExcludeItems?.includes(
                                            attributeValueId,
                                        )
                                    ) {
                                        if (
                                            mapItemsToExcludeByCustomAttributeValues[
                                                attributeValueId
                                            ]
                                        ) {
                                            mapItemsToExcludeByCustomAttributeValues[
                                                attributeValueId
                                            ] = [
                                                ...mapItemsToExcludeByCustomAttributeValues[
                                                    attributeValueId
                                                ],
                                                filteredCategories[i].items[j]
                                                    .itemRef,
                                            ];
                                        } else {
                                            mapItemsToExcludeByCustomAttributeValues[
                                                attributeValueId
                                            ] = [
                                                filteredCategories[i].items[j]
                                                    .itemRef,
                                            ];
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        const itemsRefToInclude = [
            ...new Set(
                Object.values(mapItemsToIncludeByCustomAttributeValues).flat(),
            ),
        ];
        const itemsRefToExclude = [
            ...new Set(
                Object.values(mapItemsToExcludeByCustomAttributeValues).flat(),
            ),
        ];

        if (itemsRefToInclude?.length) {
            filteredCategories = filteredCategories.map((cat, index) => {
                return {
                    ...cat,
                    items: cat.items.filter((item) => {
                        if (itemsRefToInclude.indexOf(item.itemRef) !== -1) {
                            return true;
                        }
                        return false;
                    }),
                };
            });
        }

        if (itemsRefToExclude?.length) {
            filteredCategories = filteredCategories.map((cat, index) => {
                return {
                    ...cat,
                    items: cat.items.filter((item) => {
                        if (itemsRefToExclude.indexOf(item.itemRef) !== -1) {
                            return false;
                        }
                        return true;
                    }),
                };
            });
        }

        filteredCategories = filteredCategories.filter(
            (cat) => cat.items.length !== 0,
        );

        const categoriesItemsWithInjectedPromo = injectPromotionToMenuItems(
            filteredCategories,
            itemBasedPromo,
        );
        setCategories(categoriesItemsWithInjectedPromo);
        setEnableFilters(false);
    }

    function onSelectStore(store) {
        setMenuLoading(true);
        storeChanged.current = true;
        fetchMenuList(store.vendorId, store.timezone, true);
        setShowStore(false);
    }
    const onSelectMenu = (value) => {
        setSelectedMenu(menuList.find((opt) => opt.value === value));
        navigate(`${redirectUrl}/menus/${value}`, { replace: true });
    };

    if (loading || menuLoading)
        return (
            <LoaderWithChildren>
                Please wait while we are loading the menu
            </LoaderWithChildren>
        );

    const el = document.querySelector(".category-list-scroll");
    if (el) {
        el.addEventListener("scroll", scrollHandler);
    }

    let remainingQty = 0;
    if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
        const basketItems =
            basket?.carts && basket?.carts[0]?.items
                ? basket?.carts[0]?.items
                : [];
        remainingQty = calculateRemainingQty(prepaidMaxProduct, basketItems);
    }

    const isMultiOrderWithStore =
        siteCategory === constants.SITE_CATEGORY.SITE_DEPENDENT &&
        stores.length > 1 &&
        !preOrder;

    return (
        <div
            className={`${
                !Object.keys(inBasketCount).length === 0 ? "menu-long-list" : ""
            } menu ${isMultiOrderWithStore ? "multi-order-stores" : ""}`}
        >
            <div className={`${"menu-top-fixed"}`} ref={menuTopRef}>
                <div className="box-container custom-header-wrapper header">
                    <div className="header-left">
                        <AiOutlineArrowLeft
                            size={35}
                            onClick={() => {
                                navigate(-1);
                            }}
                        />
                        <div className="header-title">
                            <div className="fs-heading">
                                {props?.outlet?.displayName}
                            </div>
                            <div className="fs-subheading">
                                {serviceData.serviceTypeDisplayName}
                                {preOrder ? (
                                    <small className="secondary-text-color header-address">
                                        - Pre Order
                                    </small>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <StoreDropdown
                        isMultiOrderWithStore={isMultiOrderWithStore}
                        onSelectStore={() => setShowStore(true)}
                    />
                </div>
                <div className="banner">
                    <div
                        className={`box-container banner-filter-wrapper ${
                            menuPayType !== constants.MENU_PAY_TYPE.PREPAID &&
                            !preOrder &&
                            (!menuList || menuList.length === 1)
                                ? "filter-flex-end"
                                : ""
                        }`}
                    >
                        {!preOrder &&
                            menuList &&
                            menuList.length > 1 &&
                            menuPayType !== constants.MENU_PAY_TYPE.PREPAID && (
                                <CustomDropdown
                                    options={menuList}
                                    onSelect={onSelectMenu}
                                    value={
                                        selectedMenu.label
                                            ? selectedMenu.label
                                            : null
                                    }
                                    placeHolder={"Select Menu"}
                                    dropDownContainerClass="select-menu-container"
                                    dropDownHeaderClass="select-menu-header"
                                    dropDownListContainerClass="select-menu-list-container"
                                    dropDownListClass="select-menu-list"
                                    dropDownItemClass="select-menu-item"
                                />
                            )}
                        {preOrder &&
                            menuPayType !== constants.MENU_PAY_TYPE.PREPAID && (
                                <SlotTimer />
                            )}
                        {menuPayType === constants.MENU_PAY_TYPE.PREPAID && (
                            <div>
                                {remainingQty} products remaining to select
                            </div>
                        )}
                        <div className="filter-button" onClick={openModal}>
                            <span style={{ paddingRight: "10px" }}>
                                Filters
                            </span>
                            <BiFilterAlt size={20} />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`${
                    Object.keys(inBasketCount)?.length
                        ? "category-list"
                        : "category-list-long"
                } category-list-scroll`}
                ref={containerRef}
            >
                {outlet && outlet.customMenuDescription ? (
                    <InfoMessage message={outlet.customMenuDescription} />
                ) : null}
                <div
                    className="promotion-list-container"
                    ref={automatedPromoElementRef}
                >
                    {automatedPromotion &&
                    Object.keys(automatedPromotion)?.length > 0 ? (
                        <div className="promo-section">
                            <div className="promo-block">
                                <div className="promo-name">
                                    {automatedPromotion?.promotionRef?.name}
                                </div>
                                {automatedPromotion?.promotionRef
                                    ?.promotionDesc && (
                                    <div className="promo-info">
                                        *
                                        {
                                            automatedPromotion?.promotionRef
                                                ?.promotionDesc
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {menuPayType !== constants.MENU_PAY_TYPE.PREPAID &&
                    mappedPromoItems &&
                    mappedPromoItems.length > 0 ? (
                        <div
                            className="box-container item-wise-promotion-container"
                            ref={itemBasedPromoElementRef}
                        >
                            {mappedPromoItems.map((promo, index) => {
                                return (
                                    <div
                                        className="item-wise-promotion"
                                        key={promo?._id || index}
                                    >
                                        <div className="promotion-name">
                                            {promo?.promotionDetails?.name}
                                        </div>
                                        <CustomCarousel>
                                            {(promo.menuPromotions || []).map(
                                                (promoMenuItem) => {
                                                    return (
                                                        <PromotionItemCard
                                                            itemData={{
                                                                ...promoMenuItem,
                                                                outletRef:
                                                                    promoMenuItem.outletRef ||
                                                                    outlet._id,
                                                            }}
                                                            inBasket={
                                                                inBasketCount[
                                                                    promoMenuItem
                                                                        ._id
                                                                ] || 0
                                                            }
                                                            key={
                                                                promoMenuItem._id
                                                            }
                                                        />
                                                    );
                                                },
                                            )}
                                        </CustomCarousel>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div
                    className={`category-nav-sub-heading`}
                    id="category-navigation-bar"
                    ref={categoryNavRef}
                >
                    {categories.map((category) => (
                        <Fragment key={category._id}>
                            <button
                                id={category.categoryName.trim() + "-nav"}
                                className={
                                    category.categoryName.trim() ===
                                    currentCategory
                                        ? "category-nav category-nav-active"
                                        : "category-nav"
                                }
                                onClick={() => {
                                    let element = document.getElementById(
                                        category.categoryName.trim(),
                                    );
                                    if (element) {
                                        el?.removeEventListener(
                                            "scroll",
                                            scrollHandler,
                                        );
                                        element.scrollIntoView({
                                            behavior: "auto",
                                            block: "start",
                                        });
                                        setTimeout(
                                            () =>
                                                setCategory(
                                                    category.categoryName.trim(),
                                                ),
                                            10,
                                        );
                                    }
                                }}
                            >
                                {category.categoryName}
                            </button>
                        </Fragment>
                    ))}
                </div>
                <div className="category-items-list">
                    <div className="box-container">
                        {categories && categories.length ? (
                            categories.map((cat) => (
                                <CategoryCard
                                    category={cat}
                                    key={cat._id}
                                    inBasketCount={inBasketCount}
                                    vendorRef={outlet?._id}
                                />
                            ))
                        ) : (
                            <p className="text-center">No items available</p>
                        )}
                    </div>
                </div>
            </div>
            {Boolean(Object.keys(inBasketCount).length) && (
                <div className="sticky-footer menu-bottom-fixed">
                    <div className="separator separator-theme"></div>
                    <div className="box-container menu-bottom-button">
                        {basket.subTotal >= serviceData.minOrder ||
                        menuPayType === constants.MENU_PAY_TYPE.PREPAID ? (
                            <CustomButton
                                className="btn-block"
                                onClick={() =>
                                    navigate(`${redirectUrl}/basket`)
                                }
                            >
                                <div className="btn-flex-row">
                                    <div>
                                        {basket &&
                                        basket.carts &&
                                        basket.carts.length !== 0 ? (
                                            <div className="circle">
                                                {" "}
                                                {
                                                    Object.keys(inBasketCount)
                                                        .length
                                                }{" "}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div>View Basket</div>
                                    <div>
                                        {basket?.subTotal !== undefined &&
                                        menuPayType !==
                                            constants.MENU_PAY_TYPE.PREPAID ? (
                                            <FormattedNumber
                                                style={`currency`}
                                                currencyDisplay="narrowSymbol"
                                                value={basket.subTotal}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                                currency={
                                                    outlet.currency || "gbp"
                                                }
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </CustomButton>
                        ) : (
                            <CustomButton
                                disabled={true}
                                className="btn-block btn-disabled"
                                onClick={() =>
                                    navigate(`${redirectUrl}/basket`)
                                }
                            >
                                <div className="btn-flex-row">
                                    <div>
                                        {basket &&
                                        basket.carts &&
                                        basket.carts.length !== 0 ? (
                                            <div className="circle circle-disabled">
                                                {" "}
                                                {
                                                    Object.keys(inBasketCount)
                                                        .length
                                                }{" "}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div>
                                        <span>Min. Order </span>
                                        <FormattedNumber
                                            style={`currency`}
                                            currencyDisplay="narrowSymbol"
                                            value={serviceData.minOrder}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            currency={outlet.currency || "gbp"}
                                        />
                                    </div>
                                    <div>
                                        {basket?.subTotal !== undefined ? (
                                            <FormattedNumber
                                                style={`currency`}
                                                currencyDisplay="narrowSymbol"
                                                value={basket.subTotal}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                                currency={
                                                    outlet.currency || "gbp"
                                                }
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </CustomButton>
                        )}
                    </div>
                </div>
            )}
            <CustomModal
                isOpen={enableFilters}
                onRequestClose={closeModal}
                title={"Filters"}
            >
                <FilterModal
                    handleFilterChange={handleFilterChange}
                    dynamicCustomAttributes={dynamicCustomAttributes}
                    setDynamicCustomAttributes={setDynamicCustomAttributes}
                    setAttributeValueIds={setAttributeValueIds}
                    attributeValueIds={attributeValueIds}
                />
            </CustomModal>

            <CustomModal
                isOpen={showStores}
                onRequestClose={() => setShowStore(false)}
                title={"Select Store"}
            >
                <div className="box-container menu-store-list-container">
                    <SiteOutletList
                        outlets={stores}
                        onSelectStore={onSelectStore}
                        siteAutomatedPromotion={siteAutoPromo}
                    />
                </div>
            </CustomModal>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        basket: state.cart,
        outlet: state.outletData,
        serviceData: {
            preOrder: state.serviceData?.preOrder,
            minOrder: state.serviceData?.minOrder,
            menuRef: state.serviceData?.menuRef,
            serviceTypeDisplayName: state.serviceData?.serviceTypeDisplayName,
        },
        prepaidMaxProduct: state.prepaidMaxProduct,
        menuPayType: state.menuPayType,
        siteCategory:
            state.siteData && state.siteData.category
                ? state.siteData.category
                : null,
        siteZoneId:
            state.zoneData && state.zoneData?._id ? state.zoneData._id : null,
        siteData: state.siteData,
    };
};

export default connect(mapStateToProps, null)(MenuItems);
