import { ErrorMessage } from "formik";
import { CustomInput } from "../custom-input";

export const YourDetail = ({ props, handleChangeField }) => {
    return (
        <>
            <h4>Your Personal Details</h4>
            <div className="sub-section">
                <div className="row">
                    <span className="column-two text-secondary">Name*</span>
                    <div className="column-two flex-end">
                        <div className="checkout-input-group">
                            <input
                                type="text"
                                {...props.getFieldProps("name")}
                                name="name"
                                placeholder="Insert name"
                                className={"checkout-input"}
                            />
                            {props.touched.name && props.errors.name ? (
                                <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="error"
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <span className="column-two text-secondary">
                        Mobile Number
                    </span>
                    <div className="column-two flex-end">
                        <div className="checkout-input-group">
                            <input
                                type="text"
                                {...props.getFieldProps("mobileNumber")}
                                name="mobileNumber"
                                placeholder="Insert mobile number"
                                className={"checkout-input"}
                            />
                            {props.touched.mobileNumber &&
                            props.errors.mobileNumber ? (
                                <ErrorMessage
                                    name="mobileNumber"
                                    component="div"
                                    className="error"
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <span className="column-two text-secondary">Email*</span>
                    <div className="column-two flex-end">
                        <div className="checkout-input-group">
                            <input
                                type="email"
                                {...props.getFieldProps("email")}
                                name="email"
                                placeholder="Insert email"
                                className={"checkout-input"}
                            />
                            {props.touched.email && props.errors.email ? (
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="error"
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="checkout-input-group">
                        <CustomInput
                            onChange={(value) =>
                                handleChangeField(props, "note", value)
                            }
                            value={props.values.note}
                            name="note"
                            placeHolder="Add note"
                            className={"checkout-input"}
                        />
                        {props.touched.note && props.errors.note ? (
                            <ErrorMessage
                                name="note"
                                component="div"
                                className="error"
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};
