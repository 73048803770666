import { useEffect, useState } from "react";
import {
    AiOutlineInfoCircle,
    AiOutlineMinus,
    AiOutlineMinusSquare,
    AiOutlinePlus,
    AiOutlinePlusSquare,
} from "react-icons/ai";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { IMAGE_SIZES } from "../../../constants/image";
import CustomButton from "../../custom-button";
import ItemPlaceholderImage from "../../../assets/imgs/item-placeholder.png";
import ItemModifierModal from "../CategoryCard/ItemModifierModal";
import CustomModal from "../../custom-modal";
import {
    addItemsToCart,
    setQuantityLoader,
} from "../../../store/actions/ProductActions";
import {
    calculateRemainingQty,
    checkPrePaidDisable,
    getImageUrl,
    useDebounce,
} from "../../../common/utils";
import AllergensModal from "../CategoryCard/AllergensModal";
import constants from "../../../constants/constants";
import AttributesModal from "../CategoryCard/AttributesModal";

const PromotionItemCard = (props) => {
    const {
        itemData,
        inBasket,
        outletData,
        basket,
        menuPayType,
        prepaidMaxProduct,
        quantityLoader,
        setQuantityLoader,
    } = props;
    const { serviceType, outletId } = useParams();
    const [enableModifiers, setEnableModifiers] = useState(false);
    const [diff, setDiff] = useState(0);
    const [showAllergensModal, setShowAllergensModal] = useState(false);
    const [showCustomAttributeModal, setShowCustomAttributeModal] =
        useState(false);
    const bodyStyles = document.body.style;
    const backgroundColor = bodyStyles.getPropertyValue(
        "--theme-background-color",
    );
    const textColor = bodyStyles.getPropertyValue("--theme-text-color");
    const outletRef = itemData.outletRef ? itemData.outletRef : outletId;
    const currentBasket =
        (basket?.carts || []).find(
            (v) => String(v.vendorRef) === String(outletRef),
        ) || {};

    function openModal() {
        setEnableModifiers(true);
    }

    function closeModal() {
        setEnableModifiers(false);
    }

    const handleQuantityChange = useDebounce(() => {
        setQuantityLoader(true);
        props
            .addItemsToCart({ ...itemData, quantity: diff })
            .then(() => {
                setDiff(0);
                setQuantityLoader(false);
            })
            .catch(() => {
                setDiff(0);
                setQuantityLoader(false);
            });
    }, 300);

    useEffect(() => {
        if (diff) {
            handleQuantityChange();
        }
    }, [diff]);

    const handleItemAdd = () => {
        const d = diff + 1;
        if (itemData.assignedModifiers.length || itemData.variants.length) {
            openModal();
        } else {
            if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
                let basketItems =
                    basket?.carts && basket?.carts.length > 0
                        ? basket?.carts[0].items
                        : [];
                if (
                    !checkPrePaidDisable(
                        calculateRemainingQty(prepaidMaxProduct, basketItems) -
                            d,
                        menuPayType,
                    )
                ) {
                    setDiff(d);
                }
            } else {
                setDiff(d);
            }
        }
    };

    const handleMinusClick = () => {
        setDiff(diff - 1);
    };

    const basketItemIndex = (currentBasket.items || []).findIndex(
        (i) => i.assignedMenuItemRef === itemData._id,
    );
    let modifiersItemsData = JSON.parse(JSON.stringify(itemData));
    let prepaidDisabled = false;
    if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
        const basketItems =
            basket?.carts && basket?.carts.length > 0
                ? basket?.carts[0].items
                : [];
        prepaidDisabled = checkPrePaidDisable(
            calculateRemainingQty(prepaidMaxProduct, basketItems) - (diff + 1),
            menuPayType,
        );
    }

    return (
        <div style={{ height: "250px", position: "relative" }}>
            <div className="promotion-menu-item">
                <div className="promotion-item-logo">
                    <img
                        src={
                            itemData.itemImage
                                ? getImageUrl(itemData.itemImage) +
                                  IMAGE_SIZES.logo
                                : ItemPlaceholderImage
                        }
                        onError={(e) => {
                            e.target.src = getImageUrl(itemData.itemImage);
                        }}
                        alt="Display not found"
                    />
                </div>
                <div id={itemData?._id} className="promotion-item-card">
                    <div className="item-info">
                        <div className="promotion-item-name">
                            {itemData.itemName}
                        </div>
                        <div
                            className={`row ${
                                itemData.customAttributes &&
                                itemData.customAttributes.length > 0
                                    ? "justify-space-between"
                                    : "justify-center"
                            } ${
                                itemData?.assignedModifiers?.length > 0 ||
                                itemData?.variants?.length > 0
                                    ? ""
                                    : "align-center"
                            }`}
                        >
                            <div className="item-price-info">
                                <div className="item-price">
                                    {itemData?.customAttributes?.length === 0 &&
                                    (itemData?.assignedModifiers?.length ||
                                        itemData?.variants?.length > 0) ? (
                                        <span className="variant-available">
                                            From{" "}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    <FormattedNumber
                                        style={`currency`}
                                        currencyDisplay="narrowSymbol"
                                        value={itemData.itemPrice}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={outletData.currency || "gbp"}
                                    />
                                </div>
                                {itemData.customAttributes &&
                                itemData.customAttributes.length > 0 &&
                                (itemData?.assignedModifiers?.length > 0 ||
                                    itemData?.variants?.length > 0) ? (
                                    <span className="variant-available">
                                        From
                                    </span>
                                ) : (
                                    <></>
                                )}
                            </div>
                            {((itemData.customAttributes &&
                                itemData.customAttributes.length > 0) ||
                                (itemData.nutritions &&
                                    Object.values(itemData.nutritions).some(
                                        (val) => val !== 0,
                                    ))) && (
                                <div
                                    className="custom-attribute-details"
                                    onClick={() =>
                                        setShowCustomAttributeModal(true)
                                    }
                                >
                                    <AiOutlineInfoCircle size={22} />
                                </div>
                            )}
                        </div>
                        <div className="price-button-section">
                            <div
                                className={`item-action ${
                                    quantityLoader ? "disable-qty-btn" : ""
                                }`}
                            >
                                {inBasket + diff > 0 ? (
                                    <div className="action-button-space-between">
                                        {inBasket + diff &&
                                        itemData.assignedModifiers.length ===
                                            0 &&
                                        itemData.variants.length === 0 ? (
                                            <AiOutlineMinus
                                                onClick={handleMinusClick}
                                                color={textColor}
                                                size={20}
                                                style={{ strokeWidth: "50" }}
                                            />
                                        ) : (
                                            <>&nbsp;</>
                                        )}
                                        <span className="item-quantity">
                                            {inBasket + diff}
                                        </span>
                                        <AiOutlinePlus
                                            onClick={handleItemAdd}
                                            color={textColor}
                                            size={20}
                                            style={{ strokeWidth: "50" }}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="action-button"
                                        onClick={handleItemAdd}
                                    >
                                        ADD
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                isOpen={enableModifiers}
                onRequestClose={closeModal}
                hideDivider={true}
                title={itemData.itemName}
                modalContainerClass={"modifier-modal-class"}
            >
                {basketItemIndex >= 0 &&
                Boolean(inBasket + diff) &&
                itemData.assignedModifiers.length === 0 &&
                itemData.variants.length === 0 ? (
                    <ItemModifierModal
                        itemData={{
                            ...modifiersItemsData,
                            quantity: inBasket,
                            ...(currentBasket.items || [])[basketItemIndex],
                            specialInstructions: (currentBasket.items || [])[
                                basketItemIndex
                            ].specialInstructions,
                        }}
                        closeModal={closeModal}
                        isEdit={true}
                        itemIndex={basketItemIndex}
                    />
                ) : (
                    <ItemModifierModal
                        enableModifiers={enableModifiers}
                        itemData={modifiersItemsData}
                        closeModal={closeModal}
                    />
                )}
            </CustomModal>
            <AttributesModal
                showAttributeModal={showCustomAttributeModal}
                setShowAttributeModal={setShowCustomAttributeModal}
                itemData={itemData}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        basket: state.cart,
        outletData: state.outletData,
        siteData: state.siteData,
        menuPayType: state.menuPayType,
        prepaidMaxProduct: state.prepaidMaxProduct,
        quantityLoader: state.quantityLoader,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addItemsToCart: (item) => dispatch(addItemsToCart(item)),
        setQuantityLoader: (value) => {
            return dispatch(setQuantityLoader(value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionItemCard);
