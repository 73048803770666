import { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import {
    calculateRemainingQty,
    capitalize,
    checkPrePaidDisable,
    notify,
} from "../../../common/utils";
import constants from "../../../constants/constants";
import {
    addItemsToCart,
    deleteItemFromCart,
} from "../../../store/actions/ProductActions";
import { CustomDropdown } from "../../custom-dropdown";
import CustomModal from "../../custom-modal";
import ItemModifierModal from "../../menu-items/CategoryCard/ItemModifierModal";

const ItemCard = (props) => {
    const {
        storeName,
        item,
        index,
        outletData,
        menuPayType,
        prepaidMaxProduct,
        basket,
    } = props;
    const [enableModifiers, setEnableModifiers] = useState(false);

    function openModal() {
        setEnableModifiers(true);
    }

    function closeModal() {
        setEnableModifiers(false);
    }

    const handleQuantityChange = (value) => {
        const quantity = value - item.quantity;
        if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
            const remainingQty = calculateRemainingQty(
                prepaidMaxProduct,
                basket.carts[0].items.filter(
                    (i) => i.cartSKU !== item.cartSKU,
                ) || [],
            );
            if (checkPrePaidDisable(remainingQty - value, menuPayType)) {
                notify("Not allowed to enter more than remaining qty");
                return;
            }
        }
        props.addItemsToCart({ ...item, quantity: quantity });
    };

    const getSubHeading = (item) => {
        let result = "";
        for (let i = 0; i < item.variants.length; i++) {
            if (item.variants[i].isSelected) {
                result += capitalize(item.variants[i].variantName || "");
            }
        }
        for (let i = 0; i < item.assignedModifiers.length; i++) {
            let modifierName = item.assignedModifiers[i].displayName;
            let count = 0;
            for (
                let j = 0;
                j < item.assignedModifiers[i].modifiers.length;
                j++
            ) {
                if (item.assignedModifiers[i].modifiers[j].isSelected) {
                    count += 1;
                    if (count === 1) {
                        modifierName += " - ";
                    } else {
                        modifierName += ", ";
                    }
                    modifierName +=
                        item.assignedModifiers[i].modifiers[j].displayName +
                        " x " +
                        item.assignedModifiers[i].modifiers[j].quantity;
                }
            }
            if (count) {
                if (result) {
                    result += "; ";
                }
                result += modifierName;
            }
        }
        if (result)
            return (
                <div className="item-description secondary-text-color">
                    {result}
                </div>
            );
        return;
    };

    return (
        <div className="item-container">
            <div className="item-name-price">
                <div className="item-name">
                    <div>
                        {storeName} - {item.itemName}
                    </div>
                </div>
                {menuPayType !== constants.MENU_PAY_TYPE.PREPAID ? (
                    <div className="item-price">
                        <FormattedNumber
                            style={`currency`}
                            currencyDisplay="narrowSymbol"
                            value={item.subTotal}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={outletData.currency || "gbp"}
                        />
                    </div>
                ) : null}
            </div>
            {getSubHeading(item)}
            <div className="item-footer">
                <div className="item-extra-info">
                    <CustomDropdown
                        options={[...Array(99)].map((_, index) => ({
                            label: index + 1,
                            value: index + 1,
                        }))}
                        onSelect={handleQuantityChange}
                        value={item.quantity}
                        dropDownContainerClass="custom-qty-select-container"
                        dropDownHeaderClass="custom-qty-select-header"
                        dropDownListContainerClass="custom-qty-select-list-container"
                        dropDownListClass="custom-qty-select-list"
                        dropDownItemClass="custom-qty-select-item"
                    />
                    <div
                        style={{
                            marginLeft: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <FaEdit size={21} onClick={openModal} />
                    </div>
                </div>
                <div className="item-count">
                    <BiTrash
                        size={25}
                        color="red"
                        onClick={() => props.deleteItemFromCart(index, item)}
                    />
                </div>
            </div>
            <CustomModal
                isOpen={enableModifiers}
                onRequestClose={closeModal}
                hideDivider={true}
                title={item.itemName}
                modalContainerClass={"modifier-modal-class"}
            >
                <ItemModifierModal
                    itemData={item}
                    closeModal={closeModal}
                    isEdit={true}
                    itemIndex={index}
                />
            </CustomModal>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        basket: state.cart,
        outletData: state.outletData,
        menuPayType: state.menuPayType,
        prepaidMaxProduct: state.prepaidMaxProduct,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addItemsToCart: (item) => dispatch(addItemsToCart(item)),
        deleteItemFromCart: (index, item) =>
            dispatch(deleteItemFromCart(index, item)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCard);
