const constants = {
    ZERO_DECIMAL_COUNTRIES: [
        "bif",
        "clp",
        "djf",
        "gnf",
        "jpy",
        "kmf",
        "krw",
        "mga",
        "pyg",
        "rwf",
        "ugx",
        "vnd",
        "vuv",
        "xaf",
        "xof",
        "xpf",
    ],
    ROUTES: {
        SITE: "site",
        SITE_DETAIL: "site/:siteId",
        ZONE: "zone",
        ZONE_DETAIL: "zone/:zoneId",
        OUTLET: "outlet",
        OUTLET_DETAIL: "outlet/:outletId",
        SERVICE_TYPE: "service-type",
        SERVICE_TYPE_DETAIL: "service-type/:serviceType",
        CUSTOMER: "customer",
        CUSTOMER_DETAIL: "customer/:customerId",
        //NOT USED
        ORDER: "orders",
        ORDER_DETAILS: "orders/:orderId",
        PAYMENT_DETAILS: "payment-link/:paymentId",
        ISSUE: "issues",
        ISSUE_DETAIL: "issues/:issueType",
        FEEDBACK: "feedback",
        FEEDBACK_REDIRECT: "feedback/redirect",
        LOYALTY: "loyalty",
        LOYALTY_POINTS: "loyalty/points",
        LOYALTY_OUTLET: "loyalty/points/:loyaltyId",
        BASKET: "basket",
        CHECKOUT: "checkout",
        MENUS: "menus",
        MENUS_DETAIL: "menus/:menuId",
        MULTI_ORDER_DETAIL: "multi/orders/:cartId",
        UPSELL: "upsell",
    },
    ICON_SIZE: 20,
    LOCATION_INPUT: {
        VENDOR: "Vendor",
        CUSTOMER: "Customer",
    },
    MENU_PAY_TYPE: {
        PREPAID: "prepaid",
        PAID: "paid",
    },
    SERVICE_TYPE: {
        onsiteDelivery: "onsiteDelivery",
        offsiteDelivery: "offsiteDelivery",
        onsiteCounter: "onsiteCounter",
        onsiteOffCounter: "onsiteOffCounter",
        onsiteCollection: "onsiteCollection",
        offsiteCollection: "offsiteCollection",
        onsiteExpress: "onsiteExpress",
    },
    OUTLET_STORE_TYPE: {
        SITE_DEPENDENT: "SITE DEPENDENT",
        SITE_INDEPENDENT: "SITE INDEPENDENT",
    },
    SITE_CATEGORY: {
        GEOGRAPHICAL: "GEOGRAPHICAL",
        SITE_DEPENDENT: "SITE DEPENDENT",
    },
    SITE_NAVIGATION_TYPE: {
        ATOZ: "ATOZ",
    },
    ESTIMATED_PREP_TIME_MAPPING: {
        5: "5mins",
        10: "10mins",
        15: "15mins",
        20: "20mins",
        30: "30mins",
        45: "45mins",
        60: "60mins",
        90: "90mins",
    },
    REGEX: {
        MOBILE_NUMBER: /^\d*$/
    }
};
export default constants;
