import { useNavigate } from "react-router-dom";
import CustomButton from "../custom-button";
import CustomModal from "../custom-modal";

const StockAlertModal = ({ stockError, setStockError, items }) => {
    const navigate = useNavigate();
    return (
        <CustomModal
            isOpen={Boolean(stockError && Object.keys(stockError).length)}
            onRequestClose={() => {
                setStockError(null);
                navigate(-1);
            }}
            ariaHideApp={false}
            hideDivider={true}
            modalContainerClass={"checkout-modal-container"}
        >
            {Boolean(stockError && Object.keys(stockError).length) && (
                <div className="box-container">
                    <p className="mt-0">
                        {" "}
                        Sorry, the selected items are currently out of stock.
                        Please modify the quantity or select a different item.
                    </p>
                    <ul className="stock-alert-modal-list">
                        {items.map((item, index) => {
                            if (stockError[item.cartSKU]) {
                                return <li key={index}> {item.itemName} </li>;
                            } else {
                                return <></>;
                            }
                        })}
                    </ul>
                    <div className="error-pop-up-button">
                        <CustomButton
                            className="btn-block"
                            onClick={() => {
                                setStockError(null);
                                navigate(-1);
                            }}
                        >
                            <span>Okay</span>
                        </CustomButton>
                    </div>
                </div>
            )}
        </CustomModal>
    );
};

export default StockAlertModal;
