import { useEffect } from "react";
import { useNavigate } from "react-router";
import CustomButton from "../custom-button";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import "./logout.scss";
import NoqLogo from "../../assets/imgs/logo.png";
import { connect } from "react-redux";
import { Loader } from "../custom-loader";

const SignOut = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (props.user) {
            navigate("/customer", { replace: true });
        }
    }, [props.user]);
    if (props.user) return <Loader />;
    return (
        <div className="logout-container">
            <div className="logout-header">
                <div className="title-with-icon">
                    <CgProfile size={35} />
                    <h2>{props.redirectURL ? "Logged Out!" : "Welcome !"}</h2>
                </div>
                <div>
                    <span
                        onClick={() =>
                            props.redirectURL
                                ? navigate(props.redirectURL)
                                : navigate(-1)
                        }
                    >
                        <AiOutlineCloseCircle size={35} />
                    </span>
                </div>
            </div>
            <div className="mt-100">
                <CustomButton
                    type="button"
                    className="btn-block btn-bw"
                    onClick={() => {
                        props.redirectURL
                            ? navigate(props.redirectURL)
                            : navigate("/login");
                    }}
                >
                    {" "}
                    {props.redirectURL ? "Order Again" : "Sign In"}
                </CustomButton>
            </div>
            <div className="footer">
                <div className="greyed-text">Powered by</div>
                <div>
                    <img src={NoqLogo} alt="noq-logo" />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        redirectURL: state.redirectURL,
        user: state.user,
    };
};

export default connect(mapStateToProps, null)(SignOut);
