import { network } from "../common/network";
import apiConsts from "../constants/apiConstants";
import { getLatLngByZipcode, vendorDateTime } from "../common/utils";

export const getOutlets = async (outletId, siteId = "", zoneId = "") => {
    const response = await network(
        `${apiConsts.outletURI}/${outletId}?siteRef=${siteId}&zoneRef=${zoneId}`,
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getOrderNowMenuDetails = async (
    outletId,
    serviceType,
    timezone,
    vendorIds = null,
    isMultiOrder = false,
    siteRef = "",
) => {
    if (isMultiOrder) {
        const response = await network(
            `${
                apiConsts.serviceTypeURI
            }/${serviceType}/order-now-menu?timezone=${timezone}&date=${vendorDateTime(
                timezone,
            ).toISOString()}&vendorIds=${JSON.stringify(vendorIds)}`,
        );
        if (response.success) return response.data;
        throw response.message;
    }
    const response = await network(
        `${
            apiConsts.outletURI
        }/${outletId}/service-type/${serviceType}/order-now-menu?timezone=${timezone}&date=${vendorDateTime(
            timezone,
        ).toISOString()}&siteRef=${siteRef}`,
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getPreOrderMenuDetails = async (outletId, serviceType) => {
    const response = await network(
        `${apiConsts.outletURI}/${outletId}/service-type/${serviceType}/pre-order`,
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getMenuItemsList = async (outletId, menuId, serviceType) => {
    const response = await network(
        `${apiConsts.outletURI}/${outletId}/menu/${menuId}?serviceType=${serviceType}`,
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getCustomAllergensAndDietaryByMenu = async (outletId, menuId) => {
    const response = await network(
        `${apiConsts.outletURI}/${outletId}/menu/${menuId}/get-custom-allergen-dietary`,
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getPostCodeInfo = async (postCode) => {
    // const response = await axios.get(`https://api.postcodes.io/postcodes/${postCode}`, {baseURL: ""});
    // if(response.error) return false;
    // return {lat: response.data.result.latitude, lng: response.data.result.longitude};
};

export const createPaymentIntent = async (payload) => {
    const response = await network(
        `${apiConsts.paymentURI}/create`,
        "POST",
        payload,
    );
    if (response.success) return response.data;
    throw response.message;
};

export const createOrder = async (payload) => {
    const response = await network(
        `${apiConsts.orderURI}/create`,
        "POST",
        payload,
    );
    if (response.success) return response;
    throw response.message;
};

export const createAdyenOrder = async (payload) => {
    const response = await network(
        `${apiConsts.orderURI}/create-adyen`,
        "POST",
        payload,
    );
    if (response.success) return response;
    throw response.message;
};

export const completeAdyenOrder = async (
    cartId,
    sessionId,
    paymentSucceeded = true,
) => {
    const response = await network(
        `${apiConsts.orderURI}/complete-adyen/${cartId}/${paymentSucceeded}`,
        "PUT",
        { sessionId },
    );
    if (response.success) return response;
    throw response.message;
};

export const completeAdyenOrderBySessionId = async (
    sessionId,
    paymentSucceeded = true,
) => {
    const response = await network(
        `${apiConsts.orderURI}/complete-adyen-by-session-id/${sessionId}/${paymentSucceeded}`,
        "PUT",
    );
    if (response.success) return response;
    throw response.message;
};

export const deleteAdyenOrder = async (cartId) => {
    const response = await network(
        `${apiConsts.orderURI}/delete-by-cart/${cartId}`,
        "DELETE",
    );
    if (response.success) return response;
    // throw response.message;
};

export const createFreeOrder = async (payload) => {
    const response = await network(
        `${apiConsts.orderURI}/create-free-order`,
        "POST",
        payload,
    );
    if (response.success) return response;
    throw response.message;
};

export const getOrder = async (orderId) => {
    const response = await network(`${apiConsts.orderURI}/${orderId}`);
    if (response.success) return response.data;
    throw response.message;
};

export const getMultiOrder = async (cartId) => {
    const response = await network(`${apiConsts.multiOrderURI}/${cartId}`);
    if (response.success) return response.data;
    throw response.message;
};

export const getSite = async (siteId) => {
    const response = await network(`${apiConsts.siteURI}/${siteId}`);
    if (response.success) return response.data;
    throw response.message;
};

export const getSiteZone = async (siteId) => {
    const response = await network(`${apiConsts.siteURI}/${siteId}/zones`);
    if (response.success) return response.data;
    throw response.message;
};

export const getZone = async (siteId, zoneId) => {
    const response = await network(
        `${apiConsts.siteURI}/${siteId}/zones/${zoneId}`,
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getOutletList = (
    siteId,
    zoneId,
    postalCode,
    serviceType = null,
) => {
    return new Promise(async (resolve, reject) => {
        let url = `${apiConsts.siteURI}/${siteId}/outlets`;

        if (zoneId) url += `?zoneId=${zoneId}`;
        let geoLocation = null;
        if (postalCode) {
            try {
                geoLocation = await getLatLngByZipcode(postalCode);
            } catch {
                reject("Postcode is not valid");
                return;
            }
            url +=
                (url.indexOf("?") === -1 ? "?" : "&") +
                `postalCode=${postalCode}&coordinates=[${geoLocation.latitude},${geoLocation.longitude}]`;
        }

        if (serviceType) {
            url +=
                (url.indexOf("?") === -1 ? "?" : "&") +
                `serviceType=${serviceType}`;
        }

        const response = await network(url);

        if (response.success) {
            if (postalCode && response.data) {
                var origin1 = new window.google.maps.LatLng(
                    geoLocation.latitude,
                    geoLocation.longitude,
                );
                var destination = [];
                for (let outlet of response.data) {
                    const [lng, lat] = outlet.coordinates;
                    destination.push(new window.google.maps.LatLng(lat, lng));
                }

                var service = new window.google.maps.DistanceMatrixService();
                service.getDistanceMatrix(
                    {
                        origins: [origin1],
                        destinations: destination,
                        travelMode: "DRIVING",
                    },
                    (mapResponse, status) => {
                        const data = [];
                        const unknowDistance = [];
                        for (let outletIndex in response.data) {
                            let outlet = response.data[outletIndex];
                            const address =
                                mapResponse &&
                                mapResponse.rows[0] &&
                                mapResponse.rows[0].elements &&
                                mapResponse.rows[0].elements[outletIndex]
                                    ? mapResponse.rows[0].elements[outletIndex]
                                    : null;
                            if (outlet && address && address.status === "OK") {
                                data.push({
                                    ...outlet,
                                    distance: (
                                        (address.distance.value / 1000) *
                                        0.621371
                                    ).toFixed(2),
                                });
                            } else {
                                unknowDistance.push({
                                    ...outlet,
                                    distance: "",
                                });
                            }
                        }
                        // the basics of a callback function.
                        resolve([
                            ...data.sort((a, b) => a.distance - b.distance),
                            ...unknowDistance,
                        ]);
                        return;
                    },
                );
            } else {
                resolve(response.data);
                return;
            }
        } else {
            reject(response.message);
            return;
        }
    });
};

export const checkStockAlert = async (cartObj) => {
    const response = await network(apiConsts.stockURI, "POST", cartObj);
    if (response.success) return response.data;
    throw response;
};

export const checkLoyaltyAvailable = async (
    siteId = "",
    outletId = "",
    isMultiOrder = false,
) => {
    let url = `${apiConsts.loyaltyURI}/loyalty-by-site-or-vendor?`;
    if (isMultiOrder) {
        url += `siteId=${siteId}`;
    } else {
        url += `vendorId=${outletId}&siteId=${siteId}`;
    }
    const response = await network(url, "GET");
    if (response.success) return response.data;
    throw response;
};

export const getAllFilledSlots = async (outletId, menuId, serviceType) => {
    const response = await network(
        `${apiConsts.filledSlotURL}/${outletId}/${menuId}/service-type/${serviceType}`,
        "GET",
    );
    if (response.success) return response.data;
    return [];
};

export const getUserCart = async (userId, cartId) => {
    const response = await network(
        `/v2/web/cart/get-cart?userId=${userId}&cartId=${cartId}`,
        "GET",
    );
    if (response.success) return response.data;
    throw response;
};

export const addItemsToUserCart = async (cartObj) => {
    const response = await network(`/v2/web/cart/add-to-cart`, "POST", cartObj);
    if (response.success) return response.data;
    throw response;
};
export const updateUserCart = async (cartObj) => {
    const response = await network(`/v2/web/cart/update-cart`, "POST", cartObj);
    if (response.success) return response.data;
    throw response;
};

export const removeCart = async (cartObj) => {
    const response = await network(
        `/v2/web/cart/remove-cart`,
        "DELETE",
        cartObj,
    );
    if (response.success) return response.data;
    throw response;
};

export const getOrders = async () => {
    const response = await network(`${apiConsts.orderURI}`, "GET").catch(
        (err) => err,
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getPaymentLink = async (paymentLinkId) => {
    const response = await network(
        `${apiConsts.paymentLinkURI}/${paymentLinkId}`,
        "GET",
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getLoyalties = async () => {
    const response = await network(`${apiConsts.loyaltyURI}`, "GET").catch(
        (err) => err,
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getLoyaltyDetail = async (loyaltyId) => {
    const response = await network(
        `${apiConsts.loyaltyURI}/${loyaltyId}/detail`,
        "GET",
    ).catch((err) => err);
    if (response.success) return response.data;
    throw response.message;
};

export const createFeedback = async (payload) => {
    const response = await network(
        `${apiConsts.feedbackURI}`,
        "POST",
        payload,
    ).catch((err) => {
        throw err.message;
    });
    if (response.success) return response.data;
    throw response.message;
};

export const createGuestFeedback = async (payload) => {
    const response = await network(
        `${apiConsts.feedbackURI}/guest`,
        "POST",
        payload,
    ).catch((err) => {
        throw err.message;
    });
    if (response.success) return response.data;
    throw response.message;
};

export const getFeedbackByOrder = async (orderId) => {
    const response = await network(
        `${apiConsts.feedbackURI}/order/${orderId}`,
        "GET",
    );
    if (response.success) return response.data;
    throw response.message;
};

export const createIssue = async (payload) => {
    const response = await network(`${apiConsts.issueURI}`, "POST", payload);
    if (response.success) return response.data;
    throw response.message;
};

export const getPrepaidInformation = async (menuId, email) => {
    const response = await network(
        `${apiConsts.webURI}/${menuId}${apiConsts.prepaidURI}`,
        "POST",
        { email },
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getUserPromotion = async (userId, assignedPromotions) => {
    if (assignedPromotions.length === 0) {
        return null;
    }

    const promotionIds =
        assignedPromotions
            .filter(
                (promo) =>
                    promo?.promotionRef?.membershipRelated === true &&
                    promo?.promotionRef?.promotionType === "save",
            )
            .map((promo) => {
                return promo.promotionRef._id;
            }) || [];

    if (promotionIds.length) {
        const response = await network(
            `${apiConsts.userPromotionURI}/${userId}/assigned-promotions/${promotionIds}`,
            "GET",
        );
        if (response.success) return response.data;
        throw response.message;
    }
    return {};
};

export const createSession = async (
    outletRef,
    payload,
    returnUrl,
    userDetails,
) => {
    const response = await network(
        `${apiConsts.adyenPaymentSessionURI}/${outletRef}`,
        "POST",
        { ...payload, returnUrl, userDetails },
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getStoresEstPrepTime = async (vendorIds) => {
    const response = await network(
        `/v2/web/estimated-prep-time?vendorIds=${JSON.stringify(vendorIds)}`,
        "GET",
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getPromotionByPromoCode = async (promoCode, payload) => {
    const url = `${apiConsts.getPromoCodePromotionURI}/${promoCode}`;
    const response = await network(url, "POST", payload);
    if (response.success) return response.data;
    throw response.message;
};

export const getUpsellItems = async ({ menuIds, serviceType, outletId }) => {
    const response = await network(`${apiConsts.upsellURI}`, "POST", {
        menuIds,
        serviceType,
        outletId,
    });
    if (response.success) return response.data;
    throw response.message;
};

export const getAutomatedPromoRefByStores = async (vendorIds) => {
    const response = await network(
        `/v2/web/stores-automated-promo?vendorIds=${JSON.stringify(vendorIds)}`,
        "GET",
    );
    if (response.success) return response.data;
    throw response.message;
};

export const getItemBasedPromotions = async (siteId, vendorId, timezone) => {
    let uri = `/v2/web/item-base-promo?vendorId=${vendorId}&timezone=${timezone}`;
    if (siteId) {
        uri += `&siteId=${siteId}`;
    }
    const response = await network(uri, "GET");
    if (response.success) return response.data;
    throw response.message;
};

export const getItemBasedPromotionByPromoIds = async (promoIds) => {
    const response = await network(
        `/v2/web/validate-item-base-promocode?promotionIds=${JSON.stringify(
            promoIds,
        )}`,
        "GET",
    );
    if (response.success) return response.data;
    throw response.message;
};
