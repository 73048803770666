import CustomModal from "../../custom-modal";

const NutritionMapping = {
    energy: { title: "Energy", unit: "kcal" },
    protein: { title: "Proteins", unit: "Grams" },
    carbohydrate: { title: "Carbohydrates", unit: "Grams" },
    fat: { title: "Fat", unit: "Grams" },
    saturatedFat: { title: "Saturated fat", unit: "Grams" },
};

const AttributesModal = (props) => {
    const { itemData, showAttributeModal, setShowAttributeModal } = props;
    const attributeData = itemData.customAttributes || [];
    return (
        <CustomModal
            isOpen={showAttributeModal}
            onRequestClose={() => setShowAttributeModal(false)}
            ariaHideApp={false}
            hideDivider={true}
            modalContainerClass={"custom-attribute-modal-container"}
            title={`${itemData?.itemName || "Item"} Details`}
        >
            <div>
                <div className="header-divider"></div>
                <div className="attribute-modal-content">
                    {(attributeData || [])
                        .filter(
                            (attr) =>
                                attr &&
                                attr.attributeValues &&
                                attr.attributeValues.length > 0,
                        )
                        .map((attribute, index) => {
                            const allAttributeValues =
                                attribute.attributeValues.map(
                                    (value) => value?.name,
                                );
                            return (
                                <div className="attribute-block" key={index}>
                                    <div className="attribute-title">
                                        {attribute.attributeName}
                                    </div>
                                    <div className="attribute-values">
                                        {allAttributeValues.join(", ")}
                                    </div>
                                </div>
                            );
                        })}

                    {itemData.nutritions &&
                        Object.values(itemData.nutritions).some(
                            (val) => val
                        ) && (
                            <div className="nutrition-block">
                                <div className="nutrition-title">Nutrition</div>
                                {Object.entries(itemData.nutritions)?.map(
                                    ([nutritionKey, value]) => {
                                        if (!value) return <></>;
                                        return (
                                            <div className="nutrition-attribute-container">
                                                <div className="nutrition-name">
                                                    {
                                                        NutritionMapping[
                                                            nutritionKey
                                                        ].title
                                                    }
                                                </div>
                                                <div className="nutrition-unit flex-row-end">
                                                    {
                                                        NutritionMapping[
                                                            nutritionKey
                                                        ].unit
                                                    }
                                                </div>
                                                <div className="nutrition-value flex-row-end">
                                                    {value}
                                                </div>
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        )}
                </div>
            </div>
        </CustomModal>
    );
};

export default AttributesModal;
