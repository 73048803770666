import { CustomDropdown } from "../custom-dropdown";
import { getMonth, getTime } from "../../common/utils";
import { ErrorMessage } from "formik";
import { CustomInput } from "../custom-input";
import constants from "../../constants/constants";

const renderPreOrder = (
    serviceData,
    dateLabel = "Pick Up Date",
    timeLabel = "Pick Up Time",
) => (
    <>
        <div className="flex-col text-bold">
            <h2>{serviceData.serviceTypeDisplayName} - Pre Order</h2>
            <div className="flex-row">
                <div>{dateLabel}</div>
                <div>{getMonth(new Date(serviceData.deliveryDate))}</div>
            </div>
            <div className="flex-row">
                <div>{timeLabel}</div>
                <div>{getTime(new Date(serviceData.deliveryDate))}</div>
            </div>
        </div>
    </>
);

const renderOutletServiceTypeComponent = ({
    props,
    service,
    setAdditionVerificationTableNum,
}) => {
    return (
        <>
            <div className="row">
                {service.locationInput === "Vendor" && (
                    <span className="column-two">
                        {service?.locationTitleCustom?.trim() !== ""
                            ? service?.locationTitleCustom
                            : "Location"}
                        *
                    </span>
                )}
                <div className="column-two flex-end">
                    <div className="checkout-input-group">
                        {service.locationInput === "Vendor" ? (
                            <>
                                <CustomDropdown
                                    options={service.locationName.map(
                                        (loc) => ({ label: loc, value: loc }),
                                    )}
                                    onSelect={(value) =>
                                        props.setFieldValue(
                                            "vendorZoneName",
                                            value,
                                        )
                                    }
                                    value={props.values.vendorZoneName}
                                    dropDownContainerClass="table-menu-container"
                                    dropDownHeaderClass="table-menu-header"
                                    dropDownListContainerClass="table-menu-list-container"
                                    dropDownListClass="table-menu-list"
                                    dropDownItemClass="table-menu-item"
                                />
                                <ErrorMessage
                                    name="vendorZoneName"
                                    component="div"
                                    className="error"
                                />
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="column-two label-location">
                    <label>
                        {service.locationIdentifier}
                        {service?.locationInput ===
                            constants.LOCATION_INPUT.VENDOR &&
                        !service?.isLocationIdentifierRequired
                            ? ""
                            : "*"}
                    </label>
                </div>
                <div className="column-two">
                    <div className="checkout-input-group">
                        <input
                            type="text"
                            {...props.getFieldProps("tableNumber")}
                            onChange={(e) => {
                                props.setFieldValue(
                                    "tableNumber",
                                    e.target.value,
                                );
                                setAdditionVerificationTableNum(e.target.value);
                            }}
                            className="checkout-input"
                            name="tableNumber"
                            value={props.values.tableNumber}
                        />
                        <ErrorMessage
                            name="tableNumber"
                            component="div"
                            className="error"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const renderSiteServiceTypeComponent = ({
    props,
    service,
    locationIdentifier,
    locationNames,
    serviceData,
    setAdditionVerificationTableNum,
}) => {
    if (service.locationInput === "Vendor") {
        return (
            <>
                <div className="row">
                    <div className="column-two label-location">
                        <label>
                            {locationIdentifier}
                            {service.locationInput ===
                                constants.LOCATION_INPUT.VENDOR &&
                            !service.isLocationIdentifierRequired
                                ? ""
                                : "*"}
                        </label>
                    </div>
                    <div className="column-two">
                        <div className="checkout-input-group">
                            <CustomDropdown
                                options={locationNames.map((loc) => ({
                                    label: loc,
                                    value: loc,
                                }))}
                                onSelect={(value) => {
                                    props.setFieldValue("tableNumber", value);
                                    setAdditionVerificationTableNum(value);
                                }}
                                value={props.values.tableNumber}
                                dropDownContainerClass="table-menu-container"
                                dropDownHeaderClass="table-menu-header"
                                dropDownListContainerClass="table-menu-list-container"
                                dropDownListClass="table-menu-list"
                                dropDownItemClass="table-menu-item"
                            />
                            <ErrorMessage
                                name="tableNumber"
                                component="div"
                                className="error"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <div className="row">
            <div className="column-two label-location">
                <label>
                    {locationIdentifier}
                    {service.locationInput ===
                        constants.LOCATION_INPUT.VENDOR &&
                    !service.isLocationIdentifierRequired
                        ? ""
                        : "*"}
                </label>
            </div>
            <div className="column-two">
                <div className="checkout-input-group">
                    <input
                        type={
                            serviceData?.isInputTypeNumber ? "number" : "text"
                        }
                        {...props.getFieldProps("tableNumber")}
                        onChange={(e) => {
                            props.setFieldValue("tableNumber", e.target.value);
                            setAdditionVerificationTableNum(e.target.value);
                        }}
                        className="checkout-input"
                        name="tableNumber"
                        value={props.values.tableNumber}
                    />
                    <ErrorMessage
                        name="tableNumber"
                        component="div"
                        className="error"
                    />
                </div>
            </div>
        </div>
    );
};
export const ServiceTypeInfo = ({
    locationNames,
    service,
    locationIdentifierSelect,
    props,
    siteData,
    outletData,
    serviceType,
    serviceData,
    handleChangeField,
    setAdditionVerificationTableNum,
}) => {
    return (
        <>
            {serviceType === "onsiteCollection" ||
            serviceType === "offsiteCollection" ||
            serviceType === "onsiteExpress" ? (
                <>
                    {serviceData.preOrder === true &&
                        serviceData.deliveryDate &&
                        renderPreOrder(serviceData)}
                    {!serviceData.preOrder && (
                        <h4>
                            {serviceData.serviceTypeDisplayName} -{" "}
                            <span>As soon as possible</span>
                        </h4>
                    )}
                </>
            ) : null}
            {serviceType === constants.SERVICE_TYPE.onsiteDelivery && (
                <>
                    {/* <h4>Table Ordering</h4> */}
                    <h4>
                        {serviceData.serviceTypeDisplayName}{" "}
                        {serviceData.preOrder ? "- Pre Order" : ""}
                    </h4>
                    <div className="sub-section">
                        {outletData.storeType ===
                            constants.OUTLET_STORE_TYPE.SITE_DEPENDENT &&
                        siteData?.category ===
                            constants.SITE_CATEGORY.SITE_DEPENDENT
                            ? renderSiteServiceTypeComponent({
                                  props,
                                  service,
                                  locationIdentifier:
                                      locationIdentifierSelect?.locationIdentifier ||
                                      service?.locationIdentifier,
                                  locationNames,
                                  serviceData,
                                  setAdditionVerificationTableNum,
                              })
                            : renderOutletServiceTypeComponent({
                                  props,
                                  service,
                                  setAdditionVerificationTableNum,
                              })}
                    </div>
                </>
            )}
            {(serviceType === constants.SERVICE_TYPE.offsiteDelivery ||
                serviceType === constants.SERVICE_TYPE.onsiteDelivery) &&
            serviceData.deliveryDate ? (
                <>
                    <h4>
                        {serviceData.serviceTypeDisplayName}{" "}
                        {serviceData.preOrder ? "- Pre Order" : ""}
                    </h4>
                    <div className="sub-section">
                        <div className="row">
                            <span className="column-two">Delivery Date</span>
                            <span className="column-two flex-end">
                                {getMonth(new Date(serviceData.deliveryDate))}
                            </span>
                        </div>
                        <div className="row">
                            <span className="column-two">Delivery Time</span>
                            <span className="column-two flex-end">
                                {getTime(new Date(serviceData.deliveryDate))}
                            </span>
                        </div>
                    </div>
                </>
            ) : null}
            {serviceType === constants.SERVICE_TYPE.offsiteDelivery ? (
                <>
                    <h4>Delivery Address</h4>
                    <div className="sub-section">
                        <div className="row">
                            <span className="column-two text-secondary">
                                Postcode*
                            </span>
                            <div className="column-two flex-end">
                                <div className="checkout-input-group">
                                    <CustomInput
                                        value={serviceData.postCode}
                                        onChange={(value) =>
                                            handleChangeField(
                                                props,
                                                "postalcode",
                                                value,
                                            )
                                        }
                                        name="postalcode"
                                        className={"checkout-input"}
                                        disabled={true}
                                    />
                                    <ErrorMessage
                                        name="postalcode"
                                        component="div"
                                        className="error"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <span className="column-two text-secondary">
                                Flat/House Number*
                            </span>
                            <div className="column-two flex-end">
                                <div className="checkout-input-group">
                                    <input
                                        type="text"
                                        {...props.getFieldProps("houseNumber")}
                                        name="houseNumber"
                                        className={"checkout-input"}
                                    />
                                    {props.touched.houseNumber &&
                                    props.errors.houseNumber ? (
                                        <ErrorMessage
                                            name="houseNumber"
                                            component="div"
                                            className="error"
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <span className="column-two text-secondary">
                                Street Name*
                            </span>
                            <div className="column-two flex-end">
                                <div className="checkout-input-group">
                                    <input
                                        type="text"
                                        {...props.getFieldProps("street")}
                                        name="street"
                                        className={"checkout-input"}
                                    />
                                    {props.touched.street &&
                                    props.errors.street ? (
                                        <ErrorMessage
                                            name="street"
                                            component="div"
                                            className="error"
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <span className="column-two text-secondary">
                                Special Instructions
                            </span>
                            <div className="column-two flex-end">
                                <div className="checkout-input-group">
                                    <input
                                        type="text"
                                        {...props.getFieldProps(
                                            "specialInstructions",
                                        )}
                                        name="specialInstructions"
                                        className={"checkout-input"}
                                    />
                                    {props.touched.specialInstructions &&
                                    props.errors.specialInstructions ? (
                                        <ErrorMessage
                                            name="specialInstructions"
                                            component="div"
                                            className="error"
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};
