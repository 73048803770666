import { useNavigate, useParams } from "react-router-dom";
import useQuery from "../../common/useQuery";
import "./site-detail.scss";
import { useState, useEffect } from "react";
import {
    getAutomatedPromotion,
    getBannerClassName,
    getImageUrl,
} from "../../common/utils";
import { connect } from "react-redux";
import { apiService } from "../../services";
import { Loader } from "../custom-loader";
import constants from "../../constants/constants";
import LandingAuth from "../../components/landing-auth";
import { setGuestSession } from "../../store/actions/UserAction";
import { setRedirectURL } from "../../store/actions/UserAction";
import MainHeader from "../common/MainHeader";
import SiteOutletInfoModal from "../common/SiteOutletInfoModal";
import NonGeographicalArea from "./NonGeographicalArea";
import GeographicalArea from "./GeographicalArea";
import { IMAGE_SIZES } from "../../constants/image";

function SiteDetail(props) {
    const navigate = useNavigate();
    const { siteId } = useParams();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [site, setSite] = useState();
    const [isLoading, setLoading] = useState(false);
    const [siteAutomatedPromotion, setSiteAutomatedPromotion] = useState({});
    const query = useQuery();

    const onError = (err) => {
        console.log(err);
        setLoading(false);
        setSite(props.siteData);
    };

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const fetchOutlets = () => {
        apiService
            .getOutletList(siteId, null, query.get("postalCode"))
            .then((res) => {
                if (
                    props.siteData.category !==
                    constants.SITE_CATEGORY.GEOGRAPHICAL
                ) {
                    if (res.length === 1) {
                        navigate(
                            `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.OUTLET}/${res[0].vendorId}`,
                            { state: { showBack: false }, replace: true },
                        );
                    }
                }
                setSite({ ...props.siteData, outlets: res });
                setLoading(false);
            })
            .catch(onError);
    };

    useEffect(() => {
        const authType = query.get("authType");
        authType ? props.setGuestSession("guest") : props.setGuestSession(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.get("authType")]);

    const fetchData = async () => {
        let zones = [];
        if (
            props.siteData &&
            props.siteData.category !== constants.SITE_CATEGORY.GEOGRAPHICAL
        ) {
            zones = props.siteData.siteZones || [];
        }

        if (zones && zones.length && zones.length === 1) {
            navigate(
                `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.ZONE}/${zones[0]._id}`,
                { state: { showBack: false }, replace: true },
            );
        } else if (zones && zones.length && zones.length > 1) {
            setSite({ ...props.siteData, zones: zones });
        } else if (
            props.siteData &&
            props.siteData.category !== constants.SITE_CATEGORY.SITE_DEPENDENT
        ) {
            fetchOutlets();
        } else {
            setSite({ ...props.siteData });
        }
        setSiteAutomatedPromotion(
            getAutomatedPromotion(props?.siteData?.assignedPromotions || []),
        );
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteId, props.siteData]);

    useEffect(() => {
        if (site && query.get("postalCode")) {
            setLoading(true);
            fetchOutlets();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.get("postalCode")]);

    if (!site) return <Loader />;

    const className = getBannerClassName(site.siteImage, site.mapImage);

    return (
        <div className="site">
            <MainHeader
                showInfoButton={true}
                showBackButton={query.has("postalCode")}
                name={query.has("postalCode") ? "Change Postalcode" : ""}
                setRedirectURL={setRedirectURL}
                openModal={openModal}
                showAccountButton={true}
            />
            <div className="box-container text-center">
                <div className="title">{site.displayName}</div>
            </div>
            {
                <div className={`banner ${className}`}>
                    {site.siteImage && (
                        <div className="banner-top">
                            <img
                                src={
                                    getImageUrl(site.siteImage) +
                                    IMAGE_SIZES.logo
                                }
                                onError={(e) => {
                                    e.target.src = getImageUrl(site.siteImage);
                                }}
                                alt="Logo not found"
                            />
                        </div>
                    )}
                    {site.mapImage && (
                        <div className="banner-main">
                            <img
                                src={
                                    getImageUrl(site.mapImage) +
                                    IMAGE_SIZES.banner
                                }
                                onError={(e) => {
                                    e.target.src = getImageUrl(site.mapImage);
                                }}
                                alt="Banner not found"
                            />
                        </div>
                    )}
                </div>
            }
            {site.description && site.description.trim().length > 0 ? (
                <div className="site-landing-description-inception">
                    <div className="site-landing-description-container">
                        {(site.description.split("\r\n") || []).map(
                            (desc, index) => {
                                return (
                                    <p
                                        className="box-container text-center site-landing-description"
                                        key={index}
                                    >
                                        {desc}
                                    </p>
                                );
                            },
                        )}
                    </div>
                </div>
            ) : null}
            {Boolean(localStorage.getItem("jwt_access_token")) ||
            props.authType === "guest" ? (
                site.category === constants.SITE_CATEGORY.GEOGRAPHICAL ? (
                    <div className="box-container zone-list-wrapper">
                        <GeographicalArea
                            isLoading={isLoading}
                            className={className}
                            site={site}
                            siteAutomatedPromo={siteAutomatedPromotion || {}}
                        />
                    </div>
                ) : (
                    <NonGeographicalArea
                        settings={site.settings || {}}
                        zones={site.zones || []}
                        outlets={site.outlets || []}
                        siteType={site.category}
                        className={className}
                        siteAutomatedPromo={siteAutomatedPromotion || {}}
                    />
                )
            ) : (
                <LandingAuth />
            )}
            <SiteOutletInfoModal
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                {...site}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        siteData: state.siteData,
        authType: state.authType,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setGuestSession: (authType) => {
            dispatch(setGuestSession(authType));
        },
        setRedirectURL: (url) => {
            dispatch(setRedirectURL(url));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetail);
