import { useState } from "react";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
    calculateRemainingQty,
    checkPrePaidDisable,
    getImageUrl,
} from "../../../common/utils";
import constants from "../../../constants/constants";
import { IMAGE_SIZES } from "../../../constants/image";
import CustomModal from "../../custom-modal";
import ItemModifierModal from "../../menu-items/CategoryCard/ItemModifierModal";
import ItemPlaceholderImage from "../../../assets/imgs/item-placeholder.png";
import "../cart.scss";

function MenuItemCard(props) {
    const {
        itemData,
        inBasket,
        outletData,
        basket,
        menuPayType,
        prepaidMaxProduct,
    } = props;
    const [enableModifiers, setEnableModifiers] = useState(false);
    const [diff, setDiff] = useState(0);
    const { serviceType, outletId } = useParams();

    const outletRef = itemData.outletRef ? itemData.outletRef : outletId;
    const currentBasket =
        (basket?.carts || []).find(
            (v) => String(v.vendorRef) === String(outletRef),
        ) || {};

    function openModal() {
        setEnableModifiers(true);
    }

    function closeModal() {
        setEnableModifiers(false);
    }

    const basketItemIndex = (currentBasket.items || []).findIndex(
        (i) => i.assignedMenuItemRef === itemData._id,
    );
    let modifiersItemsData = JSON.parse(JSON.stringify(itemData));
    let prepaidDisabled = false;
    if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
        const basketItems =
            basket?.carts && basket?.carts.length > 0
                ? basket?.carts[0].items
                : [];
        prepaidDisabled = checkPrePaidDisable(
            calculateRemainingQty(prepaidMaxProduct, basketItems) - (diff + 1),
            menuPayType,
        );
    }

    return (
        <>
            <div id={itemData._id}>
                <div
                    className="menu-item"
                    onClick={() => setEnableModifiers(true)}
                >
                    <div className="upselling-item-logo">
                        <img
                            src={
                                itemData.itemImage
                                    ? getImageUrl(itemData.itemImage) +
                                      IMAGE_SIZES.logo
                                    : ItemPlaceholderImage
                            }
                            onError={(e) => {
                                e.target.src = getImageUrl(itemData.itemImage);
                            }}
                            alt="Display not found"
                        />
                    </div>
                    <div className="item-name ellipsis">
                        {itemData.itemName}
                    </div>
                    {menuPayType !== constants.MENU_PAY_TYPE.PREPAID ? (
                        <div className="item-price">
                            {itemData.assignedModifiers.length ||
                            itemData.variants.length
                                ? "From "
                                : ""}
                            <FormattedNumber
                                style={`currency`}
                                currencyDisplay="narrowSymbol"
                                value={itemData.itemPrice}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                currency={outletData.currency || "gbp"}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <CustomModal
                isOpen={enableModifiers}
                onRequestClose={closeModal}
                hideDivider={true}
                title={itemData.itemName}
                modalContainerClass={"modifier-modal-class"}
            >
                {basketItemIndex >= 0 &&
                Boolean(inBasket + diff) &&
                itemData.assignedModifiers.length === 0 &&
                itemData.variants.length === 0 ? (
                    <ItemModifierModal
                        itemData={{
                            ...modifiersItemsData,
                            isUpsell: true,
                            quantity: inBasket,
                            ...(currentBasket.items || [])[basketItemIndex],
                            specialInstructions: (currentBasket.items || [])[
                                basketItemIndex
                            ].specialInstructions,
                        }}
                        closeModal={closeModal}
                        isEdit={true}
                        itemIndex={basketItemIndex}
                    />
                ) : (
                    <ItemModifierModal
                        enableModifiers={enableModifiers}
                        itemData={{ isUpsell: true, ...modifiersItemsData }}
                        closeModal={closeModal}
                    />
                )}
            </CustomModal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        basket: state.cart,
        outletData: state.outletData,
        siteData: state.siteData,
        menuPayType: state.menuPayType,
        prepaidMaxProduct: state.prepaidMaxProduct,
        quantityLoader: state.quantityLoader,
    };
};

export default connect(mapStateToProps, null)(MenuItemCard);
