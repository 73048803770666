import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Loader } from "../custom-loader";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CustomButton from "../custom-button";
import constants from "../../constants/constants";
import { getImageUrl } from "../../common/utils";
import "./loyalty.scss";
import { connect } from "react-redux";

const Loyalty = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    if (loading) return <Loader />;
    return (
        <div className="loyalty-container">
            <div className="loyalty-header">
                <AiOutlineArrowLeft
                    size={35}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
                <div className="header-title">
                    <span>Loyalty Points</span>
                </div>
            </div>
            <div className="box-container">
                <div className="loyalty-message">
                    If you place an online order or pay using your phone,
                    loyalty points are automatically added to you account.
                </div>
                <div className="loyalty-message">
                    If you placing an order served by a waiter or at the
                    counter, please show the below Qr code to collect points.
                </div>
                <div className="loyalty-qr-code-full-page">
                    <img
                        src={getImageUrl(props.user.qrCodeImage)}
                        alt="loyalty-qr-code"
                    />
                </div>
                {/* <div className="apple-wallet-container">
                <CustomButton
                className="loyalty-apple-wallet"
                >
                    Add to Apple Wallet
                </CustomButton></div> */}
                <div className="loyalty-message-light">
                    Click here below to check your current redeemable points.
                </div>
                <CustomButton
                    className="btn-block current-points-button"
                    onClick={() =>
                        navigate(`/${constants.ROUTES.LOYALTY_POINTS}`)
                    }
                >
                    My current points
                </CustomButton>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, null)(Loyalty);
