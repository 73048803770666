import CustomButton from "../custom-button";
import "../outlets/outlet.scss";
import "./serviceType.scss";
import { useEffect, useRef, useState } from "react";
import Actionsheet from "actionsheet-react";
import MenuSelection from "./MenuSelection";
import useQuery from "../../common/useQuery";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiService } from "../../services";
import {
    setOutletData,
    removeServiceData,
    setOrderType,
    emptyCart,
    setServiceData,
} from "../../store/actions/ProductActions";
import {
    setGuestSession,
    setUniqueDeviceId,
} from "../../store/actions/UserAction";
import { setRedirectURL } from "../../store/actions/UserAction";
import { connect } from "react-redux";
import { Loader } from "../custom-loader";
import { CustomInput } from "../custom-input";
import { FormattedNumber } from "react-intl";
import * as uuid from "uuid";
import LiveOrderMenuSelection from "./LiveOrderMenuSelection";
import CustomModal from "../custom-modal";
import PreOrderMenuSelection from "./PreOrderMenuSelection";
import constants from "../../constants/constants";
import LandingAuth from "../../components/landing-auth";
import MainBanner from "../main-banner";
import MainHeader from "../common/MainHeader";
import { CustomDropdown } from "../custom-dropdown";
import {
    generateLocationDropdown,
    generateOutletDetailUrl,
    toggleActionSheet,
} from "../../common/utils";

const actionSheetStyle = {
    borderRadius: 0,
    margin: "auto",
    left: "auto",
    maxWidth: "768px",
    minHeight: "500px",
};

async function onSearchPostCode(origin, destination) {
    if (!window.google) {
        return null;
    }
    const service = new window.google.maps.DistanceMatrixService();
    // build request
    const request = {
        origins: [origin],
        destinations: [destination],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
        // avoidHighways: false,
        // avoidTolls: false,
    };
    let result = null;
    const response = await service
        .getDistanceMatrix(request)
        .catch((err) => console.log("err"));
    const originList = response?.originAddresses || [];
    for (let i = 0; i < originList.length; i++) {
        if (
            response.rows[i].elements &&
            response.rows[i].elements.length &&
            response.rows[i].elements[0].status !== "NOT_FOUND"
        ) {
            result = response.rows[i].elements[0];
        }
    }
    return result;
}

const renderOnsiteServiceType = ({
    isDependent,
    isRegisterOpen,
    service,
    serviceType,
    setVendorZoneName,
    vendorZoneName,
    setTableNumber,
    tableNumber,
    locationLabel,
    locationNames,
    locationIdentifier,
    locationError,
    setLocationError,
}) => {
    if (
        ((isRegisterOpen && service.isLiveOrdersActive) ||
            service.isPreOrdersActive) &&
        serviceType === constants.SERVICE_TYPE.onsiteDelivery
    ) {
        if (
            service.locationInput === constants.LOCATION_INPUT.VENDOR &&
            isDependent
        ) {
            return (
                <>
                    {service.vendorZoneName ? (
                        <div className="row">
                            <h4 className="column-two flex-start m-0">
                                Location
                            </h4>
                            <div className="column-two flex-end m-0">
                                <CustomInput
                                    disabled
                                    onChange={(e) => null}
                                    name="vendorZoneName"
                                    value={service.vendorZoneName}
                                    className={"label-input"}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="row">
                        <h4 className="column-two flex-start m-0">
                            {locationIdentifier}
                        </h4>
                        <div className="column-two flex-end m-0">
                            <div className="custom-dropdown-wrapper">
                                <CustomDropdown
                                    options={locationNames.map((loc) => ({
                                        label: loc,
                                        value: loc,
                                    }))}
                                    onSelect={(value) => {
                                        setTableNumber(value);
                                        setLocationError(null);
                                    }}
                                    value={tableNumber}
                                    placeHolder={`Select ${
                                        locationIdentifier || "Location"
                                    }`}
                                    className="menu-select-box"
                                    label={locationIdentifier}
                                />
                                {locationError ? (
                                    <span className="text-danger error-msg">
                                        {locationError}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (service.locationInput === constants.LOCATION_INPUT.VENDOR) {
            return (
                <>
                    <div className="row">
                        <h4 className="column-two flex-start m-0">
                            {locationLabel?.trim() !== ""
                                ? locationLabel
                                : "Location"}
                        </h4>
                        <div className="column-two flex-end m-0">
                            <div className="custom-dropdown-wrapper">
                                <CustomDropdown
                                    options={locationNames.map((loc) => ({
                                        label: loc,
                                        value: loc,
                                    }))}
                                    onSelect={(value) => {
                                        setVendorZoneName(value);
                                        setLocationError(null);
                                    }}
                                    value={vendorZoneName}
                                    placeHolder={`Select ${
                                        locationLabel?.trim() !== ""
                                            ? locationLabel
                                            : "Location"
                                    }`}
                                    className="menu-select-box"
                                    label={"Location"}
                                />
                                {locationError ? (
                                    <span className="text-danger error-msg">
                                        {locationError}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <h4 className="column-two flex-start m-0">
                            {service.locationIdentifier}
                        </h4>
                        <div className="location-identifier-input flex-end m-0">
                            <CustomInput
                                onChange={(e) => setTableNumber(e)}
                                name="tableNumber"
                                value={tableNumber}
                                className={"label-input"}
                            />
                        </div>
                    </div>
                </>
            );
        }

        if (service.locationInput === constants.LOCATION_INPUT.CUSTOMER) {
            return (
                <div className="row">
                    <h4 className="column-two flex-start m-0">
                        {service.locationIdentifier}
                    </h4>
                    <div className="location-identifier-input flex-end m-0">
                        <CustomInput
                            onChange={(e) => setTableNumber(e)}
                            name="tableNumber"
                            value={tableNumber}
                            className={"label-input"}
                        />
                    </div>
                </div>
            );
        }
    }
    return null;
};

const Services = (props) => {
    const ref = useRef();
    const { state: pastParams } = useLocation();
    const { outletData, deviceId } = props;
    const { zoneId, siteId, outletId, serviceType } = useParams();
    const [postCode, setPostCode] = useState("");
    const [isVerified, setIsVerified] = useState(false);
    const [isServing, setServing] = useState(false);
    const outlet = props.outletData;
    const navigate = useNavigate();
    const [menuList, setMenuList] = useState([]);
    const [isLiveOrderLoading, setIsLiveOrderLoading] = useState(false);
    const [isPreOrderLoading, setIsPreOrderLoading] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState({});
    const [locationError, setLocationError] = useState(null); //dropdown
    const [tableNumber, setTableNumber] = useState(""); //dropdown
    const [locationNames, setLocationNames] = useState([]); //dropdown
    const [locationIdentifierSelect, setLocationIdentifierSelect] = useState(
        {},
    ); //dropdown
    const [service, setService] = useState(outlet.settings[serviceType] || {});
    const [minOrder, setMinOrder] = useState(0);
    const [liveModal, setLiveModal] = useState(false);
    const [preModal, setPreModal] = useState(false);
    const [deliveryFee, setOffsiteDeliveryFee] = useState(0);
    const [deliveryFeeCutOff, setDeliveryFeeCutOff] = useState(0);
    const [vendorZoneName, setVendorZoneName] = useState();
    const [counterMenuLoading, setCounterMenuLoading] = useState(false);
    const [locationLabel, setLocationLabel] = useState("");
    const query = useQuery();
    const redirectUrl = `${generateOutletDetailUrl({
        zoneId,
        siteId,
        outletId,
    })}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`;
    const isOnlyLiveOrderServiceType =
        serviceType === constants.SERVICE_TYPE.onsiteCollection ||
        serviceType === constants.SERVICE_TYPE.offsiteCollection ||
        serviceType === constants.SERVICE_TYPE.onsiteExpress;
    const isLoggedIn =
        Boolean(localStorage.getItem("jwt_access_token")) ||
        props.authType === "guest";
    useEffect(() => {
        if (!pastParams || !pastParams.showBack) {
            const authType = query.get("authType");
            authType
                ? props.setGuestSession("guest")
                : props.setGuestSession(null);
        }
    }, [query.get("authType")]);

    useEffect(() => {
        const serviceTypeData = outlet.settings[serviceType];
        setMinOrder(serviceTypeData?.absoluteMinOrder);
        setService({
            ...serviceTypeData,
            minOrder: serviceTypeData?.absoluteMinOrder,
        });
        setServing(serviceType !== constants.SERVICE_TYPE.offsiteDelivery);
        setIsVerified(false);

        if (
            serviceType === constants.SERVICE_TYPE.onsiteDelivery &&
            outlet.settings &&
            outlet.settings.onsiteDelivery &&
            outlet.settings.onsiteDelivery.locationInput ===
                constants.LOCATION_INPUT.VENDOR
        ) {
            if (
                service.locationIdentifierSelects &&
                outletData.storeType ===
                    constants.OUTLET_STORE_TYPE.SITE_DEPENDENT
            ) {
                const { locationName, locationDetail } =
                    generateLocationDropdown(
                        service.locationIdentifierSelects,
                        zoneId,
                        true,
                    );
                setLocationIdentifierSelect(locationDetail);
                setLocationNames(locationName);
                setVendorZoneName(service.vendorZoneName || "");
            } else {
                const locationName =
                    outlet.settings.onsiteDelivery?.locationName;
                const locationTitle =
                    outlet.settings.onsiteDelivery?.locationTitleCustom;
                // setVendorZoneName((locationName && locationName.length) ? locationName[0] : "");
                setVendorZoneName("");
                setLocationNames(locationName);
                setLocationLabel(locationTitle);
            }
        } else if (
            !isLiveOrderLoading &&
            outlet.isRegisterOpen &&
            serviceTypeData &&
            serviceTypeData.isLiveOrdersActive &&
            !serviceTypeData.isPreOrdersActive &&
            isOnlyLiveOrderServiceType
        ) {
            getLiveOrderMenus(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outlet?._id, serviceType]);

    useEffect(() => {
        if (serviceType === constants.SERVICE_TYPE.offsiteDelivery) {
            setServing(false);
            setIsVerified(false);
        }
    }, [postCode, serviceType]);

    useEffect(() => {
        if (
            serviceType === constants.SERVICE_TYPE.onsiteCounter ||
            serviceType === constants.SERVICE_TYPE.onsiteOffCounter
        ) {
            getCounterMenus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceType, outletId]);

    const addServiceParams = () => {
        if (serviceType === constants.SERVICE_TYPE.offsiteDelivery) {
            setService({ ...service, postCode: postCode, deliveryFeeCutOff });
        } else if (serviceType === constants.SERVICE_TYPE.onsiteDelivery) {
            setService({ ...service, tableNumber, vendorZoneName });
        }
    };

    const validateLocationIdentifier = () => {
        if (
            serviceType === constants.SERVICE_TYPE.onsiteDelivery &&
            service?.locationInput === constants.LOCATION_INPUT.VENDOR &&
            outlet.storeType === constants.OUTLET_STORE_TYPE.SITE_DEPENDENT &&
            (tableNumber || "").trim() === ""
        ) {
            setLocationError("This field is required");
            return false;
        } else if (
            serviceType === constants.SERVICE_TYPE.onsiteDelivery &&
            service?.locationInput === constants.LOCATION_INPUT.VENDOR &&
            (vendorZoneName || "").trim() === ""
        ) {
            setLocationError(
                `Please select ${
                    outlet.settings.onsiteDelivery?.locationTitleCustom ||
                    "location"
                }`,
            );
            return false;
        }
        setLocationError(null);
        return true;
    };

    const getLiveOrderMenus = (isShowModal = true) => {
        if (!validateLocationIdentifier()) {
            return;
        }
        addServiceParams();
        setIsLiveOrderLoading(true);
        setLiveModal(isShowModal);
        props.setOrderType(false);
        apiService
            .getOrderNowMenuDetails(outletId, serviceType, outlet.timezone)
            .then((res) => {
                setMenuList(res);
                setIsLiveOrderLoading(false);
            })
            .catch(() => {
                setMenuList([]);
                setIsLiveOrderLoading(false);
            });
    };

    const getPreOrderMenus = () => {
        if (!validateLocationIdentifier()) {
            return;
        }
        addServiceParams();
        setIsPreOrderLoading(true);
        setPreModal(true);
        props.setOrderType(true);

        apiService
            .getPreOrderMenuDetails(outletId, serviceType)
            .then((res) => {
                setMenuList(res);
                setIsPreOrderLoading(false);
                if (res && res.length === 1) {
                    setPreModal(false);
                    setSelectedMenu(res[0]);
                    toggleActionSheet(ref, true);
                }
            })
            .catch(() => {
                setIsPreOrderLoading(false);
                setMenuList([]);
            });
    };

    const getCounterMenus = () => {
        setCounterMenuLoading(true);
        apiService
            .getOrderNowMenuDetails(outletId, serviceType, outlet.timezone)
            .then((res) => {
                setCounterMenuLoading(false);
                setMenuList(res);
            })
            .catch(() => {
                setCounterMenuLoading(false);
                setMenuList([]);
            });
    };

    const handleCounterNavigate = (selectedMenu) => {
        props.setServiceData({
            serviceType: serviceType,
            serviceTypeDisplayName: service.displayName,
            preOrder: false,
            minOrder: minOrder,
            menuRef: selectedMenu,
        });
        // props.emptyCart();
        navigate(`${redirectUrl}/menus/${selectedMenu._id}`);
    };

    const handlePostcodeCheck = async () => {
        setServing(false);
        setIsVerified(false);
        if (
            outlet.settings &&
            outlet.settings.offsiteDelivery &&
            outlet.settings.offsiteDelivery.blackListedPostalCodes &&
            !outlet.settings.offsiteDelivery.blackListedPostalCodes.includes(
                postCode,
            ) &&
            outlet.postalCode
        ) {
            const response = await onSearchPostCode(
                outlet.postalCode,
                postCode,
            );
            //'SE1 2SS' //'EC4Y 9BE'
            if (response && response.distance) {
                const miles = response.distance.value / 1609;
                if (
                    miles <= outlet.settings.offsiteDelivery.maxDeliveryRadius
                ) {
                    const deliverySetup =
                        props.outletData.settings.offsiteDelivery
                            ?.deliverySetup;
                    if (!deliverySetup.length) {
                        // setOffsiteDeliveryFee(Math.min(defaultDeliveryFee, defaultDeliveryFeeCutOff));
                        setServing(true);
                    } else {
                        deliverySetup.sort((a, b) => a.miles - b.miles);
                        for (let setup of deliverySetup) {
                            if (setup && miles <= setup.miles) {
                                setMinOrder(setup.minOrder);
                                // NOTE: removed due to NOQ-2731
                                // if (setup.deliveryFeeCutOff && setup.deliveryFee > setup.deliveryFeeCutOff) {
                                // setOffsiteDeliveryFee(setup.deliveryFeeCutOff);
                                // } else {
                                setDeliveryFeeCutOff(
                                    setup.deliveryFeeCutOff || 0,
                                );
                                setOffsiteDeliveryFee(setup.deliveryFee || 0);
                                // }
                                setServing(true);
                                break;
                            }
                        }
                    }
                }
            }
            setIsVerified(true);
        } else {
            setIsVerified(true);
        }
    };

    if (serviceType === "onsiteExpress") {
        if (!deviceId) {
            const newdeviceId = uuid.v4();
            if (newdeviceId) {
                // console.log("newdeviceId", newdeviceId);
                props.setUniqueDeviceId(newdeviceId);
            }
        } else {
            // API call for deviceId if needed in future for scalability
        }
    }

    if (
        !props.outletData ||
        counterMenuLoading ||
        isLiveOrderLoading ||
        !service
    )
        return <Loader />;

    const postalCodeTrimmed = postCode ? postCode.replace(/\s/g, "") : "";
    if (
        outlet.isRegisterOpen &&
        service.isLiveOrdersActive &&
        !service.isPreOrdersActive &&
        isOnlyLiveOrderServiceType
    ) {
        return (
            <div className="outlet">
                <MainHeader
                    showAccountButton={true}
                    name={service.displayName}
                    onBackClick={() => props.removeServiceData(null)}
                />
                {isLoggedIn ? (
                    <LiveOrderMenuSelection
                        liveModal={true}
                        isLiveOrderLoading={isLiveOrderLoading}
                        menuList={menuList}
                        service={{
                            ...service,
                            tableNumber,
                            vendorZoneName,
                            deliveryFeeCutOff,
                        }}
                        minOrder={minOrder}
                        deliveryFee={deliveryFee}
                        vendorZoneName={vendorZoneName}
                        postCode={postCode}
                        hasReplace={true}
                    />
                ) : (
                    <LandingAuth />
                )}
            </div>
        );
    }
    return (
        <div className="outlet">
            <MainHeader
                showAccountButton={true}
                name={service.displayName}
                onBackClick={() => props.removeServiceData(null)}
            />
            <MainBanner
                logoImage={outlet.outletImage}
                bannerImage={outlet.mapImage}
                title={outlet.displayName}
                description={outlet.description}
            />
            {isLoggedIn ? (
                <div className="box-container outlet-list-wrapper">
                    <div className="precaution-information">
                        {((outlet.isRegisterOpen &&
                            service.isLiveOrdersActive) ||
                            service.isPreOrdersActive) &&
                            serviceType ===
                                constants.SERVICE_TYPE.offsiteDelivery && (
                                <>
                                    <div className="postcode-message">
                                        Please leave a clear space of one
                                        character between the two parts of the
                                        postcode
                                    </div>
                                    <div className="row">
                                        <div className="post-code-container">
                                            <CustomInput
                                                onChange={(e) => setPostCode(e)}
                                                name="postcode"
                                                value={postCode}
                                                placeHolder="Postcode"
                                                className="label-input"
                                            />
                                            <CustomButton
                                                className={"postcode-btn"}
                                                type="button"
                                                onClick={() =>
                                                    handlePostcodeCheck()
                                                }
                                                disabled={
                                                    !postCode ||
                                                    postalCodeTrimmed.length <
                                                        5 ||
                                                    postalCodeTrimmed.length > 7
                                                }
                                            >
                                                Check
                                            </CustomButton>
                                        </div>
                                    </div>
                                    {postCode.replace(/\s/g, "").length !== 0 &&
                                        (postalCodeTrimmed.length < 5 ||
                                            postalCodeTrimmed.length > 7) && (
                                            <p style={{ color: "red" }}>
                                                5 to 7 digits allowed in
                                                postcode
                                            </p>
                                        )}
                                    {isServing ? (
                                        <div className="delivery-message">
                                            <span className="delivery-message-highlight">
                                                We can deliver your order!
                                            </span>
                                            <span className="delivery-message-subheading">
                                                {" "}
                                                Minimum order{" "}
                                                <FormattedNumber
                                                    style={`currency`}
                                                    currencyDisplay="narrowSymbol"
                                                    value={minOrder}
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                    currency={
                                                        outletData.currency ||
                                                        "gbp"
                                                    }
                                                />
                                                , Delivery fee{" "}
                                                <FormattedNumber
                                                    style={`currency`}
                                                    currencyDisplay="narrowSymbol"
                                                    value={deliveryFee}
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                    currency={
                                                        outletData.currency ||
                                                        "gbp"
                                                    }
                                                />
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="delivery-message">
                                            <span className="delivery-message-highlight">
                                                {isVerified
                                                    ? "Apologies. We do not deliver to the selected postcode."
                                                    : ""}
                                            </span>
                                        </div>
                                    )}
                                </>
                            )}
                        {((outlet.isRegisterOpen &&
                            service.isLiveOrdersActive) ||
                            service.isPreOrdersActive) &&
                            serviceType !==
                                constants.SERVICE_TYPE.offsiteDelivery &&
                            Boolean(service.absoluteMinOrder) && (
                                <div className="row">
                                    <h4 className="column-two flex-start ml-0">
                                        Minimum Order
                                    </h4>
                                    <h4 className="column-two flex-end">
                                        <FormattedNumber
                                            style={`currency`}
                                            currencyDisplay="narrowSymbol"
                                            value={service.absoluteMinOrder}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            currency={
                                                outletData.currency || "gbp"
                                            }
                                        />
                                    </h4>
                                </div>
                            )}
                        {renderOnsiteServiceType({
                            isDependent:
                                outlet.storeType ===
                                constants.OUTLET_STORE_TYPE.SITE_DEPENDENT,
                            service,
                            serviceType,
                            isRegisterOpen: outlet.isRegisterOpen,
                            locationIdentifier:
                                locationIdentifierSelect?.locationIdentifier,
                            locationLabel: locationLabel,
                            locationNames,
                            tableNumber,
                            vendorZoneName,
                            setVendorZoneName,
                            setTableNumber,
                            locationError,
                            setLocationError,
                        })}
                    </div>
                    {!(
                        serviceType === constants.SERVICE_TYPE.onsiteCounter ||
                        serviceType === constants.SERVICE_TYPE.onsiteOffCounter
                    ) ? (
                        isServing && (
                            <>
                                {outlet.isRegisterOpen &&
                                    service.isLiveOrdersActive && (
                                        <CustomButton
                                            className={`btn-block outlet-detail`}
                                            onClick={getLiveOrderMenus}
                                        >
                                            {" "}
                                            Order Now{" "}
                                        </CustomButton>
                                    )}
                                {outlet.isRegisterOpen &&
                                    service.isLiveOrdersActive &&
                                    service.isPreOrdersActive && (
                                        <div className="service-type-separator">
                                            <div className="line"></div>
                                            <h4>Or</h4>
                                            <div className="line"></div>
                                        </div>
                                    )}
                                {service.isPreOrdersActive && (
                                    <CustomButton
                                        className="btn-block outlet-detail"
                                        onClick={getPreOrderMenus}
                                    >
                                        Select Timeslot{" "}
                                        <small>(Pre Order)</small>
                                    </CustomButton>
                                )}
                                {!(
                                    (outlet.isRegisterOpen &&
                                        service.isLiveOrdersActive) ||
                                    service.isPreOrdersActive
                                ) && <h3 className="">Not Accepting Orders</h3>}
                            </>
                        )
                    ) : menuList.length ? (
                        <>
                            {menuList.map((menu, index) => (
                                <CustomButton
                                    className="btn-block menu-button-detail"
                                    onClick={() =>
                                        handleCounterNavigate(menu.menu.menuRef)
                                    }
                                    key={index}
                                >
                                    {menu.menu.menuRef.displayName}
                                </CustomButton>
                            ))}
                        </>
                    ) : (
                        <h3 className="">Not Accepting Orders</h3>
                    )}
                </div>
            ) : (
                <LandingAuth />
            )}
            <CustomModal
                // showHeader={!menuList || menuList?.length > 1}
                isOpen={liveModal}
                onRequestClose={() => setLiveModal(false)}
                title={"Live Order"}
            >
                <LiveOrderMenuSelection
                    liveModal={liveModal}
                    isLiveOrderLoading={isLiveOrderLoading}
                    menuList={menuList}
                    service={{
                        ...service,
                        tableNumber,
                        vendorZoneName,
                        deliveryFeeCutOff:
                            service.deliveryFeeCutOff || deliveryFeeCutOff,
                    }}
                    minOrder={minOrder}
                    deliveryFee={deliveryFee}
                    vendorZoneName={vendorZoneName}
                    postCode={postCode}
                    setLiveModal={setLiveModal}
                />
            </CustomModal>
            <CustomModal
                isOpen={preModal}
                onRequestClose={() => setPreModal(false)}
                title={"Pre Order"}
            >
                <PreOrderMenuSelection
                    preModal={preModal}
                    isPreOrderLoading={isPreOrderLoading}
                    selectedMenu={selectedMenu}
                    setSelectedMenu={setSelectedMenu}
                    menuList={menuList}
                    onRequestClose={() => setPreModal(false)}
                    service={{
                        ...service,
                        deliveryFeeCutOff:
                            service.deliveryFeeCutOff || deliveryFeeCutOff,
                    }}
                    minOrder={minOrder}
                    deliveryFee={deliveryFee}
                    vendorZoneName={vendorZoneName}
                />
            </CustomModal>
            <Actionsheet
                ref={ref}
                sheetStyle={actionSheetStyle}
                closeOnBgTap={false}
                touchEnable={false}
            >
                <MenuSelection
                    onRequestClose={() => toggleActionSheet(ref)}
                    selectedMenu={selectedMenu}
                    service={{
                        ...service,
                        deliveryFeeCutOff:
                            service.deliveryFeeCutOff || deliveryFeeCutOff,
                    }}
                    minOrder={minOrder}
                    deliveryFee={deliveryFee}
                    vendorZoneName={vendorZoneName}
                />
            </Actionsheet>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        outletData: state.outletData,
        authType: state.authType,
        deviceId: state.deviceId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOutletData: (outlet) => {
            dispatch(setOutletData(outlet));
        },
        removeServiceData: (service) => {
            dispatch(removeServiceData(service));
        },
        setOrderType: (orderType) => {
            dispatch(setOrderType(orderType));
        },
        setServiceData: (service) => {
            dispatch(setServiceData(service));
        },
        setGuestSession: (authType) => {
            dispatch(setGuestSession(authType));
        },
        setRedirectURL: (url) => dispatch(setRedirectURL(url)),
        emptyCart: () => dispatch(emptyCart()),
        setUniqueDeviceId: (deviceId) => dispatch(setUniqueDeviceId(deviceId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
