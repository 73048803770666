import { useNavigate, useLocation } from "react-router";
import CustomButton from "../custom-button";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import NoqLogo from "../../assets/imgs/logo.png";
import "../logout/logout.scss";

const WelcomePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const redirectURL = location.state?.redirectURL;
    return (
        <div className="logout-container">
            <div className="logout-header">
                <div className="title-with-icon">
                    <CgProfile size={35} />
                    <h2>Welcome !</h2>
                </div>
                <div>
                    <span onClick={() => navigate(-1)}>
                        <AiOutlineCloseCircle size={35} />
                    </span>
                </div>
            </div>
            <div className="mt-100">
                <CustomButton
                    type="button"
                    className="btn-block btn-bw"
                    onClick={() =>
                        navigate("/login", {
                            state: { redirectURL },
                            replace: true,
                        })
                    }
                >
                    Sign In
                </CustomButton>
            </div>
            <div className="footer">
                <div className="greyed-text">Powered by</div>
                <div>
                    <img src={NoqLogo} alt="noq-logo" />
                </div>
            </div>
        </div>
    );
};

export default WelcomePage;
