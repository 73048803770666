import {
    createSearchParams,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import "./checkout.scss";
import { AiOutlineArrowLeft, AiOutlineClose } from "react-icons/ai";
import Actionsheet from "actionsheet-react";

import CustomButton from "../custom-button";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import CustomCheckbox from "../custom-checkbox";
import { Formik, Form } from "formik";
import * as yup from "yup";
import PaymentGatewayMobile from "../payment-gateway/PaymentGatewayMobile";
import {
    notify,
    generateLocationDropdown,
    toggleActionSheet,
    createRequestBody,
    basketToStockRequest,
    getPromotion,
    createOrderRequestBody,
    createUserOrder,
    round2digit,
    checkPromotionCode,
    findAutomatedPromotionToApply,
    AUTOMATED_PROMO_PROMOTION,
    DEFAULT_TIMEZONE,
    MEMBERSHIP_PROMO_PROMOTION,
    getPromotionsToApply,
    getAdyenMerchantID,
} from "../../common/utils";
import {
    emptyCart,
    injectTipToCart,
    setServiceData,
    updateCart,
} from "../../store/actions/ProductActions";
import { setUniqueMetricId } from "../../store/actions/UserAction";
import {
    checkLoyaltyAvailable,
    checkStockAlert,
    getPromotionByPromoCode,
} from "../../services/api.service";
import constants from "../../constants/constants";
import { LoaderWithChildren } from "../custom-loader";
import { apiService } from "../../services";
import StockAlertModal from "./StockAlertModal";
import UnAvailableItemErrorModal from "./UnAvailableItemErrorModal";
import { ServiceTypeInfo } from "./ServiceTypeInfo";
import { YourDetail } from "./YourDetails";
import { OrderSummary } from "./OrderSummary";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import apiConsts from "../../constants/apiConstants";
import { Loader } from "../custom-loader";
import CustomModal from "../custom-modal";
import ReactGA from "react-ga4";
import { PromoCodeInput } from "./PromoCodeInput";
import { CustomDropdown } from "../custom-dropdown";
import PromoRemoveModal from "../promo-remove-modal";

const actionSheetStyle = {
    borderRadius: 0,
    margin: "auto",
    left: "auto",
    maxWidth: "768px",
};

const defaultValidationSchema = {
    note: yup
        .string()
        .max(50, "Note cannot exceed 50 characters.")
        .label("Note"),
    name: yup.string().trim().required().label("Name"),
    email: yup.string().trim().email().required().label("Email"),
    mobileNumber: yup
        .string()
        .label("Mobile Number")
        .matches(constants.REGEX.MOBILE_NUMBER, "Mobile number is not valid"),
};

const getValidationSchema = (serviceType, outletData, vendorZoneName) => {
    if (serviceType === constants.SERVICE_TYPE.offsiteDelivery) {
        return yup.object({
            ...defaultValidationSchema,
            postalcode: yup.string().trim().required().label("Postalcode"),
            // deliveryAddress: yup.string().trim().required().label("Delivery Address"),
            houseNumber: yup
                .string()
                .trim()
                .required()
                .label("Flat/Hourse Number"),
            street: yup.string().trim().required().label("Street"),
        });
    } else if (serviceType === constants.SERVICE_TYPE.onsiteDelivery) {
        let obj;
        if (
            outletData.settings &&
            outletData.settings.onsiteDelivery &&
            outletData.settings.onsiteDelivery.isInputTypeNumber
        ) {
            obj = {
                tableNumber: yup
                    .number("Table Number must be number")
                    .required()
                    .label(
                        outletData?.settings?.onsiteDelivery
                            ?.locationIdentifier &&
                            outletData?.settings?.onsiteDelivery
                                ?.locationIdentifier !== "" &&
                            outletData?.settings?.onsiteDelivery
                                ?.locationIdentifier !== "-"
                            ? outletData?.settings?.onsiteDelivery
                                  ?.locationIdentifier
                            : "Table Number",
                    ),
            };
        } else {
            obj = {
                tableNumber: yup
                    .string()
                    .trim()
                    .required()
                    .label(
                        outletData?.settings?.onsiteDelivery
                            ?.locationIdentifier &&
                            outletData?.settings?.onsiteDelivery
                                ?.locationIdentifier !== "" &&
                            outletData?.settings?.onsiteDelivery
                                ?.locationIdentifier !== "-"
                            ? outletData?.settings?.onsiteDelivery
                                  ?.locationIdentifier
                            : "Table Number",
                    ),
            };
        }
        if (
            outletData.settings &&
            outletData.settings.onsiteDelivery &&
            outletData.settings.onsiteDelivery.locationInput ===
                constants.LOCATION_INPUT.VENDOR
        ) {
            if (
                outletData.storeType !==
                    constants.OUTLET_STORE_TYPE.SITE_DEPENDENT &&
                vendorZoneName
            ) {
                obj["vendorZoneName"] = yup
                    .string()
                    .trim()
                    .required()
                    .label("Select Location");
            }
            if (
                !outletData.settings.onsiteDelivery.isLocationIdentifierRequired
            ) {
                if (outletData.settings.onsiteDelivery.isInputTypeNumber)
                    obj["tableNumber"] = yup.number().label("Table Number");
                else
                    obj["tableNumber"] = yup
                        .string()
                        .trim()
                        .notRequired()
                        .label("Table Number");
            }
        }
        return yup.object({
            ...defaultValidationSchema,
            ...obj,
        });
    }
    return yup.object(defaultValidationSchema);
};

const formatOutletAddress = (outletData) => {
    let str = "";
    if (outletData?.address) {
        str += outletData?.address;
    }
    if (outletData?.address2) {
        str += `, ${outletData?.address2}`;
    }
    if (outletData?.city) {
        str += `, ${outletData?.city}`;
    }
    return str;
};

const deleteOrderByCartId = async (cartId) => {
    if (!cartId) return;
    apiService
        .deleteAdyenOrder(cartId)
        .then((res) => {})
        .catch((err) => {
            console.log("err", err);
        })
        .finally(() => {
            return;
        });
    return;
};

const AdyenCheckoutUI = (props) => {
    const paymentContainer = useRef(null);
    const navigate = useNavigate();
    const [session, setSession] = useState();
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState(null);
    const [store, setStore] = useState(null);

    const {
        outletRef,
        basket,
        serviceData,
        removeCart,
        type,
        formValues,
        placeAdyenOrder,
        order,
        setOrder,
        outletData,
        siteData,
        setShowPayment,
        showPayment,
        setErrorUI,
        setAdyenSessionId,
        setAllowBack,
        setPromoRemoveModalOpen,
        setPromotionErrors,
    } = props;

    const cart = createRequestBody({ ...basket }, outletRef, serviceData);
    useEffect(() => {
        if (formValues.email && formValues.name) {
            setUserDetails({
                shopperName: formValues?.name,
                shopperEmail: formValues?.email,
                telephoneNumber: formValues?.mobileNumber,
                deliveryAddress:
                    (formValues?.houseNumber || "") +
                    (formValues?.street || "") +
                    (formValues?.postalcode || ""),
            });
        }
    }, [formValues]);

    useEffect(() => {
        if (showPayment) {
            const userDetails = {
                shopperName: formValues?.name,
                shopperEmail: formValues?.email,
                telephoneNumber: formValues?.mobileNumber,
                deliveryAddress:
                    (formValues?.houseNumber || "") +
                    (formValues?.street || "") +
                    (formValues?.postalcode || ""),
            };
            apiService
                .createSession(
                    outletRef,
                    { ...cart, _id: basket._id },
                    "https://" + window.location.host + "/redirect-adyen",
                    userDetails,
                )
                .then(async (res) => {
                    setAdyenSessionId(res.session.id);
                    const orderReqBody = createOrderRequestBody(
                        cart,
                        serviceData,
                        formValues,
                    );
                    const payload = {
                        basket: { ...orderReqBody.basket },
                        sessionId: res.session.id,
                        ...orderReqBody,
                    };
                    let newOrder = await placeAdyenOrder(payload);
                    setOrder(newOrder);
                    setStore(res?.store);
                    setSession(res.session);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log("err in Adyen Checkout", err);
                    if (err.message === "INVALID_PROMOTION") {
                        setShowPayment(false);
                        setPromoRemoveModalOpen(true);
                        setPromotionErrors(err.errors[0].errors);
                        return;
                    }
                    setErrorUI(false);
                    setLoading(false);
                    notify(
                        err && err.message
                            ? err.message
                            : "Unable to create session",
                    );
                });
        }
    }, [showPayment]);

    useEffect(() => {
        let ignore = false;
        setAllowBack(true);

        if (!session || !paymentContainer.current) {
            return;
        }

        const createCheckout = async () => {
            const checkout = await AdyenCheckout({
                shopperInteraction: "Ecommerce",
                storePaymentMethod: false,
                paymentMethodsConfiguration: {
                    ideal: {
                        showImage: true,
                    },
                    card: {
                        hasHolderName: true,
                        holderNameRequired: true,
                        name: "Credit or debit card",
                        amount: {
                            value: Math.round(order.totalAmount * 100),
                            currency: (order?.currency || "GBP").toUpperCase(),
                        },
                    },
                    googlepay: {
                        configuration: {
                            merchantName: order.isMultiOrder
                                ? siteData?.displayName || " "
                                : outletData?.displayName || " ",
                            merchantId: store?.googlePayMerchantId,
                            gatewayMerchantId: getAdyenMerchantID(
                                outletData.currency,
                            ),
                        },
                    },
                    applepay: {
                        configuration: {
                            merchantName: order.isMultiOrder
                                ? siteData?.displayName || " "
                                : outletData?.displayName || " ",
                            merchantId: store?.applePayMerchantId,
                        },
                    },
                },
                locale: "en_US",
                showPayButton: true,
                clientKey: apiConsts.adyenClientKey,
                environment: process.env.REACT_APP_ADYEN_ENVIORNMENT,
                instantPaymentTypes: ["googlepay", "applepay"],
                // },
                session: {
                    id: session.id, // Unique identifier for the payment session.
                    sessionData: session.sessionData, // The payment session data.
                },
                beforeSubmit: async (state, element, actions) => {
                    setAllowBack(false);
                    await actions.resolve(state);
                    return;
                },
                onPaymentCompleted: async (response, _component) => {
                    if (
                        response.resultCode &&
                        response.resultCode === "Authorised"
                    ) {
                        apiService
                            .completeAdyenOrder(order.cartId, session.id, true)
                            .then(() => {
                                // new API
                                if (
                                    order &&
                                    order.cartId &&
                                    order.isMultiOrder === true
                                ) {
                                    removeCart();
                                    ReactGA.event("conversion_rate");
                                    navigate(`/multi/orders/${order.cartId}`, {
                                        replace: true,
                                    });
                                } else if (order && order.orderId) {
                                    removeCart();
                                    ReactGA.event("conversion_rate");
                                    navigate(`/orders/${order.orderId}`, {
                                        replace: true,
                                    });
                                }
                            })
                            .catch(() => {
                                if (
                                    order &&
                                    order.cartId &&
                                    order.isMultiOrder === true
                                ) {
                                    removeCart();
                                    ReactGA.event("conversion_rate");
                                    navigate(`/multi/orders/${order.cartId}`, {
                                        replace: true,
                                    });
                                } else if (order && order.orderId) {
                                    removeCart();
                                    ReactGA.event("conversion_rate");
                                    navigate(`/orders/${order.orderId}`, {
                                        replace: true,
                                    });
                                }
                            });
                    } else {
                        setErrorUI(false);
                        apiService
                            .completeAdyenOrder(order.cartId, session.id, false)
                            .catch((err) =>
                                console.error(
                                    `API failed for changing status to PAYMENT FAILED due to ${err?.message}`,
                                ),
                            );
                        notify(`Payment failed! please try with another card.`);
                        setShowPayment(false);
                    }
                },
                onError: (error, _component) => {
                    console.error(error);
                    setErrorUI(false);
                    apiService
                        .completeAdyenOrder(order.cartId, session.id, false)
                        .catch((err) =>
                            console.error(
                                `API failed for changing status to PAYMENT FAILED due to ${err?.message}`,
                            ),
                        );
                    notify(
                        error?.message
                            ? error?.message
                            : "Something went wrong!",
                    );
                    setShowPayment(false);
                },
            });

            if (paymentContainer.current && !ignore) {
                checkout.create(type).mount(paymentContainer.current);
            }
        };

        createCheckout();

        return () => {
            ignore = true;
        };
    }, [session]);

    if (loading)
        return (
            <div className="payment-loader-overlay">
                <Loader />
            </div>
        );

    return (
        <div className="payment-container">
            <div ref={paymentContainer}></div>
        </div>
    );
};

const Checkout = (props) => {
    const navigate = useNavigate();
    const {
        basket,
        serviceData,
        outletData,
        cartLoading,
        user,
        siteData,
        metricId,
        deviceId,
    } = props;
    const formRef = useRef();
    const [isChecked, setIsChecked] = useState(false);
    const { siteId, zoneId: paramZoneId, serviceType, outletId } = useParams();
    const zoneId = paramZoneId || serviceData?.zoneId;
    const service = outletData.settings[serviceType];
    const ref = useRef();
    const [checkoutDetails, setCheckoutDetails] = useState(null);
    const [stockError, setStockError] = useState(null);
    const [loyalty, setLoyalty] = useState(null);
    const [loyaltyUsed, setLoyaltyUsed] = useState(0);
    const [loading, setLoading] = useState(false);
    const [unavailableItems, setUnavailableItems] = useState([]);
    const [showErrors, setShowErrors] = useState(false);
    const [errorUI, setErrorUI] = useState(true);
    const [showPayment, setShowPayment] = useState(false);
    const [order, setOrder] = useState(null);
    const [locationNames, setLocationNames] = useState([]); //dropdown
    const [locationIdentifierSelect, setLocationIdentifierSelect] = useState(
        {},
    );
    const [vendorZoneName, setVendorZoneName] = useState(
        serviceData?.vendorZoneName || service?.vendorZoneName || "",
    );
    const [promotion, setPromotion] = useState({});
    const [membershipPromotion, setMembershipPromotion] = useState({});
    const [storeEstPrepTime, setStoreEstPrepTime] = useState({});
    const [tableNo, setTableNo] = useState(
        parseInt(serviceData?.tableNumber) === 0
            ? serviceData?.tableNumber
            : serviceData?.tableNumber || "",
    );
    const [automatedPromotion, setAutomatedPromotion] = useState({});
    const confirmLocationRef = useRef(null);
    const [itemBasedPromos, setItemBasedPromos] = useState([]);
    const [allowBack, setAllowBack] = useState(true);
    const [promoRemoveModalOpen, setPromoRemoveModalOpen] = useState(false);
    const [isItemBasedPromoApplied, setIsItemBasedPromoApplied] = useState(false);
    const [promotionErrors, setPromotionErrors] = useState([]);
    const [searchState, setSearchState] = useSearchParams(
        createSearchParams({
            postalcode: serviceData?.postCode || service?.postCode || "",
            specialInstructions: "",
            houseNumber: "",
            street: "",
            name: user
                ? user.firstName + " " + (user.lastName ? user.lastName : "")
                : "",
            email: user ? user.email : "",
            mobileNumber: user && user.contactNo ? user.contactNo : "",
            note: "",
            tableNumber:
                parseInt(serviceData?.tableNumber) === 0
                    ? serviceData?.tableNumber
                    : serviceData?.tableNumber || "",
            vendorZoneName: vendorZoneName || "",
        }),
    );
    const [adyenSessionId, setAdyenSessionId] = useState("");

    useEffect(() => {
        checkStockAlert({ cart: basketToStockRequest(basket) }).catch((res) =>
            setStockError(res.errors.errors),
        );
        if (user && user._id) {
            checkLoyaltyAvailable(siteId, outletId, basket.isMultiOrder)
                .then((resp) => setLoyalty(resp))
                .catch(() => setLoyalty(null));
        }

        if (
            serviceType === constants.SERVICE_TYPE.onsiteDelivery &&
            service?.locationInput === constants.LOCATION_INPUT.VENDOR
        ) {
            if (
                service.locationIdentifierSelects &&
                outletData.storeType ===
                    constants.OUTLET_STORE_TYPE.SITE_DEPENDENT
            ) {
                const { locationName, locationDetail } =
                    generateLocationDropdown(
                        service.locationIdentifierSelects,
                        zoneId,
                        true,
                    );
                setLocationIdentifierSelect(locationDetail);
                setLocationNames(locationName);
            } else {
                const locationName = service?.locationName;
                setLocationNames(locationName);
            }
            setVendorZoneName(
                serviceData?.vendorZoneName || service?.vendorZoneName || "",
            );
        } else if (
            service?.locationInput === constants.LOCATION_INPUT.CUSTOMER &&
            outletData.storeType === constants.OUTLET_STORE_TYPE.SITE_DEPENDENT
        ) {
            const { locationDetail } = generateLocationDropdown(
                service.locationIdentifierCustomerInput,
                zoneId,
                true,
            );
            setLocationIdentifierSelect(locationDetail);
        }
        getPromotionsToApply(basket, user, siteData, outletData)
            .then((promotions) => {
                if (promotions) {
                    let structuredPromos = [];
                    if (
                        promotions?.itemBasedPromotion &&
                        promotions?.itemBasedPromotion?.length
                    ) {
                        structuredPromos = (
                            promotions?.itemBasedPromotion || []
                        ).map((promo) => {
                            return {
                                promotionRef: promo._id,
                                name: promo.name,
                                promotionType: promo.promotionType,
                                savingType: promo.savingType,
                                value: promo.amountSaved,
                            };
                        });
                        if (structuredPromos?.length) {
                            setItemBasedPromos(structuredPromos);
                            props.updateCart({
                                itemBasedPromotion: structuredPromos,
                            });
                        }
                    }

                    // check if items are left after itemBasedPromotion;
                    let itemWithOutPromo = false;

                    if (structuredPromos.length) {
                        outerLoop: for (const cart of basket.carts) {
                            for (const item of cart.items) {
                                const hasPromo = structuredPromos.some(
                                    (p) =>
                                        String(p.promotionRef) ===
                                        String(
                                            item?.assignedItemBasePromocode
                                                ?.promocodeRef,
                                        ),
                                );

                                if (!hasPromo) {
                                    itemWithOutPromo = true;
                                    break outerLoop;
                                }
                            }
                        }
                    } else {
                        itemWithOutPromo = true;
                    }

                    if (
                        itemWithOutPromo &&
                        promotions?.membershipPromotion &&
                        Object.keys(promotions?.membershipPromotion).length
                    ) {
                        setMembershipPromotion(promotions.membershipPromotion);
                        props.updateCart({
                            promotion: promotions.membershipPromotion,
                            itemBasedPromotion: structuredPromos,
                        });
                    } else {
                        props.updateCart({
                            itemBasedPromotion: structuredPromos,
                        });
                    }

                    if (itemWithOutPromo && promotions?.automatedPromotion) {
                        setAutomatedPromotion(promotions?.automatedPromotion);
                    }

                    if(basket.isMultiOrder) {
                        setIsItemBasedPromoApplied(basket.carts.filter(d => d?.itemBasedPromotion?.length).length > 0);
                    }
                } else {
                    props.updateCart();
                }
            })
            .catch((err) => {
                props.updateCart();
            });

        document.body.style.position = "fixed";
        return () => (document.body.style.position = null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let newArray = [];
        if (basket && basket.carts && basket.carts.length) {
            if (serviceData.preOrder) {
                for (let i = 0; i < basket.carts.length; i++) {
                    if (basket.carts && basket.carts.items) {
                        for (let j = 0; j < basket.carts[i].items.length; j++) {
                            if (
                                serviceData.menuRef.menu.menuRef._id !==
                                basket.carts[i].items[j].menu.menuRef
                            ) {
                                newArray.push(
                                    basket.carts[i].items[j].itemName,
                                );
                            }
                        }
                    }
                }
                setUnavailableItems(newArray);
                if (newArray.length) {
                    setShowErrors(true);
                }
            } else {
                // getStoresEstPrepTime();
                apiService
                    .getOrderNowMenuDetails(
                        outletId,
                        serviceType,
                        outletData.timezone,
                        (basket.carts || []).map((v) => v.vendorRef),
                        basket.isMultiOrder,
                    )
                    .then((res) => {
                        const activeMenu = res.map(
                            (menu) => menu.menu.menuRef._id,
                        );
                        for (let i = 0; i < basket.carts.length; i++) {
                            for (
                                let j = 0;
                                j < basket.carts[i].items.length;
                                j++
                            ) {
                                if (
                                    !activeMenu.includes(
                                        basket.carts[i].items[j].menu.menuRef,
                                    )
                                ) {
                                    newArray.push(
                                        basket.carts[i].items[j].itemName,
                                    );
                                }
                            }
                        }
                        setUnavailableItems(newArray);
                        if (newArray.length) {
                            setShowErrors(true);
                        }
                    })
                    .catch(() => {
                        navigate(-1);
                        notify("Sorry, selected items are not available!");
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getStoresEstPrepTime = () => {
        setLoading(true);
        apiService
            .getStoresEstPrepTime((basket.carts || []).map((v) => v.vendorRef))
            .then((res) => {
                let estPrepTimeByVendorRef = {};
                if (res && res.length) {
                    res.forEach((record) => {
                        estPrepTimeByVendorRef[record._id] =
                            record.estimatedPreparationTime;
                    });
                }
                setStoreEstPrepTime(estPrepTimeByVendorRef);
            })
            .catch((err) => {
                notify(err.message);
            })
            .finally(() => setLoading(false));
    };

    const handleLoyaltyChange = () => {
        let u = 0;
        let subTotal = basket.subTotal;
        if (basket?.promotion?.amount > 0) {
            subTotal -= basket.promotion.amount;
        }
        if (basket?.itemBasedPromotion.length) {
            basket?.itemBasedPromotion.forEach((promo) => {
                subTotal -= promo.amount;
            });
        }

        if (subTotal > loyalty.availablePoints * loyalty.redeemValue) {
            u = loyalty.availablePoints * loyalty.redeemValue;
        } else if (subTotal > 0) {
            u = parseInt(subTotal / loyalty.redeemValue) * loyalty.redeemValue;
        }
        if (loyaltyUsed) {
            setLoyaltyUsed(0);
            props.updateCart({
                loyaltyUsed: 0,
                loyalty: null,
                promotion: basket.promotion,
                itemBasedPromotion: basket?.itemBasedPromotion || [],
            });
        } else {
            setLoyaltyUsed(u);
            props.updateCart({
                loyaltyUsed: u,
                loyalty,
                promotion: basket.promotion,
                itemBasedPromotion: basket?.itemBasedPromotion || [],
            });
        }
    };

    const handlePromotionChange = (promoType, removePromotion) => {
        const promotionToApply =
            promoType === MEMBERSHIP_PROMO_PROMOTION
                ? membershipPromotion
                : promotion;
        if (removePromotion) {
            props.updateCart({
                loyalty: basket.loyalty,
                loyaltyUsed: basket.loyaltyUsed,
                itemBasedPromotion: basket?.itemBasedPromotion || [],
            });
            return;
        }
        props.updateCart({
            promotion: promotionToApply,
            loyalty: basket.loyalty,
            loyaltyUsed: basket.loyaltyUsed,
            itemBasedPromotion: basket.itemBasedPromotion,
        });
    };

    const handleAutomatedPromotionChange = async (promoId, removePromotion) => {
        try {
            if (removePromotion) {
                props.updateCart({
                    loyalty: basket.loyalty,
                    loyaltyUsed: basket.loyaltyUsed,
                    itemBasedPromotion: basket.itemBasedPromotion,
                });
                return;
            }

            if (
                automatedPromotion &&
                automatedPromotion.usageType &&
                ["singlePerUser", "multiplePerUser"].includes(
                    automatedPromotion.usageType,
                )
            ) {
                if (!formRef?.current?.values?.email) {
                    notify("Email is required to use this promotion");
                    return;
                }
            }

            let outletIds = [];
            basket.carts.map((cart) => outletIds.push(cart.vendorRef));

            const parentVendorRef =
                siteData && siteData.vendorRef
                    ? siteData.vendorRef
                    : outletData.parentVendorRef || outletData._id;

            const payloadData = {
                parentVendorRef,
                outletIds: JSON.stringify(outletIds),
                siteId,
                cartId: basket?._id,
                timezone:
                    siteData && siteData.timezone
                        ? siteData.timezone
                        : outletData?.timezone || DEFAULT_TIMEZONE,
                email: formRef?.current?.values?.email || "",
            };

            await apiService
                .getPromotionByPromoCode(promoId, payloadData)
                .then((promotion) => {
                    if (promotion && Object.keys(promotion).length) {
                        const promotionPayload = {
                            promotionRef: promotion?._id,
                            name: promotion?.name,
                            promotionType: promotion?.promotionType,
                            savingType: promotion?.savingType,
                            value: promotion?.amountSaved,
                            quantity: promotion?.quantity,
                        };
                        props.updateCart({
                            promotion: promotionPayload,
                            loyalty: basket?.loyalty,
                            loyaltyUsed: basket?.loyaltyUsed,
                            itemBasedPromotion:
                                basket?.itemBasedPromotion || [],
                        });
                    }
                });
        } catch (err) {
            notify(err?.message || "Something went wrong!");
        }
    };

    const handlePromoCodeInput = async (promoCode) => {
        setLoading(true);

        try {
            let outletIds = [];
            basket.carts.map((cart) => outletIds.push(cart.vendorRef));

            const parentVendorRef =
                outletData.parentVendorRef || outletData._id;

            const payloadData = {
                parentVendorRef,
                outletIds: JSON.stringify(outletIds),
                siteId,
            };

            const promotion = await apiService.getPromotionByPromoCode(
                promoCode,
                payloadData,
            );
            if (!promotion || !Object.keys(promotion).length) {
                setPromotion({});
                setLoading(false);
                return;
            }

            const validatedPromotion = await checkPromotionCode(
                basket,
                promotion,
                promoCode,
            );
            if (
                !validatedPromotion ||
                !Object.keys(validatedPromotion).length
            ) {
                setPromotion({});
                setLoading(false);
                return;
            }

            if (validatedPromotion && Object.keys(validatedPromotion).length) {
                setPromotion(validatedPromotion);
                props.updateCart({
                    promotion: validatedPromotion,
                    loyalty: basket.loyalty,
                    loyaltyUsed: basket.loyaltyUsed,
                    itemBasedPromotion: basket?.itemBasedPromotion || [],
                });
            }

            setLoading(false);
            return;
        } catch (error) {
            setPromotion({});
            console.log(
                "LOG: > file: index.js:473 > handlePromoCodeInput > err:",
                error,
            );
            setLoading(false);
            notify(error.message);
        }
    };

    const handleChangeField = (props, name, value) => {
        props.setFieldTouched(name, value);
        props.setFieldValue(name, value);
    };

    const handleProceedToPay = (values) => {
        props.updateCart({
            loyaltyUsed,
            loyalty,
            promotion: basket.promotion,
            itemBasedPromotion: basket?.itemBasedPromotion || [],
            locationIdentifierLabel: serviceData.locationTitleCustom,
        });
        props.setServiceData({
            ...serviceData,
            tableNumber: values.tableNumber,
            locationIdentifierLabel: serviceData.locationTitleCustom,
        });
        setCheckoutDetails(values);
        if (
            props.outletData?.paymentGateway === "adyen" &&
            getTotalAmount() > 0
        ) {
            setShowPayment(true);
        } else if (getTotalAmount() > 0) {
            toggleActionSheet(ref, true);
        } else if (values && getTotalAmount() === 0) {
            handleFreeCheckout(values);
        }
    };

    const handleSubmit = (values) => {
        setSearchState(new URLSearchParams(values), {
            replace: true,
        });
        if (
            serviceType === constants.SERVICE_TYPE.onsiteDelivery &&
            ((service?.locationInput === "Vendor" &&
                service?.locationConfirmation) ||
                (service?.locationInput === "Customer" &&
                    service.locationConfirmationCustomer))
        ) {
            toggleActionSheet(confirmLocationRef, true);
        } else {
            handleProceedToPay(values);
        }
    };

    const tableNumberValidation = () => {
        if (service.locationInput === "Vendor") {
            if (
                service.isLocationIdentifierRequired &&
                (!tableNo ||
                    (typeof tableNo === String && tableNo?.trim() === ""))
            ) {
                return true;
            } else {
                return false;
            }
        } else if (
            !tableNo ||
            (typeof tableNo === String && tableNo?.trim() === "")
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handleFreeCheckout = async (checkoutDetails) => {
        setLoading(true);
        const reqBody = createRequestBody(
            basket,
            outletId,
            serviceData,
            checkoutDetails,
        );
        const userDetails = {
            shopperEmail: checkoutDetails.email,
        };

        apiService
            .createFreeOrder({ ...reqBody, userDetails })
            .then((orderDetails) => {
                props.removeCart();
                // custom event for conversion NOTE: event_name is conversion_rate
                ReactGA.event("conversion_rate");
                if (
                    orderDetails &&
                    orderDetails.data.isMultiOrder === true &&
                    orderDetails.data.cartRef
                ) {
                    navigate(`/multi/orders/${orderDetails.data.cartRef}`, {
                        replace: true,
                    });
                    return;
                }
                navigate(`/orders/${orderDetails.data._id}`, { replace: true });
                return;
            })
            .catch((err) => {
                if (err.message === "INVALID_PROMOTION") {
                    setPromoRemoveModalOpen(true);
                    setPromotionErrors(err.errors);
                    return;
                }

                setLoading(false);
                notify(err.message);
            });
    };

    const getTotalAmount = () => {
        return basket.totalAmount;
        // return round2digit(basket.subTotal - loyaltyUsed + getServiceFee(basket.subTotal - loyaltyUsed, outlet, serviceType) + basket.tipAmount + getDeliveryFee(basket.subTotal, outlet, serviceType, serviceData.deliveryFee) + getPlatformFee(basket.subTotal, outlet));
    };

    const placeAdyenOrder = async (payload) => {
        const orderDetails = await createUserOrder({
            ...payload,
            metricId,
            deviceId,
        });
        if (orderDetails.status === 200) {
            // props.removeCart();
            props.setUniqueMetricId(null);
            // handleLoader(false)
            return {
                isMultiOrder: orderDetails.data.isMultiOrder,
                orderId: orderDetails.data._id,
                totalAmount: orderDetails.data.totalAmount,
                cartId: orderDetails.data.cartRef,
                currency: orderDetails.data.currency,
            };
        } else {
            // handleLoader(false)
            notify(orderDetails.message);
            return null;
        }
    };
    const getAllSearchParams = () => {
        const params = {};
        for (const [key, value] of searchState.entries()) {
            params[key] = value;
        }
        return params;
    };

    return (
        <div className="checkout-page">
            <PromoRemoveModal
                errors={promotionErrors}
                isOpen={promoRemoveModalOpen}
            />
            <div className="box-container custom-header-wrapper header">
                <div className="header-left">
                    <AiOutlineArrowLeft
                        size={35}
                        onClick={() => navigate(-1)}
                    />
                    <div className="header-title">
                        <div className="header-ellipsis">
                            {outletData?.displayName} - Checkout
                        </div>
                        <small className="secondary-text-color header-address header-sub-title">
                            {formatOutletAddress(outletData)}
                        </small>
                    </div>
                </div>
            </div>
            <Formik
                initialValues={getAllSearchParams()}
                validateOnMount
                innerRef={formRef}
                enableReinitialize={true}
                validationSchema={getValidationSchema(
                    serviceType,
                    outletData,
                    vendorZoneName,
                )}
                onSubmit={handleSubmit}
            >
                {(props) => (
                    <Form noValidate>
                        <div className="separator separator-theme"></div>
                        <div className="cart-order-list">
                            <div className="box-container order-container">
                                <div className="order-summary-section">
                                    <ServiceTypeInfo
                                        locationNames={locationNames}
                                        service={service}
                                        locationIdentifierSelect={
                                            locationIdentifierSelect
                                        }
                                        props={props}
                                        siteData={siteData}
                                        outletData={outletData}
                                        serviceType={serviceType}
                                        serviceData={serviceData}
                                        handleChangeField={handleChangeField}
                                        setAdditionVerificationTableNum={
                                            setTableNo
                                        }
                                    />
                                    <YourDetail
                                        props={props}
                                        handleChangeField={handleChangeField}
                                    />

                                    {
                                        !isItemBasedPromoApplied && (
                                            <PromoCodeInput
                                            promotion={
                                                basket.promotionType !==
                                                AUTOMATED_PROMO_PROMOTION
                                                    ? basket.promotion
                                                    : ""
                                            }
                                            handlePromoCodeInput={
                                                handlePromoCodeInput
                                            }
                                        />
                                        )
                                    }

                                    <div className="separator separator-theme mt-5"></div>
                                    <div className="row justify-space-between">
                                        <h4>Order Summary</h4>
                                        {/* {!basket.isMultiOrder && outletId && storeEstPrepTime[outletId] ? <h4>Est. Prep. Time {constants.ESTIMATED_PREP_TIME_MAPPING[storeEstPrepTime[outletId]]}</h4> : null} */}
                                    </div>
                                    <OrderSummary
                                        basket={basket}
                                        serviceType={serviceType}
                                        currency={outletData?.currency}
                                        loyalty={loyalty}
                                        loyaltyUsed={basket.loyaltyUsed}
                                        handleLoyaltyChange={
                                            handleLoyaltyChange
                                        }
                                        handlePromotionChange={
                                            handlePromotionChange
                                        }
                                        promotion={promotion}
                                        storesEstimatedPrepTime={
                                            storeEstPrepTime
                                        }
                                        handleAutomatedPromoChange={
                                            handleAutomatedPromotionChange
                                        }
                                        automatedPromotion={automatedPromotion}
                                        membershipPromotion={
                                            membershipPromotion
                                        }
                                        itemBasedPromotion={itemBasedPromos}
                                    />
                                    <div className="separator separator-theme"></div>
                                    <div className="checkout-total-section">
                                        <div className="form-group">
                                            <CustomCheckbox
                                                containerClass={
                                                    "accept-subscription"
                                                }
                                                label={""}
                                                isChecked={isChecked}
                                                handleClick={() =>
                                                    setIsChecked(!isChecked)
                                                }
                                            />
                                            <label htmlFor="accept-subscription">
                                                <small>
                                                    I do not want to receive
                                                </small>{" "}
                                                <small className="secondary-text-color">
                                                    updates about promotions,
                                                    discounts and special offers
                                                    from{" "}
                                                    {outletData.displayName}
                                                </small>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sticky-footer checkout-wrapper-footer">
                            <div className="separator separator-theme"></div>
                            <div className="box-container footer">
                                <CustomButton
                                    className="btn-block"
                                    disabled={!props.isValid || loading}
                                    type="submit"
                                    loader={loading}
                                >
                                    {" "}
                                    {getTotalAmount() > 0
                                        ? "Proceed to Pay"
                                        : "Place Order"}
                                </CustomButton>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {props.outletData?.paymentGateway === "adyen" ? (
                <CustomModal
                    isOpen={showPayment}
                    onRequestClose={() => {
                        if (allowBack) {
                            setShowPayment(false);
                            if (order?.cartId && adyenSessionId) {
                                apiService
                                    .completeAdyenOrder(
                                        order.cartId,
                                        adyenSessionId,
                                        false,
                                    )
                                    .catch((err) =>
                                        console.error(
                                            `API failed for changing status to PAYMENT FAILED due to ${err?.message}`,
                                        ),
                                    );
                            }
                        }
                    }}
                    showClose={allowBack}
                    title="Select payment method"
                    hideDivider={false}
                >
                    <div id="payment-page">
                        <div className="container">
                            <AdyenCheckoutUI
                                type="dropin"
                                basket={props.basket}
                                serviceData={props.serviceData}
                                outletRef={outletId}
                                removeCart={props.removeCart}
                                formValues={formRef?.current?.values}
                                placeAdyenOrder={placeAdyenOrder}
                                order={order}
                                setOrder={setOrder}
                                siteData={siteData}
                                outletData={outletData}
                                setShowPayment={setShowPayment}
                                showPayment={showPayment}
                                setErrorUI={setErrorUI}
                                errorUI={errorUI}
                                setAllowBack={setAllowBack}
                                allowBack={allowBack}
                                setAdyenSessionId={setAdyenSessionId}
                                setPromoRemoveModalOpen={
                                    setPromoRemoveModalOpen
                                }
                                setPromotionErrors={setPromotionErrors}
                            />
                        </div>
                        {errorUI ? (
                            <p className="warning box-container">
                                While payment is processing, please do not
                                press/close and do not put this app into
                                background. <br /> It may cause payment failure
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                </CustomModal>
            ) : (
                <Actionsheet
                    ref={ref}
                    sheetStyle={actionSheetStyle}
                    closeOnBgTap={false}
                >
                    <div className="payment-section-wrapper">
                        <div className="action-header" id="header-payment">
                            <h3 className="mt-0">Select Payment Type</h3>
                            <AiOutlineClose
                                onClick={() => toggleActionSheet(ref)}
                                size={25}
                            />
                        </div>
                        <div className="payment-section">
                            <PaymentGatewayMobile
                                totalAmount={getTotalAmount()}
                                formValues={{
                                    ...checkoutDetails,
                                    newsLetter: isChecked,
                                    email: formRef?.current?.values?.email,
                                }}
                            />
                        </div>
                    </div>
                </Actionsheet>
            )}
            <UnAvailableItemErrorModal
                items={unavailableItems}
                setShowErrors={setShowErrors}
                showErrors={showErrors}
            />
            <StockAlertModal
                items={basketToStockRequest(basket).items}
                setStockError={setStockError}
                stockError={stockError}
            />
            {cartLoading && (
                <div className="loader-overlay">
                    <LoaderWithChildren />
                </div>
            )}
            {serviceType === constants.SERVICE_TYPE.onsiteDelivery &&
                ((service?.locationInput === "Vendor" &&
                    service?.locationConfirmation) ||
                    (service?.locationInput === "Customer" &&
                        service?.locationConfirmationCustomer)) && (
                    <Actionsheet
                        ref={confirmLocationRef}
                        sheetStyle={actionSheetStyle}
                        closeOnBgTap={false}
                    >
                        <div className="location-confirmation-wrapper">
                            <div
                                className="action-header"
                                id="header-confirmation"
                            >
                                <h3 className="mt-0">Confirm Your Location</h3>
                                <AiOutlineClose
                                    onClick={() =>
                                        toggleActionSheet(confirmLocationRef)
                                    }
                                    size={25}
                                />
                            </div>
                            <div className="confirmation-section">
                                <div className="confirm-box-info">
                                    Could we kindly request you to confirm your
                                    table number? This will help ensure your
                                    order is promptly and accurately delivered
                                    to you.
                                </div>
                                <div className="form-section row">
                                    <div className="column-two label-location">
                                        <label>
                                            {outletData?.storeType ===
                                                constants.OUTLET_STORE_TYPE
                                                    .SITE_DEPENDENT &&
                                            siteData?.category ===
                                                constants.SITE_CATEGORY
                                                    .SITE_DEPENDENT
                                                ? locationIdentifierSelect?.locationIdentifier ||
                                                  service?.locationIdentifier
                                                : service.locationIdentifier
                                                ? service.locationIdentifier
                                                : "Table Number"}
                                            {service?.locationInput ===
                                                constants.LOCATION_INPUT
                                                    .VENDOR &&
                                            !service?.isLocationIdentifierRequired
                                                ? ""
                                                : "*"}
                                        </label>
                                    </div>
                                    <div className="column-two">
                                        <div className="checkout-input-group">
                                            {outletData?.storeType ===
                                                constants.OUTLET_STORE_TYPE
                                                    .SITE_DEPENDENT &&
                                            siteData?.category ===
                                                constants.SITE_CATEGORY
                                                    .SITE_DEPENDENT &&
                                            service?.locationInput ===
                                                "Vendor" ? (
                                                <CustomDropdown
                                                    options={locationNames.map(
                                                        (loc) => ({
                                                            label: loc,
                                                            value: loc,
                                                        }),
                                                    )}
                                                    onSelect={(value) => {
                                                        setTableNo(value);
                                                        formRef.current.setFieldValue(
                                                            "tableNumber",
                                                            value,
                                                        );
                                                    }}
                                                    value={tableNo}
                                                    dropDownContainerClass="table-menu-container"
                                                    dropDownHeaderClass="table-menu-header"
                                                    dropDownListContainerClass="table-menu-list-container menu-position-top"
                                                    dropDownListClass="table-menu-list"
                                                    dropDownItemClass="table-menu-item"
                                                />
                                            ) : (
                                                <input
                                                    type={
                                                        serviceData?.isInputTypeNumber
                                                            ? "number"
                                                            : "text"
                                                    }
                                                    onChange={(event) => {
                                                        setTableNo(
                                                            event.target.value,
                                                        );
                                                        formRef.current.setFieldValue(
                                                            "tableNumber",
                                                            event.target.value,
                                                        );
                                                    }}
                                                    className="checkout-input"
                                                    name="tableNumber"
                                                    value={tableNo}
                                                />
                                            )}
                                            {tableNumberValidation() ? (
                                                <div className="danger">
                                                    {outletData?.storeType ===
                                                        constants
                                                            .OUTLET_STORE_TYPE
                                                            .SITE_DEPENDENT &&
                                                    siteData?.category ===
                                                        constants.SITE_CATEGORY
                                                            .SITE_DEPENDENT
                                                        ? locationIdentifierSelect?.locationIdentifier ||
                                                          service?.locationIdentifier
                                                        : service.locationIdentifier
                                                        ? service.locationIdentifier
                                                        : "Table Number"}{" "}
                                                    is required field
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <CustomButton
                                    className="btn-block confirm-location-btn"
                                    disabled={tableNumberValidation()}
                                    onClick={() => {
                                        handleProceedToPay(
                                            formRef.current.values,
                                        );
                                        toggleActionSheet(
                                            confirmLocationRef,
                                            false,
                                        );
                                    }}
                                >
                                    Continue
                                </CustomButton>
                                {/* </form> */}
                            </div>
                        </div>
                    </Actionsheet>
                )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        outletData: state.outletData,
        serviceData: state.serviceData,
        siteData: state.siteData,
        basket: state.cart,
        cartLoading: state.cartLoading,
        user: state.user,
        metricId: state.metricId,
        deviceId: state.deviceId,
    };
};

const mapDispatchToPros = (dispatch) => {
    return {
        setServiceData: (serviceData) => {
            dispatch(setServiceData(serviceData));
        },
        updateCart: (body) => {
            dispatch(updateCart(body));
        },
        injectTipToCart: (tip) => dispatch(injectTipToCart(tip)),
        removeCart: () => dispatch(emptyCart()),
        setUniqueMetricId: (value) => dispatch(setUniqueMetricId(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToPros)(Checkout);
