import CustomButton from "../custom-button";
import CustomModal from "../custom-modal";

export default function PromoRemoveModal({ isOpen, errors }) {
    const refreshPage = () => {
        window.location.reload();
    };
    return (
        errors.length > 0 && (
            <CustomModal
                modalContainerClass={"checkout-modal-container"}
                isOpen={isOpen}
                showHeader={false}
                hideDivider={true}
            >
                <p className="promo-error-title">{errors[0].name}</p>
                <div className="box-container">
                    <p className="text-gray-600 text-sm">{errors[0].message}</p>
                    <div className="error-pop-up-button">
                        <CustomButton
                            className="btn-block btn-small"
                            onClick={refreshPage}
                        >
                            <span>OK</span>
                        </CustomButton>
                    </div>
                </div>
            </CustomModal>
        )
    );
}
