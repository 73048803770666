import { ErrorMsg } from "../constants/ErrorConstant";
import { toast } from "react-toastify";
import moment from "moment";
import "moment-timezone";
import { useEffect, useRef } from "react";
import constants from "../constants/constants";
import { apiService } from "../services";
import apiConsts from "../constants/apiConstants";

export const DEFAULT_TIMEZONE = "Europe/London";
export const weekday = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
];
/**
 * This is util functions
 */
export const transformValidationErrors = (err) => {
    let errors = {};
    (err.errors || []).forEach((error) => {
        errors = {
            ...errors,
            [error.param]: ErrorMsg[error.msg]
                ? ErrorMsg[error.msg]
                : error.msg,
        };
    });

    return errors;
};

export const getFullName = (key, object) => {
    if (typeof object[key] === "object" && object[key]) {
        const value = object[key];
        return `${value.firstName || ""} ${value.lastName || ""}`;
    }
    return "";
};

export function notify(error) {
    return toast.error(error, { position: "top-center" });
}
export function notifySuccess(msg) {
    return toast.success(msg, { position: "top-center" });
}

export const getImageUrl = (image, isBackground = false) => {
    if (image) return `${process.env.REACT_APP_IMAGE_URL}/${image}`;
    // if (isBackground) return defaultImage;
};

export const checkTimeBetween = (from, to) => {
    const start = new Date(from);
    const end = new Date(to);
    const current = new Date();
    const startSeconds =
        start.getHours() * 3600 + start.getMinutes() * 60 + start.getSeconds();
    const endSeconds =
        end.getHours() * 3600 + end.getMinutes() * 60 + end.getSeconds();
    const currentSeconds =
        current.getHours() * 3600 +
        current.getMinutes() * 60 +
        current.getSeconds();
    if (startSeconds < currentSeconds && currentSeconds < endSeconds) {
        return true;
    } else {
        return false;
    }
};

export function getInBasketCount(basket) {
    var countObject = {};
    for (var i = 0; basket.carts && i < basket.carts.length; i++) {
        for (
            var j = 0;
            basket.carts[i].items && j < basket.carts[i].items.length;
            j++
        ) {
            if (countObject[basket.carts[i].items[j].assignedRef]) {
                countObject[basket.carts[i].items[j].assignedRef] +=
                    basket.carts[i].items[j].quantity;
            } else {
                countObject[basket.carts[i].items[j].assignedRef] =
                    basket.carts[i].items[j].quantity;
            }
        }
    }
    return countObject;
}

function rearrangeModifier(modifier) {
    let tempMod = {
        ...modifier.modifierGroup,
        ...modifier,
    };
    delete tempMod.modifierGroup;
    return tempMod;
}

export function restructureMenu(menu,outletId) {
    menu.forEach((category) => {
        category.items.sort((itemA, itemB) => {
            return itemA.displayOrder - itemB.displayOrder;
        });
    });
    return menu.map((cat) => ({
        ...cat,
        items: cat.items.map((item) => {
            let tempItem = {
                ...item.item,
                ...item,
                outletRef : outletId,
                price: item.itemPrice,
                assignedModifiers:
                    item.assignedModifiers && item.assignedModifiers.length
                        ? item.assignedModifiers.map((modifier) =>
                              rearrangeModifier(modifier),
                          )
                        : [],
                variants: item.item.variants ? item.item.variants : [],
                subTotal: item.itemPrice,
                category: {
                    menuCategoryRef: item.menuCategoryRef,
                    categoryName: cat.categoryName,
                },
                itemName: item.itemDisplayName,
                menu: { ...item.menuRefData },
                menuGroup: {
                    menuGroupRef: cat.menuGroupRef,
                },
                printerRef:
                    item.printer && item.printer.length
                        ? item.printer[0].printerRef
                        : null,
                assignedPrinters:
                    item.printer && item.printer.length
                        ? item.printer[0].assignedPrinters || []
                        : [],
                kdsRef: item.kds && item.kds.length ? item.kds[0].kdsRef : null,
                kdsGroupRef:
                    item.kds && item.kds.length
                        ? item.kds[0].kdsGroupRef
                        : null,
            };
            delete tempItem.item;
            delete tempItem.menuRefData;
            return tempItem;
        }),
    }));
}

export const createArray = (arr, boolArr) => {
    let res = [];
    for (let i = 0; i < boolArr.length; i++) {
        if (boolArr[i]) {
            res.push(arr[i]);
        }
    }
    return res;
};

export const basketToRequest = (basket, serviceData, extraData = {}) => {
    let userRef = null;
    try {
        const user = JSON.parse(localStorage.getItem("user")) || {};
        userRef = user._id || null;
    } catch (err) {
        console.log("User not found!");
    }
    let newCart = {
        ...basket,
        ...serviceData,
        // deliveryFee: basket.deliveryFee || serviceData.deliveryFee || 0,
        deliveryFee: basket.deliveryFee || 0,
        deliveryDate: basket.deliveryDate || new Date(),
        currency: basket.currency || "gbp",
        type: "WEB",
        userRef,
    };
    if (extraData.loyalty && extraData.loyaltyUsed) {
        newCart.loyaltyRef = extraData.loyalty.loyaltyRef;
        newCart.redeemPoint = parseInt(
            extraData.loyaltyUsed / extraData.loyalty.redeemValue,
        );
    }
    newCart.items = (newCart.items || []).map((oldItem) => {
        let item = {
            ...oldItem,
            itemImage: oldItem.imageUrl,
            price: oldItem.itemPrice,
            itemType: "item",
        };

        for (let i = 0; i < item.variants.length; i++) {
            if (item.variants[i].isSelected) {
                item.variant = {
                    ...item.variants[i],
                    price: item.variants[i].variantSellingPrice,
                    sku: item.variants[i].variantSKU,
                    name: item.variants[i].variantName,
                    grossProfit: item.variants[i].variantGrossProfit,
                    markupMargin: item.variants[i].variantMarkupMargin,
                };
                break;
            }
        }
        delete item.variants;
        delete item.assignedModifiers;
        delete item.imageUrl;
        return item;
    });
    delete newCart.menuRef;
    return newCart;
};

export const basketToStockRequest = (basket) => {
    let newCart = {
        items: [],
    };
    if (!basket || !basket.carts || basket.carts.length === 0) {
        return newCart;
    }
    const items = [];
    for (let cart of basket.carts) {
        for (let oldItem of cart.items) {
            let item = {
                ...oldItem,
                itemImage: oldItem.imageUrl,
                price: oldItem.itemPrice,
                itemType: "item",
            };

            for (let i = 0; i < item.variants.length; i++) {
                if (item.variants[i].isSelected) {
                    item.variant = {
                        ...item.variants[i],
                        price: item.variants[i].variantSellingPrice,
                        name: item.variants[i].variantName,
                    };
                    break;
                }
            }
            delete item.variants;
            delete item.assignedModifiers;
            delete item.imageUrl;
            items.push(item);
        }
    }
    newCart.items = items;
    return newCart;
};

export const getTip = (amount, outlet, serviceType) => {
    if (!outlet || !outlet.settings[serviceType]) return 0;
    const service = outlet.settings[serviceType];
    if (service.preSetTipType === "Percentage") {
        return (service.preSetTipAmount * amount) / 100;
    }
    return service.preSetTipAmount || 0;
};

export const getServiceFee = (amount, outlet, serviceType) => {
    if (!outlet || !outlet.settings[serviceType]) return 0;
    const service = outlet.settings[serviceType];
    let serviceFee = 0;
    if (service.serviceChargeCutOff && amount >= service.serviceChargeCutOff) {
        return +serviceFee.toFixed(2); //NOTE: applied changes based on #NOQ-2940
    }
    if (service.serviceChargeType === "Percentage") {
        serviceFee = (service?.serviceChargeAmount * amount) / 100;
    } else if (service.serviceChargeType === "Flat") {
        serviceFee = service?.serviceChargeAmount;
    }
    //NOTE: applied changes based on #NOQ-2940
    // if (service.serviceChargeCutOff && serviceFee > service.serviceChargeCutOff) {
    //   return +service.serviceChargeCutOff.toFixed(2);
    // }
    return +serviceFee.toFixed(2);
};

export const getDeliveryFee = (
    amount,
    outlet,
    serviceType,
    offSiteDelivery = null,
) => {
    if (serviceType !== "onsiteDelivery" && serviceType !== "offsiteDelivery")
        return 0;
    if (!outlet || !outlet.settings[serviceType]) return 0;
    const service = outlet.settings[serviceType];
    if (service.deliveryFeeCutOff && amount >= service.deliveryFeeCutOff) {
        return 0.0;
    }
    if (serviceType === "offsiteDelivery") {
        return offSiteDelivery;
    }
    let deliveryFee = 0;
    if (service.deliveryFeeType === "Percentage") {
        deliveryFee = (service?.deliveryFeeAmount * amount) / 100;
    } else if (service.deliveryFeeType === "Flat") {
        deliveryFee = service?.deliveryFeeAmount;
    }
    // if (service.deliveryFeeCutOff && deliveryFee > service.deliveryFeeCutOff) {
    //   return +service.deliveryFeeCutOff.toFixed(2);
    // } NOTE: NOQ-2731
    return +deliveryFee.toFixed(2);
};

// export const getServicePercentage = (amount, outlet, serviceType) => {
//   if (!outlet || !outlet.settings[serviceType]) return 0;
//   const service = outlet.settings[serviceType];
//   let serviceFee = 0;
//   if (service.serviceChargeType === 'Percentage') {
//     serviceFee = (service?.serviceChargeAmount * amount) / 100;
//   } else if (service.serviceChargeType === 'Flat') {
//     serviceFee = service?.serviceChargeAmount;
//   }
//   if (service.serviceChargeCutOff && serviceFee > service.serviceChargeCutOff) {
//     return (service.serviceChargeCutOff * 100) / amount;
//   }
//   return ((serviceFee * 100) / amount);
// }

export const getFormatedTime = ({ hours, minutes, seconds }) => {
    return (
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
    );
};

export const getTimeRemaining = (e) => {
    const difference = e - new Date().getTime();
    const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return { difference, hours, minutes, seconds };
};

export const getZoneObject = (siteData, zoneId, isReturn = true) => {
    if (siteData && siteData.siteZones) {
        for (let i = 0; i < siteData.siteZones.length; i++) {
            if (siteData.siteZones[i]._id === zoneId) {
                return siteData.siteZones[i];
            }
        }
        if (!isReturn) return null;
        return siteData ? siteData.displayName : {};
    }
    return {};
};

export const getPlatformFee = (amount, outlet) => {
    if (!outlet) return 0;
    // if (outlet.bankingType !== "OWN") return platformFee(outlet.parentVendorRef.noqFee, amount);
    return platformFee(outlet.noqFee, amount);
};

const platformFee = (noqFee, amount) => {
    let platformFee = 0;
    const platformFix = parseFloat(noqFee.platformFix);
    const platform = parseFloat(noqFee.platform);
    if (platform && platformFix) {
        platformFee = (platform * amount) / 100 + platformFix;
    } else if (platform) {
        platformFee = (platform * amount) / 100;
    } else if (platformFix) {
        platformFee = platformFix;
    }
    return +platformFee.toFixed(2);
};

export const round2digit = (numb) =>
    Math.round((numb + Number.EPSILON) * 100) / 100;

export const getMonth = (dateObj) => {
    if (!dateObj) return "";
    if (typeof dateObj === "string") dateObj = new Date(dateObj);
    return `${dateObj.getDate()}/${
        dateObj.getMonth() + 1
    }/${dateObj.getFullYear()}`;
};

export const getTime = (dateObj) => {
    if (!dateObj) return "";
    if (typeof dateObj === "string") dateObj = new Date(dateObj);
    var hours = dateObj.getHours();
    var minutes = dateObj.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
};

export const vendorTZdate = (date, timeZone) => {
    return moment(date).tz(timeZone).toDate();
};

export const vendorDateTime = (timeZone) => {
    if (moment().tz(timeZone).isDST())
        return moment().add(1, "hour").tz(timeZone);
    return moment().tz(timeZone);
};

export const vendorMomentDateTime = (date, timeZone) => {
    return moment(date).tz(timeZone);
};

export const momentIsAfter = (startDate, endDate) =>
    moment(startDate).isAfter(endDate);
export const momentIsAfterOrSame = (startDate, endDate) =>
    moment(startDate).isSameOrAfter(endDate);
export const isSameOrBefore = (startDate, endDate) =>
    moment(startDate).isSameOrBefore(endDate);
export const momentIsBefore = (startDate, endDate) =>
    moment(startDate).isBefore(endDate);
export const momentIsEqual = (startDate, endDate) =>
    moment(startDate).isSame(endDate);

export const isSameOrInBetween = (startDate, endDate) => {
    const currentDate = moment();
    const startingDate = moment(startDate);
    const endingDate = moment(endDate);

    return (
        currentDate.isBetween(startingDate, endingDate) ||
        currentDate.isSame(startingDate) ||
        currentDate.isSame(endingDate)
    );
};

/** NEW FUNCTION */
export const getDates = (
    timezone = "Europe/London",
    preOrderSettings,
    maxDaysForCollection,
    timings,
    preOrderAcceptedBefore,
) => {
    try {
        if (timezone) moment.tz.setDefault(timezone);
    } catch (err) {
        console.log(err);
    }
    if (
        preOrderSettings &&
        preOrderSettings.oftenType === "CUSTOM_RANGE" &&
        !isSameOrInBetween(preOrderSettings.startDate, preOrderSettings.endDate)
    ) {
        return enumerateDaysBetweenDates(
            timezone,
            preOrderSettings.startDate,
            preOrderSettings.endDate,
            preOrderSettings,
            timings,
            preOrderAcceptedBefore,
        );
    } else if (
        preOrderSettings &&
        preOrderSettings.oftenType === "CUSTOM_RANGE" &&
        isSameOrInBetween(preOrderSettings.startDate, preOrderSettings.endDate)
    ) {
        return makeDates(
            timezone,
            preOrderSettings,
            timings,
            preOrderAcceptedBefore,
        );
    } else if (
        preOrderSettings &&
        preOrderSettings.oftenType === "SINGLE_DATE" &&
        momentIsAfterOrSame(preOrderSettings.startDate, moment().startOf("day"))
    ) {
        return enumerateDaysBetweenDates(
            timezone,
            preOrderSettings.startDate,
            preOrderSettings.startDate,
            preOrderSettings,
            [],
            preOrderAcceptedBefore,
        );
    } else if (
        preOrderSettings &&
        preOrderSettings.oftenType === "ALL_TIME" &&
        maxDaysForCollection
    ) {
        const startDate = moment();
        return enumerateDaysBetweenDates(
            timezone,
            startDate,
            startDate.clone().add(maxDaysForCollection - 1, "days"),
            preOrderSettings,
            timings,
            preOrderAcceptedBefore,
        );
    }
    return [];
};

const makeDates = (
    timezone,
    preOrderSettings,
    timings,
    preOrderAcceptedBefore,
) => {
    if (moment(preOrderSettings.startDate).isSame(moment())) {
        return enumerateDaysBetweenDates(
            timezone,
            preOrderSettings.startDate,
            preOrderSettings.endDate,
            preOrderSettings,
            timings,
            preOrderAcceptedBefore,
        );
    } else {
        return enumerateDaysBetweenDates(
            timezone,
            moment().toDate(),
            preOrderSettings.endDate,
            preOrderSettings,
            timings,
            preOrderAcceptedBefore,
        );
    }
};

export const enumerateDaysBetweenDates = (
    timezone,
    startDate,
    endDate,
    preOrderSettings,
    openTimings,
    preOrderAcceptedBefore,
) => {
    const dates = [];
    const currDate = moment(startDate).tz(timezone);
    const lastDate = moment(endDate).tz(timezone).endOf("day");

    let isValid = preOrderAcceptedBefore;

    if (preOrderSettings.oftenType === "CUSTOM_RANGE") {
        isValid =
            preOrderAcceptedBefore &&
            moment()
                .tz(timezone)
                .add(preOrderAcceptedBefore * 60, "minutes")
                .isSameOrAfter(currDate);
    }

    dates.push(
        isValid
            ? moment(
                  currDate
                      .add(preOrderAcceptedBefore, "hours")
                      .clone()
                      .toDate(),
              )
            : moment(currDate.clone().toDate()),
    );

    while (currDate.add(1, "days").tz(timezone).diff(lastDate) < 0) {
        dates.push(moment(currDate.clone().toDate()));
    }

    if (preOrderSettings.oftenType === "SINGLE_DATE") {
        return dates
            .filter((date) => {
                return date.isSameOrBefore(lastDate);
            })
            .map((date) => {
                return date.toDate();
            });
    } else {
        return dates
            .filter((date) => {
                return (
                    Object.keys(openTimings).findIndex(
                        (timing) =>
                            moment(date).format("dddd").toLowerCase() ===
                                timing && openTimings[timing].isActive === true,
                    ) > -1
                );
            })
            .map((date) => {
                return date.toDate();
            });
    }
};

export const getCurrentDate = (timezone, preOrderSettings) => {
    let startDate = null;
    for (let i = 0; i < Object.keys(preOrderSettings.timings).length; i++) {
        const currentDay = moment().clone().tz(timezone).add(i, "days");
        if (
            preOrderSettings.timings[currentDay.format("dddd").toLowerCase()]
                .isActive
        ) {
            startDate = currentDay;
            break;
        }
    }
    return startDate;
};
export const isPreOrderActive = (timezone, preOrderSettings) => {
    let startDate = getCurrentDate(timezone, preOrderSettings);

    if (
        preOrderSettings.oftenType === "CUSTOM_RANGE" ||
        preOrderSettings.oftenType === "SINGLE_DATE"
    ) {
        startDate = moment(preOrderSettings.startDate).tz(timezone);
    }
    if (!startDate) return false;
    return isSameOrBefore(
        startDate.subtract(preOrderSettings.bookingAcceptedBefore, "day"),
        moment(),
    );
};
/** END NEW DATE FUNCTIONS FOR PRE ORDER */

export const useDebounce = (callback, delay) => {
    const latestCallback = useRef();
    const latestTimeout = useRef();

    useEffect(() => {
        latestCallback.current = callback;
    }, [callback]);

    return () => {
        if (latestTimeout.current) {
            clearTimeout(latestTimeout.current);
        }

        latestTimeout.current = setTimeout(() => {
            latestCallback.current();
        }, delay);
    };
};

export const makeTimeSlots = (
    siteDetail,
    filledSlots,
    selectedDate,
    slotsOfDay = [],
    timezone = "Europe/London",
    slotDuration = 0,
    preOrderAcceptedBefore = 0,
    minTimeForFirstSlot = 0,
) => {
    const filteredSlots = filledSlots
        .filter((filledSlot) => {
            return (
                moment(selectedDate, "Do MMM YYYY", true).format(
                    "YYYY-MM-DD",
                ) === moment.utc(filledSlot.deliveryDate).format("YYYY-MM-DD")
            );
        })
        .map((filteredDate) => {
            return moment(filteredDate.deliveryDate).format("HH:mm");
        });
    let date = moment(selectedDate, "Do MMM YYYY", true).startOf("day");
    try {
        if (timezone) moment.tz.setDefault(timezone);
    } catch (err) {
        console.log(err);
    }
    let timeSlots = [];
    const currentTime = moment(moment.tz(timezone).format("HH:mm"), "HH:mm a");
    // if (siteDetail && siteDetail.type === 'EVENT') { // TODO: remove after some testing from NOQ side.
    //   const startTime = moment(date, "Do MMM YYYY", true).add(moment.duration(siteDetail.absoluteStartTime));
    //   const endTime = minTimeForFirstSlot ?
    //     moment(date, "Do MMM YYYY", true).add(moment.duration(siteDetail.absoluteEndTime)).subtract(minTimeForFirstSlot, 'minutes') :
    //     moment(date, "Do MMM YYYY", true).add(moment.duration(siteDetail.absoluteEndTime));
    //   const firstSlotTime = minTimeForFirstSlot ?
    //     moment(date, "Do MMM YYYY", true).add(moment.duration(siteDetail.absoluteStartTime)).add(minTimeForFirstSlot, 'minutes') :
    //     moment(date, "Do MMM YYYY", true).add(moment.duration(siteDetail.absoluteStartTime));
    //   while (startTime.diff(endTime) <= 0) {
    //     if (!minTimeForFirstSlot || moment(startTime.clone().toDate()).isSameOrAfter(firstSlotTime)) {
    //       timeSlots.push(moment(startTime.clone().toDate()));
    //     }
    //     startTime.add(slotDuration, 'minutes');
    //   }
    // } else {
    for (let i = 0; i < slotsOfDay.length; i++) {
        const slotOfDay = slotsOfDay[i];
        const startTime = moment(date, "Do MMM YYYY", true).add(
            moment.duration(slotOfDay.from),
        );
        const endTime = minTimeForFirstSlot
            ? moment(date, "Do MMM YYYY", true)
                  .add(moment.duration(slotOfDay.to))
                  .subtract(minTimeForFirstSlot, "minutes")
            : moment(date, "Do MMM YYYY", true).add(
                  moment.duration(slotOfDay.to),
              );
        const firstSlotTime = minTimeForFirstSlot
            ? moment(date, "Do MMM YYYY", true)
                  .add(moment.duration(slotOfDay.from))
                  .add(minTimeForFirstSlot, "minutes")
            : moment(date, "Do MMM YYYY", true).add(
                  moment.duration(slotOfDay.from),
              );
        while (startTime.diff(endTime) <= 0) {
            if (
                !minTimeForFirstSlot ||
                moment(startTime.clone().toDate()).isSameOrAfter(firstSlotTime)
            ) {
                timeSlots.push(moment(startTime.clone().toDate()));
            }
            startTime.add(slotDuration, "minutes");
        }
    }
    // }
    return timeSlots
        .filter((timeSlot) => {
            const bufferTimeSlot = minTimeForFirstSlot
                ? timeSlot.isAfter(
                      moment(currentTime.clone().toDate()).add(
                          minTimeForFirstSlot,
                          "minutes",
                      ),
                  )
                : timeSlot.isAfter(moment(currentTime.clone().toDate()));
            if (
                moment(date, "Do MMM YYYY", true).isSameOrAfter(
                    moment.tz(timezone),
                    "day",
                ) &&
                !preOrderAcceptedBefore
            ) {
                return bufferTimeSlot;
            } else if (
                preOrderAcceptedBefore &&
                moment(date, "Do MMM YYYY", true).isSameOrAfter(
                    moment
                        .tz(timezone)
                        .add(preOrderAcceptedBefore * 60, "minutes"),
                    "day",
                )
            ) {
                return timeSlot.isAfter(
                    moment(
                        moment
                            .tz(timezone)
                            .add(preOrderAcceptedBefore * 60, "minutes"),
                    ).add(minTimeForFirstSlot, "minutes"),
                );
            }
            return false;
        })
        .map((timeSlot) => {
            return {
                label: timeSlot.format("hh:mm A"),
                value: timeSlot.format("HH:mm"),
            };
        })
        .filter((timeSlot) => {
            return !filteredSlots.includes(timeSlot.value);
        });
};

export const capitalize = (str = "") =>
    str.charAt(0).toUpperCase() + str.slice(1);

export const capitalizeEachWord = (str = "") => {
    const words = str.toLowerCase().split(" ");
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }
    return words.join(" ");
};

export const observerShowDescription = (tag = "p") => {
    const ps = document.querySelectorAll(tag);
    const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
            entry.target.classList[
                entry.target.scrollHeight > entry.contentRect.height
                    ? "add"
                    : "remove"
            ]("truncated");
        }
    }, []);

    ps.forEach((p) => {
        observer.observe(p);
    });
};

export const totalQuantity = (basket) => {
    let total = 0;
    if (
        (Object.keys(basket) || []).length > 0 &&
        basket.items &&
        basket.items.length > 0
    ) {
        basket.items.map((item) => (total += item.quantity));
    }
    return total;
};

export const totalQuantityCount = (basket) => {
    let total = 0;
    if (
        basket &&
        Object.keys(basket).length > 0 &&
        basket.carts &&
        basket.carts.length > 0
    ) {
        for (let cart of basket.carts) {
            for (let item of cart.items) {
                total += item.quantity;
            }
        }
    }
    return total;
};

export const calculateRemainingQty = (maxProducts, items) => {
    return maxProducts - items.reduce((prev, next) => prev + next.quantity, 0);
};

export const checkPrePaidDisable = (qty, menuType) => {
    return qty < 0 && menuType === constants.MENU_PAY_TYPE.PREPAID;
};

export const prePaidBasketToRequest = (
    basket,
    outletId,
    serviceData,
    prepaidEmail,
    prepaidFullName,
    prepaidTableNumber,
    prepaidVendorZoneName,
) => {
    const cart = basketToRequest(
        { ...basket, vendorRef: outletId },
        serviceData,
    );
    return {
        cart: {
            ...cart,
            subTotal: 0,
            totalAmount: 0,
            totalAmountAfterDiscount: 0,
            vendorTotal: 0,
            serviceCharge: 0,
            deliveryFee: 0,
            platformFee: 0,
            tipAmount: 0,
            tip: null,
            locationIdentifierLabel: serviceData.locationTitleCustom
                ? serviceData.locationTitleCustom
                : "",
        },
        siteZones: cart.siteZones,
        tableNumber: prepaidTableNumber || "",
        vendorZoneName:
            prepaidVendorZoneName || serviceData?.vendorZoneName || "",
        name: prepaidFullName,
        email: prepaidEmail,
        prePaidOrder: true,
        deliveryAddress: {
            houseNumber: "",
            street: "",
            postalcode: "",
        },
        address: "",
        specialInstructions: "",
        contactNumber: null,
        formattedContactNumber: null,
        note: "",
        newsLetter: false,
        userZoneIdentifier: cart?.locationIdentifier
            ? cart.locationIdentifier
            : null,
        deliveryDate:
            serviceData && serviceData.preOrder ? cart.deliveryDate : null,
    };
};

export const generateOutletDetailUrl = ({ siteId, zoneId, outletId }) => {
    if (siteId && outletId && zoneId) {
        return `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.ZONE}/${zoneId}/${constants.ROUTES.OUTLET}/${outletId}`;
    }

    if (siteId && outletId) {
        return `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.OUTLET}/${outletId}`;
    }

    return `/${constants.ROUTES.OUTLET}/${outletId}`;
};

export const generateServiceTypeDetailUrl = ({
    siteId,
    zoneId,
    serviceType,
}) => {
    if (siteId && zoneId) {
        return `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.ZONE}/${zoneId}`;
    }

    return `/${constants.ROUTES.SITE}/${siteId}`;
};

export const getBannerClassName = (logoImage, bannerImage) => {
    if (!logoImage && !bannerImage) return "no-image";
    if (!bannerImage) return "no-banner-image";
    if (!logoImage) return "no-logo-image";
    return "";
};

export const generateLocationDropdown = (
    locationIdentifierSelects,
    zoneId,
    isDependent = false,
) => {
    const locationName = [];
    let locationDetail = null;
    if (zoneId && isDependent) {
        locationDetail = locationIdentifierSelects.find(
            (v) => String(v.siteZoneId) === String(zoneId) && v.isActive,
        );
    } else {
        locationDetail = locationIdentifierSelects.find((v) => v.isDefault);
    }
    if (locationDetail && locationDetail.tableNoRange) {
        for (
            let index = locationDetail.tableNoRange.from || 0;
            index <= (locationDetail.tableNoRange.to || 0);
            index++
        ) {
            locationName.push(index);
        }
    }
    return { locationDetail, locationName };
};

export const toggleActionSheet = (ref, isOpen = false) => {
    if (isOpen) ref.current.open();
    else ref.current.close();
};
export const getLatLngByZipcode = async (zipcode) => {
    return new Promise((resolve, reject) => {
        if (!window.google) return reject("Google Geocoder not found");
        const geocoder = new window.google.maps.Geocoder();
        const address = zipcode;
        geocoder.geocode(
            { address: "zipcode " + address },
            function (results, status) {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    let latitude = results[0].geometry.location.lat();
                    let longitude = results[0].geometry.location.lng();
                    resolve({ latitude, longitude });
                    return;
                }
                reject("Address not found");
                return;
            },
        );
    });
};

export const findAutomatedPromotionToApply = async (
    siteData,
    outletData,
    cart,
) => {
    try {
        const siteAutomatedPromotion = siteData?.assignedPromotions?.find(
            (promo) =>
                promo?.promotionRef?.promotionType ===
                AUTOMATED_PROMO_PROMOTION,
        );
        const outletAutomatedPromotion = outletData?.assignedPromotions?.find(
            (promo) =>
                promo?.promotionRef?.promotionType ===
                AUTOMATED_PROMO_PROMOTION,
        );

        let promoObject = {};
        if (
            siteAutomatedPromotion &&
            Object.keys(siteAutomatedPromotion).length
        ) {
            promoObject = {
                ...siteAutomatedPromotion.promotionRef,
            };
        } else if (
            outletAutomatedPromotion &&
            Object.keys(outletAutomatedPromotion).length
        ) {
            if (cart.isMultiOrder && cart?.carts?.length > 1) {
                const vendorIds = cart?.carts?.map((cart) => cart.vendorRef);
                await apiService
                    .getAutomatedPromoRefByStores(vendorIds)
                    .then((response) => {
                        if (response && response.length) {
                            const isSamePromoAssigned = response.every(
                                ({ promotionRef }) =>
                                    promotionRef === response[0].promotionRef,
                            );
                            if (!isSamePromoAssigned) {
                                promoObject = {};
                            } else {
                                promoObject = {
                                    ...outletAutomatedPromotion.promotionRef,
                                };
                            }
                        }
                    })
                    .catch((err) => {
                        promoObject = {};
                    });
            } else {
                promoObject = {
                    ...outletAutomatedPromotion.promotionRef,
                };
            }
        }
        return promoObject;
    } catch (err) {
        return {};
    }
};

export const checkPromotionCode = async (cart, promotion, promoCode) => {
    try {
        // validation
        if (promotion) {
            const today = new Date().getTime();
            const startDate = new Date(promotion.startDate).getTime();
            const endDate = moment(promotion.endDate)
                .endOf("day")
                .toDate()
                .getTime();

            if (promotion.isArchived === true) {
                notify(
                    "Oops! The promo code you've entered has expired. Please double-check the code or use a different one.",
                );
                return {};
            }

            const foundPromoCode = promotion.promoCodes.find(
                (promoC) => promoC.code === promoCode,
            );

            let itemQty = 0,
                subTotal = 0;

            for (let i = 0; i < cart.carts.length; i++) {
                let tempCart = cart.carts[i];
                subTotal += cart.carts[i].subTotal;
                for (let j = 0; j < tempCart.items.length; j++) {
                    itemQty += tempCart.items[j].quantity;
                }
            }

            if (promotion.usageType === "single") {
                if (!foundPromoCode) {
                    notify(
                        "The code you've entered does not match any active promotion. Please ensure you've typed it correctly and try again.",
                    );
                    return {};
                }

                if (foundPromoCode.isUsed) {
                    notify(
                        "The promo code you're trying to use has already reached its usage limit. Stay tuned - more promos are on the way!",
                    );
                    return {};
                }
            } else if (promotion.usageType === "multiple") {
                if (promotion.maxUsage <= promotion.noOfTimesUsed) {
                    notify(
                        "The promo code you're trying to use has already reached its usage limit. Stay tuned - more promos are on the way!",
                    );
                    return {};
                }
            } else if (promotion.usageType === "singleMultiUse") {
                if (promotion.amountUsed >= promotion.amountSaved) {
                    notify(
                        "The promo code you're trying to use has exceed the total value. Stay tuned - more promos are on the way!",
                    );
                    return {};
                } else {
                    promotion.amountSaved -= promotion.amountUsed;
                }
            }

            if (promotion.purchaseConditions) {
                let itemQty = 0,
                    subTotal = 0;

                for (let i = 0; i < cart.carts.length; i++) {
                    let tempCart = cart.carts[i];
                    subTotal += cart.carts[i].subTotal;
                    for (let j = 0; j < tempCart.items.length; j++) {
                        itemQty += tempCart.items[j].quantity;
                    }
                }
                if (promotion.usageType === "singleMultiUse") {
                    if (promotion.amountUsed >= promotion.amountSaved) {
                        notify(
                            "The promo code you're trying to use has exceed the total value. Stay tuned - more promos are on the way!",
                        );
                        return {};
                    } else {
                        promotion.amountSaved -= promotion.amountUsed;
                    }
                }

                if (promotion.qtyOrAmountValidation === "minQuantitySelected") {
                    if (promotion.quantity > itemQty) {
                        notify(
                            `The code you've entered requires minimum quantity of ${promotion.quantity}.`,
                        );
                        return {};
                    }
                }
                if (promotion.qtyOrAmountValidation === "minAmountSelected") {
                    if (promotion.minAmount > subTotal) {
                        notify(
                            `The code you've entered requires minimum amount of ${
                                currencyMap[
                                    cart?.currency?.toUpperCase() || "GBP"
                                ] + promotion.minAmount
                            }.`,
                        );
                        return {};
                    }
                }
            }

            // check if promotion is live
            if (startDate > today || endDate < today) {
                notify(
                    "Oops! The promo code you've entered has expired. Please double-check the code or use a different one.",
                );
                return {};
            }

            // if day specific promotion then check timings
            if (promotion.dayTimeSpecific === true) {
                const currentDay = weekday[new Date().getDay()];

                // check if day is active
                if (promotion.dayTimes[currentDay].isActive !== true) {
                    notify(
                        `The code you've entered is not available currently, please try another code.`,
                    );
                    return {};
                }

                const notInTime =
                    promotion.dayTimes[currentDay].times.filter((time) => {
                        const fromDate = new Date();
                        const toDate = new Date();
                        const from = time.from.split(":");
                        const to = time.to.split(":");
                        fromDate.setHours(from[0]);
                        fromDate.setMinutes(from[1]);
                        fromDate.setMilliseconds(0);
                        toDate.setHours(to[0]);
                        toDate.setMinutes(to[1]);
                        toDate.setMilliseconds(0);
                        return (
                            fromDate.getTime() <= today &&
                            toDate.getTime() >= today
                        );
                    }).length === 0;

                if (notInTime) {
                    notify(
                        `The code you've entered is not available currently, please try another code.`,
                    );
                    return {};
                }
            }

            return {
                promotionRef: promotion._id,
                name: promotion.name,
                promotionType: promotion.promotionType,
                savingType: promotion.savingType,
                value: promotion.amountSaved,
                quantity: promotion.quantity,
                promoCode: foundPromoCode.code,
            };
        }
    } catch (error) {
        notify("Oops! Something went wrong, please try again!");
        return {};
    }
};

export const getPromotion = async (cart, assignedPromotions = [], userId) => {
    try {
        if (assignedPromotions.length === 0 || !userId) {
            return {};
        }

        let membership = {};
        await apiService
            .getUserPromotion(userId, assignedPromotions || [])
            .then((membershipResp) => {
                membership = membershipResp;
            })
            .catch(() => {
                return {};
            });

        if (Object.keys(membership).length === 0) {
            return {};
        }

        const promotion = membership?.promotionRef || null;

        // validation
        if (promotion) {
            if (promotion?.savingType === "amount") {
                const amountUsed = membership.amountUsed || 0;
                if (promotion.amountSaved - amountUsed > 0) {
                    promotion.amountSaved -= amountUsed;
                } else {
                    return {};
                }
            }

            const today = new Date().getTime();
            const startDate = new Date(promotion.startDate).getTime();
            const endDate = moment(promotion.endDate)
                .endOf("day")
                .toDate()
                .getTime();

            if (promotion.isArchived === true) {
                return {};
            }

            let itemQty = 0;
            for (let i = 0; i < cart.carts.length; i++) {
                let tempCart = cart.carts[i];
                for (let j = 0; j < tempCart.items.length; j++) {
                    itemQty += tempCart.items[j].quantity;
                }
            }

            if (promotion.quantity > itemQty) {
                return {};
            }

            // check if promotion is live
            if (startDate > today || endDate < today) {
                return {};
            }

            // if day specific promotion then check timings
            if (promotion.dayTimeSpecific === true) {
                const currentDay = weekday[new Date().getDay()];

                // check if day is active
                if (promotion.dayTimes[currentDay].isActive !== true) {
                    return {};
                }

                const notInTime =
                    promotion.dayTimes[currentDay].times.filter((time) => {
                        const fromDate = new Date();
                        const toDate = new Date();
                        const from = time.from.split(":");
                        const to = time.to.split(":");
                        fromDate.setHours(from[0]);
                        fromDate.setMinutes(from[1]);
                        fromDate.setMilliseconds(0);
                        toDate.setHours(to[0]);
                        toDate.setMinutes(to[1]);
                        toDate.setMilliseconds(0);
                        return (
                            fromDate.getTime() <= today &&
                            toDate.getTime() >= today
                        );
                    }).length === 0;

                if (notInTime) {
                    return {};
                }
            }

            return {
                promotionRef: promotion._id,
                name: promotion.name,
                promotionType: promotion.promotionType,
                savingType: promotion.savingType,
                membershipId: membership.membershipId,
                value: promotion.amountSaved,
                quantity: promotion.quantity,
            };
        }
    } catch (error) {
        return {};
    }
};

export const updateLocalCart = (cart, outletData, body = {}) => {
    body.isMultiOrder = cart.isMultiOrder;
    let masterTotalAmount = 0,
        subTotal = 0,
        vatTotal = 0,
        currency = cart?.currency || "GBP";
    if (!cart || Object.keys(cart).length === 0) return {};

    cart?.carts?.forEach((singleCart) => {
        subTotal += singleCart.subTotal;
    });

    masterTotalAmount = subTotal;

    // if (body.isMultiOrder === true) {
    if (body.itemBasedPromotion && body?.itemBasedPromotion?.length) {
        let updatedPromotions = body.itemBasedPromotion;
        updatedPromotions = updatedPromotions?.map((promo) => {
            promo.amount = 0;
            return promo;
        });
        cart?.carts?.forEach((singleCart) => {
            singleCart?.items?.forEach((item) => {
                const promoIndex = updatedPromotions?.findIndex(
                    (p) =>
                        String(p.promotionRef) ===
                        String(item?.assignedItemBasePromocode?.promocodeRef),
                );

                if (promoIndex > -1) {
                    const promoAmount =
                        item.subTotal *
                        (updatedPromotions[promoIndex]?.value / 100);

                    updatedPromotions[promoIndex].amount =
                        (updatedPromotions[promoIndex]?.amount || 0) +
                        promoAmount;
                }
            });
        });

        updatedPromotions = updatedPromotions?.filter(
            (promo) => promo.amount > 0,
        );

        body.itemBasedPromotion = JSON.parse(JSON.stringify(updatedPromotions));
        if (body?.itemBasedPromotion && body?.itemBasedPromotion?.length) {
            const itemBasedDiscountAmount = body.itemBasedPromotion.reduce(
                (acc, item) => acc + item.amount,
                0,
            );
            if (masterTotalAmount - itemBasedDiscountAmount > 0) {
                masterTotalAmount -= itemBasedDiscountAmount;
            } else {
                masterTotalAmount = 0;
            }
        }
    }

    if (
        masterTotalAmount > 0 &&
        body.promotion &&
        Object.keys(body.promotion).length
    ) {
        let amountLeftForPromotion = 0;
        cart?.carts?.forEach((singleCart) => {
                singleCart?.items?.forEach((item) => {
                    if (body?.itemBasedPromotion?.length) {
                        const promoIndex = body?.itemBasedPromotion?.findIndex(
                            (p) =>
                                String(p.promotionRef) ===
                                String(
                                    item?.assignedItemBasePromocode?.promocodeRef,
                                ),
                        );
    
                        if (promoIndex === -1) {
                            amountLeftForPromotion += item.subTotal;
                        }
                    } else {
                        amountLeftForPromotion += item.subTotal;
                    }
                });
        });

        if (amountLeftForPromotion > 0) {
            body.promotion = calculatePromotions(
                amountLeftForPromotion,
                body.promotion,
            );
            if (body?.promotion?.amount <= masterTotalAmount) {
                masterTotalAmount -= body.promotion.amount;
            } else {
                body.promotion.amount = masterTotalAmount;
                masterTotalAmount = 0;
            }
        } else {
            body.promotion = {};
        }
    }

    if (body.loyalty && body.loyaltyUsed) {
        if (masterTotalAmount - body.loyaltyUsed >= 0) {
            masterTotalAmount -= body.loyaltyUsed;
        } else {
            body.loyalty = null;
            body.loyaltyUsed = 0;
        }
    }
    // }

    let totalAmount = 0;

    cart.carts.map((v) => {
        v = calculateTotalFees(
            v,
            outletData,
            JSON.parse(JSON.stringify(body)),
            subTotal,
        );
        currency = v.currency;
        totalAmount += v.totalAmount;
        currency = v.currency;
        return v;
    });

    const finalCart = cumulateMasterCartTaxesV3(cart);

    return {
        ...finalCart,
        userRef: body.userRef || null,
        subTotal: subTotal,
        totalAmount: totalAmount,
        totalAmountAfterDiscount: totalAmount,
        vatTotal: vatTotal,
        currency: currency,
        isMultiOrder: body.isMultiOrder || false,
        loyaltyUsed: body.loyaltyUsed || 0,
        loyalty: body.loyalty || null,
        promotion: body.promotion || null,
        itemBasedPromotion: body.itemBasedPromotion || [],
    };
};

export const calculatePromotions = (subTotal, promotion) => {
    if (promotion && Object.keys(promotion).length) {
        if (promotion.savingType === "percent") {
            promotion.amount = (subTotal * promotion.value) / 100 || 0;
        } else {
            promotion.amount = promotion.value;
        }
        promotion.amount = round2digit(promotion.amount);
        return promotion;
    } else {
        return {};
    }
};

export const calculateTotalFees = (
    cartData,
    vendor,
    reqData,
    masterTotalAmount,
) => {
    /** Tip calculation */
    let tipAmount = 0;
    let tip = null;
    if (reqData.tip === undefined && cartData.tip !== undefined) {
        tip = cartData.tip;
    } else if (reqData.tip !== undefined) {
        tip = reqData.tip;
    }
    if (tip && Object.keys(tip).length) {
        if (tip.type === "Percentage") {
            tip.amount = (cartData.subTotal * tip.value) / 100 || 0;
        }
        tip.amount = round2digit(tip.amount);
        tipAmount = tip.amount;
        cartData.tip = tip;
    } else if (tip !== null) {
        tip = calculateTip(cartData.subTotal, vendor, cartData.serviceType);
        if (tip) {
            tip.amount = round2digit(tip.amount);
            tipAmount = tip.amount;
            cartData.tip = tip;
        }
    }
    cartData.tipAmount = tipAmount;
    cartData.tip = tip;
    /** Tip calculation */

    let deliveryFee = cartData.deliveryFee;
    let platformFee = cartData.platformFee;
    let subTotal = cartData.subTotal;
    let serviceCharge = cartData.serviceCharge;

    const subTotalPercentage =
        percentageOfFlatAmt(subTotal, masterTotalAmount) || 0;

    /** DeliveryFee calculation */
    deliveryFee = calculateDeliveryFee(
        vendor,
        cartData.subTotal,
        cartData.serviceType,
        deliveryFee,
    );
    // }
    cartData.deliveryFee = deliveryFee;
    /** DeliveryFee calculation */

    platformFee = calculatePlatformFee(cartData.subTotal, vendor);
    cartData.platformFee = round2digit(platformFee);
    if (reqData.itemBasedPromotion && reqData.itemBasedPromotion.length) {
        let updatedPromotions = reqData.itemBasedPromotion;
        updatedPromotions = updatedPromotions?.map((promo) => {
            promo.amount = 0;
            return promo;
        });
        cartData?.items?.forEach((item) => {
            const promoIndex = updatedPromotions?.findIndex(
                (p) =>
                    String(p.promotionRef) ===
                    String(item?.assignedItemBasePromocode?.promocodeRef),
            );

            if (promoIndex > -1) {
                const promoAmount =
                    item.subTotal *
                    (updatedPromotions[promoIndex]?.value / 100);

                updatedPromotions[promoIndex].amount =
                    (updatedPromotions[promoIndex]?.amount || 0) + promoAmount;
            }
        });

        updatedPromotions = updatedPromotions?.filter(
            (promo) => promo.amount > 0,
        );

        cartData.itemBasedPromotion = [...updatedPromotions];
        if (cartData.itemBasedPromotion && cartData.itemBasedPromotion.length) {
            const itemBasedDiscountAmount =
                cartData?.itemBasedPromotion?.reduce(
                    (acc, item) => acc + item.amount,
                    0,
                ) || 0;
            if (subTotal - itemBasedDiscountAmount > 0) {
                subTotal -= itemBasedDiscountAmount;
            } else {
                subTotal = 0;
            }
        }
    }

    if (
        subTotal > 0 &&
        reqData.promotion &&
        Object.keys(reqData.promotion).length
    ) {
        let amountLeftForPromotion = 0;
        cartData?.items?.forEach((item) => {
            if (cartData?.itemBasedPromotion?.length) {
                const promoIndex = cartData?.itemBasedPromotion?.findIndex(
                    (p) =>
                        String(p.promotionRef) ===
                        String(item?.assignedItemBasePromocode?.promocodeRef),
                );

                if (promoIndex === -1) {
                    amountLeftForPromotion += item.subTotal;
                }
            } else {
                amountLeftForPromotion += item.subTotal;
            }
        });

        if (amountLeftForPromotion > 0) {
            const tempPromotionAmount =
                (reqData.promotion.amount * subTotalPercentage) / 100;
            cartData.promotion = {
                ...reqData.promotion,
                amount: tempPromotionAmount,
            };
            if (cartData?.promotion?.amount) {
                if (subTotal - cartData?.promotion?.amount > 0) {
                    subTotal -= cartData.promotion.amount;
                } else {
                    cartData.promotion.amount = subTotal;
                    subTotal = 0;
                }
            }
        } else {
            cartData.promotion = {};
        }
    } else {
        cartData.promotion = {};
    }

    /** Loyalty calculation */
    cartData.loyalty = null;
    cartData.loyaltyUsed = 0;
    const loyaltyUsed = (reqData.loyaltyUsed * subTotalPercentage) / 100;

    if (reqData.loyalty && loyaltyUsed && subTotal - loyaltyUsed >= 0) {
        subTotal -= loyaltyUsed;
        cartData.loyalty = reqData.loyalty;
        cartData.loyaltyUsed = loyaltyUsed;
    } else {
        cartData.loyalty = null;
        cartData.loyaltyUsed = 0;
    }
    /** Loyalty calculation */

    serviceCharge =
        calculateServiceCharge(subTotal, vendor, cartData.serviceType) || 0;
    cartData.serviceCharge = serviceCharge;
    /** Service charge calculation */

    if (reqData.isMultiOrder) {
        platformFee = cartData.platformFee = 0;
        deliveryFee = cartData.deliveryFee = 0;
        serviceCharge = cartData.serviceCharge = 0;
    }

    cartData.vendorTotal = Math.max(
        subTotal + serviceCharge + tipAmount + deliveryFee,
        0,
    );

    const totalAmount = Math.max(cartData.vendorTotal + platformFee, 0);

    cartData.totalAmount = round2digit(totalAmount);
    cartData.totalAmountAfterDiscount = round2digit(totalAmount);
    if (vendor.isMicroserviceActive) {
        const tempData = calculateItemLevelTaxWebV3(cartData);
        if (tempData) {
            return tempData;
        }
    }
    return cartData;
};

const calculateItemLevelTaxWebV3 = (cart) => {
    let subTotal = calculateSubTotal(cart.items || []);
    let loyaltyDisInPercentage = 0,
        flatPromotionInPercentage = 0,
        itemBasedPromotionAmount = 0,
        amountLeftForPromotion = 0;
        cart?.items?.forEach((item) => {
            if (cart?.itemBasedPromotion?.length) {
                const promoIndex = cart?.itemBasedPromotion?.findIndex(
                    (p) =>
                        String(p.promotionRef) ===
                        String(item?.assignedItemBasePromocode?.promocodeRef),
                );
    
                if (promoIndex === -1) {
                    amountLeftForPromotion += item.subTotal;
                } else {
                    itemBasedPromotionAmount +=
                        cart?.itemBasedPromotion[promoIndex]?.amount || 0;
                }
            } else {
                amountLeftForPromotion += item.subTotal;
            }
        });
    if (cart.promotion && cart.promotion.amount > 0) {
        flatPromotionInPercentage = percentageOfFlatAmt(
            cart.promotion.amount,
            amountLeftForPromotion,
        );
    }

    if (
        cart.loyalty &&
        cart.loyaltyUsed &&
        cart.loyaltyUsed > 0 &&
        (subTotal || 0) - (cart.promotion.amount || 0) > 0
    ) {
        loyaltyDisInPercentage = percentageOfFlatAmt(
            cart.loyaltyUsed,
            subTotal -
                (cart.promotion.amount || 0) -
                (itemBasedPromotionAmount || 0),
        );
    }
    cart.inclusiveTaxTotal = 0;
    cart.exclusiveTaxTotal = 0;

    for (let i = 0; i < cart.items.length; i++) {
        let subTotalAfterDiscount = round2digit(
            cart.items[i].itemPrice * cart.items[i].quantity,
        );
        // item based promotion calculation
        let itemBasedPromotionAmount = 0;
        const promocodeRef =
            cart?.items[i]?.assignedItemBasePromocode?.promocodeRef;
        if (cart?.itemBasedPromotion?.length && promocodeRef) {
            const promoIndex = cart?.itemBasedPromotion?.findIndex(
                (p) => String(p.promotionRef) === String(promocodeRef),
            );

            if (promoIndex > -1) {
                itemBasedPromotionAmount =
                    subTotalAfterDiscount *
                    (cart?.itemBasedPromotion[promoIndex]?.value / 100);
            }
        }
        subTotalAfterDiscount -= itemBasedPromotionAmount || 0;

        // calculate item level promotion
        if (itemBasedPromotionAmount === 0 && flatPromotionInPercentage) {
            cart.items[i].promotionDiscounts = evaluateAmtFromPercentage(
                flatPromotionInPercentage,
                subTotalAfterDiscount,
            );
        } else {
            cart.items[i].promotionDiscounts = 0;
        }
        subTotalAfterDiscount -= cart.items[i].promotionDiscounts || 0;

        ///// loyalty
        if (loyaltyDisInPercentage) {
            cart.items[i].loyaltyDiscounts = evaluateAmtFromPercentage(
                loyaltyDisInPercentage,
                subTotalAfterDiscount,
            );
        } else {
            cart.items[i].loyaltyDiscounts = 0;
        }
        subTotalAfterDiscount -= cart.items[i].loyaltyDiscounts || 0;

        // calculate item tax here
        const cartItem = calculateCartTaxesV3({
            cartItem: cart.items[i],
            subTotalAfterDiscount,
        });

        cart.items[i] = { ...cartItem };
        cart.inclusiveTaxTotal += cartItem.inclusiveTaxTotal;
        cart.exclusiveTaxTotal += cartItem.exclusiveTaxTotal;
        cart.vendorTotal += cartItem.exclusiveTaxTotal;
        cart.totalAmount += cartItem.exclusiveTaxTotal;
        cart.totalAmountAfterDiscount += cartItem.exclusiveTaxTotal;

        if (cart.items[i].modifiers && cart.items[i].modifiers.length) {
            for (let j = 0; j < cart.items[i].modifiers.length; j++) {
                const mdf = cart.items[i].modifiers[j];
                mdf.subTotal = round2digit(
                    mdf.price * mdf.quantity * cart.items[i].quantity,
                );

                let mdfSubtotalAfterDiscount = mdf.subTotal || 0;

                // item based promotion calculation
                let modifierItemBasedPromotionAmount = 0;
                if (cart?.itemBasedPromotion?.length && promocodeRef) {
                    const promoIndex = cart?.itemBasedPromotion?.findIndex(
                        (p) => String(p.promotionRef) === String(promocodeRef),
                    );

                    if (promoIndex > -1) {
                        modifierItemBasedPromotionAmount =
                            mdfSubtotalAfterDiscount *
                            (cart?.itemBasedPromotion[promoIndex]?.value / 100);
                    }
                }
                mdfSubtotalAfterDiscount -=
                    modifierItemBasedPromotionAmount || 0;
                if (
                    modifierItemBasedPromotionAmount === 0 &&
                    flatPromotionInPercentage
                ) {
                    mdf.promotionDiscounts = evaluateAmtFromPercentage(
                        flatPromotionInPercentage,
                        mdfSubtotalAfterDiscount,
                    );
                } else {
                    mdf.promotionDiscounts = 0;
                }
                mdfSubtotalAfterDiscount -= mdf.promotionDiscounts || 0;

                ///// loyalty
                if (loyaltyDisInPercentage) {
                    mdf.loyaltyDiscounts = evaluateAmtFromPercentage(
                        loyaltyDisInPercentage,
                        mdfSubtotalAfterDiscount,
                    );
                } else {
                    mdf.loyaltyDiscounts = 0;
                }
                mdfSubtotalAfterDiscount -= mdf.loyaltyDiscounts || 0;
                // if modifier is not item level
                if (mdf.itemRef === null || !mdf.itemRef) {
                    mdf.taxesNew = cart.items[i].taxesNew;
                }

                // calculate item tax here
                const updateModifier = calculateCartTaxesV3({
                    cartItem: mdf,
                    subTotalAfterDiscount: mdfSubtotalAfterDiscount,
                });

                cart.items[i].modifiers[j] = { ...updateModifier };
                cart.inclusiveTaxTotal += updateModifier.inclusiveTaxTotal;
                cart.exclusiveTaxTotal += updateModifier.exclusiveTaxTotal;
                cart.vendorTotal += updateModifier.exclusiveTaxTotal;
                cart.totalAmount += updateModifier.exclusiveTaxTotal;
                cart.totalAmountAfterDiscount +=
                    updateModifier.exclusiveTaxTotal;
            }
        }
    }

    const data = cumulateCartTaxesV3(cart);
    if (data) {
        cart = data;
    }

    return { ...cart };
};

const evaluateAmtFromPercentage = (percentage, subTotal) => {
    return Number((Number(subTotal) / 100) * percentage);
};

const cumulateCartTaxesV3 = (cart) => {
    const taxesArray = [];

    cart?.items?.forEach((item) => {
        taxesArray.push(...item.taxesNew);
        item?.modifiers?.forEach((modifier) => {
            taxesArray.push(...modifier.taxesNew);
        });
    });

    if (taxesArray?.length) {
        const groupedData = groupAndSumTaxAmountV3(taxesArray);
        cart.taxesNew = groupedData;
    } else {
        cart.taxesNew = [];
    }
    return cart;
};

const calculateCartTaxesV3 = ({ cartItem, subTotalAfterDiscount }) => {
    // Make a deep copy of the cartItem to avoid modifying the original object
    cartItem = JSON.parse(JSON.stringify(cartItem));

    // Initialize tax-related variables
    let inclusiveTaxTotal = 0,
        exclusiveTaxTotal = 0;

    // If item not taxable then all taxes will be 0
    if (cartItem?.isItemTaxable === false) {
        cartItem.inclusiveTaxTotal = inclusiveTaxTotal;
        cartItem.exclusiveTaxTotal = exclusiveTaxTotal;
        cartItem.vat = 0;
        return cartItem;
    }

    // Calculate inclusive taxes
    let sumOfTaxRate = 0;
    cartItem?.taxesNew?.forEach((tax) => {
        const isTaxInclusive = tax?.isTaxInclusive;
        if (isTaxInclusive) {
            sumOfTaxRate += tax?.taxRate;
        }
    });

    inclusiveTaxTotal = calculateInclusiveTax(
        subTotalAfterDiscount,
        sumOfTaxRate,
    );

    cartItem?.taxesNew?.forEach((tax) => {
        const isTaxInclusive = tax?.isTaxInclusive;
        if (isTaxInclusive) {
            const taxAmount =
                (subTotalAfterDiscount * tax.taxRate) / (100 + sumOfTaxRate);
            tax.taxAmount = taxAmount;
        }
    });

    // Update subtotal
    subTotalAfterDiscount -= inclusiveTaxTotal;

    // Calculate exclusive taxes
    cartItem?.taxesNew?.forEach((tax) => {
        const isTaxExclusive = tax?.isTaxInclusive === false;
        if (isTaxExclusive) {
            const taxAmount = calculateExclusiveTax(
                subTotalAfterDiscount,
                tax?.taxRate,
            );
            exclusiveTaxTotal += taxAmount;
            tax.taxAmount = taxAmount;
        }
    });

    // Update clonedCartItem with tax properties
    cartItem.inclusiveTaxTotal = Math.max(inclusiveTaxTotal, 0);
    cartItem.exclusiveTaxTotal = Math.max(exclusiveTaxTotal, 0);
    cartItem.vat = 0;

    // Return the updated clonedCartItem
    return cartItem;
};

const calculateExclusiveTax = (total, taxPercentage) => {
    return (total * taxPercentage) / 100;
};

const calculateInclusiveTax = (total, taxPercentage) => {
    return total * (taxPercentage / (100 + taxPercentage));
};

const groupAndSumTaxAmountV3 = (data) => {
    const groupedData = {};

    data.forEach((item) => {
        const taxId = item.taxRef;

        if (groupedData[taxId]) {
            groupedData[taxId].taxAmount += item.taxAmount;
        } else {
            groupedData[taxId] = {
                taxRef: taxId,
                taxName: item?.taxName,
                taxRate: item?.taxRate,
                isTaxInclusive: item?.isTaxInclusive,
                taxAmount: item?.taxAmount,
            };
        }
    });

    return Object.values(groupedData);
};

/**
 *
 * @param {Object} vendor
 * @param {Number} amount
 * @param {String} serviceType
 * @returns
 */
const calculateTip = (amount, outlet, serviceType) => {
    if (!outlet || !outlet.settings[serviceType]) return null;
    const service = outlet.settings[serviceType];

    if (
        service.preSetTipType === "Percentage" &&
        service.preSetTipAmount >= 0
    ) {
        return {
            type: service.preSetTipType,
            value: service.preSetTipAmount,
            amount: +((service.preSetTipAmount * amount) / 100).toFixed(2),
        };
    } else if (
        service.preSetTipType === "Flat" &&
        service.preSetTipAmount >= 0
    ) {
        return {
            type: service.preSetTipType,
            value: service.preSetTipAmount,
            amount: +(service.preSetTipAmount || 0).toFixed(2) || 0,
        };
    }

    return null;
};

/**
 *
 * @param {Object} vendor
 * @param {Number} amount
 * @returns
 */
const calculatePlatformFee = (amount, outlet) => {
    if (!outlet) return 0;
    const noqFee = outlet.noqFee;
    let platformFee = 0;
    const platformFix = parseFloat(noqFee.platformFix);
    const platform = parseFloat(noqFee.platform);
    if (platform && platformFix) {
        platformFee = (platform * amount) / 100 + platformFix;
    } else if (platform) {
        platformFee = (platform * amount) / 100;
    } else if (platformFix) {
        platformFee = platformFix;
    }
    return +platformFee.toFixed(2);
};

/**
 *
 * @param {Object} vendor
 * @param {Number} amount
 * @param {String} serviceType
 * @returns
 */
const calculateServiceCharge = (amount, outlet, serviceType) => {
    if (!outlet || !outlet.settings[serviceType]) return 0;
    const service = outlet.settings[serviceType];
    let serviceFee = 0;
    if (service.serviceChargeCutOff && amount >= service.serviceChargeCutOff) {
        return +serviceFee.toFixed(2); //NOTE: NOQ-2940
    }
    if (service.serviceChargeType === "Percentage") {
        serviceFee = (service?.serviceChargeAmount * amount) / 100;
    } else if (service.serviceChargeType === "Flat") {
        serviceFee = service?.serviceChargeAmount;
    }
    // if (service.serviceChargeCutOff && serviceFee > service.serviceChargeCutOff) {
    //   return +service.serviceChargeCutOff.toFixed(2);
    // } NOTE: NOQ-2940
    return +serviceFee.toFixed(2);
};

/**
 *
 * @param {Object} vendor
 * @param {Number} amount
 * @param {String} serviceType
 * @returns
 */
const calculateDeliveryFee = (
    outlet,
    amount,
    serviceType,
    offSiteDelivery = null,
) => {
    if (serviceType !== "onsiteDelivery" && serviceType !== "offsiteDelivery")
        return 0;
    if (!outlet || !outlet.settings[serviceType]) return 0;
    const service = outlet.settings[serviceType];
    if (service.deliveryFeeCutOff && amount >= service.deliveryFeeCutOff) {
        return 0.0;
    }
    if (serviceType === "offsiteDelivery") {
        return offSiteDelivery;
    }
    let deliveryFee = 0;
    if (service.deliveryFeeType === "Percentage") {
        deliveryFee = (service?.deliveryFeeAmount * amount) / 100;
    } else if (service.deliveryFeeType === "Flat") {
        deliveryFee = service?.deliveryFeeAmount;
    }
    // if (service.deliveryFeeCutOff && amount > service.deliveryFeeCutOff) {
    //   return +service.deliveryFeeCutOff.toFixed(2);
    // }
    return +deliveryFee.toFixed(2);
};

const formattedAddress = (formValues) => {
    let str = "";
    if (formValues.houseNumber) {
        str += formValues.houseNumber;
    }
    if (formValues.deliveryAddress) {
        str += `, ${formValues.deliveryAddress}`;
    }
    if (formValues.street) {
        str += `, ${formValues.street}`;
    }
    if (formValues.postalcode) {
        str += `, ${formValues.postalcode}`;
    }
    return str;
};

const mapRequest = (cart, checkoutDetails, extraData = {}) => {
    return {
        siteZones: cart.siteZones,
        tableNumber: checkoutDetails.tableNumber,
        vendorZoneName: checkoutDetails.vendorZoneName,
        name: checkoutDetails.name,
        email: checkoutDetails.email,
        deliveryAddress: {
            houseNumber: checkoutDetails.houseNumber,
            street: checkoutDetails.street,
            postalcode: checkoutDetails.postalcode,
        },
        address: formattedAddress(checkoutDetails),
        specialInstructions: checkoutDetails.specialInstructions,
        contactNumber: checkoutDetails.mobileNumber
            ? checkoutDetails.mobileNumber
            : null,
        formattedContactNumber: checkoutDetails.mobileNumber
            ? `+${checkoutDetails.mobileNumber}`
            : null,
        note: checkoutDetails.note ? checkoutDetails.note : "",
        newsLetter: checkoutDetails.newsLetter || false,
        userZoneIdentifier: cart.locationIdentifier
            ? cart.locationIdentifier
            : null,
        deliveryDate: checkoutDetails.deliveryDate,
        cardData: extraData?.cardData || null,
        paymentMethodType: extraData?.paymentMethodType || "CARD",
        paymentIntentConfirm: extraData?.paymentIntentConfirm || null,
        paymentGateway: extraData?.paymentIntentConfirm ? "stripe" : "adyen",
    };
};

export const createRequestBody = (
    basket,
    outletId,
    serviceData,
    checkoutDetails,
    extraData,
) => {
    let userRef = null;
    try {
        const user = JSON.parse(localStorage.getItem("user")) || {};
        userRef = user._id || null;
    } catch (err) {
        console.log("User not found!");
    }
    if (basket.isMultiOrder) {
        if (basket.loyalty && basket.loyaltyUsed) {
            basket.loyaltyRef = basket.loyalty.loyaltyRef;
            basket.redeemPoint = parseInt(
                basket.loyaltyUsed / basket.loyalty.redeemValue,
            );
            delete basket.loyalty;
            delete basket.loyaltyUsed;
        }

        if (!checkoutDetails) {
            return {
                ...basket,
                ...serviceData,
                deliveryFee: basket.deliveryFee || 0,
                deliveryDate: basket.deliveryDate || new Date(),
                isMultiOrder: basket.isMultiOrder,
                currency: (basket.carts || [])[0].currency || "GBP",
                userRef,
                carts: basket.carts.map((basket) => {
                    const cart = basketToRequest(
                        { ...basket, site: basket.site },
                        serviceData,
                    );
                    return {
                        ...cart,
                        itemBasedPromotion: basket?.itemBasedPromotion?.length
                            ? cart.itemBasedPromotion
                            : [],
                        isMultiOrder: basket.isMultiOrder,
                    };
                }),
            };
        }
        return {
            cart: {
                ...basket,
                ...serviceData,
                deliveryFee: basket.deliveryFee || 0,
                deliveryDate: basket.deliveryDate || new Date(),
                isMultiOrder: basket.isMultiOrder,
                currency: (basket.carts || [])[0].currency || "GBP",
                userRef,
                carts: basket.carts.map((basket) => {
                    const cart = basketToRequest({ ...basket }, serviceData);
                    return {
                        ...cart,
                        itemBasedPromotion: basket?.itemBasedPromotion?.length
                            ? cart.itemBasedPromotion
                            : [],
                        isMultiOrder: basket.isMultiOrder,
                    };
                }),
            },
            ...mapRequest(basket, checkoutDetails, extraData),
        };
    }
    let singleBasket = basket.carts ? basket.carts[0] : basket;
    if (basket.loyalty && basket.loyaltyUsed) {
        singleBasket.loyaltyRef = basket.loyalty.loyaltyRef;
        singleBasket.redeemPoint = parseInt(
            basket.loyaltyUsed / basket.loyalty.redeemValue,
        );
        delete singleBasket.loyalty;
        delete singleBasket.loyaltyUsed;
    }

    const cart = basketToRequest(
        {
            ...singleBasket,
            itemBasedPromotion: basket?.itemBasedPromotion?.length
                ? singleBasket.itemBasedPromotion
                : [],
            _id: basket._id,
            site: basket.site,
            vendorRef: outletId,
        },
        serviceData,
    );

    if (!checkoutDetails) return cart;
    return {
        cart: cart,
        isMultiOrder: basket.isMultiOrder,
        ...mapRequest(basket, checkoutDetails, extraData),
    };
};

export const mapOrderRequest = (basket, serviceData) => {
    let userRef = null;
    try {
        const user = JSON.parse(localStorage.getItem("user")) || {};
        userRef = user._id || null;
    } catch (err) {
        console.log("User not found!");
    }
    let newCart = {
        ...basket,
        ...serviceData,
        deliveryFee: basket.deliveryFee || 0,
        deliveryDate: basket.deliveryDate || new Date(),
        currency: basket.currency || "gbp",
        type: "WEB",
        userRef,
    };
    return newCart;
};
export const createOrderRequestBody = (
    basket,
    serviceData,
    checkoutDetails,
    extraData,
) => {
    if (basket.isMultiOrder) {
        return {
            basket: basket,
            carts: basket.carts.map((cart) => {
                return mapOrderRequest({ ...cart }, serviceData);
            }),
            ...mapRequest(basket, checkoutDetails, extraData),
        };
    }
    let singleBasket = basket.carts ? basket.carts[0] : basket;
    const cart = mapOrderRequest(
        { ...singleBasket, _id: basket._id },
        serviceData,
    );

    return {
        basket: cart,
        isMultiOrder: basket.isMultiOrder,
        ...mapRequest(basket, checkoutDetails, extraData),
    };
};

export const camelToTitleCase = (str = "") => {
    return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
    });
};

export const createUserOrder = async (reqData) => {
    const body = {
        cart: reqData.basket,
        cardData: reqData?.cardData || null,
        paymentIntent:
            reqData?.paymentIntentConfirm &&
            reqData?.paymentIntentConfirm.paymentIntent
                ? reqData?.paymentIntentConfirm.paymentIntent
                : null,
        sessionId: reqData?.sessionId ? reqData.sessionId : null,
        metricId: reqData?.metricId ? reqData.metricId : null,
        deviceId: reqData?.deviceId ? reqData.deviceId : null,
        paymentGateway: reqData.paymentGateway,
        siteZones: reqData.siteZones,
        tableNumber: reqData.tableNumber,
        vendorZoneName: reqData.vendorZoneName,
        name: reqData.name,
        email: reqData.email,
        deliveryAddress: reqData.deliveryAddress,
        address: reqData.address,
        specialInstructions: reqData.specialInstructions,
        contactNumber: reqData.contactNumber ? reqData.contactNumber : null,
        formattedContactNumber: reqData.contactNumber
            ? `+${reqData.contactNumber}`
            : null,
        note: reqData.note ? reqData.note : "",
        paymentMethodType: reqData.paymentMethodType,
        newsLetter: reqData.newsLetter,
        userZoneIdentifier: reqData.userZoneIdentifier,
        deliveryDate: reqData.deliveryDate,
    };

    if (reqData.paymentGateway === "stripe") {
        const userOrder = await apiService
            .createOrder(body)
            .catch((err) => err);
        return userOrder;
    } else {
        const userOrder = await apiService
            .createAdyenOrder(body)
            .catch((err) => err);
        return userOrder;
    }
};

export const currencyMap = {
    AED: "د.إ",
    AFN: "؋",
    ALL: "L",
    AMD: "֏",
    ANG: "ƒ",
    AOA: "Kz",
    ARS: "$",
    AUD: "$",
    AWG: "ƒ",
    AZN: "₼",
    BAM: "KM",
    BBD: "$",
    BDT: "৳",
    BGN: "лв",
    BHD: ".د.ب",
    BIF: "FBu",
    BMD: "$",
    BND: "$",
    BOB: "$b",
    BOV: "BOV",
    BRL: "R$",
    BSD: "$",
    BTC: "₿",
    BTN: "Nu.",
    BWP: "P",
    BYN: "Br",
    BYR: "Br",
    BZD: "BZ$",
    CAD: "$",
    CDF: "FC",
    CHE: "CHE",
    CHF: "CHF",
    CHW: "CHW",
    CLF: "CLF",
    CLP: "$",
    CNH: "¥",
    CNY: "¥",
    COP: "$",
    COU: "COU",
    CRC: "₡",
    CUC: "$",
    CUP: "₱",
    CVE: "$",
    CZK: "Kč",
    DJF: "Fdj",
    DKK: "kr",
    DOP: "RD$",
    DZD: "دج",
    EEK: "kr",
    EGP: "£",
    ERN: "Nfk",
    ETB: "Br",
    ETH: "Ξ",
    EUR: "€",
    FJD: "$",
    FKP: "£",
    GBP: "£",
    GEL: "₾",
    GGP: "£",
    GHC: "₵",
    GHS: "GH₵",
    GIP: "£",
    GMD: "D",
    GNF: "FG",
    GTQ: "Q",
    GYD: "$",
    HKD: "$",
    HNL: "L",
    HRK: "kn",
    HTG: "G",
    HUF: "Ft",
    IDR: "Rp",
    ILS: "₪",
    IMP: "£",
    INR: "₹",
    IQD: "ع.د",
    IRR: "﷼",
    ISK: "kr",
    JEP: "£",
    JMD: "J$",
    JOD: "JD",
    JPY: "¥",
    KES: "KSh",
    KGS: "лв",
    KHR: "៛",
    KMF: "CF",
    KPW: "₩",
    KRW: "₩",
    KWD: "KD",
    KYD: "$",
    KZT: "₸",
    LAK: "₭",
    LBP: "£",
    LKR: "₨",
    LRD: "$",
    LSL: "M",
    LTC: "Ł",
    LTL: "Lt",
    LVL: "Ls",
    LYD: "LD",
    MAD: "MAD",
    MDL: "lei",
    MGA: "Ar",
    MKD: "ден",
    MMK: "K",
    MNT: "₮",
    MOP: "MOP$",
    MRO: "UM",
    MRU: "UM",
    MUR: "₨",
    MVR: "Rf",
    MWK: "MK",
    MXN: "$",
    MXV: "MXV",
    MYR: "RM",
    MZN: "MT",
    NAD: "$",
    NGN: "₦",
    NIO: "C$",
    NOK: "kr",
    NPR: "₨",
    NZD: "$",
    OMR: "﷼",
    PAB: "B/.",
    PEN: "S/.",
    PGK: "K",
    PHP: "₱",
    PKR: "₨",
    PLN: "zł",
    PYG: "Gs",
    QAR: "﷼",
    RMB: "￥",
    RON: "lei",
    RSD: "Дин.",
    RUB: "₽",
    RWF: "R₣",
    SAR: "﷼",
    SBD: "$",
    SCR: "₨",
    SDG: "ج.س.",
    SEK: "kr",
    SGD: "S$",
    SHP: "£",
    SLL: "Le",
    SOS: "S",
    SRD: "$",
    SSP: "£",
    STD: "Db",
    STN: "Db",
    SVC: "$",
    SYP: "£",
    SZL: "E",
    THB: "฿",
    TJS: "SM",
    TMT: "T",
    TND: "د.ت",
    TOP: "T$",
    TRL: "₤",
    TRY: "₺",
    TTD: "TT$",
    TVD: "$",
    TWD: "NT$",
    TZS: "TSh",
    UAH: "₴",
    UGX: "USh",
    USD: "$",
    UYI: "UYI",
    UYU: "$U",
    UYW: "UYW",
    UZS: "лв",
    VEF: "Bs",
    VES: "Bs.S",
    VND: "₫",
    VUV: "VT",
    WST: "WS$",
    XAF: "FCFA",
    XBT: "Ƀ",
    XCD: "$",
    XOF: "CFA",
    XPF: "₣",
    XSU: "Sucre",
    XUA: "XUA",
    YER: "﷼",
    ZAR: "R",
    ZMW: "ZK",
    ZWD: "Z$",
    ZWL: "$",
};
export const restructureUpsellingItems = (data) => {
    return data.map((store) => {
        store.menu.forEach((record) => {
            record.items.sort((itemA, itemB) => {
                return itemA.itemPrice - itemB.itemPrice;
            });
        });
        return {
            ...store,
            menu: store.menu.map((menuRecord) => {
                return {
                    ...menuRecord,
                    items: menuRecord.items
                        .map((item) => {
                            let tempItem = {
                                ...item.item,
                                ...item,
                                price: item.itemPrice,
                                assignedModifiers:
                                    item.assignedModifiers &&
                                    item.assignedModifiers.length
                                        ? item.assignedModifiers.map(
                                              (modifier) =>
                                                  rearrangeModifier(modifier),
                                          )
                                        : [],
                                variants: item.item.variants
                                    ? item.item.variants
                                    : [],
                                subTotal: item.itemPrice,
                                category: {
                                    menuCategoryRef: item.menuCategoryRef,
                                    categoryName: menuRecord.categoryName,
                                },
                                itemName: item.itemDisplayName,
                                menu: { ...item.menuRefData },
                                menuGroup: {
                                    menuGroupRef: menuRecord.menuGroupRef,
                                },
                                printerRef:
                                    item.printer && item.printer.length
                                        ? item.printer[0].printerRef
                                        : null,
                                assignedPrinters:
                                    item.printer && item.printer.length
                                        ? item.printer[0].assignedPrinters || []
                                        : [],
                                kdsRef:
                                    item.kds && item.kds.length
                                        ? item.kds[0].kdsRef
                                        : null,
                                kdsGroupRef:
                                    item.kds && item.kds.length
                                        ? item.kds[0].kdsGroupRef
                                        : null,
                            };
                            delete tempItem.item;
                            delete tempItem.menuRefData;
                            return tempItem;
                        })
                        .sort((a, b) => a.itemPrice - b.itemPrice),
                };
            }),
        };
    });
};

export const AUTOMATED_PROMO_PROMOTION = "automatedPromo";
export const MEMBERSHIP_PROMO_PROMOTION = "save";
export const PROMO_CODE_PROMOTION = "promoCode";

export const getAutomatedPromotion = (promotionList) => {
    if (promotionList && promotionList.length) {
        return promotionList.find(
            (promo) =>
                promo?.promotionRef?.promotionType ===
                AUTOMATED_PROMO_PROMOTION,
        );
    }
    return {};
};

export const mapMenuItemFromPromoItems = (categoriesData, promotionItems) => {
    let categoriesItems = [];
    let allPromotionItems = [];
    let existingPromoItemsInMenu = [];
    if (categoriesData && categoriesData.length) {
        for (const category of categoriesData) {
            if (category && category.items && category.items.length) {
                categoriesItems = [...categoriesItems, ...category.items];
            }
        }
    }

    if (categoriesItems?.length && promotionItems?.length) {
        existingPromoItemsInMenu = promotionItems.map((promoItem) => {
            return {
                ...promoItem,
                menuPromotions: (promoItem?.menuPromotions || []).filter(
                    (menuItem) =>
                        categoriesItems.findIndex(
                            (catItem) => catItem.itemRef === menuItem.itemRef,
                        ) > -1,
                ),
            };
        });
    }

    if (existingPromoItemsInMenu && existingPromoItemsInMenu.length) {
        for (const promoItem of existingPromoItemsInMenu) {
            if (
                promoItem &&
                promoItem.menuPromotions &&
                promoItem.menuPromotions.length
            ) {
                allPromotionItems = [
                    ...allPromotionItems,
                    ...promoItem.menuPromotions,
                ];
            }
        }
    }
    const syncedCategoriesItemsWithPromo = categoriesItems?.filter(
        (catItem) =>
            allPromotionItems.findIndex(
                (promoItem) => promoItem?.itemRef === catItem?.itemRef,
            ) > -1,
    );

    if (
        syncedCategoriesItemsWithPromo &&
        syncedCategoriesItemsWithPromo.length
    ) {
        return existingPromoItemsInMenu
            ?.filter((promo) => promo.menuPromotions.length > 0)
            ?.map((promoItem) => {
                return {
                    ...promoItem,
                    menuPromotions: promoItem?.menuPromotions?.map(
                        (menuItem) => {
                            const foundItemIndex =
                                syncedCategoriesItemsWithPromo.findIndex(
                                    (catItem) =>
                                        catItem?.itemRef === menuItem?.itemRef,
                                );
                            return {
                                ...menuItem,
                                ...syncedCategoriesItemsWithPromo[
                                    foundItemIndex
                                ],
                                assignedItemBasePromocode: {
                                    promocodeRef:
                                        promoItem?.promotionDetails?._id ||
                                        null,
                                },
                            };
                        },
                    ),
                };
            });
    } else {
        return [];
    }
};

export const injectPromotionToMenuItems = (categories, promotionItems) => {
    let allPromoItems = [];
    (promotionItems || []).forEach((promoData) => {
        return (promoData?.menuPromotions || []).forEach((menuItem) => {
            allPromoItems.push({
                ...menuItem,
                promotion: promoData?.promotionDetails,
            });
        });
    });
    if (allPromoItems && allPromoItems.length) {
        return categories.map((cat) => {
            return {
                ...cat,
                items: cat.items.map((item) => {
                    const foundItemIndex = allPromoItems.findIndex(
                        (itemData) => itemData?.itemRef === item?.itemRef,
                    );
                    return {
                        ...item,
                        assignedItemBasePromocode: {
                            promocodeRef:
                                allPromoItems[foundItemIndex]?.promotion?._id ||
                                null,
                        },
                    };
                }),
            };
        });
    } else {
        return categories;
    }
};

export const getPromotionsToApply = async (
    basket,
    user,
    siteData,
    outletData,
) => {
    const promotions = {
        membershipPromotion: {},
        automatedPromotion: {},
        itemBasedPromotion: [],
    };
    if (basket && basket.carts && basket.carts.length) {
        const itemPromotionsRef = basket.carts
            .map((cart) => {
                return (cart?.items || [])
                    .filter((cartItem) => {
                        return cartItem?.assignedItemBasePromocode
                            ?.promocodeRef;
                    })
                    .map((item) => {
                        return item?.assignedItemBasePromocode?.promocodeRef;
                    })
                    ?.flat(1);
            })
            ?.flat(1);
        const uniquePromotionsRef = [...new Set(itemPromotionsRef)];
        if (uniquePromotionsRef?.length) {
            try {
                const itemBasedPromotions =
                    await apiService.getItemBasedPromotionByPromoIds(
                        uniquePromotionsRef,
                    );
                promotions.itemBasedPromotion = itemBasedPromotions;
            } catch (err) {
                promotions.itemBasedPromotion = [];
            }
        }
    }

    if (
        user &&
        user._id &&
        siteData &&
        siteData.assignedPromotions &&
        siteData.assignedPromotions.length
    ) {
        try {
            const promoSavePromotion = await getPromotion(
                basket,
                siteData.assignedPromotions,
                user?._id,
            );
            promotions.membershipPromotion = promoSavePromotion;
        } catch (err) {
            promotions.membershipPromotion = {};
        }
    }

    try {
        const automatedPromoPromotion = await findAutomatedPromotionToApply(
            siteData,
            outletData,
            basket,
        );
        promotions.automatedPromotion = automatedPromoPromotion;
    } catch (err) {
        promotions.automatedPromotion = {};
    }

    return promotions;
};

export const orderContainerColor = (status) => {
    let backgroundColor = "";
    switch (status) {
        case "COMPLETED":
            backgroundColor = "#CCD5AE";
            break;
        case "PAYMENT PENDING":
        case "PENDING":
        case "PAYMENT INPROGRESS":
            backgroundColor = "#FEFAE0";
            break;
        case "ACCEPTED":
        case "READY FOR PICKUP":
        case "DISPATCHED":
            backgroundColor = "#E9EDC9";
            break;
        case "REFUNDED":
        case "PARTIAL REFUND":
            backgroundColor = "#FFCF96";
            break;
        case "PAYMENT FAILED":
        case "REFUSED":
        case "UNPAID":
        case "CANCELLED":
            backgroundColor = "#F38181";
            break;
        default:
            backgroundColor = "#F5F7F8";
            break;
    }

    return backgroundColor;
};

export const calculateAllCartLevelTotal = (cart, reqData) => {
    const { total, promotion, itemBasedPromotion } = reqData;

    let subTotal = calculateSubTotal(cart.items);

    let promotionAmount = 0;
    const subTotalPercentage = percentageOfFlatAmt(subTotal, total) || 0;

    let updatedPromotions = itemBasedPromotion;
    if (updatedPromotions?.length) {
        updatedPromotions = updatedPromotions?.map((promo) => {
            promo.amount = 0;
            return { ...promo };
        });
        cart?.items?.forEach((item) => {
            const promoIndex = itemBasedPromotion?.findIndex(
                (p) =>
                    String(p.promotionRef) ===
                    String(item?.assignedItemBasePromocode?.promocodeRef),
            );
            if (promoIndex > -1) {
                const promoAmount =
                    item.subTotal *
                    (updatedPromotions[promoIndex]?.value / 100);

                promotionAmount += promoAmount;
                updatedPromotions[promoIndex].amount =
                    (updatedPromotions[promoIndex].amount || 0) + promoAmount;
            }
        });
        updatedPromotions = updatedPromotions?.filter(
            (promo) => promo.amount > 0,
        );
    }
    cart.itemBasedPromotion = updatedPromotions;

    if (promotion && Object.keys(promotion).length) {
        let tempPromotionAmount = (promotion.amount * subTotalPercentage) / 100;
        tempPromotionAmount = Math.floor(tempPromotionAmount * 100) / 100;
        promotionAmount += tempPromotionAmount;
        cart.promotion = { ...promotion, amount: tempPromotionAmount };
    }

    return cart;
};

export const calculateSubTotal = (items) => {
    let subtotal = 0;

    items.forEach((item) => {
        subtotal += item.subTotal;
    });

    return subtotal;
};

const percentageOfFlatAmt = (flatAmount, subTotal) => {
    return (Number(flatAmount) / Number(subTotal)) * 100;
};

export const getAllMergedCustomAttributes = (menu) => {
    menu.forEach((category) => {
        category.items.sort((itemA, itemB) => {
            return itemA.displayOrder - itemB.displayOrder;
        });
    });

    let dynamicCustomAttributes = {};

    menu.forEach((category) => {
        if (category && category.items && category.items.length) {
            category.items.forEach((item) => {
                if (
                    item.item.customAttributes &&
                    item.item.customAttributes.length
                ) {
                    return item.item.customAttributes.forEach((attribute) => {
                        if (
                            attribute.customAttributeRef &&
                            attribute.attributeValues &&
                            attribute.attributeValues.length
                        ) {
                            const validCustomAttributes =
                                attribute.attributeValues.filter(
                                    (attr) =>
                                        attr?.name?.trim() !== "" ||
                                        attr?.name !== "",
                                );
                            if (
                                !dynamicCustomAttributes[
                                    attribute.customAttributeRef
                                ]
                            ) {
                                const tempAttributeValues =
                                    attribute.attributeValues
                                        .filter(
                                            (attr) =>
                                                attr?.name?.trim() !== "" ||
                                                attr?.name !== "",
                                        )
                                        ?.map((attrVal) => attrVal?.name);
                                dynamicCustomAttributes[
                                    attribute.customAttributeRef
                                ] = {
                                    attributeName: attribute.attributeName,
                                    attributeValues: tempAttributeValues,
                                    checkedValues: new Array(
                                        tempAttributeValues.length,
                                    ).fill(false),
                                    customAttributeRef:
                                        attribute.customAttributeRef,
                                    attributeObjects: validCustomAttributes,
                                    isExcluded: attribute?.isExcluded || false,
                                };
                            } else {
                                const trimmedValues = attribute.attributeValues
                                    .filter(
                                        (attr) =>
                                            attr?.name?.trim() !== "" ||
                                            attr?.name !== "",
                                    )
                                    ?.map((attrVal) => attrVal?.name);
                                const uniqueCustomAttributes = [
                                    ...new Set([
                                        ...dynamicCustomAttributes[
                                            attribute.customAttributeRef
                                        ].attributeValues,
                                        ...trimmedValues,
                                    ]),
                                ];
                                const originalAttributeValues = [
                                    ...dynamicCustomAttributes[
                                        attribute.customAttributeRef
                                    ].attributeObjects,
                                    ...validCustomAttributes,
                                ];
                                const uniqueNamesMap = new Map();
                                dynamicCustomAttributes[
                                    attribute.customAttributeRef
                                ].attributeValues = uniqueCustomAttributes;
                                dynamicCustomAttributes[
                                    attribute.customAttributeRef
                                ].checkedValues = new Array(
                                    uniqueCustomAttributes.length,
                                ).fill(false);
                                dynamicCustomAttributes[
                                    attribute.customAttributeRef
                                ].attributeObjects =
                                    originalAttributeValues.filter((obj) => {
                                        if (!uniqueNamesMap.has(obj.name)) {
                                            uniqueNamesMap.set(obj.name, true);
                                            return true;
                                        }
                                        return false;
                                    });
                                dynamicCustomAttributes[
                                    attribute.customAttributeRef
                                ].isExcluded = attribute?.isExcluded || false;
                            }
                        } else {
                            return;
                        }
                    });
                } else {
                    return;
                }
            });
        } else {
            return;
        }
    });

    return Object.values(dynamicCustomAttributes);
};

export const createValuesIdsArray = (arr, boolArr, isExcluded) => {
    let includeItemRes = [];
    let exludeItemRes = [];
    for (let i = 0; i < boolArr.length; i++) {
        if (boolArr[i] && !isExcluded) {
            includeItemRes.push(arr[i]?._id);
        } else if (boolArr[i] && isExcluded) {
            exludeItemRes.push(arr[i]?._id);
        }
    }
    return {
        includeItemRes,
        exludeItemRes,
    };
};

export const getAdyenMerchantID = (currency) => {
    currency = (currency || "GBP").toUpperCase()?.trim();
    let merchantId = apiConsts.gatewayMerchantId;
    switch (currency) {
        case "USD":
            merchantId = apiConsts.gatewayMerchantIdUS;
            break;
        default:
            merchantId = apiConsts.gatewayMerchantId;
            break;
    }
    return merchantId;
};

const cumulateMasterCartTaxesV3 = (masterCart) => {
    const taxesArray = [];

    masterCart?.carts?.forEach((cart) => {
        cart?.items?.forEach((item) => {
            if (item?.taxesNew) {
                taxesArray.push(...item.taxesNew);
            }

            if (item?.modifiers) {
                item?.modifiers?.forEach((modifier) => {
                    if (modifier.taxesNew) {
                        taxesArray.push(...modifier.taxesNew);
                    }
                });
            }
        });
    });

    if (taxesArray?.length) {
        const groupedData = groupAndSumTaxAmountV3(taxesArray);
        masterCart.taxesNew = groupedData;
        masterCart.inclusiveTaxTotal = masterCart?.taxesNew?.reduce(
            (acc, tax) => {
                if (tax.isTaxInclusive) {
                    acc += tax.taxAmount;
                }
                return acc;
            },
            0,
        );

        masterCart.exclusiveTaxTotal = masterCart?.taxesNew?.reduce(
            (acc, tax) => {
                if (!tax.isTaxInclusive) {
                    acc += tax.taxAmount;
                }
                return acc;
            },
            0,
        );
    } else {
        masterCart.taxesNew = [];
        masterCart.inclusiveTaxTotal = 0;
        masterCart.exclusiveTaxTotal = 0;
    }

    return masterCart;
};
