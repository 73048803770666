import { useNavigate } from "react-router";
import {
    AiFillEye,
    AiFillEyeInvisible,
    AiOutlineArrowLeft,
} from "react-icons/ai";
import CustomButton from "../custom-button";
import "./register.scss";
import * as yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { signupCustomer } from "../../services/authenticate.service";
import { notify, notifySuccess } from "../../common/utils";
import { useState } from "react";
import { Loader } from "../custom-loader";
import constants from "../../constants/constants";

const Register = () => {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
    const customer = {
        registerName: "",
        registerLastName: "",
        registerMobileNUm: "",
        registerEmail: "",
        registerPassword: "",
        registerConfirmPassword: "",
        checkboxValue: false,
    };

    const validationSchema = yup.object().shape({
        registerName: yup.string().trim().required().label("First Name"),
        registerLastName: yup.string().trim().required().label("Last Name"),
        registerMobileNUm: yup
            .string()
            .label("Mobile Number")
            .nullable()
            .matches(constants.REGEX.MOBILE_NUMBER, 'Mobile number is not valid'),
        registerEmail: yup
            .string()
            .trim()
            .email("Invalid Email!")
            .required()
            .label("Email"),
        registerPassword: yup
            .string()
            .trim()
            .required()
            .label("Password")
            .min(6, "The password length should be a minimum of 6 characters.")
            .max(
                16,
                "The password length should be a maximum of 16 characters.",
            )
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[a-zA-Z]).{6,16}$/,
                "The password should contain at least one capital letter, one lowercase letter, one digit, one alphabetical character, and be between 6 and 16 characters long.",
            ),
        registerConfirmPassword: yup
            .string()
            .oneOf([yup.ref("registerPassword"), null], "Password must match")
            .trim()
            .required()
            .label("Repeat Password"),
    });

    const onSubmit = (values) => {
        const payload = {
            firstName: values.registerName,
            lastName: values.registerLastName,
            email: values.registerEmail,
            contactNo: values.registerMobileNUm
                ? values.registerMobileNUm
                : null,
            password: values.registerPassword,
            newsletterSignup: values.checkboxValue,
            user: "CUSTOMER",
        };
        setLoading(true);
        signupCustomer(payload)
            .then((res) => {
                notifySuccess("Registered Successfully!");
                navigate(`/login`, { replace: true });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notify(err.message);
            });
    };

    return (
        <>
            {loading && <Loader />}
            <div className="register-flex-col">
                <div className="register-header">
                    <div className="mr-1">
                        <AiOutlineArrowLeft
                            size={35}
                            onClick={() => {
                                navigate(-1);
                            }}
                        />
                    </div>
                    <div className="header-title">Register</div>
                </div>
                <div>
                    <Formik
                        initialValues={customer}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {(props) => (
                            <div className="form-container">
                                <div className="form-item">
                                    <label className="form-label">
                                        First Name*
                                    </label>
                                    <input
                                        {...props.getFieldProps("registerName")}
                                        name="registerName"
                                        type="text"
                                        placeholder="Insert First Name"
                                        className="form-input"
                                    />
                                    <ErrorMessage
                                        name="registerName"
                                        component="div"
                                        className="form-error"
                                    />
                                </div>
                                <div className="form-item">
                                    <label className="form-label">
                                        Last Name*
                                    </label>
                                    <input
                                        {...props.getFieldProps(
                                            "registerLastName",
                                        )}
                                        name="registerLastName"
                                        type="text"
                                        placeholder="Insert Last Name"
                                        className="form-input"
                                    />
                                    <ErrorMessage
                                        name="registerLastName"
                                        component="div"
                                        className="form-error"
                                    />
                                </div>
                                <div className="form-item">
                                    <label className="form-label">
                                        Mobile Number
                                    </label>
                                    <input
                                        {...props.getFieldProps(
                                            "registerMobileNUm",
                                        )}
                                        name="registerMobileNUm"
                                        type="text"
                                        placeholder="Insert Mobile Number"
                                        className="form-input"
                                    />
                                    <ErrorMessage
                                        name="registerMobileNUm"
                                        component="div"
                                        className="form-error"
                                    />
                                </div>
                                <div className="form-item">
                                    <label className="form-label">Email*</label>
                                    <input
                                        {...props.getFieldProps(
                                            "registerEmail",
                                        )}
                                        name="registerEmail"
                                        type="email"
                                        placeholder="Insert Email"
                                        className="form-input"
                                        autoComplete={false}
                                    />
                                    <ErrorMessage
                                        name="registerEmail"
                                        component="div"
                                        className="form-error"
                                    />
                                </div>
                                <div className="form-item">
                                    <label className="form-label">
                                        Insert Password*
                                    </label>
                                    <div className="password-form-group">
                                        {showPassword ? (
                                            <AiFillEye
                                                className="icon"
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword,
                                                    )
                                                }
                                            />
                                        ) : (
                                            <AiFillEyeInvisible
                                                className="icon"
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword,
                                                    )
                                                }
                                            />
                                        )}
                                        <input
                                            {...props.getFieldProps(
                                                "registerPassword",
                                            )}
                                            name="registerPassword"
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            placeholder="********"
                                            className="form-input"
                                            autoComplete={false}
                                        />
                                    </div>
                                    <ErrorMessage
                                        name="registerPassword"
                                        component="div"
                                        className="form-error"
                                    />
                                </div>
                                <div className="form-item">
                                    <label className="form-label">
                                        Repeat Password*
                                    </label>
                                    <div className="password-form-group">
                                        {showConfirmPassword ? (
                                            <AiFillEye
                                                className="icon"
                                                onClick={() =>
                                                    setShowConfirmPassword(
                                                        !showConfirmPassword,
                                                    )
                                                }
                                            />
                                        ) : (
                                            <AiFillEyeInvisible
                                                className="icon"
                                                onClick={() =>
                                                    setShowConfirmPassword(
                                                        !showConfirmPassword,
                                                    )
                                                }
                                            />
                                        )}
                                        <input
                                            {...props.getFieldProps(
                                                "registerConfirmPassword",
                                            )}
                                            name="registerConfirmPassword"
                                            type={
                                                showConfirmPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            placeholder="********"
                                            className="form-input"
                                        />
                                    </div>
                                    <ErrorMessage
                                        name="registerConfirmPassword"
                                        component="div"
                                        className="form-error"
                                    />
                                </div>
                                <div className="form-checkbox">
                                    <input
                                        {...props.getFieldProps(
                                            "checkboxValue",
                                        )}
                                        id="checkboxValue"
                                        name="checkboxValue"
                                        type="checkbox"
                                        className="form-input"
                                    />
                                    <label className="form-label">
                                        Sign up for marketing communication to
                                        stay updated on news, promotions and
                                        events
                                    </label>
                                </div>
                                <div className="flex-align-center">
                                    <CustomButton
                                        className="btn-block btn-bw"
                                        type="submit"
                                        onClick={props.handleSubmit}
                                        disabled={loading}
                                    >
                                        Register
                                    </CustomButton>
                                </div>
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default Register;
