import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getOrders } from "../../services/api.service";
import { notify } from "../../common/utils";
import { Loader } from "../custom-loader";
import { getTime, getMonth } from "../../common/utils";
import { FormattedNumber } from "react-intl";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaAngleRight } from "react-icons/fa";
import "./yourorder.scss";

const YourOrders = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        setLoading(true);
        getOrders()
            .then((orders) => {
                setOrders(orders);
                setLoading(false);
            })
            .catch((err) => {
                notify(err);
                setLoading(false);
            });
    }, []);

    if (loading) return <Loader />;
    return (
        <div className="orders-container">
            <div className="orders-header">
                <AiOutlineArrowLeft
                    size={35}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
                <div className="header-title">
                    <span>Your Orders</span>
                </div>
            </div>
            <div className="orders-list">
                {orders.map((order) => {
                    return (
                        <Link
                            to={`${order._id}`}
                            state={{ from: "order-list" }}
                        >
                            <div className="item-card">
                                <div className="content">
                                    <h3>
                                        {order.vendor.displayName} -{" "}
                                        {order.status}
                                    </h3>
                                    <p>
                                        {order.items.length} Items -{" "}
                                        {!order?.prePaidOrder ? (
                                            <FormattedNumber
                                                value={order.totalAmount}
                                                currency={
                                                    order?.currency || "gbp"
                                                }
                                                style={`currency`}
                                                currencyDisplay="narrowSymbol"
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                            />
                                        ) : (
                                            "Prepaid order"
                                        )}
                                    </p>
                                    <p>
                                        {order.preOrder ? "Pre Order - " : null}
                                        {order.serviceType ===
                                            "onsiteCollection" ||
                                        order.serviceType ===
                                            "offsiteCollection" ||
                                        order.serviceType ===
                                            "onsiteExpress" ? (
                                            <>
                                                {" "}
                                                {order.status === "COMPLETED"
                                                    ? "Collected @ "
                                                    : "Collect @ "}{" "}
                                            </>
                                        ) : (
                                            <>
                                                {" "}
                                                {order.status === "COMPLETED"
                                                    ? "Delivered @ "
                                                    : "Deliver @ "}{" "}
                                            </>
                                        )}
                                        {getTime(new Date(order.deliveryDate))}{" "}
                                        -{" "}
                                        {getMonth(new Date(order.deliveryDate))}
                                    </p>
                                </div>
                                <span>
                                    <FaAngleRight size={30} />
                                </span>
                            </div>
                        </Link>
                    );
                })}
                {(!orders || orders.length === 0) && (
                    <h3> No, Order are available!</h3>
                )}
            </div>
        </div>
    );
};

export default YourOrders;
