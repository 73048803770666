import "../menu.scss";
import ItemCard from "./ItemCard";

export default function CategoryCard({
    category,
    inBasketCount,
    remainingQty,
    vendorRef,
}) {
    return (
        <div id={category.categoryName.trim()}>
            <div className="category-title">{category.categoryName}</div>
            <div className="separator separator-theme"></div>
            <div className="item-list">
                {category.items.map((item) => {
                    return (
                        <ItemCard
                            remainingQty={remainingQty}
                            itemData={{
                                ...item,
                                outletRef: item.outletRef || vendorRef,
                            }}
                            inBasket={inBasketCount[item._id] || 0}
                            key={item._id}
                        />
                    );
                })}
            </div>
        </div>
    );
}
