import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsMapFill } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import constants from "../../constants/constants";
import jwtService from "../../services/jwtService";
import CustomButton from "../custom-button";

const MainHeader = ({
    name,
    setRedirectURL,
    siteId,
    openModal,
    showBottomLine = false,
    showInfoButton = false,
    showAccountButton = false,
    showBackButton = false,
    redirectURL = -1,
    onBackClick = null,
    wrapperClass = "header-account",
}) => {
    const { state: pastParams } = useLocation();
    const navigate = useNavigate();
    let backButton = null;

    if ((pastParams && pastParams.showBack) || showBackButton) {
        backButton = (
            <AiOutlineArrowLeft
                size={35}
                onClick={() => {
                    if (onBackClick) onBackClick();
                    navigate(redirectURL);
                }}
            />
        );
    }
    let element = (
        <div className="header-left">
            {backButton}
            <div className="header-title">
                <span>{name}</span>
            </div>
        </div>
    );

    if (siteId || (showInfoButton && !showBackButton)) {
        if (!pastParams || !pastParams.showBack) {
            element = (
                <CustomButton className="btn-link p-0" onClick={openModal}>
                    <BsMapFill size={20} />
                    <span style={{ paddingLeft: "5px" }}>Info</span>
                </CustomButton>
            );
        }
    }

    return (
        <>
            <div
                className={`box-container custom-header-wrapper header ${wrapperClass}`}
            >
                {element}
                {showAccountButton ? (
                    <div>
                        <Link
                            to={`${
                                jwtService.getAccessToken() !== null
                                    ? `/${constants.ROUTES.CUSTOMER}`
                                    : "/welcome-page"
                            }`}
                            state={{ redirectURL: window.location.href }}
                        >
                            <div
                                className="header-account-flex"
                                onClick={() =>
                                    setRedirectURL
                                        ? setRedirectURL(
                                              window.location.href.replace(
                                                  window.location.origin,
                                                  "",
                                              ),
                                          )
                                        : {}
                                }
                            >
                                <span>Account</span>
                                <CgProfile size={20} />
                            </div>
                        </Link>
                    </div>
                ) : null}
            </div>
            {showBottomLine ? (
                <div className="separator separator-theme"></div>
            ) : null}
        </>
    );
};

export default MainHeader;
