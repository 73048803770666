import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "../../common/useQuery";
import { connect } from "react-redux";
import "./Feedback.scss";
import { Loader } from "../custom-loader";
import { apiService } from "../../services";
import { AiFillStar, AiOutlineArrowLeft, AiOutlineStar } from "react-icons/ai";
import { notify, notifySuccess } from "../../common/utils";
import CustomButton from "../custom-button";
import constants from "../../constants/constants";
import MainHeader from "../common/MainHeader";

const ratingArray = [1, 2, 3, 4, 5];

const FeedbackInput = ({ label, selectedRating, setSelectedRating }) => {
    // const [selectedRating, setSelectedRating] = useState(0);
    return (
        <div className="feedback-input-row">
            <div className="feedback-label">{label}</div>
            <div className="rating-group">
                {ratingArray.map((rating) => {
                    if (rating <= selectedRating) {
                        return (
                            <AiFillStar
                                onClick={() => setSelectedRating(rating)}
                                size={20}
                            />
                        );
                    } else {
                        return (
                            <AiOutlineStar
                                onClick={() => setSelectedRating(rating)}
                                size={20}
                            />
                        );
                    }
                })}
            </div>
        </div>
    );
};

function Feedback(props) {
    const navigate = useNavigate();
    const params = useParams();
    const query = useQuery();
    const mailLink = Boolean(query.get("mailLink"));
    const orderId = params.orderId;
    const [orderDetails, setOrderDetails] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [quality, setQuality] = useState(0);
    const [easeOfUseRating, setEaseOfUseRating] = useState(0);
    const [value, setValue] = useState(0);
    const [speedRating, setSpeedRating] = useState(0);
    // label changed to Service from Temperature
    const [service, setService] = useState(0);
    const [eventExperienceRating, setEventExperienceRating] = useState(0);
    // label changed to Presentation from Packaging
    const [presentation, setPresentation] = useState(0);
    const [recommendedRating, setRecommendedRating] = useState(0);
    const [comments, setComments] = useState("");

    useEffect(() => {
        if (orderId) {
            setLoading(true);
            apiService
                .getOrder(orderId)
                .then((res) => {
                    setOrderDetails(res);
                    if (props.user) {
                        apiService
                            .getFeedbackByOrder(res._id)
                            .then((feedback) => {
                                if (
                                    orderDetails.serviceType &&
                                    orderDetails.serviceType === "onsiteExpress"
                                ) {
                                    setEaseOfUseRating(
                                        feedback.easeOfUseRating,
                                    );
                                    setSpeedRating(feedback.speedRating);
                                    setEventExperienceRating(
                                        feedback.eventExperienceRating,
                                    );
                                    setRecommendedRating(
                                        feedback.recommendedRating,
                                    );
                                }
                                setQuality(feedback.qualityRating);
                                setValue(feedback.valueRating);
                                setService(feedback.serviceRating);
                                setPresentation(feedback.presentationRating);
                                setComments(feedback.description);
                                setLoading(false);
                            })
                            .catch((error) => {
                                console.error(error);
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    notify(err.message);
                });
        }
    }, []);

    const onSubmit = () => {
        const payload = {
            vendorRef: orderDetails.vendor._id,
            orderRef: orderDetails._id,
            userRef: props.user ? props.user._id : null,
            description: comments,
            qualityRating: quality,
            valueRating: value,
            serviceRating: service,
            presentationRating: presentation,
        };
        if (
            orderDetails.serviceType &&
            orderDetails.serviceType === "onsiteExpress"
        ) {
            payload["easeOfUseRating"] = easeOfUseRating;
            payload["speedRating"] = speedRating;
            payload["eventExperienceRating"] = eventExperienceRating;
            payload["recommendedRating"] = recommendedRating;
        }
        setLoading(true);
        if (props.user) {
            apiService
                .createFeedback(payload)
                .then((res) => {
                    notifySuccess("Feedback submitted!");
                    setLoading(false);
                    if (mailLink)
                        navigate(
                            `/${constants.ROUTES.ORDER}/${orderDetails._id}/${constants.ROUTES.FEEDBACK_REDIRECT}`,
                            {
                                state: { orderDetails },
                            },
                        );
                    else navigate(-1);
                })
                .catch((err) => {
                    notify(err);
                    setLoading(false);
                });
        } else {
            apiService
                .createGuestFeedback(payload)
                .then((res) => {
                    notifySuccess("Feedback submitted!");
                    setLoading(false);
                    if (mailLink)
                        navigate(
                            `/${constants.ROUTES.ORDER}/${orderDetails._id}/${constants.ROUTES.FEEDBACK_REDIRECT}`,
                            {
                                state: { orderDetails },
                            },
                        );
                    else navigate(-1);
                })
                .catch((err) => {
                    notify(err);
                    setLoading(false);
                });
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <div className="feedback-page">
                <MainHeader
                    name={"Your Feedback"}
                    showBackButton={!mailLink}
                    showBottomLine={true}
                />
                {/* <div className="box-container custom-header-wrapper header">
          <div className="header-left">
            {!mailLink ?  <AiOutlineArrowLeft size={35} onClick={() => navigate(-1)} /> : null }
            <div className="header-title">
              <span>Your Feedback</span>
            </div>
          </div>
        </div>
        <div className="separator separator-theme"></div> */}
                <div className="your-order-container your-order-header-info box-container">
                    <h2 className="order-place-title">
                        {orderDetails?.vendor?.displayName}
                    </h2>
                    <div className="your-order-order-info">
                        <h2 className="order-number">
                            Order ID: {orderDetails.orderNum}
                        </h2>
                        {orderDetails?.orderReferenceNumber ? (
                            <h2 className="order-number">
                                Order Reference:{" "}
                                {orderDetails.orderReferenceNumber}
                            </h2>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <hr className="m-0" />
                <div className="box-container">
                    {orderDetails.serviceType &&
                    orderDetails.serviceType === "onsiteExpress" ? (
                        <>
                            <FeedbackInput
                                label="How easy was it to use Express at the Scottish Open?"
                                selectedRating={easeOfUseRating}
                                setSelectedRating={setEaseOfUseRating}
                            />
                            <FeedbackInput
                                label="How would you rate the speed of ordering and fulfilment?"
                                selectedRating={speedRating}
                                setSelectedRating={setSpeedRating}
                            />
                            <FeedbackInput
                                label="Has Express enhanced your event experience?"
                                selectedRating={eventExperienceRating}
                                setSelectedRating={setEventExperienceRating}
                            />
                            <FeedbackInput
                                label="Would you recommend using Express to others?"
                                selectedRating={recommendedRating}
                                setSelectedRating={setRecommendedRating}
                            />
                        </>
                    ) : (
                        <>
                            <FeedbackInput
                                label="Quality"
                                selectedRating={quality}
                                setSelectedRating={setQuality}
                            />
                            <FeedbackInput
                                label="Value"
                                selectedRating={value}
                                setSelectedRating={setValue}
                            />
                            <FeedbackInput
                                label="Service"
                                selectedRating={service}
                                setSelectedRating={setService}
                            />
                            <FeedbackInput
                                label="Presentation"
                                selectedRating={presentation}
                                setSelectedRating={setPresentation}
                            />
                        </>
                    )}
                    <div className="feedback-label">Comments</div>
                    <textarea
                        name="textValue"
                        className="issue-input-text"
                        rows={3}
                        value={comments}
                        onChange={(event) => {
                            setComments(event.target.value);
                        }}
                    />
                    <CustomButton
                        className="btn-block btn-submit"
                        onClick={() => onSubmit()}
                    >
                        Send
                    </CustomButton>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, null)(Feedback);
