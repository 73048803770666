import { network } from "../common/network";
import apiConsts from "../constants/apiConstants";
import { notify } from "../common/utils";

export const signupCustomer = async (payload) => {
    // console.log(payload);
    const response = await network(
        `${apiConsts.customerURI}/signup`,
        "POST",
        payload,
    );
    if (response.success) return response.data;
    notify(response.message);
};

export const forgotPasswordCustomer = async (email) => {
    const response = await network(
        `${apiConsts.customerURI}/reset-password-request/${email}`,
    );
    if (response.success) return response.data;
    notify(response.message);
};

export const OAuthCustomer = async (
    socialType,
    returnUrl = window.location.href,
) => {
    window.location.href = `${apiConsts.serverURL}${apiConsts.customerURI}/auth/${socialType}/?returnUrl=${returnUrl}`;
    // window.open(`${apiConsts.serverURL}${apiConsts.customerURI}/auth/${socialType}/?returnUrl=${returnUrl}`, "_blank","noq_webapp_unique_tab");
};

export const getUserDetail = async () => {
    const response = await network(`${apiConsts.customerURI}/me`);
    if (response.success) return response.data;
    throw response.message;
};

export const updateCustomer = async (payload) => {
    // console.log(payload);
    const response = await network(
        `${apiConsts.customerURI}/update-profile`,
        "POST",
        payload,
    );
    if (response.success) return response.data;
    throw response.message;
};

export const deleteCustomer = async (payload = null) => {
    const response = await network(
        `${apiConsts.customerURI}/auth/delete`,
        "DELETE",
        payload,
    );
    return response.message;
};

export const activateMembership = async (payload) => {
    const response = await network(
        `${apiConsts.customerURI}/activate-membership`,
        "POST",
        payload,
    );
    if (response.success) return response.data;
    notify(response.message);
};

export const activateSiteMembership = async (payload) => {
    const response = await network(
        `${apiConsts.customerURI}/activate-site-membership`,
        "POST",
        payload,
    );
    if (response.success) return response.data;
    notify(response.message);
};
