import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { setUser } from "../../../store/actions/UserAction";
import { AiOutlineArrowLeft } from "react-icons/ai";
import * as yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { updateCustomer } from "../../../services/authenticate.service";
import { notify } from "../../../common/utils";
import { notifySuccess } from "../../../common/utils";
import { Loader } from "../../custom-loader";
import CustomButton from "../../custom-button";
import "../../register/register.scss";
import constants from "../../../constants/constants";

const Profile = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState({
        registerName: "",
        registerLastName: "",
        registerMobileNUm: "",
        registerEmail: "",
        checkboxValue: false,
    });

    const validationSchema = yup.object().shape({
        registerName: yup.string().trim().required().label("First Name"),
        registerLastName: yup.string().trim().required().label("Last Name"),
        registerMobileNUm: yup
            .string()
            .label("Mobile Number")
            .nullable()
            .matches(constants.REGEX.MOBILE_NUMBER, 'Mobile number is not valid'),
    });

    const onSubmit = (values) => {
        // console.log(values);
        const payload = {
            firstName: values.registerName,
            lastName: values.registerLastName,
            contactNo: values.registerMobileNUm
                ? values.registerMobileNUm
                : null,
            email: values.registerEmail,
            newsletterSignup: values.checkboxValue,
        };
        setLoading(true);
        updateCustomer(payload)
            .then((updatedUser) => {
                props.setUser(updatedUser);
                setLoading(false);
                notifySuccess("Profile updated successfully!");
            })
            .catch((err) => {
                notify(err.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (props.user) {
            setCustomer({
                registerName: props.user.firstName,
                registerLastName: props.user.lastName,
                registerMobileNUm: props.user.contactNo
                    ? props.user.contactNo
                    : "",
                registerEmail: props.user.email,
                checkboxValue: props.user.newsletterSignup,
            });
        }
    }, [props.user]);

    if (!props.user || loading) return <Loader />;

    return (
        <div className="register-flex-col">
            <div className="register-header">
                <div className="mr-1">
                    <AiOutlineArrowLeft
                        size={35}
                        onClick={() => {
                            navigate(-1);
                        }}
                    />
                </div>
                <div className="header-title">Edit Profile</div>
            </div>
            <div>
                <Formik
                    initialValues={customer}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                >
                    {(formikProps) => (
                        <div className="form-container">
                            <div className="form-item">
                                <label className="form-label">
                                    First Name*
                                </label>
                                <input
                                    {...formikProps.getFieldProps(
                                        "registerName",
                                    )}
                                    name="registerName"
                                    type="text"
                                    placeholder="Insert First Name"
                                    className="form-input"
                                />
                                <ErrorMessage
                                    name="registerName"
                                    component="div"
                                    className="form-error"
                                />
                            </div>
                            <div className="form-item">
                                <label className="form-label">Last Name*</label>
                                <input
                                    {...formikProps.getFieldProps(
                                        "registerLastName",
                                    )}
                                    name="registerLastName"
                                    type="text"
                                    placeholder="Insert Last Name"
                                    className="form-input"
                                />
                                <ErrorMessage
                                    name="registerLastName"
                                    component="div"
                                    className="form-error"
                                />
                            </div>
                            <div className="form-item">
                                <label className="form-label">
                                    Mobile Number
                                </label>
                                <input
                                    {...formikProps.getFieldProps(
                                        "registerMobileNUm",
                                    )}
                                    name="registerMobileNUm"
                                    type="text"
                                    placeholder="Insert Mobile Number"
                                    className="form-input"
                                />
                                <ErrorMessage
                                    name="registerMobileNUm"
                                    component="div"
                                    className="form-error"
                                />
                            </div>
                            <div className="form-item">
                                <label className="form-label">Email*</label>
                                <input
                                    {...formikProps.getFieldProps(
                                        "registerEmail",
                                    )}
                                    name="registerEmail"
                                    type="email"
                                    placeholder="Insert Email"
                                    className="form-input"
                                    disabled
                                />
                                <ErrorMessage
                                    name="registerEmail"
                                    component="div"
                                    className="form-error"
                                />
                            </div>
                            <div className="form-checkbox">
                                <input
                                    type="checkbox"
                                    checked={formikProps.values.checkboxValue}
                                    {...formikProps.getFieldProps(
                                        "checkboxValue",
                                    )}
                                    name="checkboxValue"
                                    className="form-input"
                                />
                                <label className="form-label">
                                    Sign up for marketing communication to stay
                                    updated on news, promotions and events
                                </label>
                            </div>
                            <div className="flex-align-center">
                                <CustomButton
                                    className="btn-block btn-bw"
                                    type="submit"
                                    onClick={formikProps.handleSubmit}
                                    disabled={loading}
                                >
                                    Edit
                                </CustomButton>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => {
            dispatch(setUser(user));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
