import axios from "axios";
import jwtDecode from "jwt-decode";
import apiConsts from "../constants/apiConstants";
import EventEmitter from "eventemitter3";
import { network } from "../common/network";

const customerURI = apiConsts.customerURI;

class jwtService extends EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
        window.addEventListener("storage", (e) => {
            // console.log(e.key === 'jwt_access_token')
            if (e.key === "jwt_access_token") {
                window.location.reload();
            }
        });
    }

    setInterceptors = () => {
        axios.interceptors.request.use(
            async (config) => {
                const token = this.getAccessToken();
                if (token) {
                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${token}`,
                    };
                }
                return config;
            },
            (error) => {
                Promise.reject(error);
            },
        );

        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            async (err) => {
                const originalConfig = err.config;
                if (
                    originalConfig &&
                    !originalConfig.url.includes(`${customerURI}/login`) &&
                    err.response
                ) {
                    // Access Token was expired
                    if (err.response.status === 401 && !originalConfig._retry) {
                        originalConfig._retry = true;
                        try {
                            const rs = await network(
                                `${apiConsts.customerURI}/generate-auth-token`,
                                {
                                    requestToken: this.getRefreshToken(),
                                },
                                "POST",
                            );
                            if (rs.data && rs.data.data.token) {
                                const { token } = rs.data.data;
                                this.setSession(token);
                            } else if (!originalConfig.__isRetryRequest) {
                                // if you ever get an unauthorized response, logout the user
                                this.emit(
                                    "onAutoLogout",
                                    "Invalid Access Token!",
                                );
                            }
                            return axios(originalConfig);
                        } catch (_error) {
                            return Promise.reject(_error);
                        }
                    }
                }
                return Promise.reject(err);
            },
        );
    };

    handleAuthentication = () => {
        const access_token = this.getAccessToken();

        if (!access_token) {
            // NOTE: No access to any other page without the token and redirect user to login page.
            this.emit("onAutoLogout");
            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit("onAutoLogin", true);
        } else {
            this.setSession(null, null);
            this.emit("onAutoLogout", "Access Token expired");
        }
    };

    signInWithEmailAndPassword = (email, password, redirectURL) => {
        return new Promise((resolve, reject) => {
            network(`${customerURI}/login`, "POST", {
                email,
                password,
            })
                .then((response) => {
                    if (response && response.data && response.success) {
                        // console.log(" jwtService --> response",response);
                        const userData = response.data.user;
                        const token = userData.token;
                        const refreshToken = userData.refreshToken;
                        delete userData.token;
                        this.setSession(token, userData);
                        this.setRefreshToken(refreshToken);
                        // history.push({
                        //   // NOTE: redirectURL temp
                        //   pathname: '/customer'
                        // //   pathname: redirectURL,
                        // });
                        resolve(userData);
                    } else if (response?.errors) {
                        reject(response.errors);
                    } else {
                        reject(response?.message);
                    }
                })
                .catch((err) => {
                    reject(err?.message);
                });
        });
    };

    setSession = (access_token, user = null) => {
        // FIXME: See if the user details being saved in local storage can be removed and be stored in runtime memory.
        if (access_token) {
            localStorage.setItem("jwt_access_token", access_token);
            if (user) {
                localStorage.setItem("user", JSON.stringify(user));
            }
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + access_token;
        } else {
            localStorage.removeItem("jwt_access_token");
            localStorage.removeItem("user");
            delete axios.defaults.headers.common["Authorization"];
        }
    };

    setRefreshToken = (refreshToken) => {
        if (refreshToken) {
            localStorage.setItem("jwt_refresh_token", refreshToken);
        } else {
            localStorage.removeItem("jwt_refresh_token");
        }
    };

    logout = () => {
        // history.replace({ pathname: "/login" });
        this.setSession(null, null);
    };

    isAuthTokenValid = (access_token) => {
        if (!access_token) {
            return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            return false;
        } else {
            return true;
        }
    };

    getAccessToken = () => {
        const search = new URLSearchParams(window.location.search);
        if (search && search.get("accessToken")) {
            return search.get("accessToken");
        }
        return window.localStorage.getItem("jwt_access_token");
    };

    getRefreshToken = () => {
        return localStorage.getItem("jwt_refresh_token");
    };

    getCurrentUser = () => {
        return JSON.parse(String(window.localStorage.getItem("user")));
    };
}

const instance = new jwtService();

export default instance;
