import ActionSheet from "actionsheet-react";
import { useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import {
    AUTOMATED_PROMO_PROMOTION,
    MEMBERSHIP_PROMO_PROMOTION,
    notify,
    PROMO_CODE_PROMOTION,
    toggleActionSheet,
} from "../../common/utils";
import constants from "../../constants/constants";
import { injectTipToCart } from "../../store/actions/ProductActions";
import CustomButton from "../custom-button";
import TipSelection from "./TipSelection";
const actionSheetStyle = {
    borderRadius: 0,
    margin: "auto",
    left: "auto",
    maxWidth: "768px",
};

const SingleOrderSummaryInfo = ({
    basket,
    serviceType,
    serviceData,
    outletData,
    injectTipToCart,
    handlePromotionChange,
    loyalty,
    loyaltyUsed,
    handleLoyaltyChange,
    promotion,
    basketPromotion,
    siteData,
    automatedPromotion,
    handleAutomatedPromoChange,
    membershipPromotion,
    itemBasedPromotion,
    basketItemBasedPromotion,
}) => {
    const tipRef = useRef();
    const tipSectionRef = useRef();
    const [tip, setTip] = useState(
        basket.tip === undefined || basket.tip === null ? 0 : basket.tip.amount,
    );
    const [tipType, setTipType] = useState(
        basket.tip !== undefined
            ? basket.tip === null
                ? "Flat"
                : basket.tip.type
            : serviceData.preSetTipType,
    );
    const setTipPercent = (val) => {
        setTip(basket.subTotal * val);
    };

    const handleTipChange = () => {
        if (tip) {
            injectTipToCart(null, basket.vendorRef)
                .then(() => setTip(0))
                .catch((err) => {
                    notify(err.message || "Something went wrong!");
                });
        } else {
            toggleActionSheet(tipRef, true);
        }
    };
    const itemBasedPromotionWithAmount = itemBasedPromotion.map((promo) => {
        const promoIndex = basketItemBasedPromotion?.findIndex(
            (itemPromo) => promo.promotionRef === itemPromo.promotionRef,
        );
        return {
            ...promo,
            amount: basketItemBasedPromotion[promoIndex]?.amount || 0,
        };
    });

    return (
        <>
            <div className="sub-section">
                <div className="row">
                    <span className="column-two">Subtotal</span>
                    <div className="column-two flex-end text-bold">
                        <FormattedNumber
                            style={`currency`}
                            currencyDisplay="narrowSymbol"
                            value={basket.subTotal}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={outletData.currency || "gbp"}
                        />
                    </div>
                </div>
                {itemBasedPromotionWithAmount &&
                itemBasedPromotionWithAmount.length > 0 ? (
                    itemBasedPromotionWithAmount.map((itemPromo, index) => {
                        return (
                            <div
                                className="row"
                                key={itemPromo?.promotionRef || index}
                            >
                                <span className="column-two text-secondary">
                                    {itemPromo?.name || "Promotion"} (
                                    {itemPromo?.value + "%"})
                                </span>
                                <div
                                    className={`column-two flex-end ${
                                        itemPromo?.amount > 0
                                            ? "text-success"
                                            : ""
                                    }`}
                                >
                                    <FormattedNumber
                                        style={`currency`}
                                        currencyDisplay="narrowSymbol"
                                        value={
                                            itemPromo?.amount
                                                ? `-${itemPromo?.amount}`
                                                : 0
                                        }
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={outletData.currency || "gbp"}
                                    />
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <></>
                )}
                {membershipPromotion &&
                Object.keys(membershipPromotion).length ? (
                    <div className="row">
                        <span className="column-two text-secondary">
                            {membershipPromotion?.name || "Promotion"} (
                            {membershipPromotion?.savingType === "percent" ? (
                                membershipPromotion?.value + "%"
                            ) : (
                                <FormattedNumber
                                    style={`currency`}
                                    currencyDisplay="narrowSymbol"
                                    value={membershipPromotion?.value}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    currency={outletData.currency || "gbp"}
                                />
                            )}
                            )
                        </span>
                        <div className="column-two flex-end">
                            <div className="column-two flex-end">
                                <CustomButton
                                    className={`btn-link p-0 mr-5 ${
                                        basketPromotion?.amount &&
                                        basketPromotion?.promotionType ===
                                            MEMBERSHIP_PROMO_PROMOTION
                                            ? "text-danger"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        handlePromotionChange(
                                            MEMBERSHIP_PROMO_PROMOTION,
                                            Boolean(
                                                basketPromotion?.amount > 0 &&
                                                    basketPromotion?.promotionType ===
                                                        MEMBERSHIP_PROMO_PROMOTION,
                                            ),
                                        );
                                    }}
                                >
                                    {basketPromotion?.amount &&
                                    basketPromotion?.promotionType ===
                                        MEMBERSHIP_PROMO_PROMOTION
                                        ? "Remove"
                                        : "Add Promotion"}
                                </CustomButton>
                            </div>
                            <div
                                className={`column-two flex-end ${
                                    basketPromotion?.amount &&
                                    basketPromotion?.promotionType ===
                                        MEMBERSHIP_PROMO_PROMOTION
                                        ? "text-success"
                                        : ""
                                }`}
                            >
                                {basket?.promotion?.promotionType ===
                                    MEMBERSHIP_PROMO_PROMOTION &&
                                basketPromotion?.amount ? (
                                    <FormattedNumber
                                        style={`currency`}
                                        currencyDisplay="narrowSymbol"
                                        value={
                                            basketPromotion?.amount &&
                                            basketPromotion?.promotionType ===
                                                MEMBERSHIP_PROMO_PROMOTION
                                                ? `-${basketPromotion?.amount}`
                                                : 0
                                        }
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={outletData.currency || "gbp"}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {promotion && Object.keys(promotion).length ? (
                    <div className="row">
                        <span className="column-two text-secondary">
                            {promotion?.name || "Promotion"} (
                            {promotion?.savingType === "percent" ? (
                                promotion?.value + "%"
                            ) : (
                                <FormattedNumber
                                    style={`currency`}
                                    currencyDisplay="narrowSymbol"
                                    value={promotion?.value}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    currency={outletData.currency || "gbp"}
                                />
                            )}
                            )
                        </span>
                        <div className="column-two flex-end">
                            <div className="column-two flex-end">
                                <CustomButton
                                    className={`btn-link p-0 mr-5 ${
                                        basketPromotion?.amount &&
                                        basketPromotion?.promotionType ===
                                            PROMO_CODE_PROMOTION
                                            ? "text-danger"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        handlePromotionChange(
                                            PROMO_CODE_PROMOTION,
                                            Boolean(
                                                basketPromotion?.amount > 0 &&
                                                    basketPromotion?.promotionType ===
                                                        PROMO_CODE_PROMOTION,
                                            ),
                                        );
                                    }}
                                >
                                    {basketPromotion?.amount &&
                                    basketPromotion?.promotionType ===
                                        PROMO_CODE_PROMOTION
                                        ? "Remove"
                                        : "Add Promotion"}
                                </CustomButton>
                            </div>
                            <div
                                className={`column-two flex-end ${
                                    basketPromotion?.amount &&
                                    basketPromotion?.promotionType ===
                                        PROMO_CODE_PROMOTION
                                        ? "text-success"
                                        : ""
                                }`}
                            >
                                {basket?.promotion?.promotionType ===
                                    PROMO_CODE_PROMOTION &&
                                basketPromotion?.amount ? (
                                    <FormattedNumber
                                        style={`currency`}
                                        currencyDisplay="narrowSymbol"
                                        value={
                                            basketPromotion?.amount &&
                                            basketPromotion?.promotionType ===
                                                PROMO_CODE_PROMOTION
                                                ? `-${basketPromotion?.amount}`
                                                : 0
                                        }
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={outletData.currency || "gbp"}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {automatedPromotion &&
                Object.keys(automatedPromotion).length ? (
                    <div className="row">
                        <span className="column-two text-secondary">
                            {automatedPromotion?.name || "Promotion"} (
                            {automatedPromotion?.savingType === "percent" ? (
                                automatedPromotion?.amountSaved + "%"
                            ) : (
                                <FormattedNumber
                                    style={`currency`}
                                    currencyDisplay="narrowSymbol"
                                    value={automatedPromotion?.amountSaved}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    currency={outletData.currency || "gbp"}
                                />
                            )}
                            )
                        </span>
                        <div className="column-two flex-end">
                            <div className="column-two flex-end">
                                <CustomButton
                                    className={`btn-link p-0 mr-5 ${
                                        basketPromotion?.amount &&
                                        basketPromotion?.promotionType ===
                                            AUTOMATED_PROMO_PROMOTION
                                            ? "text-danger"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        handleAutomatedPromoChange(
                                            automatedPromotion?._id,
                                            Boolean(
                                                basketPromotion?.amount > 0 &&
                                                    basketPromotion?.promotionType ===
                                                        AUTOMATED_PROMO_PROMOTION,
                                            ),
                                        );
                                    }}
                                >
                                    {basketPromotion?.amount &&
                                    basketPromotion?.promotionType ===
                                        AUTOMATED_PROMO_PROMOTION
                                        ? "Remove"
                                        : "Add Promotion"}
                                </CustomButton>
                            </div>
                            <div
                                className={`column-two flex-end ${
                                    basketPromotion?.amount &&
                                    basketPromotion?.promotionType ===
                                        AUTOMATED_PROMO_PROMOTION
                                        ? "text-success"
                                        : ""
                                }`}
                            >
                                {basketPromotion?.promotionType ===
                                    AUTOMATED_PROMO_PROMOTION &&
                                basketPromotion?.amount ? (
                                    <FormattedNumber
                                        style={`currency`}
                                        currencyDisplay="narrowSymbol"
                                        value={
                                            basketPromotion?.amount &&
                                            basketPromotion?.promotionType ===
                                                AUTOMATED_PROMO_PROMOTION
                                                ? `-${basketPromotion?.amount}`
                                                : 0
                                        }
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={outletData.currency || "gbp"}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {basket.subTotal - (promotion?.amount || 0) > 0 &&
                    loyalty &&
                    loyalty.availablePoints > 0 && (
                        <div className="row">
                            <span className="column-two text-secondary">
                                Loyalty ({loyalty.availablePoints} points,{" "}
                                <FormattedNumber
                                    style={`currency`}
                                    currencyDisplay="narrowSymbol"
                                    value={
                                        loyalty.availablePoints *
                                        loyalty.redeemValue
                                    }
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    currency={outletData.currency || "gbp"}
                                />
                                )
                            </span>
                            <div className="column-two flex-end">
                                <div className="column-two flex-end">
                                    <CustomButton
                                        className={`btn-link p-0 mr-5 ${
                                            loyaltyUsed
                                                ? "text-danger"
                                                : "text-success"
                                        }`}
                                        onClick={handleLoyaltyChange}
                                    >
                                        {loyaltyUsed ? "Remove" : "Redeem"}
                                    </CustomButton>
                                </div>
                                <div className="column-two flex-end loyalty-used-amount">
                                    {loyaltyUsed ? (
                                        <FormattedNumber
                                            style={`currency`}
                                            currencyDisplay="narrowSymbol"
                                            value={-loyaltyUsed}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            currency={
                                                outletData.currency || "gbp"
                                            }
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                {Boolean(basket.serviceCharge) && (
                    <div className="row">
                        <span className="column-two text-secondary">
                            Service Charge
                        </span>
                        <div className="column-two flex-end">
                            <FormattedNumber
                                style={`currency`}
                                currencyDisplay="narrowSymbol"
                                value={basket.serviceCharge}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                currency={outletData.currency || "gbp"}
                            />
                        </div>
                    </div>
                )}
                {Boolean(basket.platformFee) && (
                    <div className="row">
                        <span className="column-two text-secondary">
                            Platform Fee
                        </span>
                        <div className="column-two flex-end">
                            <FormattedNumber
                                style={`currency`}
                                currencyDisplay="narrowSymbol"
                                value={basket.platformFee}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                currency={outletData.currency || "gbp"}
                            />
                        </div>
                    </div>
                )}
                {(serviceType === constants.SERVICE_TYPE.offsiteDelivery ||
                    serviceType === constants.SERVICE_TYPE.onsiteDelivery) &&
                    Boolean(basket.deliveryFee) && (
                        <div className="row">
                            <span className="column-two text-secondary">
                                Delivery Fee
                            </span>
                            <div className="column-two flex-end">
                                <FormattedNumber
                                    style={`currency`}
                                    currencyDisplay="narrowSymbol"
                                    value={basket.deliveryFee}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    currency={outletData.currency || "gbp"}
                                />
                            </div>
                        </div>
                    )}

                {!siteData?.isDisableTips ||
                siteData?.isDisableTips === false ||
                (basket && basket.tip && basket.tip.amount) ? (
                    <div className="row">
                        <span className="column-two text-secondary">Tip</span>
                        <div className="column-two flex-end">
                            <div className="column-two flex-end">
                                <CustomButton
                                    className={`btn-link p-0 mr-5 ${
                                        tip ? "text-danger" : ""
                                    }`}
                                    onClick={handleTipChange}
                                >
                                    {tip ? "Remove" : "Add Tip"}
                                </CustomButton>
                            </div>
                            <div className="column-two flex-end">
                                {tip ? (
                                    <FormattedNumber
                                        style={`currency`}
                                        currencyDisplay="narrowSymbol"
                                        value={tip}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={outletData.currency || "gbp"}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {basket.exclusiveTaxTotal > 0 && (
                    <div className="row">
                        <span className="column-two text-secondary">
                            Exclusive Taxes
                        </span>
                        <div className="column-two flex-end text-secondary text-bold">
                            <FormattedNumber
                                style={`currency`}
                                currencyDisplay="narrowSymbol"
                                value={basket.exclusiveTaxTotal}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                currency={outletData.currency || "gbp"}
                            />
                        </div>
                    </div>
                )}
                <div className="separator"></div>
                <div className="row">
                    <span className="column-two">Total</span>
                    <div className="column-two flex-end text-bold">
                        <FormattedNumber
                            style={`currency`}
                            currencyDisplay="narrowSymbol"
                            value={basket.totalAmount}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={outletData.currency || "gbp"}
                        />
                    </div>
                </div>
                {basket.inclusiveTaxTotal > 0 && (
                    <div className="row">
                        <span className="column-two text-secondary">
                            Inclusive Taxes
                        </span>
                        <div className="column-two flex-end text-secondary">
                            <FormattedNumber
                                style={`currency`}
                                currencyDisplay="narrowSymbol"
                                value={basket.inclusiveTaxTotal}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                currency={outletData.currency || "gbp"}
                            />
                        </div>
                    </div>
                )}
            </div>
            <ActionSheet ref={tipRef} sheetStyle={actionSheetStyle}>
                <div className="payment-section-wrapper">
                    <div className="action-header">
                        <h3 className="mt-0">Staff Tip</h3>
                        <AiOutlineClose
                            onClick={() => toggleActionSheet(tipRef)}
                            size={25}
                        />
                    </div>
                    <TipSelection
                        ref={tipSectionRef}
                        setTip={setTip}
                        setTipPercent={setTipPercent}
                        setTipType={setTipType}
                        onRequestClose={() => toggleActionSheet(tipRef)}
                        vendorRef={basket.vendorRef}
                        currency={outletData.currency}
                    />
                </div>
            </ActionSheet>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        outletData: state.outletData,
        serviceData: state.serviceData,
        siteData: state.siteData,
    };
};

const mapDispatchToPros = (dispatch) => {
    return {
        injectTipToCart: (tip, vendorRef) =>
            dispatch(injectTipToCart(tip, vendorRef)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToPros,
)(SingleOrderSummaryInfo);
