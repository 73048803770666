import { useLocation, useNavigate, useParams } from "react-router-dom";
import useQuery from "../../common/useQuery";
import CustomButton from "../custom-button";
import "./outlet.scss";
import { useState, useEffect } from "react";
import {
    generateOutletDetailUrl,
    getBannerClassName,
    getImageUrl,
    getZoneObject,
} from "../../common/utils";
import { connect } from "react-redux";
import constants from "../../constants/constants";
import LandingAuth from "../../components/landing-auth";
import { setGuestSession } from "../../store/actions/UserAction";
import { setRedirectURL } from "../../store/actions/UserAction";
import MainHeader from "../common/MainHeader";
import SiteOutletInfoModal from "../common/SiteOutletInfoModal";
import { IMAGE_SIZES } from "../../constants/image";

function Outlets(props) {
    const navigate = useNavigate();
    const query = useQuery();
    const { state: pastParams } = useLocation();
    const { siteId, zoneId, outletId } = useParams();
    const redirectUrl = generateOutletDetailUrl({ siteId, zoneId, outletId });
    const { siteData, outletData: outlet, zoneData } = props;

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const className = getBannerClassName(outlet.outletImage, outlet.mapImage);

    useEffect(() => {
        if (!pastParams || !pastParams.showBack) {
            const authType = query.get("authType");
            authType
                ? props.setGuestSession("guest")
                : props.setGuestSession(null);
        }
    }, [query.get("authType")]);

    const renderServiceTypes = (settings, className) => {
        const buttons = [];
        for (let service in settings) {
            if (
                (service === constants.SERVICE_TYPE.onsiteCounter ||
                    service === constants.SERVICE_TYPE.onsiteOffCounter) &&
                settings[service].isActive
            ) {
                buttons.push(
                    <CustomButton
                        key={service}
                        className="btn-block outlet-detail"
                        onClick={() =>
                            navigate(
                                `${redirectUrl}/${constants.ROUTES.SERVICE_TYPE}/${service}`,
                                { state: { showBack: true } },
                            )
                        }
                    >
                        {settings[service].displayName}
                    </CustomButton>,
                );
            } else if (
                settings[service].isActive &&
                ((outlet.isRegisterOpen &&
                    settings[service].isLiveOrdersActive) ||
                    settings[service].isPreOrdersActive)
            ) {
                buttons.push(
                    <CustomButton
                        key={service}
                        className="btn-block outlet-detail"
                        onClick={() =>
                            navigate(
                                `${redirectUrl}/${constants.ROUTES.SERVICE_TYPE}/${service}`,
                                { state: { showBack: true } },
                            )
                        }
                    >
                        {settings[service].displayName}
                    </CustomButton>,
                );
            }
        }
        if (
            buttons.length > 0 &&
            !localStorage.getItem("jwt_access_token") &&
            props.authType !== "guest"
        ) {
            return <LandingAuth />;
        }
        if (buttons.length > 0) {
            return (
                <div className="box-container outlet-list-wrapper">
                    <div className="row">
                        <div className="service-title text-bold">
                            Select Service
                        </div>
                    </div>
                    <div className={`outlet-list ${className}`}>{buttons}</div>
                </div>
            );
        }
        return (
            <div className="box-container outlet-list-wrapper">
                <p className="closed-center">
                    {" "}
                    <pre>
                        {" "}
                        {outlet.customWebAppMsg && outlet.customWebAppMsg !== ""
                            ? outlet.customWebAppMsg
                            : "Sorry, We Are closed and not accepting orders."}{" "}
                    </pre>
                </p>
            </div>
        );
    };

    return (
        <div className="outlet">
            <MainHeader
                siteId={siteId}
                name={
                    zoneId
                        ? getZoneObject(zoneData, zoneId).name
                        : siteData?.displayName
                }
                setRedirectURL={props.setRedirectURL}
                showAccountButton={true}
                openModal={openModal}
                showInfoButton={true}
            />
            <SiteOutletInfoModal
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                {...outlet}
            />
            <div className="box-container text-center">
                <div className="title">{outlet.displayName}</div>
            </div>
            {
                <div className={`banner ${className}`}>
                    {outlet.outletImage && (
                        <div className="banner-top">
                            <img
                                src={
                                    getImageUrl(outlet.outletImage) +
                                    IMAGE_SIZES.logo
                                }
                                onError={(e) => {
                                    e.target.src = getImageUrl(
                                        outlet.outletImage,
                                    );
                                }}
                                alt="Logo not found"
                            />
                        </div>
                    )}
                    {outlet.mapImage && (
                        <div className="banner-main">
                            <img
                                src={
                                    getImageUrl(outlet.mapImage) +
                                    IMAGE_SIZES.banner
                                }
                                onError={(e) => {
                                    e.target.src = getImageUrl(outlet.mapImage);
                                }}
                                alt="Logo not found"
                            />
                        </div>
                    )}
                </div>
            }
            {outlet.description && outlet.description.trim().length > 0 ? (
                <div className="outlet-landing-description-inception">
                    <div className="outlet-landing-description-container">
                        {(outlet.description.split("\r\n") || []).map(
                            (desc, index) => {
                                return (
                                    <p
                                        className={`box-container text-center outlet-landing-description`}
                                        key={index}
                                    >
                                        {desc}
                                    </p>
                                );
                            },
                        )}
                    </div>
                </div>
            ) : null}
            {renderServiceTypes(outlet.settings, className)}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        zoneData: state.zoneData,
        outletData: state.outletData,
        siteData: state.siteData,
        authType: state.authType,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setGuestSession: (authType) => {
            dispatch(setGuestSession(authType));
        },
        setRedirectURL: (url) => dispatch(setRedirectURL(url)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Outlets);
