import {
    calculateAllCartLevelTotal,
    calculateSubTotal,
    notify,
    updateLocalCart,
} from "../../common/utils";
import constants from "../../constants/constants";
import { apiService } from "../../services";
import * as actionTypes from "./ActionTypes";

export const addVendorId = (vendorId) => {
    return {
        type: actionTypes.ADD_VENDOR_ID,
        payload: vendorId,
    };
};

export const emptyCart = () => {
    return {
        type: actionTypes.EMPTY_CART_ACTION,
    };
};

// Refer below functions for logic
export const getUserCart = (value) => {
    return {
        type: actionTypes.GET_USER_CART,
        payload: value,
    };
};

export const addItemsToCart = (itemDetails) => {
    return async (dispatch, getState) => {
        dispatch(setCartLoading(true));
        const { outletData, serviceData, user, siteData, zoneData, cart } =
            getState();
        let isEmptyCart = false;

        const isMultiOrder =
            siteData &&
            siteData.category === constants.SITE_CATEGORY.SITE_DEPENDENT
                ? true
                : false;

        if (
            siteData &&
            cart.site &&
            siteData._id !== cart.site.siteRef &&
            isMultiOrder
        ) {
            dispatch(emptyCart());
            isEmptyCart = true;
        } else if (
            outletData &&
            outletData._id !== itemDetails.outletRef &&
            isMultiOrder === false
        ) {
            dispatch(emptyCart());
            isEmptyCart = true;
        }

        const payload = {
            _id: cart._id,
            item: itemDetails,
            userRef: user?._id,
            vendorRef: itemDetails.outletRef
                ? itemDetails.outletRef
                : outletData._id,
            siteCategory: siteData?.category || null,
            serviceType: serviceData?.serviceType,
            site: {
                siteRef: siteData?._id,
                displayName: siteData?.displayName,
            },
            siteZones: {
                siteZoneRef: zoneData?._id,
                name: zoneData?.name,
            },
            serviceData: getFormattedServiceData(serviceData),
            isMultiOrder: isMultiOrder,
            isEmptyCart,
        };

        return apiService
            .addItemsToUserCart(payload)
            .then((res) => {
                dispatch({
                    type: actionTypes.ADD_ITEMS_CART_ACTION,
                    payload: { ...res },
                });
                dispatch(setCartLoading(false));
            })
            .catch((err) => {
                notify("Item could not be added!");
                dispatch(setCartLoading(false));
                throw err;
            });
    };
};

export const deleteItemFromCart = (itemIndex, itemDetails) => {
    return async (dispatch, getState, user) => {
        dispatch(setCartLoading(true));
        const { siteData, serviceData, cart } = getState();
        const isMultiOrder =
            siteData &&
            siteData.category === constants.SITE_CATEGORY.SITE_DEPENDENT
                ? true
                : false;
        const payload = {
            _id: cart._id,
            item: null,
            userRef: user ? user._id : null,
            vendorRef: itemDetails.outletRef,
            siteCategory: siteData?.category || null,
            serviceData: getFormattedServiceData(serviceData),
            isMultiOrder: isMultiOrder,
            editItem: itemIndex,
            deleteItem: true,
            serviceType: cart?.serviceType,
        };

        apiService
            .addItemsToUserCart(payload)
            .then((res) => {
                if (res === true) {
                    dispatch(emptyCart());
                } else {
                    dispatch({
                        type: actionTypes.ADD_ITEMS_CART_ACTION,
                        payload: { ...res },
                    });
                }
                dispatch(setCartLoading(false));
            })
            .catch((err) => {
                notify("Item could not be updated!");
                dispatch(setCartLoading(false));
            });
    };
};

export const updateItemFromCart = (itemIndex, itemDetails) => {
    return async (dispatch, getState) => {
        dispatch(setCartLoading(true));
        let { outletData, serviceData, user, siteData, zoneData, cart } =
            getState();

        let isEmptyCart = false;
        const isMultiOrder =
            siteData &&
            siteData.category === constants.SITE_CATEGORY.SITE_DEPENDENT
                ? true
                : false;
        if (siteData && cart.site && siteData._id !== cart.site.siteRef) {
            dispatch(emptyCart());
            isEmptyCart = true;
        } else if (
            outletData &&
            outletData._id !== itemDetails.outletRef &&
            isMultiOrder === false
        ) {
            dispatch(emptyCart());
            isEmptyCart = true;
        }

        const payload = {
            _id: cart._id,
            item: itemDetails,
            userRef: user?._id,
            vendorRef: itemDetails.outletRef
                ? itemDetails.outletRef
                : outletData._id,
            siteCategory: siteData?.category || null,
            serviceType: serviceData?.serviceType,
            site: {
                siteRef: siteData?._id,
                displayName: siteData?.displayName,
            },
            siteZones: {
                siteZoneRef: zoneData?._id,
                name: zoneData?.name,
            },
            serviceData: getFormattedServiceData(serviceData),
            isMultiOrder: isMultiOrder,
            isEmptyCart,
            isEdit: true,
            editItem: itemIndex,
        };

        apiService
            .addItemsToUserCart(payload)
            .then((res) => {
                dispatch({
                    type: actionTypes.ADD_ITEMS_CART_ACTION,
                    payload: { ...res },
                });
                dispatch(setCartLoading(false));
            })
            .catch((err) => {
                notify("Item could not be updated!");
                dispatch(setCartLoading(false));
            });
    };
};

export const updateCart = (body, userCart = undefined) => {
    return async (dispatch, getState) => {
        let { cart, outletData, serviceData } = getState();
        if (cart.serviceType === constants.SERVICE_TYPE.offsiteDelivery) {
            cart.deliveryFee = serviceData.deliveryFee || 0;
            if (
                outletData &&
                outletData.settings &&
                outletData.settings[cart.serviceType]
            ) {
                outletData.settings[cart.serviceType] = {
                    ...outletData.settings[cart.serviceType],
                    deliveryFeeCutOff: serviceData.deliveryFeeCutOff,
                };
            }
        }

        const bodyData = userCart
            ? {
                  ...body,
                  loyaltyUsed: userCart.loyaltyUsed || 0,
                  loyalty: userCart.loyalty || null,
                  promotion: userCart.promotion || {},
                  itemBasedPromotion: userCart.itemBasedPromotion || [],
              }
            : body;

        let cartData = updateLocalCart(
            userCart ? userCart : cart,
            outletData,
            bodyData,
        );
        dispatch({
            type: actionTypes.ADD_ITEMS_CART_ACTION,
            payload: { ...cartData },
        });
    };
};

const getFormattedServiceData = (serviceData) => {
    return {
        absoluteMinimumOrder: serviceData?.absoluteMinimumOrder,
        serviceType: serviceData?.serviceType,
        serviceTypeDisplayName: serviceData?.serviceTypeDisplayName || "",
        tableNumber: serviceData?.tableNumber,
        postCode: serviceData?.postCode,
        preOrder: serviceData?.preOrder,
        deliveryFee: serviceData?.deliveryFee,
        vendorZoneName: serviceData?.vendorZoneName || "",
        deliveryDate: serviceData?.deliveryDate || null,
        deliveryFeeCutOff: serviceData?.deliveryFeeCutOff || 0,
    };
};

export const injectTipToCart = (tip, vendorRef) => {
    return async (dispatch, getState) => {
        let { cart, siteData, serviceData, user } = getState();
        const isMultiOrder =
            siteData &&
            siteData.category === constants.SITE_CATEGORY.SITE_DEPENDENT
                ? true
                : false;
        const payload = {
            _id: cart._id,
            item: null,
            userRef: user ? user._id : null,
            vendorRef: vendorRef,
            serviceType: serviceData?.serviceType,
            serviceData: getFormattedServiceData(serviceData),
            isMultiOrder: isMultiOrder,
            updateFeesCart: true,
            tip: tip,
            loyaltyUsed: cart.loyaltyUsed || null,
            loyalty: cart.loyalty || null,
            promotion: cart.promotion || null,
            itemBasedPromotion: cart.itemBasedPromotion || [],
        };
        dispatch(setCartLoading(true));
        return apiService
            .addItemsToUserCart(payload)
            .then((res) => {
                dispatch(updateCart({}, res));
                dispatch(setCartLoading(false));
                return res;
            })
            .catch((err) => {
                console.log("Add Tip failed!", err);
                dispatch(setCartLoading(false));
                throw err;
            });
    };
};

export const setReload = (reload) => {
    return {
        type: actionTypes.MENU_RELOAD,
        payload: reload,
    };
};

export const setOutletData = (outlet) => {
    return {
        type: actionTypes.SET_OUTLET_DATA,
        payload: outlet,
    };
};

export const setServiceData = (service) => {
    return async (dispatch, getState) => {
        const { siteData } = getState();
        if (siteData?.isDisableTips === true) {
            service.preSetTipAmount = 0;
            service.preSetTipType = "";
        }

        dispatch({
            type: actionTypes.SET_SERVICE_DATA,
            payload: service,
        });
    };
};

export const removeServiceData = (service) => {
    return async (dispatch) => {
        dispatch(clearPrepaidInformation(dispatch));
        dispatch({
            type: actionTypes.REMOVE_SERVICE_DATA,
            payload: service,
        });
    };
};

export const setOrderType = (preOrder) => {
    return async (dispatch, getState) => {
        const { serviceData } = getState();
        dispatch({
            type: actionTypes.SET_SERVICE_DATA,
            payload: { ...serviceData, preOrder: preOrder },
        });
    };
};

export const setCartLoading = (value) => {
    return {
        type: actionTypes.SET_CART_LOADING,
        payload: value,
    };
};

export const setMenuPayType = (value) => {
    return {
        type: actionTypes.SET_MENU_PAY_TYPE,
        payload: value,
    };
};

export const setPrepaidMaxProduct = (value) => {
    return {
        type: actionTypes.SET_PREPAID_MAX_PRODUCT,
        payload: value,
    };
};

export const setPrepaidEmail = (value) => {
    return {
        type: actionTypes.SET_PREPAID_EMAIL,
        payload: value,
    };
};

export const clearPrepaidInformation = () => {
    return async (dispatch) => {
        dispatch(setMenuPayType(""));
        dispatch(setPrepaidMaxProduct(""));
        dispatch(setPrepaidEmail(""));
    };
};

export const setQuantityLoaderInline = (value) => {
    return {
        type: actionTypes.SET_QUANTITY_LOADER,
        payload: value,
    };
};

export const setQuantityLoader = (value) => {
    return async (dispatch) => {
        dispatch(setQuantityLoaderInline(value));
    };
};

export const removeCart = () => {
    return async (dispatch, getState) => {
        const { cart } = getState();
        dispatch(emptyCart());
        if (cart && cart._id) {
            await apiService
                .removeCart({ _id: cart._id })
                .catch((er) => console.log(er));
        }
    };
};
