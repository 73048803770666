import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { getLoyaltyDetail } from "../../services/api.service";
import { notify } from "../../common/utils";
import { Loader } from "../custom-loader";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { getImageUrl } from "../../common/utils";
import "./loyalty-outlet.scss";
import { FormattedNumber } from "react-intl";
import { useParams } from "react-router";

const LoyaltyOutlet = () => {
    const navigate = useNavigate();
    const { loyaltyId } = useParams();
    const [loading, setLoading] = useState(false);
    const [loyalty, setLoyalty] = useState(null);

    useEffect(() => {
        setLoading(true);
        getLoyaltyDetail(loyaltyId)
            .then((resp) => {
                setLoyalty(resp);
                setLoading(false);
            })
            .catch((err) => {
                notify(err);
                navigate(-1);
                setLoading(false);
            });
    }, []);

    if (loading) return <Loader />;
    return (
        <>
            {loyalty && (
                <div className="loyalty-outlet-container">
                    <div className="loyalty-header">
                        <AiOutlineArrowLeft
                            size={35}
                            onClick={() => {
                                navigate(-1);
                            }}
                        />
                        <div className="header-title">
                            <span>{loyalty.loyaltyRef.name}</span>
                        </div>
                    </div>
                    <div className="box-container">
                        <div className="loyalty-message">
                            Show this Qr code to the waiter to redeem points.
                        </div>
                        <div className="loyalty-qr-code-full-page">
                            <img
                                src={getImageUrl(loyalty.qrCodeImage)}
                                alt="loyalty-qr-code"
                            />
                        </div>
                        <div className="sub-section">
                            <div className="row text-bold">
                                <span className="column-two">
                                    Pending Loyalty Points
                                </span>
                                <span className="column-two flex-end">
                                    {loyalty.eligiblePoints}
                                </span>
                            </div>
                            <div className="row text-bold">
                                <span className="column-two">
                                    Loyalty Points Collected
                                </span>
                                <span className="column-two flex-end">
                                    {loyalty.points}
                                </span>
                            </div>
                            <div className="row text-bold">
                                <span className="column-two">
                                    Current Points Value
                                </span>
                                <span className="column-two flex-end">
                                    <FormattedNumber
                                        value={
                                            loyalty.points *
                                            loyalty.loyaltyRef.redeemValue
                                        }
                                        style={`currency`}
                                        currencyDisplay="narrowSymbol"
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={
                                            loyalty?.loyaltyRef?.vendorRef?.currency.toUpperCase() ||
                                            "GBP"
                                        }
                                    />
                                </span>
                            </div>
                        </div>
                        <h4>Details</h4>
                        <div className="loyalty-outlet-list-heading">
                            Points are only redeemable at the following
                            locations:
                        </div>
                        {loyalty.loyaltyRef.type === "SITE"
                            ? loyalty.loyaltyRef.assignedSites.map(
                                  (site, index) => (
                                      <>
                                          <div
                                              className="loyalty-outlet-item"
                                              key={index}
                                          >
                                              <div>{`${site.displayName}, ${site.address1}, ${site.address2}, ${site.postalCode}`}</div>
                                              <div>{`${site.city}`}</div>
                                          </div>
                                      </>
                                  ),
                              )
                            : loyalty.loyaltyRef.assignedOutlets.map(
                                  (outlet, index) => (
                                      <>
                                          <div
                                              className="loyalty-outlet-item"
                                              key={index}
                                          >
                                              <div>{`${outlet.displayName}, ${outlet.address}, ${outlet.address2}, ${outlet.postalCode}`}</div>
                                              <div>{`${outlet.city}`}</div>
                                          </div>
                                      </>
                                  ),
                              )}
                    </div>
                </div>
            )}
        </>
    );
};

export default LoyaltyOutlet;
