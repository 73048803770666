import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import "./IssueMessage.scss";
import { Loader } from "../custom-loader";
import { apiService } from "../../services";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { notify } from "../../common/utils";
import CustomButton from "../custom-button";
import MainHeader from "../common/MainHeader";

const issueLabel = {
    food: "Food",
    technical: "Technical",
    payment: "Payment",
};

function IssueMessage(props) {
    const navigate = useNavigate();
    const { orderId, issueType } = useParams();

    const location = useLocation();
    const search = new URLSearchParams(location);
    const isFromMail = search.get("mailLink") ? true : false;
    const [orderDetails, setOrderDetails] = useState({});
    const [issue, setIssue] = useState("");
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (orderId) {
            apiService
                .getOrder(orderId)
                .then((res) => {
                    setOrderDetails(res);
                })
                .catch((err) => {
                    notify(err.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    const onSubmit = () => {
        const payload = {
            vendorRef: orderDetails.vendor._id,
            orderRef: orderDetails._id,
            userRef: props.user._id,
            type: (issueType || "").toUpperCase(),
            customerMessage: issue,
        };
        setLoading(true);
        apiService
            .createIssue(payload)
            .then((res) => {
                navigate(-1);
                setLoading(false);
            })
            .catch((err) => {
                notify(err.message);
                setLoading(false);
            });
    };

    return (
        <>
            {isLoading && <Loader />}
            <div className="issue-message">
                <MainHeader
                    name={`${issueLabel[issueType]} Issue`}
                    showBackButton={!isFromMail}
                    showBottomLine={true}
                />
                <div className="your-order-container your-order-header-info box-container">
                    <h2 className="order-place-title">
                        {orderDetails?.vendor?.displayName}
                    </h2>
                    <div className="your-order-order-info">
                        <h2 className="order-number">
                            Order ID: {orderDetails.orderNum}
                        </h2>
                        {orderDetails?.orderReferenceNumber ? (
                            <h2 className="order-number">
                                Order Reference:{" "}
                                {orderDetails.orderReferenceNumber}
                            </h2>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <hr className="m-0" />
                <div className="box-container">
                    <div className="issue-inconvinience-message">
                        We are sorry for the inconvinience. Please leave your
                        message detailing the issue. One of the staff members
                        will get in touch as soon as possible.
                    </div>
                    <textarea
                        name="textValue"
                        className="issue-input-text"
                        rows={8}
                        onChange={(event) => {
                            setIssue(event.target.value);
                        }}
                    />
                    <CustomButton
                        className="btn-block"
                        disabled={issue.trim().length === 0}
                        onClick={() => {
                            onSubmit();
                        }}
                    >
                        Send
                    </CustomButton>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, null)(IssueMessage);
