import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../custom-button";
import { getPrepaidInformation, removeCart } from "../../services/api.service";
import "./serviceType.scss";
import { connect } from "react-redux";
import {
    setPrepaidMaxProduct,
    setPrepaidEmail,
    emptyCart,
} from "../../store/actions/ProductActions";

const PrePaidMenuEmail = ({
    menu,
    redirectUrl,
    setPrepaidMaxProduct,
    setPrepaidEmail,
    user,
    emptyCart,
}) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState(user && user.email ? user.email : "");
    const [emailFound, setEmailFound] = useState(false);
    const [showResponse, setShowResponse] = useState(false);
    const deleteCart = () => {
        if (user) {
            removeCart({
                userRef: user._id,
            });
        }
        emptyCart();
        navigate(`${redirectUrl}/menus/${menu._id}`);
    };
    const onSubmit = () => {
        getPrepaidInformation(menu._id, email)
            .then((response) => {
                setEmailFound(true);
                setShowResponse(true);
                setPrepaidMaxProduct(
                    response.maxProducts - response.orderedProducts,
                );
                setPrepaidEmail(response.email);
            })
            .catch((err) => {
                setEmailFound(false);
                setShowResponse(true);
            });
    };

    if (showResponse) {
        if (emailFound)
            return (
                <div className="prepaid-menu-email">
                    <h4 className="message">
                        <pre>{menu.verifiedEmailMessage}</pre>
                    </h4>
                    <CustomButton
                        label="Continue"
                        type="button"
                        onClick={deleteCart}
                    />
                </div>
            );
        else
            return (
                <div className="prepaid-menu-email">
                    <h4 className="message">
                        <pre>{menu.notFoundEmailMessage}</pre>
                    </h4>
                    <CustomButton
                        label="Go Back"
                        type="button"
                        onClick={() => {
                            setEmail(user && user.email ? user.email : "");
                            setEmailFound(false);
                            setShowResponse(false);
                        }}
                    />
                </div>
            );
    }

    return (
        <div className="prepaid-menu-email">
            <h2>
                {menu.displayName}{" "}
                {menu.menuPayType === "prepaid" ? "(Guest List)" : ""}
            </h2>

            <input
                className="form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value.toString().trim())}
                label="Email"
                type="text"
                placeholder="Insert Email"
            />

            <CustomButton label="Confirm" type="button" onClick={onSubmit} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPrepaidMaxProduct: (maxProduct) => {
            dispatch(setPrepaidMaxProduct(maxProduct));
        },
        setPrepaidEmail: (email) => {
            dispatch(setPrepaidEmail(email));
        },
        emptyCart: () => dispatch(emptyCart()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrePaidMenuEmail);
