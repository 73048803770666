import ActionSheet from "actionsheet-react";
import { useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { notify, toggleActionSheet } from "../../common/utils";
import constants from "../../constants/constants";
import { injectTipToCart } from "../../store/actions/ProductActions";
import CustomButton from "../custom-button";
import TipSelection from "./TipSelection";
const actionSheetStyle = {
    borderRadius: 0,
    margin: "auto",
    left: "auto",
    maxWidth: "768px",
};

const OrderSummaryInfo = ({
    basket,
    serviceType,
    serviceData,
    outletData,
    injectTipToCart,
    siteData,
    storesEstimatedPrepTime,
}) => {
    const tipRef = useRef();
    const tipSectionRef = useRef();
    const [tip, setTip] = useState(
        basket.tip === undefined || basket.tip === null ? 0 : basket.tip.amount,
    );
    const [tipType, setTipType] = useState(
        basket.tip !== undefined
            ? basket.tip === null
                ? "Flat"
                : basket.tip.type
            : serviceData.preSetTipType,
    );
    const setTipPercent = (val) => setTip(basket.subTotal * val);

    const handleTipChange = () => {
        if (tip) {
            injectTipToCart(null, basket.vendorRef)
                .then(() => setTip(0))
                .catch((err) => {
                    notify(err.message || "Something went wrong!");
                });
        } else {
            toggleActionSheet(tipRef, true);
        }
    };
    return (
        <>
            <div className="row justify-space-between store-name">
                <div>{basket.storeName || "Default"}</div>
                {/* {storesEstimatedPrepTime[basket.vendorRef] ? <div>Est. Prep. Time {constants.ESTIMATED_PREP_TIME_MAPPING[storesEstimatedPrepTime[basket.vendorRef]]}</div> : null} */}
            </div>

            <div className="sub-section">
                {!siteData?.isDisableTips ||
                siteData?.isDisableTips === false ||
                (basket && basket.tip && basket.tip.amount) ? (
                    <div className="row">
                        <span className="column-two">Subtotal</span>
                        <div className="column-two flex-end text-bold">
                            <FormattedNumber
                                style={`currency`}
                                currencyDisplay="narrowSymbol"
                                value={basket.subTotal}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                currency={outletData.currency || "gbp"}
                            />
                        </div>
                    </div>
                ) : null}
                {Boolean(basket.serviceCharge) && (
                    <div className="row">
                        <span className="column-two text-secondary">
                            Service Charge
                        </span>
                        <div className="column-two flex-end">
                            <FormattedNumber
                                style={`currency`}
                                currencyDisplay="narrowSymbol"
                                value={basket.serviceCharge}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                currency={outletData.currency || "gbp"}
                            />
                        </div>
                    </div>
                )}
                {[
                    constants.SERVICE_TYPE.offsiteDelivery,
                    constants.SERVICE_TYPE.onsiteDelivery,
                ].includes(serviceType) &&
                    Boolean(basket.deliveryFee) && (
                        <div className="row">
                            <span className="column-two text-secondary">
                                Delivery Fee
                            </span>
                            <div className="column-two flex-end">
                                <FormattedNumber
                                    style={`currency`}
                                    currencyDisplay="narrowSymbol"
                                    value={basket.deliveryFee}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    currency={outletData.currency || "gbp"}
                                />
                            </div>
                        </div>
                    )}
                {!siteData?.isDisableTips ||
                siteData?.isDisableTips === false ||
                (basket && basket.tip && basket.tip.amount) ? (
                    <div className="row">
                        <span className="column-two text-secondary">Tip</span>
                        <div className="column-two flex-end">
                            <div className="column-two flex-end">
                                <CustomButton
                                    className={`btn-link p-0 mr-5 ${
                                        tip ? "text-danger" : ""
                                    }`}
                                    onClick={handleTipChange}
                                >
                                    {tip ? "Remove" : "Add Tip"}
                                </CustomButton>
                            </div>
                            <div className="column-two flex-end">
                                {tip ? (
                                    <FormattedNumber
                                        style={`currency`}
                                        currencyDisplay="narrowSymbol"
                                        value={tip}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        currency={outletData.currency || "gbp"}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {/* <div className="separator"></div> */}
                {basket.exclusiveTaxTotal > 0 && (
                    <div className="row">
                        <span className="column-two">Exclusive taxes</span>
                        <div className="column-two flex-end text-bold">
                            <FormattedNumber
                                style={`currency`}
                                currencyDisplay="narrowSymbol"
                                value={basket.exclusiveTaxTotal}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                currency={outletData.currency || "gbp"}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <span className="column-two">Total</span>
                    <div className="column-two flex-end text-bold">
                        <FormattedNumber
                            style={`currency`}
                            currencyDisplay="narrowSymbol"
                            value={basket.totalAmount}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            currency={outletData.currency || "gbp"}
                        />
                    </div>
                </div>
            </div>
            <ActionSheet ref={tipRef} sheetStyle={actionSheetStyle}>
                <div className="payment-section-wrapper">
                    <div className="action-header">
                        <h3 className="mt-0">Staff Tip</h3>
                        <AiOutlineClose
                            onClick={() => toggleActionSheet(tipRef)}
                            size={25}
                        />
                    </div>
                    <TipSelection
                        ref={tipSectionRef}
                        setTip={setTip}
                        setTipPercent={setTipPercent}
                        setTipType={setTipType}
                        onRequestClose={() => toggleActionSheet(tipRef)}
                        vendorRef={basket.vendorRef}
                        currency={outletData.currency}
                    />
                </div>
            </ActionSheet>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        outletData: state.outletData,
        serviceData: state.serviceData,
        siteData: state.siteData,
    };
};

const mapDispatchToPros = (dispatch) => {
    return {
        injectTipToCart: (tip, vendorRef) =>
            dispatch(injectTipToCart(tip, vendorRef)),
    };
};

export default connect(mapStateToProps, mapDispatchToPros)(OrderSummaryInfo);
