import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { notify } from "../../common/utils";
import { apiService } from "../../services";
import {
    emptyCart,
    getUserCart,
    setOutletData,
} from "../../store/actions/ProductActions";
import { Loader } from "../custom-loader";
import constants from "../../constants/constants";

function ComponentWrapper(props) {
    const location = useLocation();
    const { user, basket, serviceData } = props;
    const { siteId, outletId, zoneId } = useParams();
    const [fetchingCart, setFetchingCart] = useState(false);
    const navigate = useNavigate();

    const fetchUserCart = () => {
        return new Promise((resolve) => {
            if (!user || user?._id) {
                resolve({});
                return;
            }
            apiService
                .getUserCart(user._id, "")
                .then((res) => {
                    let isVendorFound =
                        res && res.carts && res.carts.length
                            ? res.carts.find(
                                  (v) =>
                                      String(v.vendorRef) === String(outletId),
                              )
                            : null;
                    if (
                        isVendorFound &&
                        siteId &&
                        res.site &&
                        res.site.siteRef
                    ) {
                        isVendorFound =
                            isVendorFound &&
                            String(basket.site.siteRef) === String(siteId);
                    } else if (isVendorFound && siteId) {
                        isVendorFound = false;
                    }
                    if (isVendorFound) {
                        props.getUserCart(res);
                        resolve({});
                        return;
                    } else {
                        apiService
                            .removeCart({ _id: res._id, userRef: res.userRef })
                            .then((res) => {
                                props.emptyCart();
                                resolve({});
                                return;
                            })
                            .catch(() => {
                                resolve({});
                                return;
                            });
                    }
                })
                .catch(() => {
                    props.emptyCart();
                    resolve({});
                    return;
                });
        });
    };

    const fetchOutlet = async () => {
        apiService
            .getOutlets(outletId, siteId, zoneId)
            .then(async (response) => {
                // console.log("Called Outlet")
                if (location.key === "default") {
                    sessionStorage.setItem("isDirectAccess", true);
                }
                let isDirectAccess = sessionStorage.getItem("isDirectAccess");
                if (
                    !siteId ||
                    (response.storeType ===
                        constants.OUTLET_STORE_TYPE.SITE_INDEPENDENT &&
                        isDirectAccess &&
                        (!location.state || location?.state?.showBack !== true))
                ) {
                    const bodyStyles = document.body.style;
                    bodyStyles.setProperty(
                        "--theme-text-color",
                        response.primaryTextColor || "#fff",
                    );
                    bodyStyles.setProperty(
                        "--theme-background-color",
                        response.primaryColor || "#000",
                    );
                }
                try {
                    await fetchUserCart();
                } catch (err) {
                    console.log(err);
                    console.log("cart loading error");
                }
                props.setOutletData(response);
            })
            .catch((err) => {
                notify("error", err);
                navigate("/404", { replace: true });
            });
    };

    useEffect(() => {
        if (outletId) {
            fetchOutlet();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outletId]);

    // useEffect(() => {
    //   if (outletId && user && user._id) {
    //     setFetchingCart(true);
    //     apiService.getUserCart(user._id, "").then((res) => {
    //       let isVendorFound = res && res.carts && res.carts.length ? res.carts.find((v) => String(v.vendorRef) === String(outletId)) : null
    //       if(isVendorFound && siteId && res.site && res.site.siteRef) {
    //         isVendorFound = isVendorFound && String(basket.site.siteRef) === String(siteId)
    //       } else if(isVendorFound && siteId) {
    //         isVendorFound = false;
    //       }
    //       if (isVendorFound) {
    //         props.getUserCart(res);
    //         setFetchingCart(false);
    //       } else {
    //         apiService.removeCart({ _id: res._id, userRef: res.userRef }).then((res) => {
    //           props.emptyCart();
    //           setFetchingCart(false);
    //         }).catch(() => setFetchingCart(false))
    //       }
    //     }).catch(() => {
    //       props.emptyCart();
    //       setFetchingCart(false);
    //     })
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [user, outletId]);

    useEffect(() => {
        if (
            !fetchingCart &&
            basket &&
            Object.keys(basket).length &&
            serviceData &&
            serviceData.serviceType &&
            Object.keys(serviceData).length
        ) {
            let isVendorFound = (basket.carts || []).find(
                (v) => String(v.vendorRef) === String(outletId),
            );
            if (isVendorFound && siteId && basket.site && basket.site.siteRef) {
                isVendorFound =
                    isVendorFound &&
                    String(basket.site.siteRef) === String(siteId);
            } else if (isVendorFound && siteId) {
                isVendorFound = false;
            }
            let isZoneFound = true;
            if (
                siteId &&
                zoneId &&
                basket.siteZones &&
                basket.siteZones.siteZoneRef
            ) {
                isZoneFound =
                    String(basket.siteZones.siteZoneRef) === String(zoneId);
            } else if (
                siteId &&
                ((!zoneId &&
                    basket.siteZones &&
                    basket.siteZones.siteZoneRef) ||
                    (zoneId && !basket.siteZones))
            ) {
                isZoneFound = false;
            }
            if (
                basket.serviceType !== serviceData.serviceType ||
                !isVendorFound ||
                !isZoneFound ||
                basket.preOrder !== serviceData.preOrder
            ) {
                setFetchingCart(true);
                apiService
                    .removeCart({ _id: basket._id, userRef: basket.userRef })
                    .then((res) => {
                        props.emptyCart();
                        setFetchingCart(false);
                    })
                    .catch(() => setFetchingCart(false));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basket, serviceData, outletId]);

    if (props.outletData === null || fetchingCart) return <Loader />;
    if (props.children) return props.children;
    return <Outlet />;
}

const mapStateToProps = (state) => {
    return {
        outletData: state.outletData,
        siteData: state.siteData,
        serviceData: state.serviceData,
        user: state.user,
        basket: state.cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOutletData: (outlet) => {
            dispatch(setOutletData(outlet));
        },
        emptyCart: () => dispatch(emptyCart()),
        getUserCart: (cart) => dispatch(getUserCart(cart)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentWrapper);
