import { capitalizeEachWord } from "../../common/utils";
import CustomModal from "../custom-modal";

const SiteOutletInfoModal = ({
    modalIsOpen,
    closeModal,
    displayName,
    description,
    address1,
    address2,
    postalCode,
    country,
    city,
    category,
    type,
    startDate,
    endDate,
}) => {
    return (
        <CustomModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            title={displayName + " Info"}
        >
            {Boolean(description) && (
                <>
                    <div className="info-modal-field-heading">Description</div>
                    <div className="info-modal-field-content">
                        {description}
                    </div>
                </>
            )}
            {Boolean(type === "EVENT") && (
                <>
                    <div className="info-modal-field-heading">Date</div>
                    <div className="info-modal-field-content">
                        {new Date(startDate).toDateString()}
                        {" - "}
                        {new Date(endDate).toDateString()}
                    </div>
                </>
            )}
            <div className="info-modal-field-heading">Address</div>
            <div className="info-modal-field-content">
                <div className="outlet-address-line">{address1}</div>
                <div className="outlet-address-line">{address2}</div>
                <div>{`${postalCode}, ${city}, ${country}`}</div>
            </div>
        </CustomModal>
    );
};

export default SiteOutletInfoModal;
