import "../cart.scss";
import MenuItemCard from "./menuItemCard";

export default function MenuCard({
    menu,
    inBasketCount,
    remainingQty,
    vendorRef,
    itemIds,
}) {
    if (menu.items.every((item) => itemIds.includes(item.itemRef))) return null;

    return (
        <div className="upsell-menu-container" key={menu._id}>
            <div className="menu-title">With {menu.displayName}</div>
            <div className="item-list-flex">
                {menu.items.map((item) => {
                    if (itemIds.includes(item.itemRef)) return <></>;
                    return (
                        <MenuItemCard
                            remainingQty={remainingQty}
                            itemData={{ ...item, outletRef: item.outletRef || vendorRef }}
                            inBasket={inBasketCount[item._id] || 0}
                            key={item._id}
                        />
                    );
                })}
            </div>
        </div>
    );
}
