import constants from "../../constants/constants";
import * as actionTypes from "../actions/ActionTypes";
import {
    productsInitialState,
    addItemsToCart,
    emptyCart,
    addVendorId,
    setReload,
    setOutletData,
    setServiceData,
    removeServiceData,
    setSiteData,
    setCartLoading,
    getUserCart,
    setMenuPayType,
    setPrepaidMaxProduct,
    setPrepaidEmail,
    setQuantityLoader,
    setZoneData,
} from "./productReducer";
import {
    setUser,
    userInitialState,
    setGuestSession,
    setRedirectURL,
    setUniqueMetricId,
    getUniqueMetricId,
    setUniqueDeviceId,
    getUniqueDeviceId,
} from "./userReducer";

const getServiceData = () => {
    try {
        return JSON.parse(localStorage.getItem("serviceData"));
    } catch {
        return null;
    }
};

const getAssignedPromotions = () => {
    try {
        return JSON.parse(localStorage.getItem("assignedPromotions"));
    } catch {
        return null;
    }
};

// const getOutletData = () => {
//   try {
//     return JSON.parse(sessionStorage.getItem("outletData"));
//   } catch {
//     return null;
//   }
// }

const getZoneData = () => {
    try {
        return JSON.parse(localStorage.getItem("zoneData"));
    } catch {
        return null;
    }
};
const initialState = {
    ...productsInitialState,
    outletData: null, //getOutletData(),
    siteData: null,
    zoneData: getZoneData(),
    menuReload: true,
    cartLoading: false,
    serviceData: getServiceData(),
    menuPayType:
        sessionStorage.getItem("menuPayType") || constants.MENU_PAY_TYPE.PAID,
    prepaidMaxProduct: Number(sessionStorage.getItem("prepaidMaxProduct")) || 0,
    prepaidEmail: sessionStorage.getItem("prepaidEmail"),
    quantityLoader: false,
    assignedPromotions: getAssignedPromotions(),
    isDisableSocialLogin:
        localStorage.getItem("isDisableSocialLogin") === "true",
    ...userInitialState,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_ITEMS_CART_ACTION:
            return addItemsToCart(state, action);
        case actionTypes.EMPTY_CART_ACTION:
            return emptyCart(state, action);
        case actionTypes.ADD_VENDOR_ID:
            return addVendorId(state, action);
        case actionTypes.MENU_RELOAD:
            return setReload(state, action);
        case actionTypes.SET_OUTLET_DATA:
            return setOutletData(state, action);
        case actionTypes.SET_SERVICE_DATA:
            return setServiceData(state, action);
        case actionTypes.REMOVE_SERVICE_DATA:
            return removeServiceData(state, action);
        case actionTypes.SET_SITE_DATA:
            return setSiteData(state, action);
        case actionTypes.SET_USER:
            return setUser(state, action);
        case actionTypes.SET_GUEST_SESSION:
            return setGuestSession(state, action);
        case actionTypes.SET_CART_LOADING:
            return setCartLoading(state, action);
        case actionTypes.GET_USER_CART:
            return getUserCart(state, action);
        case actionTypes.SET_REDIRECT_URL:
            return setRedirectURL(state, action);
        case actionTypes.SET_MENU_PAY_TYPE:
            return setMenuPayType(state, action);
        case actionTypes.SET_PREPAID_MAX_PRODUCT:
            return setPrepaidMaxProduct(state, action);
        case actionTypes.SET_PREPAID_EMAIL:
            return setPrepaidEmail(state, action);
        case actionTypes.SET_QUANTITY_LOADER:
            return setQuantityLoader(state, action);
        case actionTypes.SET_ZONE_DATA:
            return setZoneData(state, action);
        case actionTypes.GET_UNIQUE_METRIC_ID:
            return getUniqueMetricId(state, action);
        case actionTypes.SET_UNIQUE_METRIC_ID:
            return setUniqueMetricId(state, action);
        case actionTypes.GET_UNIQUE_DEVICE_ID:
            return getUniqueDeviceId(state, action);
        case actionTypes.SET_UNIQUE_DEVICE_ID:
            return setUniqueDeviceId(state, action);
        default:
            return state;
    }
};

export default reducer;
