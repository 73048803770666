// External Dependencies
import * as yup from "yup";
import { useState } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { ErrorMessage, Formik } from "formik";
import { RiLockPasswordFill } from "react-icons/ri";
import {
    AiFillEye,
    AiFillEyeInvisible,
    AiFillMobile,
    AiTwotoneMail,
} from "react-icons/ai";
import { FaCalendarAlt, FaIdCard, FaUserAlt } from "react-icons/fa";

// Custom Components
import MainBanner from "../main-banner";
import { Loader } from "../custom-loader";
import CustomButton from "../custom-button";
import { notify } from "../../common/utils";

// Styles
import "./activateMembership.scss";
import "react-datepicker/dist/react-datepicker.css";

// Service
import { activateMembership } from "../../services/authenticate.service";
import constants from "../../constants/constants";

const ActivateMembershipComp = ({
    customer,
    validationSchema,
    onSubmit,
    site,
    loading,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div className="activate-membership-container">
            <MainBanner
                logoImage={site.siteImage}
                bannerImage={site.mapImage}
                title={`${site.displayName}<br/>Activate your membership`}
                description={site.description}
            />
            <div className="register-flex-col">
                <Formik
                    initialValues={customer}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => (
                        <div className="form-container">
                            <div className="form-item">
                                <div className="wrapper">
                                    <FaIdCard className="icon" />
                                    <input
                                        {...props.getFieldProps(
                                            "registerMembershipId",
                                        )}
                                        name="registerMembershipId"
                                        type="text"
                                        placeholder="Membership Id"
                                        className="form-input input"
                                    />
                                </div>
                                <ErrorMessage
                                    name="registerMembershipId"
                                    component="div"
                                    className="form-error"
                                />
                            </div>
                            <div className="form-item">
                                <div className="wrapper">
                                    <FaUserAlt className="icon" />
                                    <input
                                        {...props.getFieldProps("registerName")}
                                        name="registerName"
                                        type="text"
                                        placeholder="First Name"
                                        className="form-input"
                                    />
                                </div>
                                <ErrorMessage
                                    name="registerName"
                                    component="div"
                                    className="form-error"
                                />
                            </div>
                            <div className="form-item">
                                <div className="wrapper">
                                    <FaUserAlt className="icon" />
                                    <input
                                        {...props.getFieldProps(
                                            "registerLastName",
                                        )}
                                        name="registerLastName"
                                        type="text"
                                        placeholder="Last Name"
                                        className="form-input"
                                    />
                                </div>
                                <ErrorMessage
                                    name="registerLastName"
                                    component="div"
                                    className="form-error"
                                />
                            </div>
                            <div className="form-item">
                                <div className="wrapper">
                                    <FaCalendarAlt
                                        className="icon"
                                        style={{ zIndex: 1 }}
                                    />
                                    <DatePicker
                                        showYearDropdown={true}
                                        scrollableYearDropdown={true}
                                        yearDropdownItemNumber={70}
                                        maxDate={new Date()}
                                        selected={
                                            props.values.registerBirthDate
                                        }
                                        onSelect={(date) => {
                                            props.setFieldValue(
                                                "registerBirthDate",
                                                date,
                                            );
                                        }}
                                        className="form-input date-picker"
                                        placeholderText="Date of Birth"
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                                <ErrorMessage
                                    name="registerBirthDate"
                                    component="div"
                                    className="form-error"
                                />
                            </div>
                            <div className="form-item">
                                <div className="wrapper">
                                    <AiFillMobile className="icon" />
                                    <input
                                        {...props.getFieldProps(
                                            "registerMobileNum",
                                        )}
                                        name="registerMobileNum"
                                        type="text"
                                        placeholder="Mobile No."
                                        className="form-input"
                                    />
                                </div>
                                <ErrorMessage
                                    name="registerMobileNum"
                                    component="div"
                                    className="form-error"
                                />
                            </div>

                            <div className="form-item">
                                <div className="wrapper">
                                    <AiTwotoneMail className="icon" />
                                    <input
                                        {...props.getFieldProps(
                                            "registerEmail",
                                        )}
                                        name="registerEmail"
                                        type="email"
                                        placeholder="Email"
                                        className="form-input"
                                        autoComplete={false}
                                    />
                                </div>
                                <ErrorMessage
                                    name="registerEmail"
                                    component="div"
                                    className="form-error"
                                />
                            </div>
                            <div className="form-item">
                                <div className="wrapper">
                                    <RiLockPasswordFill className="icon" />
                                    {showPassword ? (
                                        <AiFillEye
                                            className="eye-icon"
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        />
                                    ) : (
                                        <AiFillEyeInvisible
                                            className="eye-icon"
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        />
                                    )}
                                    <input
                                        {...props.getFieldProps(
                                            "registerPassword",
                                        )}
                                        name="registerPassword"
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        placeholder="Password"
                                        className="form-input"
                                        autoComplete={false}
                                    />
                                </div>
                                <ErrorMessage
                                    name="registerPassword"
                                    component="div"
                                    className="form-error"
                                />
                            </div>
                            <div className="flex-align-center">
                                <CustomButton
                                    className="btn-block btn-bw"
                                    type="submit"
                                    onClick={props.handleSubmit}
                                    disabled={loading}
                                >
                                    Activate Membership
                                </CustomButton>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
};

const ActivateMembership = (props) => {
    const site = props.siteData;
    const [loading, setLoading] = useState(false);
    const [isActivated, setIsActivated] = useState(false);
    const customer = {
        registerName: "",
        registerLastName: "",
        registerMobileNum: "",
        registerEmail: "",
        registerPassword: "",
        registerConfirmPassword: "",
        registerBirthDate: "",
        registerMembershipId: "",
    };

    const validationSchema = yup.object().shape({
        registerName: yup.string().trim().required().label("First Name"),
        registerLastName: yup.string().trim().label("Last Name"),
        registerMobileNum: yup
            .string()
            .label("Mobile Number")
            .nullable()
            .matches(constants.REGEX.MOBILE_NUMBER, 'Mobile number is not valid'),
        registerEmail: yup
            .string()
            .trim()
            .email("Invalid Email!")
            .required()
            .label("Email"),
        registerPassword: yup
            .string()
            .trim()
            .required()
            .label("Password")
            .min(6, "The password length should be a minimum of 6 characters.")
            .max(
                16,
                "The password length should be a maximum of 16 characters.",
            )
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[a-zA-Z]).{6,16}$/,
                "The password should contain at least one capital letter, one lowercase letter, one digit, one alphabetical character, and be between 6 and 16 characters long.",
            ),
        registerBirthDate: yup
            .date()
            .max(new Date(), "Birth date can not be of future."),
        registerMembershipId: yup
            .string()
            .trim()
            .required()
            .label("Membership ID"),
    });

    const onSubmit = (values) => {
        const payload = {
            firstName: values.registerName ? values.registerName : "",
            lastName: values.registerLastName ? values.registerLastName : "",
            contactNo: values.registerMobileNum
                ? values.registerMobileNum
                : null,
            birthdate: values.registerBirthDate
                ? new Date(values.registerBirthDate).toISOString()
                : null,
            email: values.registerEmail,
            password: values.registerPassword,
            user: "CUSTOMER",
            membershipId: values.registerMembershipId,
            siteId: site._id,
        };
        setLoading(true);
        activateMembership(payload)
            .then((res) => {
                setIsActivated(true);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notify(err.message);
            });
    };

    const ThankYouNote = () => {
        return (
            <div className="activate-membership-container">
                <MainBanner
                    logoImage={site.siteImage}
                    bannerImage={site.mapImage}
                    title={`${site.displayName}<br/>Activate your membership`}
                    description={""}
                />
                <div className="thank-you-note-container">
                    <div className="thank-you-note-text">Thank You!</div>
                    <div className="thank-you-note-text">
                        You will receive an email with <br />
                        your Membership ID.
                    </div>
                </div>
            </div>
        );
    };
    if (isActivated) {
        return <ThankYouNote />;
    }

    return (
        <>
            {loading && <Loader />}
            <ActivateMembershipComp
                site={site}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                loading={loading}
                customer={customer}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        siteData: state.siteData,
    };
};

export default connect(mapStateToProps, null)(ActivateMembership);
