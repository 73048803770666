import "../outlets/outlet.scss";
import { useEffect, useState } from "react";
import {
    getBannerClassName,
    getImageUrl,
    observerShowDescription,
} from "../../common/utils";
import { IMAGE_SIZES } from "../../constants/image";

const MainBanner = ({
    logoImage = null,
    bannerImage = null,
    description = "",
    title = "",
}) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        observerShowDescription();
    }, [logoImage, bannerImage, description, title]);

    const className = getBannerClassName(logoImage, bannerImage);

    return (
        <>
            {title ? (
                <div className="box-container text-center">
                    <div
                        className="title"
                        dangerouslySetInnerHTML={{ __html: title }}
                    ></div>
                </div>
            ) : null}
            {logoImage || bannerImage ? (
                <div className={`banner ${className}`}>
                    {logoImage && (
                        <div className="banner-top">
                            <img
                                src={getImageUrl(logoImage) + IMAGE_SIZES.logo}
                                onError={(e) => {
                                    e.target.src = getImageUrl(logoImage);
                                }}
                                alt="Logo not available"
                            />
                        </div>
                    )}
                    {bannerImage && (
                        <div className="banner-main">
                            <img
                                src={
                                    getImageUrl(bannerImage) +
                                    IMAGE_SIZES.banner
                                }
                                onError={(e) => {
                                    e.target.src = getImageUrl(bannerImage);
                                }}
                                alt="Logo not available"
                            />
                        </div>
                    )}
                </div>
            ) : null}
            {description && description.trim().length > 0 ? (
                <div className="service-type-landing-description-inception">
                    <div className="service-type-landing-description-container">
                        {(description.split("\r\n") || []).map((desc) => {
                            return (
                                <p
                                    className={`box-container service-type-landing-description ${
                                        visible ? "remove-line-camp" : ""
                                    }`}
                                >
                                    {desc}
                                </p>
                            );
                        })}
                        <label
                            className={`box-container ${
                                visible ? "show-view-more" : ""
                            }`}
                            onClick={() => setVisible(!visible)}
                        >
                            {" "}
                            {visible ? "View Less" : "View More"}
                        </label>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default MainBanner;
