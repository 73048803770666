import { updateObject } from "../actions/utility";

const getUserData = () => {
    try {
        return JSON.parse(localStorage.getItem("user"));
    } catch {
        return null;
    }
};

export const getUniqueMetricId = () => {
    try {
        const metricId = JSON.parse(localStorage.getItem("metricId"));
        return metricId !== "null" ? metricId : null;
    } catch {
        return null;
    }
};

export const getUniqueDeviceId = () => {
    try {
        return JSON.parse(localStorage.getItem("deviceId"));
    } catch {
        return null;
    }
};

const getGuestSession = () => {
    try {
        return sessionStorage.getItem("authType");
    } catch {
        return "";
    }
};

const getRedirectURL = () => {
    try {
        return sessionStorage.getItem("redirectURL");
    } catch {
        return null;
    }
};

export const userInitialState = {
    user: getUserData(),
    authType: getGuestSession(),
    metricId: getUniqueMetricId(),
    redirectURL: getRedirectURL(),
    deviceId: getUniqueDeviceId(),
};

export const setUser = (state, action) => {
    localStorage.setItem("user", JSON.stringify(action.payload));
    return updateObject(state, {
        user: action.payload,
    });
};
export const setUniqueMetricId = (state, action) => {
    localStorage.setItem("metricId", JSON.stringify(action.payload));
    return updateObject(state, {
        metricId: action.payload,
    });
};
export const setUniqueDeviceId = (state, action) => {
    localStorage.setItem("deviceId", JSON.stringify(action.payload));
    return updateObject(state, {
        deviceId: action.payload,
    });
};
export const setGuestSession = (state, action) => {
    sessionStorage.setItem("authType", action.payload);
    return updateObject(state, {
        authType: action.payload,
    });
};

export const setRedirectURL = (state, action) => {
    sessionStorage.setItem("redirectURL", action.payload);
    return updateObject(state, {
        redirectURL: action.payload,
    });
};
