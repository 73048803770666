import { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router";
import useQuery from "../../common/useQuery";
import { setUser } from "../../store/actions/UserAction";
import { connect } from "react-redux";
import {
    AiFillEye,
    AiFillEyeInvisible,
    AiOutlineArrowLeft,
} from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { ImFacebook2 } from "react-icons/im";
import { BsApple } from "react-icons/bs";
import "./login.scss";
import * as yup from "yup";
import { ErrorMessage, Formik } from "formik";
import CustomButton from "../custom-button";
import {
    OAuthCustomer,
    getUserDetail,
} from "../../services/authenticate.service";
import jwtService from "../../services/jwtService";
import { notify } from "../../common/utils";
import { Loader } from "../custom-loader";
import constants from "../../constants/constants";
import { apiService } from "../../services";

const SignIn = ({ isDisableSocialLogin, assignedPromotions, ...props }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const location = useLocation();
    const query = useQuery();
    const redirectURL = location.state?.redirectURL;
    const customer = {
        loginEmail: "",
        loginPassword: "",
    };

    const validationSchema = yup.object().shape({
        loginEmail: yup
            .string()
            .trim()
            .email("Invalid Email!")
            .required()
            .label("Email"),
        loginPassword: yup.string().trim().required().label("Password"),
    });

    const onSubmit = async (values) => {
        setLoading(true);
        await jwtService
            .signInWithEmailAndPassword(
                values.loginEmail,
                values.loginPassword,
                redirectURL,
            )
            .then(async (user) => {
                props.setUser(user);
                if (redirectURL) {
                    navigate(redirectURL.replace(window.location.origin, ""), {
                        replace: true,
                    });
                } else
                    navigate(`/${constants.ROUTES.CUSTOMER}`, {
                        replace: true,
                    });
            })
            .catch((error) => {
                notify(error);
                props.setUser(null);
                setLoading(false);
            });
    };

    const oauthButtonClick = (socialType) => {
        OAuthCustomer(socialType, redirectURL);
    };

    const fetchUser = (redirectAfterAuthentication) => {
        setLoading(true);
        getUserDetail()
            .then((user) => {
                props.setUser(user);
                if (
                    redirectAfterAuthentication.replace(
                        window.location.origin,
                        "",
                    ) === "/login"
                ) {
                    setLoading(false);
                    navigate(`/${constants.ROUTES.CUSTOMER}`, {
                        replace: true,
                        state: { noBackURL: true },
                        // negative scenario
                    });
                } else if (redirectAfterAuthentication !== "null") {
                    setLoading(false);
                    navigate(
                        `${redirectAfterAuthentication.replace(
                            window.location.origin,
                            "",
                        )}`,
                        {
                            replace: true,
                        },
                    );
                } else {
                    setLoading(false);
                    navigate(
                        `${redirectAfterAuthentication.replace(
                            window.location.origin,
                            "",
                        )}`,
                        {
                            replace: true,
                        },
                    );
                }
            })
            .catch((err) => {
                setLoading(false);
                notify(err);
            });
    };

    useEffect(() => {
        const accessToken = query.get("accessToken");
        const redirectAfterAuthentication = query.get(
            "redirectAfterAuthentication",
        );
        if (accessToken) fetchUser(redirectAfterAuthentication);
        else if (redirectAfterAuthentication)
            navigate(
                `${redirectAfterAuthentication.replace(
                    window.location.origin,
                    "",
                )}`,
                {
                    replace: true,
                },
            );
        // failure case added
    }, []);

    useEffect(() => {
        if (
            !loading &&
            props.user &&
            localStorage.getItem("jwt_access_token") !== null
        ) {
            props.redirectURL
                ? navigate(props.redirectURL)
                : navigate(`/${constants.ROUTES.CUSTOMER}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <>
            {loading && <Loader loaderBgColor="white" />}
            <div className="login-flex-col">
                <div className="login-header">
                    <div className="mr-1">
                        <AiOutlineArrowLeft
                            size={35}
                            onClick={() => {
                                navigate(-1);
                            }}
                        />
                    </div>
                    <div className="header-title">Sign In</div>
                </div>
                <div
                    className={`login-form-container  ${
                        isDisableSocialLogin ? "no-social-login" : ""
                    }`}
                >
                    {isDisableSocialLogin !== true ? (
                        <Fragment>
                            <div className="social-oauth-buttons">
                                <div className="row">
                                    <CustomButton
                                        type="button"
                                        className="oauth-button"
                                        onClick={() =>
                                            oauthButtonClick("google")
                                        }
                                    >
                                        <FcGoogle /> Google
                                    </CustomButton>
                                    <CustomButton
                                        type="button"
                                        className="oauth-button"
                                        onClick={() =>
                                            oauthButtonClick("facebook")
                                        }
                                    >
                                        <ImFacebook2 /> Facebook
                                    </CustomButton>
                                </div>
                                <div className="row">
                                    {/* <CustomButton type="button" className='oauth-button' onClick={() => OAuthCustomer('instagram')}><FaInstagram /> Instagram</CustomButton> */}
                                    <CustomButton
                                        type="button"
                                        className="oauth-button"
                                        onClick={() =>
                                            oauthButtonClick("apple")
                                        }
                                    >
                                        <BsApple /> Apple ID
                                    </CustomButton>
                                </div>
                            </div>
                            <div>
                                <hr className="seprator"></hr>
                            </div>
                            <div className="login-terms-conditions or-text">
                                or
                            </div>
                        </Fragment>
                    ) : (
                        <></>
                    )}

                    <Formik
                        initialValues={customer}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {(props) => (
                            <div className="form-container">
                                <div className="form-item">
                                    <label className="form-label">Email*</label>
                                    <input
                                        {...props.getFieldProps("loginEmail")}
                                        name="loginEmail"
                                        type="email"
                                        placeholder="Insert Email"
                                        className="form-input"
                                    />
                                    <ErrorMessage
                                        name="loginEmail"
                                        component="div"
                                        className="form-error"
                                    />
                                </div>
                                <div className="form-item">
                                    <label className="form-label">
                                        Password*
                                    </label>
                                    <div className="password-form-group">
                                        {showPassword ? (
                                            <AiFillEye
                                                className="icon"
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword,
                                                    )
                                                }
                                            />
                                        ) : (
                                            <AiFillEyeInvisible
                                                className="icon"
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword,
                                                    )
                                                }
                                            />
                                        )}
                                        <input
                                            {...props.getFieldProps(
                                                "loginPassword",
                                            )}
                                            name="loginPassword"
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            placeholder="********"
                                            className="form-input"
                                        />
                                    </div>
                                    <ErrorMessage
                                        name="loginPassword"
                                        component="div"
                                        className="form-error"
                                    />
                                </div>
                                <div className="form-item-end">
                                    <span
                                        onClick={() =>
                                            navigate("/forgot-password")
                                        }
                                    >
                                        Forgot Password
                                    </span>
                                </div>
                                <div>
                                    <CustomButton
                                        type="submit"
                                        onClick={props.handleSubmit}
                                        disabled={loading}
                                        className="btn-block btn-login-page"
                                    >
                                        Sign In
                                    </CustomButton>
                                </div>
                                <div className="form-item-center">
                                    First Time User?
                                </div>
                                <div>
                                    <CustomButton
                                        type="button"
                                        onClick={() => navigate("/register")}
                                        disabled={loading}
                                        className="btn-block btn-trans"
                                    >
                                        Register
                                    </CustomButton>
                                </div>
                            </div>
                        )}
                    </Formik>

                    <div className="login-terms-conditions">
                        <div>By signing you are accepting</div>
                        <div>
                            <a
                                target="_blank"
                                href="https://www.noqgroup.com/terms-and-conditions/"
                                rel="noreferrer"
                            >
                                {" "}
                                Terms and Conditions
                            </a>{" "}
                            and{" "}
                            <a
                                target="_blank"
                                href="https://www.noqgroup.com/privacy-policy/"
                                rel="noreferrer"
                            >
                                {" "}
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.user,
        redirectURL: state.redirectURL,
        assignedPromotions: state.assignedPromotions,
        isDisableSocialLogin: state.isDisableSocialLogin,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => {
            dispatch(setUser(user));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
