import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../custom-button";
import "./serviceType.scss";
import { Loader } from "../custom-loader";
import {
    setMenuPayType,
    setServiceData,
    setPrepaidMaxProduct,
    setPrepaidEmail,
    removeCart,
} from "../../store/actions/ProductActions";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import constants from "../../constants/constants";
import PrePaidMenuEmail from "./PrePaidMenuEmail";
import { notify } from "../../common/utils";
// import MainHeader from "../common/MainHeader";

const LiveOrderMenuSelection = ({
    isLiveOrderLoading,
    menuList,
    setServiceData,
    service,
    minOrder,
    deliveryFee,
    liveModal,
    removeCart,
    vendorZoneName,
    setMenuPayType,
    setPrepaidMaxProduct,
    setPrepaidEmail,
    menuPayType,
    hasReplace = false,
    setLiveModal,
}) => {
    const navigate = useNavigate();
    const { zoneId, siteId, outletId, serviceType } = useParams();
    const redirectUrl = zoneId
        ? `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.ZONE}/${zoneId}/${constants.ROUTES.OUTLET}/${outletId}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`
        : siteId
        ? `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.OUTLET}/${outletId}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`
        : `/${constants.ROUTES.OUTLET}/${outletId}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`;
    const [showPrepaidMenuEmail, setShowPrepaidMenuEmail] = useState(false);
    const [menu, setMenu] = useState("");

    useEffect(() => {
        if (!isLiveOrderLoading && liveModal && menuList.length === 1) {
            handleNavigate(menuList[0].menu.menuRef);
        }
    }, [liveModal, menuList, isLiveOrderLoading]);

    const handleNavigate = (selectedMenu) => {
        if (
            selectedMenu.menuPayType === constants.MENU_PAY_TYPE.PREPAID &&
            !(
                serviceType === "onsiteCollection" ||
                serviceType === "onsiteDelivery" ||
                serviceType === "onsiteExpress"
            )
        ) {
            notify("This service type is not allow to order for prepaid menu");
            return;
        }
        setServiceData({
            ...service,
            serviceType: serviceType,
            serviceTypeDisplayName: service.displayName,
            preOrder: false,
            minOrder: minOrder,
            deliveryFee:
                serviceType === "offsiteDelivery" ||
                serviceType === "onsiteDelivery"
                    ? deliveryFee
                    : 0,
            menuRef: selectedMenu,
            tableNumber:
                serviceType === "onsiteDelivery" ? service.tableNumber : null,
            postCode:
                serviceType === "offsiteDelivery" ? service.postCode : null,
            vendorZoneName: vendorZoneName,
            locationIdentifier: service.locationIdentifier,
        });
        if (selectedMenu.menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
            setMenu(selectedMenu);
            setShowPrepaidMenuEmail(true);
            setMenuPayType(selectedMenu.menuPayType);
        } else {
            setPrepaidMaxProduct(null);
            setPrepaidEmail(null);
            if (menuPayType === "prepaid") removeCart();
            setMenuPayType(selectedMenu.menuPayType);
            navigate(`${redirectUrl}/menus/${selectedMenu._id}`, {
                replace: hasReplace,
            });
        }
    };
    if (isLiveOrderLoading) return <Loader />;

    if (showPrepaidMenuEmail)
        return <PrePaidMenuEmail menu={menu} redirectUrl={redirectUrl} />;
    return (
        <div className="box-container">
            {/* <MainHeader name="Menus" showBottomLine={true} showBackButton={true} onBackClick={() => setLiveModal(false)} /> */}
            {menuList && menuList.length > 1 ? (
                <>
                    <div className="row">
                        <div className="column-two menu-selection-heading">
                            Select Menu
                        </div>
                    </div>
                    {menuList.map((menu, index) => (
                        <CustomButton
                            className="btn-block menu-button-detail"
                            onClick={() => handleNavigate(menu.menu.menuRef)}
                            key={index}
                        >
                            {menu.menu.menuRef.displayName}{" "}
                            {menu.menu.menuRef.menuPayType === "prepaid"
                                ? "(Guest List)"
                                : ""}
                        </CustomButton>
                    ))}
                </>
            ) : null}
            {!menuList || menuList.length === 0 ? (
                <p>Menu is not available</p>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        menuPayType: state.menuPayType,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setServiceData: (service) => {
            dispatch(setServiceData(service));
        },
        setMenuPayType: (type) => dispatch(setMenuPayType(type)),
        removeCart: () => dispatch(removeCart()),
        setPrepaidMaxProduct: (maxProduct) => {
            dispatch(setPrepaidMaxProduct(maxProduct));
        },
        setPrepaidEmail: (email) => {
            dispatch(setPrepaidEmail(email));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LiveOrderMenuSelection);
