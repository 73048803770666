import "./checkbox.scss";

export default function CustomCheckbox({
    isChecked,
    label,
    type = "box",
    handleClick,
    containerClass,
}) {
    return (
        <div
            className={`checkbox-container ${containerClass}`}
            onClick={handleClick}
        >
            <div
                className={`${type}-${
                    isChecked ? "checked" : "unchecked"
                }-radio`}
            >
                <div
                    className={`${type}-${
                        isChecked ? "checked" : "unchecked"
                    }-radio-outline`}
                >
                    <div
                        className={`${type}-${
                            isChecked ? "checked" : "unchecked"
                        }-radio-inner`}
                    ></div>
                </div>
            </div>
            {label && <div className="checkbox-label">{label}</div>}
        </div>
    );
}
