import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.scss";
import store from "./store";

const app = (
    <Provider store={store}>
        <App />
    </Provider>
);

const root = createRoot(document.getElementById("root"));
root.render(app);

if (module.hot) {
    module.hot.accept();
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
