import { connect } from "react-redux";
import "../cart.scss";
import ItemCard from "./ItemCard";

const CartList = (props) => {
    const { basket } = props;
    return (
        <div className="box-container">
            <div className="item-list">
                {basket.carts.map((cart) => {
                    return cart.items.map((item, index) => (
                        <ItemCard
                            storeName={cart.storeName}
                            item={{
                                ...item,
                                outletRef: item.outletRef || cart.vendorRef,
                            }}
                            index={index}
                            key={index}
                        />
                    ));
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        basket: state.cart,
    };
};

export default connect(mapStateToProps, null)(CartList);
