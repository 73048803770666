import Slider from "react-slick";
import "./carousel.scss";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const CustomCarousel = (props) => {
    const PrevArrow = ({ style, onClick, className }) => (
        <AiOutlineLeft
            onClick={onClick}
            style={{
                ...style,
                left: "-12px",
                zIndex: 1,
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                strokeWidth: "50",
            }}
            className={className}
            size={20}
        />
    );
    const NextArrow = ({ style, onClick, className }) => (
        <AiOutlineRight
            onClick={onClick}
            style={{
                ...style,
                right: "-12px",
                zIndex: 1,
                position: "absolute",
                bottom: "50%",
                transform: "translateY(50%)",
                strokeWidth: "50",
            }}
            className={className}
            size={20}
        />
    );
    const settings = props.settings
        ? props.settings
        : {
              dots: false,
              infinite: false,
              speed: 500,
              slidesToShow: 3,
              slidesToScroll: 1,
              className: "slider-carousel",
              initialSlide: 0,
              prevArrow: <PrevArrow />,
              nextArrow: <NextArrow />,
              adaptiveHeight: true,
              responsive: [
                  {
                      breakpoint: 768,
                      settings: {
                          slidesToShow: 3,
                          slidesToScroll: 2,
                          infinite: false,
                          initialSlide: 0,
                          arrows: true,
                      },
                  },
                  {
                      breakpoint: 550,
                      settings: {
                          slidesToShow: 2,
                          slidesToScroll: 1,
                          initialSlide: 0,
                          arrows: true,
                          infinite: false,
                      },
                  },
              ],
              lazyLoad: "true",
          };

    return (
        <>
            <Slider {...settings}>{props.children}</Slider>
        </>
    );
};

export default CustomCarousel;
