import AdyenCheckout from "@adyen/adyen-web";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { AiOutlineCloseCircle, AiOutlineWarning } from "react-icons/ai";
import { connect } from "react-redux";
import { useNavigate } from "react-router";

import useQuery from "../../common/useQuery";
import { notify } from "../../common/utils";
import apiConsts from "../../constants/apiConstants";
import { apiService } from "../../services";
import { removeCart } from "../../services/api.service";
import { emptyCart } from "../../store/actions/ProductActions";
import CustomButton from "../custom-button";
import { Loader } from "../custom-loader";

const RedirectAdyen = (props) => {
    const navigate = useNavigate();
    const query = useQuery();
    const redirectResult = query.get("redirectResult");
    const sessionId = query.get("sessionId");
    const [status, setStatus] = useState({
        loading: true,
        paymentSucceeded: null,
        errorMessage: "",
    });

    useEffect(() => {
        createCheckout()
            .then((checkout) => {
                checkout.submitDetails({
                    details: { redirectResult: redirectResult },
                });
            })
            .catch((err) => {
                setStatus({
                    loading: false,
                    paymentSucceeded: false,
                    errorMessage:
                        "Payment failed! \n Please try with another card.",
                });
            });
    }, [redirectResult, sessionId]);

    const onPaymentSuccess = async () => {
        ReactGA.event("conversion_rate");
        props.emptyLocalCart();
        try {
            const result = await apiService.completeAdyenOrderBySessionId(
                sessionId,
                true,
            );
            const data = result.data;

            if (data) {
                if (data.isMultiOrder && data.cartId) {
                    navigate(`/multi/orders/${data.cartId}`, {
                        replace: true,
                    });
                    return;
                }

                if (data.orderId) {
                    navigate(`/orders/${data.orderId}`, {
                        replace: true,
                    });
                    return;
                }
            }
        } catch (error) {
            setStatus({
                loading: false,
                paymentSucceeded: true,
                errorMessage: "Something went Wrong! \nPlease try again!",
            });
            removeCart().catch(() => {});
        }
    };

    const onPaymentFailure = async () => {
        try {
            await apiService.completeAdyenOrderBySessionId(sessionId, false);
        } catch (error) {
            notify(error);
        }
        setStatus({
            loading: false,
            paymentSucceeded: false,
            errorMessage: "Payment failed! \nPlease try with another card.",
        });
    };

    const onPaymentCompleted = async (response, _component) => {
        const paymentSucceeded = response?.resultCode === "Authorised";
        paymentSucceeded ? onPaymentSuccess() : onPaymentFailure();
    };

    const onError = async (error, _component) => {
        setStatus({
            loading: false,
            paymentSucceeded: false,
            errorMessage: error.message,
        });
    };

    const createCheckout = async () => {
        try {
            const checkout = await AdyenCheckout({
                shopperInteraction: "Ecommerce",
                storePaymentMethod: false,
                locale: "en_US",
                showPayButton: true,
                clientKey: apiConsts.adyenClientKey,
                environment: process.env.REACT_APP_ADYEN_ENVIORNMENT,
                instantPaymentTypes: ["googlepay", "applepay"],
                session: {
                    id: sessionId,
                },
                onPaymentCompleted,
                onError,
            });
            return checkout;
        } catch (error) {
            return error;
        }
    };

    if (status.loading) {
        return <Loader />;
    }

    return (
        <>
            {status.paymentSucceeded ? (
                <div>
                    <div className={`adyen-container`}>
                        <div className={`adyen-child-container`}>
                            <AiOutlineWarning size={100} color="#F9E897" />
                            <h4 className="mt-10 mb-10 info-message">
                                {status.errorMessage}
                            </h4>
                            <CustomButton
                                onClick={() => {
                                    setStatus({
                                        loading: true,
                                        paymentSucceeded: null,
                                        errorMessage: "",
                                    });
                                    onPaymentSuccess();
                                }}
                                className="btn-block"
                            >
                                Try again
                            </CustomButton>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className={`adyen-container`}>
                        <div className={`adyen-child-container`}>
                            <AiOutlineCloseCircle size={100} color="#FF6868" />
                            <h4 className="mt-10 mb-10 error-message">
                                {status.errorMessage}
                            </h4>
                            <CustomButton
                                onClick={() => navigate(-1)}
                                className="btn-block"
                            >
                                Go back
                            </CustomButton>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const mapDispatchToPros = (dispatch) => {
    return {
        emptyLocalCart: () => dispatch(emptyCart()),
    };
};

export default connect(null, mapDispatchToPros)(RedirectAdyen);
