import { BrowserRouter } from "react-router-dom";
import { withErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import { IntlProvider } from "react-intl";
import Authentication from "./components/authentication";
import "react-toastify/dist/ReactToastify.css";

import BaseRouter from "./router";
import { ErrorFallback } from "./components/error-component/ErrorFallback";
import Layouts from "./container/Layouts";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import apiConsts from "./constants/apiConstants";

const RouterWithErrorBoundary = withErrorBoundary(BaseRouter, {
    FallbackComponent: ErrorFallback,
});

const stripePromise = loadStripe(
    apiConsts.stripeKey ? apiConsts.stripeKey : "",
);
function App() {
    return (
        <BrowserRouter>
            <Authentication>
                <Layouts>
                    <Elements
                        stripe={stripePromise}
                        options={{
                            locale: "en",
                            fonts: [
                                {
                                    family: "Cantarell",
                                    src: "url(https://fonts.gstatic.com/s/cantarell/v10/B50NF7ZDq37KMUvlO015jKJrPqySLQ.woff2)",
                                    weight: "500",
                                },
                            ],
                        }}
                    >
                        <IntlProvider locale="en">
                            <RouterWithErrorBoundary />
                            <ToastContainer limit={1} />
                        </IntlProvider>
                    </Elements>
                </Layouts>
            </Authentication>
        </BrowserRouter>
    );
}

export default App;
