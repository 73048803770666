import { Fragment, useEffect, useState } from "react";
import CustomCheckbox from "../../custom-checkbox";
import {
    AiOutlineInfoCircle,
    AiOutlineMinusSquare,
    AiOutlinePlusSquare,
} from "react-icons/ai";
import { FormattedNumber } from "react-intl";
import "../menu.scss";
import CustomButton from "../../custom-button";
import { connect } from "react-redux";
import {
    addItemsToCart,
    updateItemFromCart,
} from "../../../store/actions/ProductActions";
import { useParams } from "react-router-dom";
import CustomModal from "../../custom-modal";
import {
    calculateRemainingQty,
    checkPrePaidDisable,
    notify,
    observerShowDescription,
} from "../../../common/utils";
import constants from "../../../constants/constants";
import AllergensModal from "./AllergensModal";
import AttributesModal from "./AttributesModal";

const ItemBanner = (props) => (
    <div className="item-banner">
        <div className="item-banner-heading">{props.heading}</div>
        <div className="item-banner-sub-heading">{props.subHeading}</div>
    </div>
);

const ModifierWithoutPrice = ({
    modifier,
    backgroundColor,
    isLast,
    handleSelection,
    handleMinusClick,
    handlePlusClick,
    type = "box",
    groupMaxSelection,
}) => (
    <div className="item-row free-modifier">
        <div className="checkbox-price">
            <div>
                <CustomCheckbox
                    label={modifier.displayName}
                    isChecked={modifier.isSelected}
                    handleClick={handleSelection}
                    type={type}
                />
                <div
                    className={`calories ${
                        modifier.isSelected ? "selected" : ""
                    }`}
                    style={{ height: "14px" }}
                >
                    {modifier.kcal ? modifier.kcal + " Kcal" : ""}
                </div>
            </div>
            {!(groupMaxSelection === 1 && modifier.maximumQty === 1) &&
                modifier.isSelected && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {Boolean(modifier.maximumQty) && (
                            <span
                                style={{
                                    fontSize: "1.2rem",
                                    paddingRight: "5px",
                                }}
                            >
                                Max. Qty {modifier.maximumQty}
                            </span>
                        )}
                        {Boolean(modifier.quantity) && (
                            <AiOutlineMinusSquare
                                color={backgroundColor}
                                size={40}
                                onClick={handleMinusClick}
                            />
                        )}
                        {Boolean(modifier.quantity) && (
                            <span className="modifier-option-quantity">
                                {modifier.quantity}
                            </span>
                        )}
                        <AiOutlinePlusSquare
                            color={backgroundColor}
                            size={40}
                            onClick={handlePlusClick}
                        />
                    </div>
                )}
        </div>
    </div>
);

const ModifierWithPrice = ({
    modifier,
    backgroundColor,
    isLast,
    handleSelection,
    handleMinusClick,
    handlePlusClick,
    currency,
    type = "box",
    groupMaxSelection,
    menuPayType,
}) => (
    <div className="item-row">
        <div className="checkbox-price">
            <CustomCheckbox
                label={modifier.displayName}
                isChecked={modifier.isSelected}
                handleClick={handleSelection}
                type={type}
            />
            {menuPayType !== constants.MENU_PAY_TYPE.PREPAID ? (
                <span>
                    +
                    <FormattedNumber
                        style={`currency`}
                        currencyDisplay="narrowSymbol"
                        value={modifier.price}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        currency={currency || "gbp"}
                    />
                    {" each"}
                </span>
            ) : null}
        </div>
        <div className="modifier-footer">
            <div className="calories" style={{ height: "14px" }}>
                {modifier.kcal ? modifier.kcal + " Kcal" : ""}
            </div>
            {!(groupMaxSelection === 1 && modifier.maximumQty === 1) &&
                modifier.isSelected && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {Boolean(modifier.maximumQty) && (
                            <span
                                style={{
                                    fontSize: "1.2rem",
                                    paddingRight: "5px",
                                }}
                            >
                                Max. Qty {modifier.maximumQty}
                            </span>
                        )}
                        <AiOutlineMinusSquare
                            color={backgroundColor}
                            size={40}
                            onClick={handleMinusClick}
                        />
                        <span className="modifier-option-quantity">
                            {modifier.quantity}
                        </span>
                        <AiOutlinePlusSquare
                            color={backgroundColor}
                            size={40}
                            onClick={handlePlusClick}
                        />
                    </div>
                )}
        </div>
    </div>
);

const getSubHeading = (modifierGroup) => {
    let subHeading = modifierGroup.isRequired ? "Required - " : "";
    if (
        modifierGroup.selectionType === "Multiple" ||
        modifierGroup.selectionType === "Single"
    ) {
        subHeading += "Choose up to ";
        subHeading += modifierGroup.maximumSelect
            ? modifierGroup.maximumSelect
            : 1;
    } else {
        subHeading += "Unlimited Selection";
    }
    return subHeading;
};

const getMaxSelection = (modifierGroup) => {
    if (modifierGroup.selectionType === "Unlimited") {
        return 999;
    } else {
        return modifierGroup.maximumSelect ? modifierGroup.maximumSelect : 1;
    }
};

function ItemModifierModal(props) {
    const {
        outletData,
        enableModifiers,
        cartLoading,
        prepaidMaxProduct,
        menuPayType,
        basket,
    } = props;
    const [specialInstructions, setSpecialInstructions] = useState(
        props.itemData.specialInstructions || "",
    );
    const [itemData, setItemData] = useState(props.itemData);
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState(false);
    const { siteId } = useParams();
    const [visible, setVisible] = useState(false);
    const bodyStyles = document.body.style;
    const backgroundColor = bodyStyles.getPropertyValue(
        "--theme-background-color",
    );
    // siteId ? props.siteData.primaryColor : props.outletData.primaryColor ? props.outletData.primaryColor : "black";
    const [showCustomAttributeModal, setShowCustomAttributeModal] =
        useState(false);
    const [noteError, setNoteError] = useState("");

    useEffect(() => {
        if (enableModifiers) {
            let newErrors = {};
            if (props.itemData.variants.length) {
                newErrors = { variant: "Please select a variant" };
            }
            props.itemData.assignedModifiers.forEach((group) => {
                if (group.isRequired) {
                    newErrors[group._id] =
                        "Please select atleast one " + group.displayName;
                }
            });
            setErrors(newErrors);
            calculateSubTotal({
                ...props.itemData,
                quantity: props.itemData.quantity ? props.itemData.quantity : 1,
            });
        }
    }, [props.itemData, enableModifiers]);

    useEffect(() => {
        observerShowDescription();
    }, []);

    const handleVariableSelection = (variantIndex) => {
        let variantPrice = itemData.itemPrice;
        calculateSubTotal({
            ...itemData,
            variants: itemData.variants.map((variant, ind) => {
                if (ind === variantIndex) {
                    variantPrice = variant.variantSellingPrice;
                    return { ...variant, isSelected: true };
                } else {
                    return { ...variant, isSelected: false };
                }
            }),
            itemPrice: variantPrice,
        });
        if (errors["variant"]) {
            let newErrors = { ...errors };
            delete newErrors["variant"];
            setErrors(newErrors);
        }
    };

    const handleSingleSelection = (modifierIndex, groupIndex) => {
        let newGroup = itemData.assignedModifiers[groupIndex];
        newGroup.currentSelection = 1;

        newGroup.modifiers = newGroup.modifiers.map((mod, index) => {
            if (index === modifierIndex) {
                if (newGroup.feeType === "Paid") {
                    newGroup.modifierSubTotal =
                        newGroup.modifiers[modifierIndex].price;
                }
                if (errors[newGroup._id]) {
                    let newErrors = { ...errors };
                    delete newErrors[newGroup._id];
                    setErrors(newErrors);
                }
                return {
                    ...mod,
                    isSelected: true,
                    quantity: 1,
                };
            } else {
                return {
                    ...mod,
                    isSelected: false,
                    quantity: 0,
                };
            }
        });
        calculateSubTotal({
            ...itemData,
            assignedModifiers: itemData.assignedModifiers.map((mod, ind) => {
                if (ind === groupIndex) {
                    return newGroup;
                } else {
                    return mod;
                }
            }),
        });
    };

    const handleSingleUnrequiredSelection = (modifierIndex, groupIndex) => {
        let newGroup = itemData.assignedModifiers[groupIndex];

        if (newGroup.modifiers[modifierIndex].isSelected === true) {
            newGroup.modifiers[modifierIndex].isSelected = false;
            if (newGroup.feeType === "Paid") {
                newGroup.modifierSubTotal -=
                    newGroup.modifiers[modifierIndex].price *
                    newGroup.modifiers[modifierIndex].quantity;
            }
            newGroup.currentSelection = 0;
        } else {
            if (newGroup.currentSelection === 1) {
                newGroup.modifiers = newGroup.modifiers.map((mod, index) => {
                    if (index === modifierIndex) {
                        if (newGroup.feeType === "Paid") {
                            newGroup.modifierSubTotal =
                                newGroup.modifiers[modifierIndex].price;
                        }
                        return {
                            ...mod,
                            isSelected: true,
                            quantity: 1,
                        };
                    } else {
                        return {
                            ...mod,
                            isSelected: false,
                            quantity: 0,
                        };
                    }
                });
            } else {
                newGroup.modifiers[modifierIndex].isSelected = true;
                newGroup.modifiers[modifierIndex].quantity = 1;
                if (newGroup.feeType === "Paid") {
                    newGroup.modifierSubTotal =
                        newGroup.modifiers[modifierIndex].price;
                }
                newGroup.currentSelection = 1;
            }
        }
        calculateSubTotal({
            ...itemData,
            assignedModifiers: itemData.assignedModifiers.map((mod, ind) => {
                if (ind === groupIndex) {
                    return newGroup;
                } else {
                    return mod;
                }
            }),
        });
    };

    const handleMultipleSelection = (
        modifierIndex,
        groupIndex,
        maxSelection,
    ) => {
        let newGroup = itemData.assignedModifiers[groupIndex];
        if (maxSelection === 1) {
            handleSingleUnrequiredSelection(modifierIndex, groupIndex);
            return;
        }

        if (newGroup.modifiers[modifierIndex].isSelected === true) {
            newGroup.modifiers[modifierIndex].isSelected = false;
            if (newGroup.feeType === "Paid") {
                newGroup.modifierSubTotal -=
                    newGroup.modifiers[modifierIndex].price *
                    newGroup.modifiers[modifierIndex].quantity;
            }
            newGroup.currentSelection -= 1;
            if (newGroup.isRequired && newGroup.currentSelection === 0) {
                let newErrors = { ...errors };
                newErrors[newGroup._id] =
                    "Please select atleast one " + newGroup.displayName;
                setErrors(newErrors);
            }
        } else {
            if (newGroup.currentSelection === maxSelection) {
                return;
            } else {
                newGroup.modifiers[modifierIndex].isSelected = true;
                newGroup.modifiers[modifierIndex].quantity = 1;
                if (newGroup.feeType === "Paid") {
                    if (newGroup.modifierSubTotal) {
                        newGroup.modifierSubTotal +=
                            newGroup.modifiers[modifierIndex].price;
                    } else {
                        newGroup.modifierSubTotal =
                            newGroup.modifiers[modifierIndex].price;
                    }
                }
                newGroup.currentSelection = newGroup.currentSelection
                    ? newGroup.currentSelection + 1
                    : 1;
                if (errors[newGroup._id]) {
                    let newErrors = { ...errors };
                    delete newErrors[newGroup._id];
                    setErrors(newErrors);
                }
            }
        }
        calculateSubTotal({
            ...itemData,
            assignedModifiers: itemData.assignedModifiers.map((mod, ind) => {
                if (ind === groupIndex) {
                    return newGroup;
                } else {
                    return mod;
                }
            }),
        });
    };

    function handlePlusClick(modifierIndex, groupIndex, maxSelection) {
        calculateSubTotal({
            ...itemData,
            assignedModifiers: itemData.assignedModifiers.map((mod, ind) => {
                if (ind === groupIndex) {
                    const modifierSubTotal =
                        mod.feeType === "Paid" &&
                        (mod.modifiers[modifierIndex].quantity === 0 ||
                            mod.modifiers[modifierIndex].quantity <
                                maxSelection)
                            ? mod.modifiers[modifierIndex].price
                            : 0;
                    return {
                        ...mod,
                        modifiers: mod.modifiers.map((item, index) => {
                            if (index === modifierIndex) {
                                return {
                                    ...item,
                                    quantity: item.quantity
                                        ? item.quantity < maxSelection
                                            ? item.quantity + 1
                                            : item.quantity
                                        : 1,
                                };
                            } else {
                                return item;
                            }
                        }),
                        modifierSubTotal: (mod.modifierSubTotal +=
                            modifierSubTotal),
                    };
                } else {
                    return mod;
                }
            }),
        });
    }

    function handleMinusClick(modifierIndex, groupIndex) {
        calculateSubTotal({
            ...itemData,
            assignedModifiers: itemData.assignedModifiers.map((mod, ind) => {
                if (ind === groupIndex) {
                    const modifierSubTotal =
                        mod.feeType === "Paid" &&
                        mod.modifiers[modifierIndex].quantity > 1
                            ? mod.modifiers[modifierIndex].price
                            : 0;
                    return {
                        ...mod,
                        modifiers: mod.modifiers.map((item, index) => {
                            if (index === modifierIndex) {
                                return {
                                    ...item,
                                    quantity:
                                        item.quantity > 1
                                            ? item.quantity - 1
                                            : 1,
                                };
                            } else {
                                return item;
                            }
                        }),
                        modifierSubTotal: (mod.modifierSubTotal -=
                            modifierSubTotal),
                    };
                } else {
                    return mod;
                }
            }),
        });
    }

    const handleAddBasket = async () => {
        if (Object.keys(errors).length) {
            setShowErrors(true);
        } else if (cartLoading !== true) {
            if (props.isEdit) {
                props
                    .updateItemFromCart(props.itemIndex, {
                        ...itemData,
                        specialInstructions: specialInstructions,
                    })
                    .then(() => {
                        props.closeModal();
                    })
                    .catch((err) => {
                        notify(err.message);
                    });
            } else {
                props
                    .addItemsToCart({
                        ...itemData,
                        specialInstructions: specialInstructions,
                    })
                    .then(() => {
                        props.closeModal();
                    })
                    .catch((err) => {
                        notify(err.message);
                    });
            }
        }
    };

    const calculateSubTotal = (item) => {
        let price = item.itemPrice;
        item.assignedModifiers.forEach((group) => {
            price += group.modifierSubTotal ? group.modifierSubTotal : 0;
        });
        groupModifiers(item);
        setItemData({ ...item, price, subTotal: price * item.quantity });
    };

    const groupModifiers = (item) => {
        item.modifiers = [];
        for (let i = 0; i < item.assignedModifiers.length; i++) {
            let curr = 0;
            for (
                let j = 0;
                j < item.assignedModifiers[i].modifiers.length;
                j++
            ) {
                if (item.assignedModifiers[i].modifiers[j].isSelected) {
                    item.modifiers.push({
                        ...item.assignedModifiers[i].modifiers[j],
                        price:
                            item.assignedModifiers[i].feeType === "Free"
                                ? 0
                                : item.assignedModifiers[i].modifiers[j].price,
                        modifierGroup: {
                            modifierGroupRef:
                                item.assignedModifiers[i].modifierGroupId,
                            displayName: item.assignedModifiers[i].displayName,
                            selectionType:
                                item.assignedModifiers[i].selectionType || "",
                            maximumSelect:
                                item.assignedModifiers[i].maximumSelect || "",
                            feeType: item.assignedModifiers[i].feeType || "",
                        },
                    });
                    curr += 1;
                }
                if (curr === item.assignedModifiers[i].currentSelection) {
                    break;
                }
            }
        }
    }

    const handleAddQty = () => {
        if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
            let basketItems =
                basket?.carts && basket?.carts.length > 0
                    ? basket?.carts[0].items
                    : [];
            basketItems = props.isEdit
                ? basketItems.filter((i) => i.cartSKU !== itemData.cartSKU)
                : basketItems;
            if (
                !checkPrePaidDisable(
                    calculateRemainingQty(prepaidMaxProduct, basketItems) -
                        (itemData.quantity + 1),
                    menuPayType,
                )
            ) {
                setItemData({
                    ...itemData,
                    quantity: itemData.quantity + 1,
                    subTotal: itemData.price * (itemData.quantity + 1),
                });
            }
        } else {
            setItemData({
                ...itemData,
                quantity: itemData.quantity + 1,
                subTotal: itemData.price * (itemData.quantity + 1),
            });
        }
    };
    let prepaidDisabled = false,
        buttonPrepaidDisabled = false;
    if (menuPayType === constants.MENU_PAY_TYPE.PREPAID) {
        let basketItems =
            basket?.carts && basket?.carts.length > 0
                ? basket?.carts[0].items
                : [];
        if (props.isEdit) {
            basketItems = basketItems.filter(
                (i) => i.cartSKU !== itemData.cartSKU,
            );
            prepaidDisabled = checkPrePaidDisable(
                calculateRemainingQty(prepaidMaxProduct, basketItems) -
                    (itemData.quantity + 1),
                menuPayType,
            );
            buttonPrepaidDisabled = checkPrePaidDisable(
                calculateRemainingQty(prepaidMaxProduct, basketItems) -
                    itemData.quantity,
                menuPayType,
            );
        } else {
            prepaidDisabled = checkPrePaidDisable(
                calculateRemainingQty(prepaidMaxProduct, basketItems) - 1,
                menuPayType,
            );
            buttonPrepaidDisabled = checkPrePaidDisable(
                calculateRemainingQty(prepaidMaxProduct, basketItems) - 1,
                menuPayType,
            );
        }
    }

    const description = itemData.description;
    return (
        <div className="modifier-container">
            <div className="modifier-header-extended">
                <div className="box-container modifier-quantity-price">
                    <div className="modifier-quantity">
                        <span style={{ paddingRight: "20px" }}>Quantity</span>
                        <AiOutlineMinusSquare
                            color={backgroundColor}
                            size={40}
                            onClick={() => {
                                itemData.quantity > 1 &&
                                    setItemData({
                                        ...itemData,
                                        quantity: itemData.quantity - 1,
                                        subTotal:
                                            itemData.price *
                                            (itemData.quantity - 1),
                                    });
                            }}
                        />
                        <span className="modifier-option-quantity">
                            {itemData.quantity}
                        </span>
                        <AiOutlinePlusSquare
                            color={backgroundColor}
                            size={40}
                            onClick={() => handleAddQty()}
                            className={`${prepaidDisabled ? "disabled" : ""}`}
                        />
                    </div>
                    {menuPayType !== constants.MENU_PAY_TYPE.PREPAID ? (
                        <div className="item-modifier-selling-price">
                            <FormattedNumber
                                style={`currency`}
                                currencyDisplay="narrowSymbol"
                                value={itemData.itemPrice}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                currency={outletData.currency || "gbp"}
                            />
                        </div>
                    ) : null}
                </div>
                <div className="modifier-separator"></div>
            </div>
            <div className="modifier-list-container">
                {description && (
                    <>
                        <div className="box-container modifier-description">
                            <p
                                className={`m-0 ${
                                    visible ? "remove-line-camp" : ""
                                }`}
                            >
                                <pre>{description}</pre>
                            </p>
                            <label
                                className={`${visible ? "show-view-more" : ""}`}
                                onClick={() => setVisible(!visible)}
                            >
                                {" "}
                                {visible ? "View Less" : "View More"}
                            </label>
                        </div>
                    </>
                )}
                {((itemData.customAttributes &&
                    itemData.customAttributes.length > 0) ||
                    (itemData.nutritions &&
                        Object.values(itemData.nutritions).some(
                            (val) => val !== 0,
                        ))) && (
                    <div className="box-container">
                        <div
                            className="custom-attribute-details"
                            onClick={() => setShowCustomAttributeModal(true)}
                        >
                            <AiOutlineInfoCircle />
                            Click for Item details
                        </div>
                    </div>
                )}
                {Boolean(itemData.variants.length) && (
                    <>
                        <ItemBanner
                            heading="Select Variant"
                            subHeading="Required"
                        />
                        <div className="varient-wrapper">
                            {itemData.variants.map((variant, index) => {
                                return (
                                    <div key={index} className="item-row">
                                        <div className="checkbox-price">
                                            <CustomCheckbox
                                                label={variant.variantName}
                                                isChecked={variant.isSelected}
                                                handleClick={() =>
                                                    handleVariableSelection(
                                                        index,
                                                    )
                                                }
                                                type="circle"
                                            />
                                            {menuPayType !==
                                            constants.MENU_PAY_TYPE.PREPAID ? (
                                                <FormattedNumber
                                                    style={`currency`}
                                                    currencyDisplay="narrowSymbol"
                                                    value={
                                                        variant.variantSellingPrice
                                                    }
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                    currency={
                                                        outletData.currency ||
                                                        "gbp"
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                        <div className="calories"></div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
                {Boolean(itemData.assignedModifiers.length) &&
                    itemData.assignedModifiers.map((modifierGroup, index) => {
                        const groupIndex = index;
                        return (
                            <Fragment key={index}>
                                <ItemBanner
                                    heading={
                                        "Choose " + modifierGroup.displayName
                                    }
                                    subHeading={getSubHeading(modifierGroup)}
                                />
                                <div className="varient-wrapper">
                                    {modifierGroup.modifiers.map(
                                        (opt, index) => {
                                            if (
                                                modifierGroup.feeType === "Free"
                                            ) {
                                                return (
                                                    <ModifierWithoutPrice
                                                        key={index}
                                                        modifier={opt}
                                                        backgroundColor={
                                                            backgroundColor
                                                        }
                                                        type={
                                                            modifierGroup.isRequired &&
                                                            getMaxSelection(
                                                                modifierGroup,
                                                            ) === 1
                                                                ? "circle"
                                                                : "box"
                                                        }
                                                        isLast={
                                                            index ===
                                                            modifierGroup
                                                                .modifiers
                                                                .length -
                                                                1
                                                        }
                                                        handleSelection={() =>
                                                            modifierGroup.isRequired &&
                                                            getMaxSelection(
                                                                modifierGroup,
                                                            ) === 1
                                                                ? handleSingleSelection(
                                                                      index,
                                                                      groupIndex,
                                                                  )
                                                                : handleMultipleSelection(
                                                                      index,
                                                                      groupIndex,
                                                                      getMaxSelection(
                                                                          modifierGroup,
                                                                      ),
                                                                  )
                                                        }
                                                        handleMinusClick={() =>
                                                            handleMinusClick(
                                                                index,
                                                                groupIndex,
                                                            )
                                                        }
                                                        handlePlusClick={() =>
                                                            handlePlusClick(
                                                                index,
                                                                groupIndex,
                                                                opt.maximumQty
                                                                    ? opt.maximumQty
                                                                    : 999,
                                                            )
                                                        }
                                                        groupMaxSelection={getMaxSelection(
                                                            modifierGroup,
                                                        )}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <ModifierWithPrice
                                                        key={index}
                                                        modifier={opt}
                                                        currency={
                                                            outletData.currency
                                                        }
                                                        backgroundColor={
                                                            backgroundColor
                                                        }
                                                        type={
                                                            modifierGroup.isRequired &&
                                                            getMaxSelection(
                                                                modifierGroup,
                                                            ) === 1
                                                                ? "circle"
                                                                : "box"
                                                        }
                                                        isLast={
                                                            index ===
                                                            modifierGroup
                                                                .modifiers
                                                                .length -
                                                                1
                                                        }
                                                        handleSelection={() =>
                                                            modifierGroup.isRequired &&
                                                            getMaxSelection(
                                                                modifierGroup,
                                                            ) === 1
                                                                ? handleSingleSelection(
                                                                      index,
                                                                      groupIndex,
                                                                  )
                                                                : handleMultipleSelection(
                                                                      index,
                                                                      groupIndex,
                                                                      getMaxSelection(
                                                                          modifierGroup,
                                                                      ),
                                                                  )
                                                        }
                                                        handleMinusClick={() =>
                                                            handleMinusClick(
                                                                index,
                                                                groupIndex,
                                                            )
                                                        }
                                                        handlePlusClick={() =>
                                                            handlePlusClick(
                                                                index,
                                                                groupIndex,
                                                                opt.maximumQty
                                                                    ? opt.maximumQty
                                                                    : 999,
                                                            )
                                                        }
                                                        groupMaxSelection={getMaxSelection(
                                                            modifierGroup,
                                                        )}
                                                        menuPayType={
                                                            menuPayType
                                                        }
                                                    />
                                                );
                                            }
                                        },
                                    )}
                                </div>
                            </Fragment>
                        );
                    })}
                <div className="add-note-container box-container">
                    {(description ||
                        (itemData.allergens && itemData.allergens.length > 0) ||
                        itemData.kiloCalories ||
                        (itemData.diet && itemData.diet.length > 0) ||
                        Boolean(itemData.variants.length) ||
                        Boolean(itemData.assignedModifiers.length)) && (
                        <div className="modifier-separator"></div>
                    )}
                    <h3>Add Note</h3>
                    <div className="">
                        <input
                            className="item-add-note"
                            placeholder="Insert Note"
                            value={specialInstructions}
                            onChange={(e) => {
                                if (
                                    e.target.value &&
                                    e.target.value.length > 50
                                ) {
                                    setNoteError(
                                        "Note cannot exceed 50 characters.",
                                    );
                                } else {
                                    setNoteError("");
                                }
                                setSpecialInstructions(e.target.value);
                            }}
                        />
                    </div>
                    {noteError && (
                        <div className="text-red mt-5">{noteError}</div>
                    )}
                </div>
            </div>
            <div className="sticky-footer modifier-bottom-fixed">
                <div className="separator separator-theme"></div>
                <div className="box-container bottom-button">
                    <CustomButton
                        className="btn-block"
                        onClick={handleAddBasket}
                        loader={cartLoading}
                        disabled={
                            buttonPrepaidDisabled ||
                            cartLoading ||
                            (noteError && noteError.length)
                        }
                    >
                        <div className="add-to-basket-button">
                            <span>Add to Basket</span>
                            {menuPayType !== constants.MENU_PAY_TYPE.PREPAID ? (
                                <FormattedNumber
                                    style={`currency`}
                                    currencyDisplay="narrowSymbol"
                                    value={itemData.subTotal}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                    currency={outletData.currency || "gbp"}
                                />
                            ) : null}
                        </div>
                    </CustomButton>
                </div>
            </div>
            <CustomModal
                isOpen={showErrors && Boolean(Object.keys(errors).length)}
                onRequestClose={() => setShowErrors(false)}
                ariaHideApp={false}
                hideDivider={true}
                modalContainerClass={"modifier-validation-modal-container"}
            >
                <div className="box-container">
                    <ul className="modifier-validation-modal-list">
                        {Object.keys(errors).map((key) => (
                            <li
                                key={key}
                                className="modifier-validation-modal-item"
                            >
                                {" "}
                                {errors[key]}{" "}
                            </li>
                        ))}
                    </ul>
                    <div className="error-pop-up-button">
                        <CustomButton
                            className="btn-block"
                            onClick={() => setShowErrors(false)}
                        >
                            <span>Okay</span>
                        </CustomButton>
                    </div>
                </div>
            </CustomModal>
            <AttributesModal
                showAttributeModal={showCustomAttributeModal}
                setShowAttributeModal={setShowCustomAttributeModal}
                itemData={itemData}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        outletData: state.outletData,
        siteData: state.siteData,
        cartLoading: state.cartLoading,
        menuPayType: state.menuPayType,
        basket: state.cart,
        prepaidMaxProduct: state.prepaidMaxProduct,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addItemsToCart: (item) => dispatch(addItemsToCart(item)),
        updateItemFromCart: (index, item) =>
            dispatch(updateItemFromCart(index, item)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemModifierModal);
